import { useEffect, useRef, useState } from "react";
import {
  ButtonPrimario,
  ButtonSecundario,
  ButtonSmall,
  ButtonSmallSecundario,
  ComplementaryFlex,
  ContenedorBotones,
  ContenedorBotonesExterior,
  DivTitulos,
  DivTitulosSubTitulo,
  DivTitulosTitulo,
  Flex1,
  Flex1Inline,
  Flex3,
  Flex8,
  FlexInlineMarginTextLabel,
  FlexInlineTextLabel,
  FlexRow,
  FlexRowInline,
  InputMini,
  NormalText,
  TextLabelTitle,
} from "../../../communStyled";
import {
  Checkbox,
  ChevronTabIcon,
  ConfirmationModal,
  Dropdown,
  Modal,
  RegistroDeHorarios,
  RegistroDeSecuencia,
  SearchDropdown,
  SortingTable,
  Tabs,
  Warning,
} from "../../../../Components";
import { Subject } from "rxjs";
import {
  Contenedor,
  ContenedorBotonesExteriorHorarios,
  ContenedorBotonesHorarios,
  ContenedorBotonesLeft,
  ContenedorDeHorarios,
  ContenedorTabs,
  ContenedorTiempoExtraordinario,
  DuracionDeJornadaRow,
  DuracionDeJornadaRowLabel,
  FlexRowDuracionDeJornada,
} from "./HorarioYControlDeAsistenciaStyled";
import { useDispatch, useSelector } from "react-redux";
import { horarioCompleto, horarios } from "../../../../types";
import useViewPortSize from "../../../../Hooks/useViewPortSize";
import { ccHoursBlur, ccHoursChange, ccNumberLimit, scrollIntoView } from "../../../../Utils/sharedFunctions";
import { apiRequest, apiRoutes } from "../../../../Api/services";
import actions from "../../../../Redux/Actions";

export default function HorarioYControlDeAsistencia() {
  const alertRef = useRef(null);
  const dispatch = useDispatch();
  const { smallViewPort } = useViewPortSize();
  const sortingTableRef = useRef<any>(null);
  const empresa: any = useSelector((store: any) => store.empresa);
  const messageSubject = new Subject<string>();
  const successfulSubject = new Subject<string>();
  const warningSubject = new Subject<string>();
  const AlertSubject = new Subject<string>();
  const currentEmpresa: any = useSelector((store: any) => store.currentEmpresa);

  const [tabOpenTiempoExtraordinario, setTabOpenTiempoExtraordinario] =
    useState(true);
  const [activeTab, setActiveTab] = useState("HORARIOS");
  const [secuenciasActive, setSecuenciasActive] = useState(false);
  const [horariosArray, setHorariosArray] = useState([
    ...empresa.empresa.horariosYControlDeAsistencia.horarios,
  ]);
  const [secuenciasArray, setSecuenciasArray] = useState([
    ...empresa.empresa.horariosYControlDeAsistencia.secuencias,
  ]);
  const [activeSecuencias, setActiveSecuencias] = useState(false);

  const [rowsArrayCopy, setRowsArrayCopy] = useState([...horariosArray]);
  const [disabled, setDisabled] = useState(true);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [quienTieneEsteHorarioArray, setQuienTieneEsteHorarioArray] = useState(
    []
  );
  const [quienTieneEsteHorarioArrayCopy, setQuienTieneEsteHorarioArrayCopy] = useState(
    []
  );
  const [quienTieneEstaSecuenciaArray, setQuienTieneEstaSecuenciaArray] =
    useState([]);
  const [quienTieneEstaSecuenciaArrayCopy, setQuienTieneEstaSecuenciaArrayCopy] = useState(
    []
  );
  const [openModalRegistroDeHorario, setOpenModalRegistroDeHorario] =
    useState(false);
  const [openModalRegistroDeSecuencia, setOpenModalRegistroDeSecuencia] =
    useState(false);
  const [
    openModalConfirmationDeleteHorario,
    setOpenModalConfirmationDeleteHorario,
  ] = useState(false);
  const [openModalQuienTieneEsteHorario, setOpenModalQuienTieneEsteHorario] =
    useState(false);
  const [ocultarHorariosInactivos, setOcultarHorariosInactivos] =
    useState(true);
  const sortingTableRefSecuencias = useRef<any>(null);
  const [rowsArrayCopySecuencias, setRowsArrayCopySecuencias] = useState([
    ...secuenciasArray,
  ]);
  const [selectedValueSecuencias, setSelectedValueSecuencias] = useState<any>();
  const [
    openModalConfirmationDeleteSecuencias,
    setOpenModalConfirmationDeleteSecuencias,
  ] = useState(false);
  const [
    openModalQuienTieneEstaSecuencias,
    setOpenModalQuienTieneEstaSecuencias,
  ] = useState(false);
  const [ocultarSecuenciasInactivas, setOcultarSecuenciasInactivas] =
    useState(true);

  const [duracionDeLaJornada, setDuracionDeLaJornada] = useState(
    empresa.empresa.horariosYControlDeAsistencia.duracionDeLaJornada
  );

  const [controlDeAsistencia, setControlDeAsistencia] = useState(
    empresa.empresa.horariosYControlDeAsistencia.controlDeAsistencia
  );

  const [tiempoExtraordinario, setTiempoExtraordinario] = useState(
    empresa.empresa.horariosYControlDeAsistencia.tiempoExtraordinario
  );

  const [openModalRegenerarListasDeAsistencia, setOpenModalRegenerarListasDeAsistencia] = useState(false);

  useEffect(() => {
    setSecuenciasActive(false);
    if (activeSecuencias) {
      setSecuenciasActive(true);
    }
    if (rowsArrayCopy.length !== 0) {
      for (const item of rowsArrayCopy) {
        if (!item["inactivo"] && item["Tipo"] !== "Horario Especial") {
          setSecuenciasActive(true);
        }
      }
    }
  }, [rowsArrayCopy, activeSecuencias]);

  useEffect(() => {
    setHorariosArray([
      ...empresa.empresa.horariosYControlDeAsistencia.horarios,
    ]);
  }, [empresa.empresa.horariosYControlDeAsistencia.horarios]);

  useEffect(() => {
    setSecuenciasArray([
      ...empresa.empresa.horariosYControlDeAsistencia.secuencias,
    ]);
  }, [empresa.empresa.horariosYControlDeAsistencia.secuencias]);

  useEffect(() => {
    setRowsArrayCopy([...horariosArray.map((horario: any) => {
      return {
        ...horario,
        empleados: horario.inactivo ? [] : empresa.trabajadores.controlDePersonal.trabajadores.filter((trabajador: any) => {
          
          // Find the closest schedule to today that hasn't occurred yet
          const today = new Date();
          
          const closestToToday = trabajador.horario
            .filter((horarioFA: any) => horarioFA.fechaDeFinalizacion)
            .sort((a: any, b: any) => new Date(a.fechaDeFinalizacion).getTime() - new Date(b.fechaDeFinalizacion).getTime())
            .find((horarioFA: any) => new Date(horarioFA.fechaDeFinalizacion) > today);
          
          // Default to the schedule without fechaDeFinalizacion if no such schedule is found
          const result = closestToToday || trabajador.horario.find((horarioFA: any) => !horarioFA.fechaDeFinalizacion);
          
          return trabajador.info.status === "ACTIVO" && result && result.horario === horario.id;
        }) || []
      }
    })]);
  }, [horariosArray]);

  useEffect(() => {
    setRowsArrayCopySecuencias([...secuenciasArray.map((secuencia: any) => {
      return {
        ...secuencia,
        empleados: secuencia.inactivo ? [] : empresa.trabajadores.controlDePersonal.trabajadores.filter((trabajador: any) => {
          
          // Find the closest sequence to today that hasn't occurred yet
          const today = new Date();
          
          const closestToToday = trabajador.horario
            .filter((secuenciaFA: any) => secuenciaFA.fechaDeFinalizacion)
            .sort((a: any, b: any) => new Date(a.fechaDeFinalizacion).getTime() - new Date(b.fechaDeFinalizacion).getTime())
            .find((secuenciaFA: any) => new Date(secuenciaFA.fechaDeFinalizacion) > today);
          
          // Default to the sequence without fechaDeFinalizacion if no such sequence is found
          const result = closestToToday || trabajador.horario.find((secuenciaFA: any) => !secuenciaFA.fechaDeFinalizacion);
          
          return trabajador.info.status === "ACTIVO" && result && result.secuencia === secuencia.id;
        }) || []
      }
    }
    )]);
  }, [secuenciasArray]);

  const submitForm = async () => {
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.updateInfo,
      data: {
        area: "horariosYControlDeAsistencia",
        data: { 
          tiempoExtraordinario: tiempoExtraordinario,
          duracionDeLaJornada: duracionDeLaJornada,
          controlDeAsistencia: controlDeAsistencia,
          horarios: horariosArray,
          secuencias: secuenciasArray,
         },
        id: currentEmpresa.id,
        modulo: "empresa",
      },
    });
    if (res) {
      if (res.message !== "Proceed") {
        messageSubject.next(res.message);
      } else {
        successfulSubject.next(
          "La Informacion se agrego correctamente a la base de datos"
        );
        dispatch(
          actions.empresaActions.replaceEmpresa({
            ...empresa,
            empresa: {
              ...empresa.empresa,
              horariosYControlDeAsistencia: {
                tiempoExtraordinario: tiempoExtraordinario,
                duracionDeLaJornada: duracionDeLaJornada,
                controlDeAsistencia: controlDeAsistencia,
                horarios: horariosArray,
                secuencias: secuenciasArray,
              },
            },
          })
        );
      }
    }
    else if (error) {
      messageSubject.next("Hubo un error en el servidor");
    }
    scrollIntoView(alertRef);
  };

  const cancel = () => {
    setTiempoExtraordinario({
      pagarTiempoExtraordinario: empresa.empresa.horariosYControlDeAsistencia.tiempoExtraordinario.pagarTiempoExtraordinario,
      minutosDeMargenTiempoExtra: empresa.empresa.horariosYControlDeAsistencia.tiempoExtraordinario.minutosDeMargenTiempoExtra,
    });
    setDuracionDeLaJornada({
      diurna: empresa.empresa.horariosYControlDeAsistencia.duracionDeLaJornada.diurna,
      mixto: empresa.empresa.horariosYControlDeAsistencia.duracionDeLaJornada.mixto,
      nocturna: empresa.empresa.horariosYControlDeAsistencia.duracionDeLaJornada.nocturna,
    });
    setControlDeAsistencia({
      entrada: empresa.empresa.horariosYControlDeAsistencia.controlDeAsistencia.entrada,
      salida: empresa.empresa.horariosYControlDeAsistencia.controlDeAsistencia.salida,
      llenadoDeAsistencia: empresa.empresa.horariosYControlDeAsistencia.controlDeAsistencia.llenadoDeAsistencia,
    });
    setHorariosArray([
      ...empresa.empresa.horariosYControlDeAsistencia.horarios,
    ]);
    setSecuenciasArray([
      ...empresa.empresa.horariosYControlDeAsistencia.secuencias,
    ]);
  };

  const searchDropDownArray = [
    { label: "Clave", valueLabel: "clave" },
    { label: "Descripcion", valueLabel: "descripcion" },
  ];

  const searchDropDownArraySecuencias = [
    { label: "Clave", valueLabel: "clave" },
    { label: "Descripcion", valueLabel: "descripcion" },
  ];

  const quienTieneEsteHorarioOptions = [
    { label: "No. Nomina", valueLabel: "numeroDeNomina", filtrable: true, widthPX: 195,type: "String" },
    { label: "Apellido Paterno", valueLabel: "apellidoPaterno", filtrable: true, widthPX: 195,type: "String" },
    { label: "Apellido Materno", valueLabel: "apellidoMaterno", filtrable: true, widthPX: 195,type: "String" },
    { label: "Nombre", valueLabel: "nombre", filtrable: true, widthPX: 195, type: "String" },
  ];

  const columnsArray = [
    {
      label: "Clave",
      valueLabel: "clave",
      filtrable: true,
      widthPX: 195,
      type: "String",
    },
    {
      label: "Descripcion",
      valueLabel: "descripcion",
      filtrable: true,
      widthPX: 215,
      type: "String",
    },
    {
      label: "No.De empleados",
      valueLabel: "empleados",
      filtrable: true,
      widthPX: 215,
      type: "Array",
    },
  ];

  const columnsArraySecuencias = [
    {
      label: "Clave",
      valueLabel: "clave",
      filtrable: true,
      widthPX: 195,
      type: "String",
    },
    {
      label: "Descripcion",
      valueLabel: "descripcion",
      filtrable: true,
      widthPX: 215,
      type: "String",
    },
    {
      label: "No.De empleados",
      valueLabel: "empleados",
      filtrable: true,
      widthPX: 215,
      type: "Array",
    },
  ];

  const onValueSearchDropDown = (value: any) => {
    if (value.searchValue !== "") {
      setRowsArrayCopy(
        horariosArray.filter((row) => {
          return row[value.filter.valueLabel]
            ?.toString()
            .toUpperCase()
            .includes(value.searchValue?.toString().toUpperCase());
        })
      );
    } else {
      setRowsArrayCopy([...horariosArray]);
    }
  };

  const onValueSearchDropDownSecuencias = (value: any) => {
    if (value.searchValue !== "") {
      setRowsArrayCopySecuencias(
        secuenciasArray.filter((row) => {
          return row[value.filter.valueLabel]
            .toString()
            .toUpperCase()
            .includes(value.searchValue.toString().toUpperCase());
        })
      );
    } else {
      setRowsArrayCopySecuencias([...secuenciasArray]);
    }
  };

  const onValueQuienTieneEsteHorario = (value: any) => {
    if (value.searchValue !== "") {
      setQuienTieneEsteHorarioArrayCopy(
        quienTieneEsteHorarioArray.filter((row: any) => {
          return row[value.filter.valueLabel]
            .toString()
            .toUpperCase()
            .includes(value.searchValue.toString().toUpperCase());
        })
      );
    } else {
      setQuienTieneEsteHorarioArrayCopy([...quienTieneEsteHorarioArray]);
    }
  }

  useEffect(() => {
    setQuienTieneEsteHorarioArrayCopy([...quienTieneEsteHorarioArray]);
  }, [quienTieneEsteHorarioArray]);

  const onValueQuienTieneEstaSecuencia = (value: any) => {
    if (value.searchValue !== "") {
      setQuienTieneEstaSecuenciaArrayCopy(
        quienTieneEstaSecuenciaArray.filter((row: any) => {
          return row[value.filter.valueLabel]
            .toString()
            .toUpperCase()
            .includes(value.searchValue.toString().toUpperCase());
        })
      );
    } else {
      setQuienTieneEstaSecuenciaArrayCopy([...quienTieneEstaSecuenciaArray]);
    }
  }

  useEffect(() => {
    setQuienTieneEstaSecuenciaArrayCopy([...quienTieneEstaSecuenciaArray]);
  }, [quienTieneEstaSecuenciaArray]);

  const onValueSelected = (value: any) => {
    setDisabled(!value);
    setSelectedValue(value);
    const quienTieneEsteHorarioArrayToUse = value
      ? value.empleados.map(
          (empleado: {
            datosPersonales: {
              datosPersonales: {
                nombre: any;
                apellidoPaterno: any;
                apellidoMaterno: any;
              };
            };
            info: { numeroDeNomina: any; status: string };
          }) => {
            return {
              nombre: empleado.datosPersonales.datosPersonales.nombre,
              apellidoPaterno:
                empleado.datosPersonales.datosPersonales.apellidoPaterno,
              apellidoMaterno:
                empleado.datosPersonales.datosPersonales.apellidoMaterno,
                numeroDeNomina: empleado.info.numeroDeNomina,
              inactivo: empleado.info.status !== "ACTIVO",
            };
          }
        )
      : [];
    setQuienTieneEsteHorarioArray(quienTieneEsteHorarioArrayToUse);
  };

  const onValueSelectedSecuencias = (value: any) => {
    setDisabled(!value);
    setSelectedValueSecuencias(value);
    const quienTieneEstaSecuenciaArrayToUse =
      value && value.empleados.length > 0
        ? value.empleados.map(
            (empleado: {
              datosPersonales: {
                datosPersonales: {
                  nombre: any;
                  apellidoPaterno: any;
                  apellidoMaterno: any;
                };
              };
              info: { numeroDeNomina: any; status: string };
            }) => {
              return {
                nombre: empleado.datosPersonales.datosPersonales.nombre,
                apellidoPaterno:
                  empleado.datosPersonales.datosPersonales.apellidoPaterno,
                apellidoMaterno:
                  empleado.datosPersonales.datosPersonales.apellidoMaterno,
                  numeroDeNomina: empleado.info.numeroDeNomina,
                inactivo: empleado.info.status !== "ACTIVO",
              };
            }
          )
        : [];
    setQuienTieneEstaSecuenciaArray(quienTieneEstaSecuenciaArrayToUse);
  };

  const onHorariosChange = async (newHorario: horarioCompleto) => {
    if (newHorario) {
      const index = rowsArrayCopy.findIndex(
        (horario) =>
          horario.id === newHorario.id &&
          horario.copyNumber === newHorario.copyNumber
      );
      if (index !== -1) {
        const empleados = [...rowsArrayCopy[index].empleados];
        rowsArrayCopy[index] = {
          ...rowsArrayCopy[index],
          empleados: [],
          inactivo: true,
        };
        rowsArrayCopy.push({
          ...newHorario,
          empleados: empleados,
          copyNumber: newHorario.copyNumber + 1,
        });
        empleados.length > 0 && setOpenModalRegenerarListasDeAsistencia(true);
      } else {
        rowsArrayCopy.push(newHorario);
      }
      setHorariosArray([...rowsArrayCopy]);
      const [error, res] = await apiRequest({
        method: "post",
        url: apiRoutes.updateInfo,
        data: {
          area: "horariosYControlDeAsistencia",
          data: { 
            ...empresa.empresa.horariosYControlDeAsistencia,
            horarios: rowsArrayCopy,
           },
          id: currentEmpresa.id,
          modulo: "empresa",
        },
      });
      if (res) {
        if (res.message !== "Proceed") {
          messageSubject.next(res.message);
        } else {
          successfulSubject.next(
            "La Informacion se agrego correctamente a la base de datos"
          );
          dispatch(
            actions.empresaActions.replaceEmpresa({
              ...empresa,
              empresa: {
                ...empresa.empresa,
                horariosYControlDeAsistencia: {
                  ...empresa.empresa.horariosYControlDeAsistencia,
            horarios: rowsArrayCopy,
                },
              },
            })
          );
        }
      }
      else if (error) {
        messageSubject.next("Hubo un error en el servidor");
      }
    }
    setOpenModalRegistroDeHorario(false);
  };

  const regenerarListasDeAsistencia = () => {
    setOpenModalRegenerarListasDeAsistencia(false);
    //TODO: regenerar listas de asistencia
  };

  const onSecuenciaChange = async (newSecuencia: any) => {
    if (newSecuencia) {
      const index = rowsArrayCopySecuencias.findIndex(
        (secuencia) =>
          secuencia.id === newSecuencia.id &&
          secuencia.copyNumber === newSecuencia.copyNumber
      );
      if (index !== -1) {
        const empleados = [...rowsArrayCopy[index].empleados];
        rowsArrayCopySecuencias[index] = {
          ...rowsArrayCopySecuencias[index],
          empleados: [],
          inactivo: true,
        };
        rowsArrayCopySecuencias.push({
          ...newSecuencia,
          empleados: empleados,
          copyNumber: newSecuencia.copyNumber + 1,
        });
        empleados.length > 0 && setOpenModalRegenerarListasDeAsistencia(true);
      } else {
        rowsArrayCopySecuencias.push(newSecuencia);
      }
      setSecuenciasArray([...rowsArrayCopySecuencias]);
      const [error, res] = await apiRequest({
        method: "post",
        url: apiRoutes.updateInfo,
        data: {
          area: "horariosYControlDeAsistencia",
          data: { 
            ...empresa.empresa.horariosYControlDeAsistencia,
            secuencias: rowsArrayCopySecuencias,
           },
          id: currentEmpresa.id,
          modulo: "empresa",
        },
      });
      if (res) {
        if (res.message !== "Proceed") {
          messageSubject.next(res.message);
        } else {
          successfulSubject.next(
            "La Informacion se agrego correctamente a la base de datos"
          );
          dispatch(
            actions.empresaActions.replaceEmpresa({
              ...empresa,
              empresa: {
                ...empresa.empresa,
                horariosYControlDeAsistencia: {
                  ...empresa.empresa.horariosYControlDeAsistencia,
                  secuencias: rowsArrayCopySecuencias,
                },
              },
            })
          );
        }
      }
      else if (error) {
        messageSubject.next("Hubo un error en el servidor");
      }
    }
    setOpenModalRegistroDeHorario(false);
  };

  const eliminarHorario = async () => {
    if (selectedValue) {
      const index = rowsArrayCopy.findIndex(
        (horario) =>
          horario.id === selectedValue.id &&
          horario.copyNumber === selectedValue.copyNumber
      );
      if (index !== -1 && selectedValue.empleados.length === 0) {
        rowsArrayCopy[index] = {
          ...rowsArrayCopy[index],
          empleados: [],
          inactivo: true,
        };
        setRowsArrayCopy([...rowsArrayCopy]);
        setSelectedValue(null);
        setOpenModalConfirmationDeleteHorario(false);
        const [error, res] = await  apiRequest({
          method: "post",
          url: apiRoutes.updateInfo,
          data: {
            area: "horariosYControlDeAsistencia",
            data: { 
              ...empresa.empresa.horariosYControlDeAsistencia,
              horarios: rowsArrayCopy,
             },
            id: currentEmpresa.id,
            modulo: "empresa",
          },
        });
        if (res) {
          if (res.message !== "Proceed") {
            messageSubject.next(res.message);
          } else {
            successfulSubject.next(
              "La Informacion se agrego correctamente a la base de datos"
            );
            dispatch(
              actions.empresaActions.replaceEmpresa({
                ...empresa,
                empresa: {
                  ...empresa.empresa,
                  horariosYControlDeAsistencia: {
                    ...empresa.empresa.horariosYControlDeAsistencia,
              horarios: rowsArrayCopy,
                  },
                },
              })
            );
          }
        }
        else if (error) {
          messageSubject.next("Hubo un error en el servidor");
        }
      } else {
        AlertSubject.next(
          "No se puede eliminar un horario con empleados asignados"
        );
      }
    }
  };

  const eliminarSecuencia = async () => {
    if (selectedValueSecuencias) {
      const index = rowsArrayCopySecuencias.findIndex(
        (secuencia) =>
          secuencia.id === selectedValueSecuencias.id &&
          secuencia.copyNumber === selectedValueSecuencias.copyNumber
      );
      if (index !== -1 && selectedValueSecuencias.empleados.length === 0) {
        rowsArrayCopySecuencias[index] = {
          ...rowsArrayCopySecuencias[index],
          empleados: [],
          inactivo: true,
        };
        setRowsArrayCopySecuencias([...rowsArrayCopySecuencias]);
        //aqui
        setSelectedValueSecuencias(null);
        setOpenModalConfirmationDeleteSecuencias(false);
        const [error, res] = await apiRequest({
          method: "post",
          url: apiRoutes.updateInfo,
          data: {
            area: "horariosYControlDeAsistencia",
            data: { 
              ...empresa.empresa.horariosYControlDeAsistencia,
              secuencias: rowsArrayCopySecuencias,
             },
            id: currentEmpresa.id,
            modulo: "empresa",
          },
        });
        if (res) {
          if (res.message !== "Proceed") {
            messageSubject.next(res.message);
          } else {
            successfulSubject.next(
              "La Informacion se agrego correctamente a la base de datos"
            );
            dispatch(
              actions.empresaActions.replaceEmpresa({
                ...empresa,
                empresa: {
                  ...empresa.empresa,
                  horariosYControlDeAsistencia: {
                    ...empresa.empresa.horariosYControlDeAsistencia,
                    secuencias: rowsArrayCopySecuencias,
                  },
                },
              })
            );
          }
        }
        else if (error) {
          messageSubject.next("Hubo un error en el servidor");
        }
      } else {
        AlertSubject.next(
          "No se puede eliminar una secuencia con empleados asignados"
        );
      }
    }
  };

  const onHorarioCancel = () => {
    warningSubject.next("");
    messageSubject.next("");

    setDisabled(false);
    setSelectedValue(null);
    setQuienTieneEsteHorarioArray([]);
    setOpenModalRegistroDeHorario(false);
    sortingTableRef.current?.resetTable();
  };

  const onSecuenciaCancel = () => {
    warningSubject.next("");
    messageSubject.next("");
    setDisabled(false);
    setSelectedValueSecuencias(null);
    setQuienTieneEstaSecuenciaArray([]);
    setOpenModalRegistroDeSecuencia(false);
    sortingTableRefSecuencias.current?.resetTable();
  };

  return (
    <>
      <div>
        <DivTitulos>
          <DivTitulosTitulo>EMPRESA</DivTitulosTitulo>
          <DivTitulosSubTitulo>
            HORARIO Y CONTROL DE ASISTENCIA
          </DivTitulosSubTitulo>
        </DivTitulos>
        <div ref={alertRef}>
          <Warning
            type={"Alert"}
            title={"Alerta"}
            warningSubject={messageSubject}
          />
          <Warning
            type={"Warning"}
            title={"Advertencia"}
            warningSubject={warningSubject}
          />
          <Warning
            type={"Successful"}
            title={"Exito!"}
            warningSubject={successfulSubject}
          />
        </div>

        <Contenedor>
          <ContenedorTabs>
            <Tabs
              onValue={(tab: string) => setActiveTab(tab)}
              Tabs={[
                { label: "HORARIOS", enable: true },
                { label: "SECUENCIAS", enable: secuenciasActive },
              ]}
            />
            <ContenedorDeHorarios>
              <Warning
                type={"Alert"}
                title={"Warning"}
                warningSubject={AlertSubject}
              />
              {activeTab === "HORARIOS" && (
                <>
                  <SearchDropdown
                    onValue={onValueSearchDropDown}
                    options={searchDropDownArray}
                  />
                  <SortingTable
                    ref={sortingTableRef}
                    tableHeight={"383px"}
                    iSortDescending={true}
                    onSelectElement={(horario: any) => onValueSelected(horario)}
                    rowsArray={rowsArrayCopy}
                    columnsArray={columnsArray}
                    showElementsPerPage={10}
                    multiselect={false}
                    showHidden={!ocultarHorariosInactivos}
                  />
                  <ContenedorBotonesExteriorHorarios>
                    <ContenedorBotonesHorarios>
                      <ContenedorBotonesLeft>
                        <ButtonSmall
                          onClick={() => setOpenModalRegistroDeHorario(true)}
                        >
                          {!selectedValue
                            ? "Nuevo Horario"
                            : selectedValue?.inactivo ||
                              selectedValue?.Tipo === "Horario Especial"
                            ? "Ver Horario"
                            : "Editar Horario"}
                        </ButtonSmall>
                        <ButtonSmallSecundario
                          disabled={
                            disabled ||
                            !selectedValue ||
                            selectedValue?.inactivo
                          }
                          onClick={() =>
                            setOpenModalConfirmationDeleteHorario(true)
                          }
                        >
                          Eliminar Horario
                        </ButtonSmallSecundario>
                      </ContenedorBotonesLeft>
                      <ButtonSmall
                        style={{ justifySelf: "flex-end" }}
                        disabled={
                          disabled ||
                          !selectedValue ||
                          selectedValue?.empleados.length === 0
                        }
                        onClick={() => setOpenModalQuienTieneEsteHorario(true)}
                      >
                        ¿Quien tiene este horario?
                      </ButtonSmall>
                    </ContenedorBotonesHorarios>
                    <div>
                      <label>
                        <Checkbox
                          checked={ocultarHorariosInactivos}
                          handleClick={(value: boolean) =>
                            setOcultarHorariosInactivos(value)
                          }
                        />
                        <FlexInlineMarginTextLabel>
                          Ocultar horarios inactivos
                        </FlexInlineMarginTextLabel>
                      </label>
                    </div>
                  </ContenedorBotonesExteriorHorarios>
                </>
              )}
              {activeTab === "SECUENCIAS" && (
                <>
                  <SearchDropdown
                    onValue={onValueSearchDropDownSecuencias}
                    options={searchDropDownArraySecuencias}
                  />
                  <SortingTable
                    ref={sortingTableRefSecuencias}
                    tableHeight={"383px"}
                    iSortDescending={true}
                    onSelectElement={(secuencia: any) =>
                      onValueSelectedSecuencias(secuencia)
                    }
                    rowsArray={rowsArrayCopySecuencias}
                    columnsArray={columnsArraySecuencias}
                    showElementsPerPage={10}
                    multiselect={false}
                    showHidden={!ocultarSecuenciasInactivas}
                  />
                  <ContenedorBotonesExteriorHorarios>
                    <ContenedorBotonesHorarios>
                      <ContenedorBotonesLeft>
                        <ButtonSmall
                          onClick={() => setOpenModalRegistroDeSecuencia(true)}
                        >
                          {!selectedValueSecuencias
                            ? "Nuevo Secuencia"
                            : selectedValueSecuencias?.inactivo
                            ? "Ver Secuencia"
                            : "Editar Secuencia"}
                        </ButtonSmall>
                        <ButtonSmallSecundario
                          disabled={
                            disabled ||
                            !selectedValueSecuencias ||
                            selectedValueSecuencias?.inactivo
                          }
                          onClick={() =>
                            setOpenModalConfirmationDeleteSecuencias(true)
                          }
                        >
                          Eliminar Secuencias
                        </ButtonSmallSecundario>
                      </ContenedorBotonesLeft>
                      <ButtonSmall
                        style={{ justifySelf: "flex-end" }}
                        disabled={
                          disabled ||
                          !selectedValueSecuencias ||
                          selectedValueSecuencias?.empleados.length === 0
                        }
                        onClick={() =>
                          setOpenModalQuienTieneEstaSecuencias(true)
                        }
                      >
                        ¿Quien tiene esta Secuencias?
                      </ButtonSmall>
                    </ContenedorBotonesHorarios>
                    <div>
                      <label>
                        <Checkbox
                          checked={ocultarSecuenciasInactivas}
                          handleClick={(value: boolean) =>
                            setOcultarSecuenciasInactivas(value)
                          }
                        />
                        <FlexInlineMarginTextLabel>
                          Ocultar Secuencias inactivas
                        </FlexInlineMarginTextLabel>
                      </label>
                    </div>
                  </ContenedorBotonesExteriorHorarios>
                </>
              )}
            </ContenedorDeHorarios>
          </ContenedorTabs>
          <ContenedorTiempoExtraordinario>
            <TextLabelTitle>TIEMPO EXTRAORDINARIO</TextLabelTitle>
            <ChevronTabIcon
              tabOpen={tabOpenTiempoExtraordinario}
              toggleTab={() =>
                setTabOpenTiempoExtraordinario(!tabOpenTiempoExtraordinario)
              }
            />
            {(tabOpenTiempoExtraordinario ||
              !smallViewPort) && (
                <div>
                  <Flex1>
                    <label>
                      <Checkbox
                        checked={tiempoExtraordinario.pagarTiempoExtraordinario}
                        handleClick={(value: boolean) =>
                          setTiempoExtraordinario({
                            ...tiempoExtraordinario,
                            pagarTiempoExtraordinario: value,
                          })
                        }
                      />
                      <FlexInlineMarginTextLabel>
                        Pagar tiempo extraordinario
                      </FlexInlineMarginTextLabel>
                    </label>
                  </Flex1>
                  <FlexRow inverse='true'>
                    <Flex1>
                      <InputMini
                        value={tiempoExtraordinario.minutosDeMargenTiempoExtra}
                        onChange={(e) =>
                          setTiempoExtraordinario({
                            ...tiempoExtraordinario,
                            minutosDeMargenTiempoExtra: ccNumberLimit(parseInt(e.target.value), 60),
                          })
                        }
                        type="number"
                      />
                    </Flex1>
                    <ComplementaryFlex>
                      Minutos de margen en entradas anticipadas o salidas
                      tardias, Antes de pagar tiempo extra.
                    </ComplementaryFlex>
                  </FlexRow>
                  <TextLabelTitle>DURACION DE JORNADA (HORAS)</TextLabelTitle>
                  <FlexRowDuracionDeJornada>
                    <DuracionDeJornadaRow>
                      <DuracionDeJornadaRowLabel style={{marginLeft: '0px'}}>Diurna</DuracionDeJornadaRowLabel>
                      <InputMini
                        value={duracionDeLaJornada.diurna}
                        onChange={(e) =>
                          setDuracionDeLaJornada({
                            ...duracionDeLaJornada,
                            diurna: ccHoursChange(e.target.value),
                          })
                        }
                        onBlur={(e) =>
                          setDuracionDeLaJornada({
                            ...duracionDeLaJornada,
                            diurna: ccHoursBlur(e.target.value),
                          })
                        }
                        type="text"
                      />
                    </DuracionDeJornadaRow>
                    <DuracionDeJornadaRow>
                      <DuracionDeJornadaRowLabel>Mixta</DuracionDeJornadaRowLabel>
                      <InputMini
                        value={duracionDeLaJornada.mixto}
                        onChange={(e) =>
                          setDuracionDeLaJornada({
                            ...duracionDeLaJornada,
                            mixto: ccHoursChange(e.target.value),
                          })
                        }
                        onBlur={(e) =>
                          setDuracionDeLaJornada({
                            ...duracionDeLaJornada,
                            mixto: ccHoursBlur(e.target.value),
                          })
                        }
                        type="text"
                      />
                    </DuracionDeJornadaRow>
                    <DuracionDeJornadaRow>
                      <DuracionDeJornadaRowLabel>Nocturna</DuracionDeJornadaRowLabel>
                      <InputMini
                        value={duracionDeLaJornada.nocturna}
                        onChange={(e) =>
                          setDuracionDeLaJornada({
                            ...duracionDeLaJornada,
                            nocturna: ccHoursChange(e.target.value),
                          })
                        }
                        onBlur={(e) =>
                          setDuracionDeLaJornada({
                            ...duracionDeLaJornada,
                            nocturna: ccHoursBlur(e.target.value),
                          })
                        }
                        type="text"
                      />
                    </DuracionDeJornadaRow>
                  </FlexRowDuracionDeJornada>
                  <TextLabelTitle style={{paddingBottom: '0px'}}>PARAMETROS PARA CONTROL DE ASISTENCIA</TextLabelTitle>
                  <NormalText>
                  Minutos de tolerancia antes de considerar retardos por llegar tarde, o descuentos por salir temprano (solo horario por dia).
                  </NormalText>
                  <FlexRowDuracionDeJornada>
                  <DuracionDeJornadaRow>
                      <DuracionDeJornadaRowLabel style={{marginLeft: '0px'}}>Entrada</DuracionDeJornadaRowLabel>
                      <InputMini
                        value={controlDeAsistencia.entrada}
                        onChange={(e) =>
                          setControlDeAsistencia({
                            ...controlDeAsistencia,
                            entrada: ccNumberLimit(parseInt(e.target.value), 60),
                          })
                        }
                        type="number"
                      />
                    </DuracionDeJornadaRow>
                    <DuracionDeJornadaRow>
                      <DuracionDeJornadaRowLabel>Salida</DuracionDeJornadaRowLabel>
                      <InputMini
                        value={controlDeAsistencia.salida}
                        onChange={(e) =>
                          setControlDeAsistencia({
                            ...controlDeAsistencia,
                            salida: ccNumberLimit(parseInt(e.target.value), 60),
                          })
                        }
                        type="number"
                      />
                    </DuracionDeJornadaRow>
                  </FlexRowDuracionDeJornada>
                  <NormalText>
                  Llenado de asistencia
                  </NormalText>
                  <Dropdown
                      value={controlDeAsistencia.llenadoDeAsistencia}
                      onValue={(value: any) => setControlDeAsistencia({...controlDeAsistencia, llenadoDeAsistencia: value})}
                      options={['Manual', 'Importado']}
                    />
                </div>
              )}
          </ContenedorTiempoExtraordinario>
        </Contenedor>

        <ContenedorBotonesExterior>
          <ContenedorBotones>
            <ButtonPrimario onClick={() => submitForm()}>
              Aceptar
            </ButtonPrimario>
            <ButtonSecundario onClick={() => cancel()}>
              Cancelar
            </ButtonSecundario>
          </ContenedorBotones>
        </ContenedorBotonesExterior>
      </div>

      {/* <app-quien-tiene-este-horario-modal [Horario]="quienTieneEsteHorarioObject"></app-quien-tiene-este-horario-modal> */}

     <RegistroDeHorarios
        horarioSelected={selectedValue}
        onAccept={(newHorario: horarioCompleto) => onHorariosChange(newHorario)}
        onClose={() => onHorarioCancel()}
        modalState={openModalRegistroDeHorario}
        horariosArray={rowsArrayCopy}
        duracionDeLaJornada={duracionDeLaJornada}
      />

      <RegistroDeSecuencia
        secuenciaSelected={selectedValueSecuencias}
        onAccept={(newSecuencia: any) => onSecuenciaChange(newSecuencia)}
        onClose={() => onSecuenciaCancel()}
        modalState={openModalRegistroDeSecuencia}
        secuenciasArray={rowsArrayCopySecuencias}
        horariosArray={rowsArrayCopy}
      />

      <ConfirmationModal
        title={"¿Quieres regenerar las listas de Asistencia?"}
        text={"las listas de asistencia son generadas al iniciar el periodo, ¿deceas regenerar las listas de asistencia?"}
        show={openModalRegenerarListasDeAsistencia}
        redButton={true}
        onCancel={() => setOpenModalRegenerarListasDeAsistencia(false)}
        onAccept={() => regenerarListasDeAsistencia()}
      />
      <ConfirmationModal
        title={"¿Estas seguro que deseas eliminar el horario?"}
        text={"La siguiente operacion es irreversible."}
        show={openModalConfirmationDeleteHorario}
        redButton={true}
        onCancel={() => setOpenModalConfirmationDeleteHorario(false)}
        onAccept={() => eliminarHorario()}
      />
      <ConfirmationModal
        title={"¿Estas seguro que deseas eliminar la secuencia?"}
        text={"La siguiente operacion es irreversible."}
        show={openModalConfirmationDeleteSecuencias}
        redButton={true}
        onCancel={() => setOpenModalConfirmationDeleteSecuencias(false)}
        onAccept={() => eliminarSecuencia()}
      />
      <Modal show={openModalQuienTieneEsteHorario}>
      <TextLabelTitle>{selectedValue?.clave}</TextLabelTitle>
      <SearchDropdown
                    onValue={onValueQuienTieneEsteHorario}
                    options={quienTieneEsteHorarioOptions}
                  />
        <SortingTable
          tableHeight={"383px"}
          iSortDescending={true}
          rowsArray={quienTieneEsteHorarioArrayCopy}
          columnsArray={quienTieneEsteHorarioOptions}
          showElementsPerPage={6}
          multiselect={false}
          hideCheckbox={true}
        />

        <ContenedorBotonesExterior
          style={{ marginBottom: "0px", paddingBottom: "0px" }}
        >
          <ContenedorBotones style={{ marginRight: "0px" }}>
              <ButtonPrimario onClick={() => setOpenModalQuienTieneEsteHorario(false)}>
                Aceptar
              </ButtonPrimario>
          </ContenedorBotones>
        </ContenedorBotonesExterior>
    </Modal>
      <Modal show={openModalQuienTieneEstaSecuencias}>
      <TextLabelTitle>{selectedValue?.clave}</TextLabelTitle>
      <SearchDropdown
                    onValue={onValueQuienTieneEstaSecuencia}
                    options={quienTieneEsteHorarioOptions}
                  />
        <SortingTable
          tableHeight={"383px"}
          iSortDescending={true}
          rowsArray={quienTieneEstaSecuenciaArrayCopy}
          columnsArray={quienTieneEsteHorarioOptions}
          showElementsPerPage={6}
          multiselect={false}
          hideCheckbox={true}
        />

        <ContenedorBotonesExterior
          style={{ marginBottom: "0px", paddingBottom: "0px" }}
        >
          <ContenedorBotones style={{ marginRight: "0px" }}>
              <ButtonPrimario onClick={() => setOpenModalQuienTieneEstaSecuencias(false)}>
                Aceptar
              </ButtonPrimario>
          </ContenedorBotones>
        </ContenedorBotonesExterior>
    </Modal>
    </>
  );
}
