import { useState, useEffect, SetStateAction } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  Alert,
  BotonesCarta,
  ButtonColumDiv,
  ButtonControlCuentaDiv,
  ButtonDiv,
  ButtonEye,
  CartaEmpresas,
  CheckboxHipperLinkContainer,
  ContenedorCarta,
  ContenedorConLinea,
  ContenedorDeCartaYLogo,
  ContenedorDeContenedor,
  ContenedorLogo,
  DivUserFoto,
  EmpresasbuttonsDiv,
  FormClass,
  HyperlinkIniciar,
  IniciarHyperLinkDIV,
  InputGrande,
  Inputfile,
  LoginContainer,
  Logo,
  MarginTable,
  SubTitlo,
  TextLabelCheckBox,
  TextLabelMin,
  TitleForm,
  TitleFormDiv,
  Titulo,
} from "./loginStyled";
import { sha1 } from "crypto-hash";
import { apiRequest, apiRoutes } from "../../Api/services";
import actions from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getRefreshToken,
  saveRefreshToken,
  saveToken,
  saveType,
  signOut,
} from "../../Services/tokenStorage";
import { Subject } from "rxjs";
import {
  Button,
  Copyright,
  FlexInlineMarginTextLabel,
  Footers,
  Hyperlink,
  Obligatorio,
  PrivacyNotice,
  SecondButtonStyleList,
  TextLabel,
  TextLabelObligatorio,
} from "../communStyled";
import {
  Checkbox,
  ConfirmationModal,
  FilterInput,
  Modal,
  SortingTable,
  TermsAndConditions,
  Warning,
} from "../../Components";
import useViewPortSize from "../../Hooks/useViewPortSize";
import { useNavigate } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import logo300200 from "./../../assets/img/logo300200.png";
import logoIMG from "./../../assets/img/sipLogo2.png";
import { getDifferenceInDays, getRandomID } from "../../Utils/sharedFunctions";
import paths from "../../paths";
import { useJwt } from "react-jwt";

export default function Login() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { viewPortWidth } = useViewPortSize();
  const token = getRefreshToken();
  const { decodedToken, isExpired } = useJwt(token || "");

  const informacionGeneralDeUsuario: any = useSelector(
    (store: any) => store.informacionGeneralDeUsuario
  );
  const currentEmpresa: any = useSelector((store: any) => store.currentEmpresa);

  const alertSubject = new Subject<string>();
  const alertSubjectEmpresas = new Subject<string>();
  const successfulSubject = new Subject<string>();
  const warningSubject = new Subject<string>();

  const [registerStep, setRegisterStep] = useState(0);
  const [tabMode, setTabMode] = useState("Login");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [recoverPasswordToken, setRecoverPasswordToken] = useState("");
  const [isCorreoElectronicoValid, setIsCorreoElectronicoValid] =
    useState<boolean>(false);
  const [isCorreoElectronicoTouched, setIsCorreoElectronicoTouched] =
    useState<boolean>(false);
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const [isPasswordTouched, setIsPasswordTouched] = useState<boolean>(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordConfirmationVisibility, setPasswordConfirmationVisibility] =
    useState<boolean>(false);
  const [isPasswordConfirmationTouched, setIsPasswordConfirmationTouched] =
    useState<boolean>(false);
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
  const [termsAndConditionsModal, setTermsAndConditionsModal] =
    useState<boolean>(false);
  const [confirmationCrearEmpresaModal, setConfirmationCrearEmpresaModal] =
    useState<boolean>(false);
  const [confirmationDeleteEmpresaModal, setConfirmationDeleteEmpresaModal] =
    useState<boolean>(false);
  const [
    confirmationDeleteEmpresaColaboradorModal,
    setconfirmationDeleteEmpresaColaboradorModal,
  ] = useState<boolean>(false);
  const [
    confirmationTransferirEmpresaModal,
    setConfirmationTransferirEmpresaModal,
  ] = useState<boolean>(false);
  // const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [userImage, setUserImage] = useState<any>(null);
  const [currentIMG, setCurrentIMG] = useState<any>(null);
  const [currentIMGName, setCurrentIMGName] = useState<string>("");
  const [crearEmpresaButtonActive, setCrearEmpresaButtonActive] =
    useState<boolean>(false);
  const [editarEmpresaButtonActive, setEditarEmpresaButtonActive] =
    useState<boolean>(false);
  const [transferirEmpresaButtonActive, setTransferirEmpresaButtonActive] =
    useState<boolean>(false);
  const [asistenteActive, setAsistenteActive] = useState<boolean>(false);
  const [selectedEmpresa, setSelectedEmpresa] = useState<any>(null);
  const [nombreDeLaEmpresa, setNombreDeLaEmpresa] = useState<string>("");
  const [usuarioSelecionado, setUsuarioSelecionado] = useState<string>("");
  const [hasAlertBeenShown, setHasAlertBeenShown] = useState<boolean>(false);
  const [invalidUsuariosArray, setInvalidUsuariosArray] =
    useState<boolean>(false);
  const [arrayUsuarios, setArrayUsuarios] = useState<any>([]);
  const [columnsArray, setColumnsArray] = useState<any>([
    {
      label: "Empresa",
      valueLabel: "nombre",
      filtrable: true,
      widthPX: 225,
      type: "String",
    },
    {
      label: "Permisos",
      valueLabel: "creador",
      filtrable: true,
      widthPX: 225,
      type: "String",
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      // @ts-ignore
      if (!isExpired && decodedToken && decodedToken["username"]) {
        await dispatch(
          actions.informacionGeneralDeUsuarioActions.replaceInformacionGeneralDeUsuario(
            {
              // @ts-ignore
              usuario: decodedToken["username"],
            }
          )
        );
      }
    };

    fetchData();
  }, [decodedToken]);

  useEffect(() => {
    if (informacionGeneralDeUsuario.usuario) {
      setRegisterStep(1);
      loadEmpresas();
    }
  }, [informacionGeneralDeUsuario.usuario, token]);

  useEffect(() => {
    if (currentEmpresa.id) {
      if (
        currentEmpresa.creador === "Propietario" ||
        currentEmpresa.permisos.bitacora ||
        currentEmpresa.permisos.notificaciones ||
        currentEmpresa.permisos.colaboradores
      ) {
        history(paths.Perfil);
      } else {
        history(paths.Blog);
      }
    }
  }, [currentEmpresa]);

  const regex = /[^\w-@.\s]/g;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const omit_special_char_email = (string: any) => {
    let newString = string.replace(regex, "");
    setCorreoElectronico(newString);
    setIsCorreoElectronicoValid(emailRegex.test(newString.toLowerCase()));
  };

  const singUpClick = () => {
    setTabMode("Registro");
  };

  const loginClick = () => {
    setTabMode("Login");
  };

  const passwordValidations = async () => {
    alertSubject.next("");
    if (password.length <= 7) {
      alertSubject.next("La contraseña debe tener al menos 8 caracteres");
      return false;
    }
    if (password.match(/[a-z]/) === null) {
      alertSubject.next("La contraseña debe tener al menos una minúscula");
      return false;
    }
    if (password.match(/[A-Z]/) === null) {
      alertSubject.next("La contraseña debe tener al menos una mayúscula");
      return false;
    }
    if (password.match(/[0-9]/) === null) {
      alertSubject.next("La contraseña debe tener al menos un número");
      return false;
    }
    return true;
  };

  const loginUser = async () => {
    successfulSubject.next("");
    if (isCorreoElectronicoValid && password) {
      const shaPassword = await sha1(password);
      const [error, res] = await apiRequest({
        method: "post",
        url: apiRoutes.login,
        data: {
          user: correoElectronico,
          password: shaPassword,
          platformType:
            navigator.userAgent.toLowerCase().indexOf("iphone") > -1 ||
            navigator.userAgent.toLowerCase().indexOf("android") > -1
              ? "Mobile"
              : "Web",
        },
      });
      const data = res;
      if (error) {
        alertSubject.next("Hubo un error en el servidor");
        return;
      }
      if (data) {
        if (data.message) {
          alertSubject.next(data.message);
        } else {
          if (data && data.token && data.refreshToken) {
            saveToken(data.token);
            saveRefreshToken(data.refreshToken);
            saveType(data.type);
            alertSubject.next("");
            await dispatch(
              actions.informacionGeneralDeUsuarioActions.replaceInformacionGeneralDeUsuario(
                { usuario: correoElectronico }
              )
            );
          }
        }
      } else {
        setIsCorreoElectronicoTouched(true);
      }
    }
  };

  const loadEmpresas = async () => {
    const [error2, data] = await apiRequest({
      method: "get",
      url: apiRoutes.cargarEmpresas,
    });
    if (data) {
      if (data.message) {
        signOut();
        setRegisterStep(0);
        alertSubject.next(data.message);
      } else {
        const empresas = data["empresas"].map((empresa: any) => {
          return {
            ...empresa,
            creador:
              informacionGeneralDeUsuario.usuario === empresa.creador ||
              correoElectronico === empresa.creador
                ? "Propietario"
                : "Asistente",
          };
        });
        dispatch(
          actions.informacionGeneralDeUsuarioActions.replaceInformacionGeneralDeUsuario(
            {
              empresas: empresas,
              creditos: data.creditos,
              estadoDeCuenta: data.estadoDeCuenta,
              informacionDeFacturacion: data.informacionDeFacturacion,
            }
          )
        );
      }
    }
    if (error2) {
      alertSubject.next("Hubo un error en el servidor");
      signOut();
    }
  };

  const registerUser = async () => {
    if (
      isCorreoElectronicoValid &&
      password === passwordConfirmation &&
      termsAndConditions &&
      (await passwordValidations())
    ) {
      successfulSubject.next("");
      if (isCorreoElectronicoValid && password) {
        const shaPassword = await sha1(password);
        const [error, res] = await apiRequest({
          method: "post",
          url: apiRoutes.registro,
          data: {
            user: correoElectronico,
            password: shaPassword,
            type: "Master",
            platformType:
              navigator.userAgent.toLowerCase().indexOf("iphone") > -1 ||
              navigator.userAgent.toLowerCase().indexOf("android") > -1
                ? "Mobile"
                : "Web",
          },
        });
        const data = res;
        if (error) {
          alertSubject.next("Hubo un error en el servidor");
          return;
        }
        if (data) {
          if (data.message) {
            alertSubject.next(data.message);
          } else {
            dispatch(
              actions.informacionGeneralDeUsuarioActions.replaceInformacionGeneralDeUsuario(
                { ...data.dataUser, usuario: correoElectronico }
              )
            );
            saveToken(data.token);
            saveRefreshToken(data.refreshToken);
            saveType(data.type);
            alertSubject.next("");
            setRegisterStep(1);
          }
        } else {
          setIsPasswordTouched(true);
          setIsPasswordConfirmationTouched(true);
          setIsCorreoElectronicoTouched(true);
        }
      }
    }
  };

  const UpdatePassword = async () => {
    successfulSubject.next("");
    alertSubject.next("");
    if (password === passwordConfirmation && (await passwordValidations())) {
      const shaPassword = await sha1(password);
      const [error, res] = await apiRequest({
        method: "post",
        url: apiRoutes.cambiarContraseñaConToken,
        data: {
          password: shaPassword,
          token: recoverPasswordToken,
        },
      });
      const data = res;
      if (error) {
        alertSubject.next("Hubo un error en el servidor");
        return;
      }
      if (data) {
        if (data.message === "PasswordChanged") {
          loginUser();
        } else if (data.message === "SamePassword") {
          alertSubject.next(
            "No puedes cambiar tu contraseña por una utilizada anteriormente"
          );
        } else {
          alertSubject.next(data.message);
        }
      } else {
        alertSubject.next("Hubo un error en el servidor");
      }
    }
  };

  const recoverPassword = async () => {
    successfulSubject.next("");
    alertSubject.next("");
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.recuperarContraseñaToken,
      data: {
        user: correoElectronico,
      },
    });
    const data = res;
    if (error) {
      alertSubject.next("Hubo un error en el servidor");
      return;
    }
    if (data) {
      if (data.message === "Proceed") {
        setTabMode("recoverPassword");
        successfulSubject.next(
          "Te hemos enviado un correo con tu nuevo Token para recuperar tu contraseña"
        );
      } else {
        alertSubject.next(data.message);
      }
    } else {
      alertSubject.next("Hubo un error en el servidor");
    }
  };

  const goToControlDeCuenta = () => {
    history(paths.ControlDeCuenta);
  };

  const onSelectFile = (event: { target: { files: any[] } }) => {
    if (event.target.files && event.target.files[0]) {
      const image = event.target.files[0];

      // Resize the image using react-image-file-resizer
      Resizer.imageFileResizer(
        image,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri: any) => {
          setUserImage(uri);
        },
        "base64"
      );
    }
  };

  const removeIMG = () => {
    setCurrentIMG(null);
    setUserImage(null);
  };

  const crearEmpresaButtonActiveClick = () => {
    setCrearEmpresaButtonActive(!crearEmpresaButtonActive);
    setEditarEmpresaButtonActive(false);
    setTransferirEmpresaButtonActive(false);
  };

  const editarEmpresaButtonActiveClick = () => {
    setEditarEmpresaButtonActive(!editarEmpresaButtonActive);
    setCrearEmpresaButtonActive(false);
    setTransferirEmpresaButtonActive(false);
  };

  const TransferirEmpresaButtonActiveClick = () => {
    setTransferirEmpresaButtonActive(!transferirEmpresaButtonActive);
    setEditarEmpresaButtonActive(false);
    setCrearEmpresaButtonActive(false);
  };

  useEffect(() => {
    if (!transferirEmpresaButtonActive) {
      setConfirmationTransferirEmpresaModal(false);
      setUsuarioSelecionado("");
      setArrayUsuarios([]);
    }
  }, [transferirEmpresaButtonActive]);

  const showNewEmpresaAlert = () => {
    if (!selectedEmpresa) {
      if (nombreDeLaEmpresa.length === 0) {
        warningSubject.next("");
        setHasAlertBeenShown(false);
      }
      if (nombreDeLaEmpresa.length === 1) {
        setHasAlertBeenShown(true);
      }
    }
  };

  useEffect(() => {
    hasAlertBeenShown &&
      warningSubject.next(
        "Al crear una empresa es necesario llenar todos los parametros para, ya que son necesarios para habilitar la siguiente pagina Al crear una empresa es necesario llenar todos los parametros para, ya que son necesarios para habilitar la siguiente pagina"
      );
  }, [hasAlertBeenShown]);

  useEffect(() => {
    setColumnsArray([
      {
        label: "Empresa",
        valueLabel: "nombre",
        filtrable: true,
        widthPX: viewPortWidth >= 580 ? 225 : 100,
        type: "String",
      },
      {
        label: "Permisos",
        valueLabel: "creador",
        filtrable: true,
        widthPX: viewPortWidth >= 580 ? 225 : 100,
        type: "String",
      },
    ]);
  }, [viewPortWidth]);

  const onSelectEmpresa = (empresa: any) => {
    setCrearEmpresaButtonActive(false);
    setEditarEmpresaButtonActive(false);
    setTransferirEmpresaButtonActive(false);
    setSelectedEmpresa(empresa);
    warningSubject.next("");
    setNombreDeLaEmpresa(empresa ? empresa.nombre : "");

    if (empresa)
      for (const emp of informacionGeneralDeUsuario.empresas) {
        if (emp.nombre === empresa.nombre) {
          setAsistenteActive(emp.creador !== "Propietario");
        }
      }

    removeIMG();
    if (empresa && empresa.logo && empresa.logo !== "") {
      setCurrentIMG("data:image/png;charset=utf-8;base64," + empresa.logo);
      setCurrentIMGName(empresa.nombreLogo);
    }
  };

  const editarEmpresa = async () => {
    alertSubjectEmpresas.next("");
    const data = await updateIMG({
      nombre: nombreDeLaEmpresa,
      id: selectedEmpresa.id,
    });
    if (data.message !== "Proceed") {
      alertSubjectEmpresas.next(data.message);
    } else {
      loadEmpresas();
      setSelectedEmpresa(null);
      setNombreDeLaEmpresa("");
      removeIMG();
      setCrearEmpresaButtonActive(false);
      setEditarEmpresaButtonActive(false);
      setTransferirEmpresaButtonActive(false);
    }
  };

  const updateIMG = async (body: any) => {
    const name = nombreDeLaEmpresa + getRandomID();
    if (currentIMGName) {
      const [error, res] = await apiRequest({
        method: "post",
        url: apiRoutes.removeIMG,
        data: {
          nombre: currentIMGName,
        },
      });
      const data = res;
      if (error) {
        return { message: "Hubo un error en el servidor" };
      }
      if (data && data.message === "Proceed") {
        setCurrentIMGName("");
      }
    }
    if (userImage || currentIMG) {
      const [error, res] = await apiRequest({
        method: "post",
        url: apiRoutes.postIMG,
        data: {
          img: userImage || currentIMG,
          nombre: name,
        },
      });
      const dataIMG = res;
      if (error) {
        return { message: "Hubo un error en el servidor" };
      }
      if (dataIMG) {
        body.logo = dataIMG.imgName;
        setCurrentIMGName(body.logo);
      }
    }
    return postEditForm(body);
  };

  const postEditForm = async (body: any) => {
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.editarEmpresa,
      data: {
        ...body,
      },
    });
    const data = res;
    if (error) {
      return { message: "Hubo un error en el servidor" };
    }
    if (data) {
      return data;
    }
  };

  const postForm = async (body: any) => {
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.crearEmpresa,
      data: {
        ...body,
      },
    });
    const data = res;
    if (error) {
      return { message: "Hubo un error en el servidor" };
    }
    if (data) {
      return data;
    }
  };

  const loadEmpresa = async () => {
    alertSubjectEmpresas.next("");
    const diffday = getDifferenceInDays(
      new Date(),
      new Date(selectedEmpresa.vigencia)
    );
    if (diffday.days > 0) {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.cargarEmpresa + selectedEmpresa.id,
      });
      if (error) {
        return { message: "Hubo un error en el servidor" };
      }
      if (data) {
        if (data.message) {
          alertSubjectEmpresas.next(data.message);
        } else {
          let permisos = {};
          for (const colaborador of data.empresa.inicio.colaboradores) {
            if (
              informacionGeneralDeUsuario.usuario === colaborador.colaborador
            ) {
              permisos = colaborador.permisos;
            }
          }
          await dispatch(
            actions.currentEmpresaActions.replaceCurrentEmpresa({
              id: selectedEmpresa.id,
              creador: selectedEmpresa.creador,
              vigencia: selectedEmpresa.vigencia,
              permisos: permisos,
            })
          );
          await dispatch(
            actions.empresaActions.replaceEmpresa({
              ...data.empresa,
            })
          );
          dispatch(actions.currentIMGActions.replaceCurrentIMG(currentIMG));
        }
      }
    } else {
      selectedEmpresa.creador === "Propietario"
        ? alertSubjectEmpresas.next(
            "la membresia de la empresa a caducado porfavor realize el pago para tener acceso"
          )
        : alertSubjectEmpresas.next(
            "hubo un error al intentar ingresar a la compañia, porfavor contacta al administrador de esta empresa"
          );
    }
  };

  const onValueUsuarios = (value: string) => {
    setUsuarioSelecionado(value);
    setArrayUsuarios([]);
  };

  const getUsuariosList = async (text: string) => {
    setInvalidUsuariosArray(text.length !== 0 ? false : true);
    setUsuarioSelecionado(text);
    if (text !== "") {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.usariosList + text,
      });
      if (error) {
        return { message: "Hubo un error en el servidor" };
      }
      setArrayUsuarios(data || []);
    }
  };

  const subirEmpresa = () => {};
  const descargarEmpresa = () => {};

  const cerrarSesion = () => {
    signOut();
  };

  const eliminarEmpresa = async () => {
    alertSubjectEmpresas.next("");
    if (asistenteActive) {
      const [error, data] = await apiRequest({
        method: "post",
        url: apiRoutes.renunciarAColaborador,
        data: { id: selectedEmpresa.id },
      });
      if (error) {
        return { message: "Hubo un error en el servidor" };
      }
      if (data) {
        if (data.message !== "Proceed") {
          alertSubjectEmpresas.next(data.message);
        } else {
          setCurrentIMGName("");
          loadEmpresas();
          setSelectedEmpresa(null);
          setNombreDeLaEmpresa("");
          removeIMG();
          setconfirmationDeleteEmpresaColaboradorModal(false);
        }
      }
    } else {
      const [error, data] = await apiRequest({
        method: "post",
        url: apiRoutes.eliminarEmpresa,
        data: { nombre: nombreDeLaEmpresa, id: selectedEmpresa.id },
      });
      if (error) {
        return { message: "Hubo un error en el servidor" };
      }
      if (data) {
        if (data.message !== "Proceed") {
          alertSubjectEmpresas.next(data.message);
        } else {
          const [error, data] = await apiRequest({
            method: "post",
            url: apiRoutes.removeIMG,
            data: {
              nombre: currentIMGName,
            },
          });
          if (error) {
            return { message: "Hubo un error en el servidor" };
          }
          if (data) {
            if (data && data.message === "Proceed") {
              setCurrentIMGName("");
              loadEmpresas();
              setSelectedEmpresa(null);
              setNombreDeLaEmpresa("");
              removeIMG();
              setConfirmationDeleteEmpresaModal(false);
            } else {
              alertSubjectEmpresas.next(data.message);
            }
          }
        }
      }
    }
    setCrearEmpresaButtonActive(false);
    setEditarEmpresaButtonActive(false);
    setTransferirEmpresaButtonActive(false);
  };

  const crearEmpresa = async () => {
    alertSubjectEmpresas.next("");
    if (informacionGeneralDeUsuario.creditos) {
      if (informacionGeneralDeUsuario.usuario)
        postIMG({ nombre: nombreDeLaEmpresa }).then(async (data) => {
          if (data.message) {
            alertSubjectEmpresas.next(data.message);
          } else {
            await dispatch(
              actions.empresaActions.replaceEmpresa({
                ...data.empresa,
              })
            );
            await dispatch(
              actions.currentEmpresaActions.replaceCurrentEmpresa({
                ...currentEmpresa,
                ...data.currentEmpresa,
                creador: "Propietario",
              })
            );
            dispatch(actions.currentIMGActions.replaceCurrentIMG(userImage));
            history(paths.Perfil);
          }
        });
    } else {
      alertSubjectEmpresas.next("creditos insuficientes");
    }
    setConfirmationCrearEmpresaModal(false);
  };

  const postIMG = async (Body: any) => {
    const name = nombreDeLaEmpresa + getRandomID();
    if (userImage) {
      const [error, data] = await apiRequest({
        method: "post",
        url: apiRoutes.postIMG,
        data: {
          img: userImage,
          nombre: name,
        },
      });
      if (error) {
        return { message: "Hubo un error en el servidor" };
      }
      if (data) {
        Body.logo = data.imgName;
        return postForm(Body);
      }
    } else {
      Body.logo = "";
      return postForm(Body);
    }
  };

  const transferirEmpresa = async () => {
    alertSubjectEmpresas.next("");
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.transferirEmpresa,
      data: {
        nombre: nombreDeLaEmpresa,
        id: selectedEmpresa.id,
        UsuarioReceptor: usuarioSelecionado,
      },
    });
    if (error) {
      return { message: "Hubo un error en el servidor" };
    }
    if (data) {
      if (data.message !== "Proceed") {
        alertSubjectEmpresas.next(data.message);
      } else {
        setCurrentIMGName("");
        loadEmpresas();
        setSelectedEmpresa(null);
        setNombreDeLaEmpresa("");
        removeIMG();
      }
    }
    setCrearEmpresaButtonActive(false);
    setEditarEmpresaButtonActive(false);
    setTransferirEmpresaButtonActive(false);
    cleanTransferirEmpresa();
  };

  const cleanTransferirEmpresa = () => {
    setconfirmationDeleteEmpresaColaboradorModal(false);
    setUsuarioSelecionado("");
    setArrayUsuarios([]);
    setTransferirEmpresaButtonActive(false);
  };

  return (
    <>
      <LoginContainer>
        <ContenedorDeContenedor>
          {registerStep === 0 && (
            <ContenedorDeCartaYLogo>
              <ContenedorLogo>
                <Logo src={logoIMG} />
                <Titulo>Sistema Integral de Personal</Titulo>
                <SubTitlo>
                  La forma más eficiente de administrar los recursos humanos
                </SubTitlo>
              </ContenedorLogo>

              <ContenedorCarta>
                <div>
                  <BotonesCarta />
                  <FormClass>
                    <TitleFormDiv>
                      <TitleForm>
                        {tabMode === "Registro" ? "REGISTRO" : "INICIAR SESIÓN"}
                      </TitleForm>
                    </TitleFormDiv>
                    <Warning
                      type={"Alert"}
                      title={"Warning"}
                      warningSubject={alertSubject}
                    />
                    <Warning
                      type={"Successful"}
                      title={"Exito!"}
                      warningSubject={successfulSubject}
                    />
                    <div>
                      {tabMode !== "recoverPassword" && (
                        <TextLabelObligatorio>
                          Correo Electrónico
                        </TextLabelObligatorio>
                      )}
                      {tabMode !== "recoverPassword" && (
                        <InputGrande
                          value={correoElectronico}
                          type="email"
                          autoComplete="on"
                          style={
                            isCorreoElectronicoTouched &&
                            (!isCorreoElectronicoValid || !correoElectronico)
                              ? { borderBottom: "2px solid #db282899" }
                              : {}
                          }
                          onChange={(e: any) =>
                            omit_special_char_email(e.target.value)
                          }
                          onBlur={() => setIsCorreoElectronicoTouched(true)}
                        />
                      )}
                      {isCorreoElectronicoTouched &&
                        (!isCorreoElectronicoValid || !correoElectronico) && (
                          <Alert>Este correo no es un correo válido</Alert>
                        )}
                      {tabMode !== "ForgotPassword" && (
                        <TextLabelObligatorio>Contraseña</TextLabelObligatorio>
                      )}
                      {tabMode !== "ForgotPassword" && (
                        <>
                          <InputGrande
                            style={{ width: "calc(100% - 65px)" }}
                            type={passwordVisibility ? "text" : "password"}
                            value={password}
                            onChange={(e: any) => setPassword(e.target.value)}
                          />
                          <ButtonEye
                            onMouseDown={() => setPasswordVisibility(true)}
                            onMouseUp={() => setPasswordVisibility(false)}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </ButtonEye>
                        </>
                      )}
                      {(tabMode === "Registro" ||
                        tabMode === "recoverPassword") && (
                        <div>
                          <TextLabel>
                            Confirmación de contraseña
                            <Obligatorio>*</Obligatorio>
                          </TextLabel>
                          <div>
                            <InputGrande
                              style={
                                isPasswordConfirmationTouched &&
                                isPasswordTouched &&
                                (password !== passwordConfirmation ||
                                  (password === "" &&
                                    passwordConfirmation === ""))
                                  ? {
                                      width: "calc(100% - 65px)",
                                      borderBottom: "2px solid #db282899",
                                    }
                                  : { width: "calc(100% - 65px)" }
                              }
                              type={
                                passwordConfirmationVisibility
                                  ? "text"
                                  : "password"
                              }
                              value={passwordConfirmation}
                              onChange={(e: any) =>
                                setPasswordConfirmation(e.target.value)
                              }
                            />
                            <ButtonEye
                              onMouseDown={() =>
                                setPasswordConfirmationVisibility(true)
                              }
                              onMouseUp={() =>
                                setPasswordConfirmationVisibility(false)
                              }
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </ButtonEye>
                          </div>
                          {isPasswordConfirmationTouched &&
                            isPasswordTouched &&
                            (password !== passwordConfirmation ||
                              (password === "" &&
                                passwordConfirmation === "")) && (
                              <Alert>
                                La contraseña no es válida o no coincide
                              </Alert>
                            )}
                          {tabMode === "recoverPassword" && (
                            <>
                              <TextLabel>
                                Pega tu Token aquí: <Obligatorio>*</Obligatorio>
                              </TextLabel>
                              <InputGrande
                                value={recoverPasswordToken}
                                onChange={(e: any) =>
                                  setRecoverPasswordToken(e.target.value)
                                }
                              />
                            </>
                          )}
                        </div>
                      )}
                      {tabMode === "Registro" && (
                        <CheckboxHipperLinkContainer>
                          <TextLabelMin className="container">
                            <Checkbox
                              checked={termsAndConditions}
                              handleClick={(value: boolean) =>
                                setTermsAndConditions(value)
                              }
                            />
                            <FlexInlineMarginTextLabel>
                              He leído y acepto los
                            </FlexInlineMarginTextLabel>
                          </TextLabelMin>
                          <TextLabelCheckBox
                            onClick={() => setTermsAndConditionsModal(true)}
                          >
                            Términos y Condiciones
                          </TextLabelCheckBox>
                        </CheckboxHipperLinkContainer>
                      )}

                      {tabMode === "Login" && (
                        <TextLabel>
                          Olvidaste tu contreaseña?
                          <Hyperlink
                            onClick={() => setTabMode("ForgotPassword")}
                          >
                            Recuperar contraseña.
                          </Hyperlink>
                        </TextLabel>
                      )}
                      {(tabMode === "ForgotPassword" ||
                        tabMode === "recoverPassword") && (
                        <TextLabel>
                          ¿Pulsaste por error?
                          <Hyperlink onClick={() => setTabMode("Login")}>
                            Regresar a Iniciar Sesión
                          </Hyperlink>
                        </TextLabel>
                      )}

                      {/* {tabMode === "Login" && (
                        <CheckboxHipperLinkContainer>
                          <label className="container">
                            <input
                              type="checkbox"
                              onClick={() => setRememberMe(!rememberMe)}
                            />
                            <span className="checkmark"></span>
                            RememberMe
                          </label>
                        </CheckboxHipperLinkContainer>
                      )} */}
                      <ButtonDiv>
                        {tabMode === "Registro" && (
                          <Button
                            disabled={!termsAndConditions}
                            onClick={() => registerUser()}
                          >
                            REGISTRARME
                          </Button>
                        )}

                        {tabMode === "ForgotPassword" && (
                          <Button onClick={() => recoverPassword()}>
                            Obtén tu Token
                          </Button>
                        )}

                        {tabMode === "recoverPassword" && (
                          <Button onClick={() => UpdatePassword()}>
                            Actualizar Contraseña
                          </Button>
                        )}

                        {tabMode === "Login" && (
                          <Button onClick={() => loginUser()}>
                            INICIAR SESIÓN
                          </Button>
                        )}
                      </ButtonDiv>
                      {tabMode === "Registro" && (
                        <IniciarHyperLinkDIV>
                          <TextLabel>Ya tienes una Cuenta?</TextLabel>
                          <HyperlinkIniciar onClick={() => loginClick()}>
                            Iniciar Sesión.
                          </HyperlinkIniciar>
                        </IniciarHyperLinkDIV>
                      )}
                      {tabMode !== "Registro" && (
                        <IniciarHyperLinkDIV>
                          <TextLabel>No tienes una Cuenta?</TextLabel>
                          <Hyperlink onClick={() => singUpClick()}>
                            Registrarme
                          </Hyperlink>
                        </IniciarHyperLinkDIV>
                      )}
                    </div>
                  </FormClass>
                </div>
              </ContenedorCarta>
            </ContenedorDeCartaYLogo>
          )}

          {registerStep === 1 && (
            <ContenedorConLinea>
              <Warning
                type={"Warning"}
                closeable={false}
                title={"Advertencia"}
                warningSubject={warningSubject}
              />
              <Warning
                type={"Alert"}
                title={"Warning"}
                warningSubject={alertSubjectEmpresas}
              />

              <div style={{ display: "flex" }}>
                {viewPortWidth > 1050 && <BotonesCarta />}

                <CartaEmpresas>
                  {1050 >= viewPortWidth && (
                    <EmpresasbuttonsDiv>
                      <DivUserFoto
                        style={{
                          backgroundImage: `url(${
                            userImage
                              ? userImage
                              : currentIMG
                              ? currentIMG
                              : logo300200
                          }`,
                        }}
                      ></DivUserFoto>
                      <SecondButtonStyleList
                        disabled={selectedEmpresa}
                        style={{
                          backgroundColor: selectedEmpresa
                            ? "#EBEBF9"
                            : crearEmpresaButtonActive
                            ? "#DB2828"
                            : "#262A33",
                        }}
                        onClick={() => crearEmpresaButtonActiveClick()}
                      >
                        {crearEmpresaButtonActive
                          ? "CANCELAR"
                          : "CREAR EMPRESA"}
                      </SecondButtonStyleList>
                      <SecondButtonStyleList
                        onClick={() => editarEmpresaButtonActiveClick()}
                        style={{
                          marginLeft: "0px",
                          backgroundColor:
                            !selectedEmpresa || asistenteActive
                              ? "#EBEBF9"
                              : editarEmpresaButtonActive
                              ? "#DB2828"
                              : "#262A33",
                        }}
                        disabled={
                          !(nombreDeLaEmpresa.length > 0 || !selectedEmpresa)
                        }
                      >
                        {editarEmpresaButtonActive
                          ? "CANCELAR"
                          : "EDITAR EMPRESA"}
                      </SecondButtonStyleList>
                      {(crearEmpresaButtonActive ||
                        editarEmpresaButtonActive) && (
                        <>
                          <TextLabel>Nombre de la Empresa</TextLabel>
                          <InputGrande
                            value={nombreDeLaEmpresa}
                            onChange={(e: any) =>
                              setNombreDeLaEmpresa(e.target.value)
                            }
                            onKeyUp={() => showNewEmpresaAlert()}
                          />
                          {!(userImage || currentIMG) && (
                            <SecondButtonStyleList
                              disabled={
                                nombreDeLaEmpresa.length === 0 ||
                                asistenteActive
                              }
                            >
                              <Inputfile
                                disabled={
                                  nombreDeLaEmpresa.length === 0 ||
                                  asistenteActive
                                }
                                onChange={(e: any) => onSelectFile(e)}
                                type="file"
                                name="file"
                                id="file"
                                accept="image/*"
                              />
                              <label
                                htmlFor="file"
                                style={{
                                  cursor:
                                    nombreDeLaEmpresa.length === 0
                                      ? "default"
                                      : "pointer",
                                  padding: "10px 75px",
                                }}
                              >
                                AGREGAR LOGO
                              </label>
                            </SecondButtonStyleList>
                          )}
                          {(userImage || currentIMG) && (
                            <SecondButtonStyleList
                              onClick={() => removeIMG()}
                              disabled={
                                !(
                                  nombreDeLaEmpresa.length > 0 ||
                                  selectedEmpresa
                                ) || asistenteActive
                              }
                            >
                              ELIMINAR LOGO
                            </SecondButtonStyleList>
                          )}
                          {crearEmpresaButtonActive && (
                            <SecondButtonStyleList
                              onClick={() =>
                                setConfirmationCrearEmpresaModal(true)
                              }
                              disabled={
                                nombreDeLaEmpresa.length === 0 ||
                                selectedEmpresa
                              }
                            >
                              CREAR
                            </SecondButtonStyleList>
                          )}
                          {editarEmpresaButtonActive && (
                            <SecondButtonStyleList
                              style={{ marginLeft: "0px" }}
                              onClick={() => editarEmpresa()}
                              disabled={
                                nombreDeLaEmpresa.length === 0 ||
                                !selectedEmpresa ||
                                asistenteActive
                              }
                            >
                              EDITAR
                            </SecondButtonStyleList>
                          )}
                        </>
                      )}

                      <SecondButtonStyleList
                        style={{
                          backgroundColor:
                            !selectedEmpresa || editarEmpresaButtonActive
                              ? "inherit"
                              : "#B7D32D",
                          marginLeft: "0px",
                        }}
                        onClick={() => loadEmpresa()}
                        disabled={!selectedEmpresa || editarEmpresaButtonActive}
                      >
                        UTILIZAR EMPRESA
                      </SecondButtonStyleList>
                      <SecondButtonStyleList
                        style={{ marginLeft: "0px" }}
                        onClick={() =>
                          !asistenteActive
                            ? setConfirmationDeleteEmpresaModal(true)
                            : setconfirmationDeleteEmpresaColaboradorModal(true)
                        }
                        disabled={!selectedEmpresa || editarEmpresaButtonActive}
                      >
                        {asistenteActive
                          ? "RENUNCIAR A COLABORACION"
                          : "ELIMINAR EMPRESA"}
                      </SecondButtonStyleList>
                      <SecondButtonStyleList
                        onClick={() => TransferirEmpresaButtonActiveClick()}
                        style={{
                          marginLeft: "0px",
                          backgroundColor:
                            !selectedEmpresa || asistenteActive
                              ? "#EBEBF9"
                              : transferirEmpresaButtonActive
                              ? "#DB2828"
                              : "#262A33",
                        }}
                        disabled={!selectedEmpresa || asistenteActive}
                      >
                        {transferirEmpresaButtonActive
                          ? "CANCELAR"
                          : "TRANSFERIR EMPRESA"}
                      </SecondButtonStyleList>

                      {transferirEmpresaButtonActive && (
                        <>
                          <div style={{ width: "100%" }}>
                            <TextLabel>usuario</TextLabel>
                            <FilterInput
                              option={usuarioSelecionado}
                              options={arrayUsuarios}
                              invalid={invalidUsuariosArray}
                              onValue={(item) => onValueUsuarios(item)}
                              onChange={(item) => getUsuariosList(item)}
                            />
                          </div>
                          <SecondButtonStyleList
                            onClick={() =>
                              setConfirmationTransferirEmpresaModal(true)
                            }
                            disabled={
                              !selectedEmpresa ||
                              asistenteActive ||
                              usuarioSelecionado.length === 0
                            }
                          >
                            TRANSFERIR
                          </SecondButtonStyleList>
                        </>
                      )}
                      <SecondButtonStyleList
                        style={{ marginLeft: "0px" }}
                        onClick={() => descargarEmpresa()}
                        disabled={
                          nombreDeLaEmpresa.length === 0 ||
                          !selectedEmpresa ||
                          asistenteActive ||
                          editarEmpresaButtonActive
                        }
                      >
                        DESCARGAR EMPRESA
                      </SecondButtonStyleList>
                      <SecondButtonStyleList
                        style={{ marginLeft: "0px" }}
                        onClick={() => subirEmpresa()}
                        disabled={selectedEmpresa || crearEmpresaButtonActive}
                      >
                        SUBIR EMPRESA
                      </SecondButtonStyleList>
                      <Hyperlink onClick={() => cerrarSesion()}>
                        Cerrar sesión
                      </Hyperlink>
                    </EmpresasbuttonsDiv>
                  )}

                  <MarginTable>
                    <SortingTable
                      multiselect={false}
                      rowsArray={informacionGeneralDeUsuario.empresas}
                      columnsArray={columnsArray}
                      onSelectElement={(element: any) =>
                        onSelectEmpresa(element)
                      }
                    />
                  </MarginTable>
                  {viewPortWidth > 1050 && (
                    <EmpresasbuttonsDiv>
                      <DivUserFoto
                        style={{
                          backgroundImage: `url(${
                            userImage
                              ? userImage
                              : currentIMG
                              ? currentIMG
                              : logo300200
                          }`,
                        }}
                      ></DivUserFoto>
                      <ButtonColumDiv>
                        <SecondButtonStyleList
                          disabled={selectedEmpresa}
                          style={{
                            backgroundColor: selectedEmpresa
                              ? "#EBEBF9"
                              : crearEmpresaButtonActive
                              ? "#DB2828"
                              : "#262A33",
                          }}
                          onClick={() => crearEmpresaButtonActiveClick()}
                        >
                          {crearEmpresaButtonActive
                            ? "CANCELAR"
                            : "CREAR EMPRESA"}
                        </SecondButtonStyleList>
                        <SecondButtonStyleList
                          onClick={() => editarEmpresaButtonActiveClick()}
                          style={{
                            marginLeft: "0px",
                            backgroundColor:
                              !selectedEmpresa || asistenteActive
                                ? "#EBEBF9"
                                : editarEmpresaButtonActive
                                ? "#DB2828"
                                : "#262A33",
                          }}
                          disabled={
                            !(nombreDeLaEmpresa.length > 0 || !selectedEmpresa)
                          }
                        >
                          {editarEmpresaButtonActive
                            ? "CANCELAR"
                            : "EDITAR EMPRESA"}
                        </SecondButtonStyleList>
                        {(crearEmpresaButtonActive ||
                          editarEmpresaButtonActive) && (
                          <>
                            <TextLabel>Nombre de la Empresa</TextLabel>
                            <InputGrande
                              value={nombreDeLaEmpresa}
                              onChange={(e: any) =>
                                setNombreDeLaEmpresa(e.target.value)
                              }
                              onKeyUp={() => showNewEmpresaAlert()}
                            />
                            {!(userImage || currentIMG) && (
                              <SecondButtonStyleList
                                disabled={
                                  nombreDeLaEmpresa.length === 0 ||
                                  asistenteActive
                                }
                              >
                                <Inputfile
                                  disabled={
                                    nombreDeLaEmpresa.length === 0 ||
                                    asistenteActive
                                  }
                                  onChange={(e: any) => onSelectFile(e)}
                                  type="file"
                                  name="file"
                                  id="file"
                                  accept="image/*"
                                />
                                <label
                                  htmlFor="file"
                                  style={{
                                    cursor:
                                      nombreDeLaEmpresa.length === 0
                                        ? "default"
                                        : "pointer",
                                    padding: "10px 75px",
                                  }}
                                >
                                  AGREGAR LOGO
                                </label>
                              </SecondButtonStyleList>
                            )}
                            {(userImage || currentIMG) && (
                              <SecondButtonStyleList
                                onClick={() => removeIMG()}
                                disabled={
                                  !(
                                    nombreDeLaEmpresa.length > 0 ||
                                    selectedEmpresa
                                  ) || asistenteActive
                                }
                              >
                                ELIMINAR LOGO
                              </SecondButtonStyleList>
                            )}
                            {crearEmpresaButtonActive && (
                              <SecondButtonStyleList
                                onClick={() =>
                                  setConfirmationCrearEmpresaModal(true)
                                }
                                disabled={
                                  nombreDeLaEmpresa.length === 0 ||
                                  selectedEmpresa
                                }
                              >
                                CREAR
                              </SecondButtonStyleList>
                            )}
                            {editarEmpresaButtonActive && (
                              <SecondButtonStyleList
                                style={{ marginLeft: "0px" }}
                                onClick={() => editarEmpresa()}
                                disabled={
                                  nombreDeLaEmpresa.length === 0 ||
                                  !selectedEmpresa ||
                                  asistenteActive
                                }
                              >
                                EDITAR
                              </SecondButtonStyleList>
                            )}
                          </>
                        )}

                        <SecondButtonStyleList
                          style={{
                            backgroundColor:
                              !selectedEmpresa || editarEmpresaButtonActive
                                ? "inherit"
                                : "#B7D32D",
                            marginLeft: "0px",
                          }}
                          onClick={() => loadEmpresa()}
                          disabled={
                            !selectedEmpresa || editarEmpresaButtonActive
                          }
                        >
                          UTILIZAR EMPRESA
                        </SecondButtonStyleList>
                        <SecondButtonStyleList
                          style={{ marginLeft: "0px" }}
                          onClick={() =>
                            !asistenteActive
                              ? setConfirmationDeleteEmpresaModal(true)
                              : setconfirmationDeleteEmpresaColaboradorModal(
                                  true
                                )
                          }
                          disabled={
                            !selectedEmpresa || editarEmpresaButtonActive
                          }
                        >
                          {asistenteActive
                            ? "RENUNCIAR A COLABORACION"
                            : "ELIMINAR EMPRESA"}
                        </SecondButtonStyleList>
                        <SecondButtonStyleList
                          onClick={() => TransferirEmpresaButtonActiveClick()}
                          style={{
                            marginLeft: "0px",
                            backgroundColor:
                              !selectedEmpresa || asistenteActive
                                ? "#EBEBF9"
                                : transferirEmpresaButtonActive
                                ? "#DB2828"
                                : "#262A33",
                          }}
                          disabled={!selectedEmpresa || asistenteActive}
                        >
                          {transferirEmpresaButtonActive
                            ? "CANCELAR"
                            : "TRANSFERIR EMPRESA"}
                        </SecondButtonStyleList>
                        {transferirEmpresaButtonActive && (
                          <>
                            <div style={{ width: "100%" }}>
                              <TextLabel>usuario</TextLabel>
                              <FilterInput
                                option={usuarioSelecionado}
                                options={arrayUsuarios}
                                invalid={invalidUsuariosArray}
                                onValue={(item) => onValueUsuarios(item)}
                                onChange={(item) => getUsuariosList(item)}
                              />
                            </div>
                            <SecondButtonStyleList
                              onClick={() =>
                                setConfirmationTransferirEmpresaModal(true)
                              }
                              disabled={
                                !selectedEmpresa ||
                                asistenteActive ||
                                usuarioSelecionado.length === 0
                              }
                            >
                              TRANSFERIR
                            </SecondButtonStyleList>
                          </>
                        )}
                        <SecondButtonStyleList
                          style={{ marginLeft: "0px" }}
                          onClick={() => descargarEmpresa()}
                          disabled={
                            nombreDeLaEmpresa.length === 0 ||
                            !selectedEmpresa ||
                            asistenteActive ||
                            editarEmpresaButtonActive
                          }
                        >
                          DESCARGAR EMPRESA
                        </SecondButtonStyleList>
                        <SecondButtonStyleList
                          style={{ marginLeft: "0px" }}
                          onClick={() => subirEmpresa()}
                          disabled={selectedEmpresa || crearEmpresaButtonActive}
                        >
                          SUBIR EMPRESA
                        </SecondButtonStyleList>
                        <Hyperlink onClick={() => cerrarSesion()}>
                          Cerrar sesión
                        </Hyperlink>
                      </ButtonColumDiv>
                    </EmpresasbuttonsDiv>
                  )}
                </CartaEmpresas>
              </div>

              <ButtonControlCuentaDiv>
                <label>
                  Conectado como: {informacionGeneralDeUsuario.usuario}
                </label>
                <Button
                  style={{
                    marginLeft: "0px",
                    marginTop: "10px",
                    width: "300px",
                  }}
                  onClick={() => goToControlDeCuenta()}
                >
                  Control de Cuenta
                </Button>
              </ButtonControlCuentaDiv>
            </ContenedorConLinea>
          )}
        </ContenedorDeContenedor>
        <Footers>
          <Copyright>
            Copyright © {new Date().getFullYear()} SIP. Todos los derechos
            reservados.
          </Copyright>
          <PrivacyNotice onClick={() => setTermsAndConditionsModal(true)}>
            Aviso de Privacidad | FAQ’s
          </PrivacyNotice>
        </Footers>
      </LoginContainer>
      <Modal show={termsAndConditionsModal}>
        <TermsAndConditions
          handleClose={() => setTermsAndConditionsModal(false)}
        />
      </Modal>
      <ConfirmationModal
        title={"¿Estas seguro que deseas eliminar la empresa?"}
        text={
          "La siguiente operacion es irreversible, se eliminaran todos los datos de la empresa y no podran ser recuperados."
        }
        show={confirmationDeleteEmpresaModal}
        redButton={true}
        onCancel={() => setConfirmationDeleteEmpresaModal(false)}
        onAccept={() => eliminarEmpresa()}
      />
      <ConfirmationModal
        title={"¿Estas seguro que deseas dejar de ser un colaborador?"}
        text={
          "Al renunciar como colaborador dejaras de ver la empresa y alguien en la organizacion debera darte acceso de nuevo."
        }
        show={confirmationDeleteEmpresaColaboradorModal}
        redButton={true}
        onCancel={() => setconfirmationDeleteEmpresaColaboradorModal(false)}
        onAccept={() => eliminarEmpresa()}
      />
      <ConfirmationModal
        title={"Crear Empresa"}
        text={
          "Crear una empresa tiene un costo de un crédito equivalente a la vigencia del primer mes"
        }
        show={confirmationCrearEmpresaModal}
        redButton={true}
        onCancel={() => setConfirmationCrearEmpresaModal(false)}
        onAccept={() => crearEmpresa()}
      />
      <ConfirmationModal
        title={"Transferir Empresa"}
        text={
          "¿Estas seguro que quieres transferir esta empresa?, esta accion es irreversible y perderas toda autoria sobre ella"
        }
        show={confirmationTransferirEmpresaModal}
        redButton={true}
        onCancel={() => cleanTransferirEmpresa()}
        onAccept={() => transferirEmpresa()}
      />
    </>
  );
}
