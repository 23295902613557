import styled from "styled-components";

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  position: relative;
  border-radius: 4px;
`;

export const InputGrandeDrop = styled.input`
  width: calc(100% - 27px);
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 22px;
  resize: vertical;
  background: #ffffff 0% 0% no-repeat padding-box;
  outline: none;
`;

export const Span = styled.span`
  position: absolute;
  top: 12px;
  font-size: 13px;
  color: #767676;
  right: 20px;
`;

export const DropdownContent = styled.div`
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  background: #ffffff 0% 0% no-repeat padding-box;
  max-height: 223px;
  border: 1px solid #d9d9da;
  overflow-y: auto;
  width: 98.2%;
  z-index: 1;
  margin-top: 5px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
`;

export const DropdownContentOption = styled.p`
  color: black;
  padding: 5px 16px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-decoration: none;
  display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #f3f3f3;
  &:hover {
    background-color: #f1f1f1;
    font-weight: bold;
  }
`;
