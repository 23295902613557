import styled from "styled-components";

export const Filter = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
`;

export const FilterContent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 9px 13px #00000042;
  max-height: 223px;
  border: 1px solid #dededf;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow-y: auto;
  border-top: 0px;
  width: calc(100% - 11px);
  z-index: 1;
`;

export const InputGrandeDrop = styled.input`
  width: calc(100% - 21px);
  padding: 6px;
  border: 0px solid #ccc;
  resize: vertical;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #434169;
  font-size: 16px;
  font-family: "Manrope Regular";
  color: #475259;
  &:focus {
    border-bottom: 2px solid #5d60cb;
  }
`;

export const MenuOption = styled.span`
  padding: 12px 16px;
`;
