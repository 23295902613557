import { useState, useEffect } from 'react'

const useViewPortSize = () =>  {
  const [viewPortHeight, setViewPortHeight] = useState(window.innerHeight)
  const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth)
  const [smallViewPort, setSmallViewPort] = useState(window.innerWidth <= 1375)
  
  useEffect(() => {
    const handleResize = () => {
      setViewPortHeight(window.innerHeight);
      setViewPortWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.matchMedia("(max-width: 1375px)");

    const handleViewportChange = (x) => {
      setSmallViewPort(window.innerWidth <= 1375);
    };

    window.addEventListener('resize', handleViewportChange);
    setSmallViewPort(window.innerWidth <= 1375);

    return () => {
        window.removeEventListener('resize', handleViewportChange);
    };
  }, []);

  return {smallViewPort, viewPortHeight, viewPortWidth};
}

export default useViewPortSize
