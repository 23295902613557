import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import {
  faBullseye,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import { ButtonTable, DivIconsTitle, FirstColumn, GenericColumn, GenericRow, PaginationDiv, PaginationIcon, PaginationNumber, TableContainer, TableDiv, TableRowContainer, TextLabelRow, TextLabelRowTitle, TitleColumns, Titles } from "./SortingTableStyled";
import Checkbox from "../Checkbox";
import { deepCopy } from "../../Utils/sharedFunctions";

interface Props {
  rowsArray: Array<any>;
  columnsArray: Array<any>;
  hideCheckbox?: boolean;
  iSortDescending?: boolean;
  showHover?: boolean;
  tableHeight?: string;
  showElementsPerPage?: number;
  elementOnClick?: (element: object) => void;
  onSelectElement?: (element: any) => void;
  multiselect?: boolean;
  dontShowPagination?: boolean;
  showHidden?: boolean;
}

const SortingTable = forwardRef(
  (
    {
      rowsArray,
      columnsArray,
      hideCheckbox = false,
      iSortDescending = true,
      showHover = false,
      tableHeight = "525px",
      showElementsPerPage = 20,
      elementOnClick,
      multiselect = true,
      onSelectElement,
      dontShowPagination = false,
      showHidden = false,
    }: Props,
    Ref
  ) => {
    const [selectedColumnFilterIndex, setSelectedColumnFilterIndex] =
      useState<number>(0);
    const [sortDescending, setSortDescending] = useState<boolean>(iSortDescending);
    const [originalData, setOriginalData] = useState<Array<any>>([]);
    const [paginationCopy, setPaginationCopy] = useState<Array<any>>(deepCopy(originalData));
    const [forceUpdate, setForceUpdate] = useState<boolean>(true);
    const [pagination, setPagination] = useState<number>(showElementsPerPage);
    const [pages, setPages] = useState<number>(showElementsPerPage);
    const [actualPage, setActualPage] = useState<number>(1);
    const [firstElement, setFirstElement] = useState<number>(0);
    const [lastElement, setlastElement] = useState<number>(showElementsPerPage);

    useImperativeHandle(Ref, () => ({
      setForceUpdateFunction,
      sortArrayFunction,
      getData,
      resetTable,
    }));

    useEffect(() => {
      setPaginationCopy(showHidden ? originalData : originalData.filter((a) => !a.inactivo));
    }, [originalData, showHidden]);

    useEffect(() => {
      setOriginalData(
        rowsArray
          .map((a) => {
            return { ...a };
          })
          .sort((a, b) => sortingFunction(a, b))
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iSortDescending, sortDescending]);

    useEffect(() => {
      setOriginalData(
        rowsArray
          .map((a) => {
            return { ...a };
          })
          .sort((a, b) => sortingFunction(a, b))
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsArray]);

    useEffect(() => {
      setPages(Math.floor((paginationCopy.length - 1) / pagination));
      setActualPage(0);
      setFirstElement(pagination > 0 ? actualPage * pagination + 1 : 0);
      setlastElement(
        paginationCopy.length >= (actualPage + 1) * pagination
          ? (actualPage + 1) * pagination
          : paginationCopy.length
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationCopy]);

    const resetTable = () => {
      setSelectedColumnFilterIndex(0);
      setSortDescending(iSortDescending);
      setOriginalData(
        rowsArray
          .map((a) => {
            return { ...a };
          })
          .sort((a, b) => sortingFunction(a, b))
      );
    };

    const setSelectedRowIndexFunction = (index: number) => {
      if (multiselect) {
        paginationCopy[index]["isSelectedOnTable"] =
          !paginationCopy[index]["isSelectedOnTable"];
        onSelectElement &&
          onSelectElement(
            paginationCopy
              .filter((element) => element.isSelectedOnTable)
              .map((a) => {
                return { ...a };
              })
              .map((element) => {
                delete element.isSelectedOnTable;
                return element;
              })
          );
      } else {
        for (const elementIndex in paginationCopy) {
          paginationCopy[elementIndex]["isSelectedOnTable"] =
          paginationCopy[elementIndex]["isSelectedOnTable"] === (parseInt(elementIndex) === index) ? false : parseInt(elementIndex) === index;
        }
        let elementToSent = { ...paginationCopy[index] };
        const sendEmpty = !elementToSent.isSelectedOnTable;
        delete elementToSent.isSelectedOnTable;
        onSelectElement && onSelectElement(sendEmpty ? null : elementToSent);
      }
      setForceUpdateFunction();
    };

    const setForceUpdateFunction = () => {
      setForceUpdate(!forceUpdate);
    };

    useEffect(() => {
      sortDescending ? sortArrayFunction() : setSortDescending(iSortDescending);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedColumnFilterIndex]);

    useEffect(() => {
      sortArrayFunction();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortDescending, selectedColumnFilterIndex]);

    const sortingFunction = (a: any, b: any) => {

      let AValueFirstRow =
        columnsArray[0].type === "Number"
          ? parseInt(a[columnsArray[0].valueLabel])
          : columnsArray[0].type === "Date" ? formatDayInner(a[columnsArray[0].valueLabel]) : columnsArray[0].type === "Array"
            ? a[columnsArray[0].valueLabel].length
            : a[columnsArray[0].valueLabel];

      let BValueFirstRow =
        columnsArray[0].type === "Number"
          ? parseInt(b[columnsArray[0].valueLabel])
          : columnsArray[0].type === "Date" ? formatDayInner(b[columnsArray[0].valueLabel]) :  columnsArray[0].type === "Array"
            ? b[columnsArray[0].valueLabel].length
            : b[columnsArray[0].valueLabel];

      if (selectedColumnFilterIndex !== 0) {

        let AValueSelectedRow =
          columnsArray[selectedColumnFilterIndex].type === "Number"
            ? parseInt(a[columnsArray[selectedColumnFilterIndex].valueLabel])
            : columnsArray[0].type === "Date" ? formatDayInner(a[columnsArray[selectedColumnFilterIndex].valueLabel]) :  a[columnsArray[selectedColumnFilterIndex].valueLabel];

        let BValueSelectedRow =
          columnsArray[selectedColumnFilterIndex].type === "Number"
            ? parseInt(b[columnsArray[selectedColumnFilterIndex].valueLabel])
            : columnsArray[0].type === "Date" ? formatDayInner(b[columnsArray[selectedColumnFilterIndex].valueLabel]) : b[columnsArray[selectedColumnFilterIndex].valueLabel];

        if (sortDescending) {
          return AValueSelectedRow === BValueSelectedRow
            ? AValueFirstRow === BValueFirstRow
              ? 0
              : AValueFirstRow > BValueFirstRow
                ? 1
                : -1
            : AValueSelectedRow > BValueSelectedRow
              ? 1
              : -1;
        } else {
          return AValueSelectedRow === BValueSelectedRow
            ? AValueFirstRow === BValueFirstRow
              ? 0
              : AValueFirstRow > BValueFirstRow
                ? 1
                : -1
            : AValueSelectedRow > BValueSelectedRow
              ? -1
              : 1;
        }
      } else {
        if (sortDescending) {
          return AValueFirstRow === BValueFirstRow
            ? 0
            : AValueFirstRow > BValueFirstRow
              ? 1
              : -1;
        } else {
          return AValueFirstRow === BValueFirstRow
            ? 0
            : AValueFirstRow > BValueFirstRow
              ? -1
              : 1;
        }
      }
    };

    const sortArrayFunction = () => {
      if (paginationCopy && paginationCopy.length > 0 && columnsArray) {
        setOriginalData(paginationCopy.sort((a, b) => sortingFunction(a, b)));
        setForceUpdateFunction();
      }
    };

    const getData = () => {
      return paginationCopy.map((a) => {
        return { ...a };
      });
    };

    const formatDay = (date: Date | string) => {
      if (date){
          date = new Date(date);
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          return month < 10
            ? `${day}/0${month}/${year}`
            : `${day}/${month}/${year}`;
        }
      return "";
    };

    const formatDayInner = (date: Date | string) => {
      if (date) {
        if (typeof date === 'string') {
          const parts = (date as string).split("/");
          let day = parseInt(parts[0], 10);
          let month = parseInt(parts[1], 10) - 1;
          let year = parseInt(parts[2], 10);
          return new Date(year, month, day);
        } else {
          return new Date(date);
        }
      }
      return "";
    };

    useEffect(() => {
      setPages(Math.floor((paginationCopy.length - 1) / pagination));
      setActualPage(0);
      setFirstElement(pagination > 0 ? actualPage * pagination + 1 : 0);
      setlastElement(
        paginationCopy.length >= (actualPage + 1) * pagination
          ? (actualPage + 1) * pagination
          : paginationCopy.length
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    useEffect(() => {
      setFirstElement(pagination > 0 ? actualPage * pagination + 1 : 0);
      setlastElement(
        paginationCopy.length >= (actualPage + 1) * pagination
          ? (actualPage + 1) * pagination
          : paginationCopy.length
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualPage]);

    return (
      <TableDiv style={{ height: tableHeight }}>
        <TableContainer>
          <Titles>
            <FirstColumn
              style={{ width: !hideCheckbox ? '100px' : '50px' }}
            ></FirstColumn>
            {columnsArray.length > 0 &&
              columnsArray.map((column: any, i: any) => (
                <TitleColumns
                  key={uuidv4()}
                  style={{
                    width:
                      100 >= column.widthPX ? "100px" : column.widthPX + "px",
                  }}
                >
                  <TextLabelRowTitle>{column.label}</TextLabelRowTitle>
                  {column.filtrable && (
                    <DivIconsTitle>
                      {i !== selectedColumnFilterIndex ? (
                        <ButtonTable
                          onClick={() => setSelectedColumnFilterIndex(i)}
                        >
                          <FontAwesomeIcon icon={faBullseye} />
                        </ButtonTable>
                      ) : sortDescending ? (
                        <ButtonTable
                          onClick={() => setSortDescending(false)}
                        >
                          <FontAwesomeIcon icon={faAngleDoubleDown} />
                        </ButtonTable>
                      ) : (
                        <ButtonTable
                          onClick={() => setSortDescending(true)}
                        >
                          <FontAwesomeIcon icon={faAngleDoubleUp} />
                        </ButtonTable>
                      )}
                    </DivIconsTitle>
                  )}
                </TitleColumns>
              ))}
          </Titles>
          <TableRowContainer>
            {paginationCopy.length > 0 &&
              paginationCopy.map((row, e) =>
                e >= firstElement - 1 && lastElement - 1 >= e && (showHidden ? true : !row.inactivo) ? (
                  <GenericRow
                    key={uuidv4()}
                    showhover={showHover ? "true" : "false"}
                    inactivo={row.inactivo ? "true" : "false"}
                    onClick={() => elementOnClick && elementOnClick(row)}
                  >
                    <FirstColumn
                      style={{ width: !hideCheckbox ? '100px' : '50px' }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {!hideCheckbox && (
                        <label>
                          <Checkbox
                            checked={row.isSelectedOnTable ?? false}
                            handleClick={(value: boolean) =>
                              setSelectedRowIndexFunction(e)
                            }
                          />
                        </label>
                      )}
                    </FirstColumn>
                    {columnsArray.map((column, i) => {
                      return (
                        <GenericColumn
                          key={uuidv4()}
                          style={{
                            width:
                              100 >= column.widthPX
                                ? "100px"
                                : column.widthPX + "px",
                          }}
                        >
                          <TextLabelRow>
                            {column.type !== "Array"
                              ? column.type !== "Date"
                                ? row[column.valueLabel]
                                : formatDay(row[column.valueLabel])
                              : row[column.valueLabel]
                                ? row[column.valueLabel].length
                                : 0}
                          </TextLabelRow>
                        </GenericColumn>
                      );
                    })}
                  </GenericRow>
                ) : null
              )}
          </TableRowContainer>
        </TableContainer>
        {!dontShowPagination && (
          <PaginationDiv>
            <label>
              Mostrar{" "}
              <PaginationNumber
                onChange={(e) =>
                  (parseInt(e.target.value) < 100 || !e.target.value) &&
                  setPagination(parseInt(e.target.value))
                }
                type="number"
                value={pagination}
              />{" "}
              Elementos
            </label>
            <div style={{ marginLeft: "10px" }}>
              <ButtonTable
                onClick={() => actualPage >= 1 && setActualPage(0)}
              >
                <PaginationIcon
                  style={(actualPage >= 1) ? {
                    color: '#00000099',
                    cursor: 'pointer'
                  } : { color: '#e0e1e2' }}
                >
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </PaginationIcon>
              </ButtonTable>
              <ButtonTable
                onClick={() => actualPage >= 1 && setActualPage(actualPage - 1)}
              >
                <PaginationIcon
                  style={(actualPage >= 1) ? {
                    color: '#00000099',
                    cursor: 'pointer'
                  } : { color: '#e0e1e2' }}
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </PaginationIcon>
              </ButtonTable>
              <label>
                {firstElement}-{lastElement}/{paginationCopy.length}
              </label>
              <ButtonTable
                onClick={() =>
                  pages > actualPage &&
                  pages !== 0 &&
                  setActualPage(actualPage + 1)
                }
              >
                <PaginationIcon
                  style={(pages > actualPage && pages !== 0) ? {
                    color: '#00000099',
                    cursor: 'pointer'
                  } : { color: '#e0e1e2' }}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </PaginationIcon>
              </ButtonTable>
              <ButtonTable
                onClick={() =>
                  pages > actualPage && pages !== 0 && setActualPage(pages)
                }
              >
                <PaginationIcon
                  style={(pages > actualPage && pages !== 0) ? {
                    color: '#00000099',
                    cursor: 'pointer'
                  } : { color: '#e0e1e2' }}
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </PaginationIcon>
              </ButtonTable>
            </div>
          </PaginationDiv>
        )}
      </TableDiv>
    );
  }
);

export default SortingTable;
