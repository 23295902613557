import styled from "styled-components";

export const Contenedor = styled.div`
  overflow: hidden;
`;

export const RouterOutlet = styled.div`
  float: left;
  height: 100%;
  width: calc(100% - 290px);
  @media (max-width: 1000px) {
    float: left;
    height: 100%;
    width: 100%;
  }
`;

export const Title = styled.div`
  height: 90px;
  width: 100%;
  position: relative;
  background: #b7d32d 0% 0% no-repeat padding-box;
  @media (max-width: 1000px) {
    height: 55px;
  }
`;

export const TitleSpan = styled.span`
  color: white;
  font-size: 24px;
  top: 31px;
  left: 52px;
  position: absolute;
  @media (max-width: 1000px) {
    font-size: 15px;
    top: 21px;
    left: 67px;
  }
`;

export const Fecha = styled.span`
  position: absolute;
  color: white;
  right: 44px;
  top: 23px;
  font-size: 18px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Dia = styled.span`
  position: absolute;
  color: white;
  right: 44px;
  top: 42px;
  font-size: 18px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Overlay = styled.div`
  background: black;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

export const Panel = styled.div`
  overflow-y: auto;
  height: calc(100vh - 90px);
  overflow-x: hidden;
  @media (max-width: 1000px) {
    opacity: 1;
    overflow-y: auto;
    height: calc(100vh - 55px);
  }
`;

export const Menu = styled.span`
  display: none;
  & svg {
    font-size: 30px;
  }
  @media (max-width: 1000px) {
    display: block;
    font-size: 30px;
    top: 11px;
    left: 13px;
    color: white;
    position: relative;
  }
`;

export const NavBarContainer = styled.div`
  float: left;
  height: 100%;
`;

interface NavigationContainerInterface {
  menuopen: string;
}

export const NavigationContainer = styled.nav<NavigationContainerInterface>`
  width: 290px;
  background: #262a33;
  opacity: 1;
  height: 100vh;

  @media only screen and (max-width: 1000px) {
    height: calc(100vh - 55px);
    position: absolute;
    z-index: 4;
    top: 55px;
    animation-name: ${(props) =>
      props.menuopen === "true" ? "fadeInPosition" : "fadeOutPosition"};
    left: ${(props) => (props.menuopen === "true" ? "0px" : "-290px")};
    animation-iteration-count: 1;
    animation-timing-function: ease-${(props) => (props.menuopen === "true" ? "in" : "out")};
    animation-duration: 0.5s;

    @keyframes fadeInPosition {
      0% {
        left: -290px;
      }
      100% {
        left: 0;
      }
    }

    @keyframes fadeOutPosition {
      0% {
        left: 0;
      }
      100% {
        left: -290px;
      }
    }
  }
`;

export const Logo = styled.img`
  display: block;
  margin: auto;
  height: 165px;
  padding-top: 35px;
  padding-bottom: 15px;
  cursor: pointer;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ScrollMenu = styled.div`
  overflow-y: auto;
  height: calc(100vh - 198px);
  background: #262a33;

  @media (max-width: 1000px) {
    height: calc(100vh - 55px);
  }

  /* scroll styles */

  ::-webkit-scrollbar-thumb:hover {
    background: #262a33;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: #262a33;
  }

  ::-webkit-scrollbar-track {
    border-radius: 15px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 15px;
    background-color: #262a33;
  }

  ::-webkit-scrollbar-thumb {
    background: #5e657b;
    border-radius: 10px;
  }
`;

export const MenuItem = styled.a`
  display: block;
  text-decoration: none;
  color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  cursor: pointer;

  &:hover {
    /* add hover behavior */
  }
`;

interface SubMenuInterface {
  activeroute: string;
}

export const SubMenu = styled.ul<SubMenuInterface>`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  max-height: ${(props) => (props.activeroute === "true" ? "300px" : "0")};
  transition: all 0.5s ease-out;
`;

interface SubMenuItemInterface {
  disabled: boolean;
}

export const SubMenuItem = styled.a<SubMenuItemInterface>`
  background: #5e657b;
  height: 19px;
  padding: 10px 0px 10px 35px;
  justify-content: flex-start;
  font: Regular 16px/24px Lato;
  letter-spacing: 0;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  display: block;
  text-decoration: none;
  color: ${(props) => (props.disabled ? "#942c2c" : "#f3f3f3")};

  &:hover {
    font: Regular 16px/24px Lato;
    letter-spacing: 0;
    color: ${(props) => (props.disabled ? "#942c2c" : "#262a33")};
  }
`;

export const ChevronDiv = styled.div`
  position: relative;
  font-size: 16px;
  font-family: "Lato";
  width: 210px;
`;

export const ChevronSpan = styled.span`
  padding-left: 10px;
  position: absolute;
`;

interface ChevronContainerInterface {
  activeroute: string;
}

export const ChevronContainer = styled.span<ChevronContainerInterface>`
  float: right;
  transform: ${(props) =>
    props.activeroute === "true" ? "rotate(-90deg)" : "rotate(0deg)"};
`;

export const MainMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li:hover ${SubMenu} {
    display: block;
    max-height: 300px;
    cursor: pointer;
  }

  li:hover ${ChevronContainer} {
    transform: rotate(-90deg);
  }
`;

export const MainMenuTitle = styled.a`
  display: block;
  text-decoration: none;
  color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
`;
