import styled from "styled-components";

export const Contenedor = styled.div`
display: flex;
  @media (max-width: 1790px) {
    display: inline-block;
  }
`;

export const ContenedorDeConfiguracion = styled.div`
  width: 822px;
  margin: 16px;
  padding: 16px;
  margin-left: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  @media (max-width: 1790px) {
    position: relative;
    width: calc(100vw - 412px);
    margin-right: 45px;
    max-height: unset;
  }
  @media (max-width: 1000px) {
    width: calc(100vw - 70px);
    margin-right: 16px;
    margin-left: 16px;
  }
`;

export const ContenedorCuentasBancarias = styled.div`
  width: 1004px;
  margin: 16px;
  margin-bottom: auto;
  position: relative;
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-right: 45px;
  @media (max-width: 1790px) {
    width: calc(100vw - 412px);
    margin-right: 45px;
    margin-left: 45px;
    margin-bottom: 15px;
  }
  @media (max-width: 1000px) {
    width: calc(100vw - 70px);
    margin-right: 16px;
    margin-left: 16px;
  }
`;

export const WarningLabel = styled.div`
  margin-top: 20px;
  color: red;
`;

export const AgregarNuevaCuentaLink = styled.span`
  position: absolute;
  margin-left: 5px;
  top: 25px;
  right: 25px;
  color: #2185d0;
  cursor: pointer;
`;

export const CuentasContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const CuentaOptions = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  font-size: 14px;
`;

export const CuentasModalContainer = styled.div`
min-height: 500px;
display: flex;
flex-direction: column;
`;
