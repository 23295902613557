import styled from "styled-components";

export const Checkout = styled.form`
  text-align: center;
  font-family: "Manrope Regular";
  font-size: 14px;
  color: #525252;
  height: 113px;
  width: 100%;
  @media (max-width: 1000px) {
    max-width: 500px;
  }
`;

export const Title = styled.label`
  font-family: "Manrope SemiBold";
  font-size: 16px;
`;

export const CardInfo = styled.label`
  margin-top: 20px;
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 1000px) {
    justify-content: flex-end;
  }
`;
