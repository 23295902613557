import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Contenedor } from "./DatePickerStyled";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

interface Props {
  label: string;
  pickerWidth?: string;
  onValue: (data: any, isValid: boolean) => void;
  disabled?: boolean;
  value: Date | null;
  filterFunction?: (day: Date) => boolean;
}

const DatePickerComponent = forwardRef(
  ({ label, pickerWidth = "250px", onValue, disabled, value, filterFunction }: Props, ref) => {
    const [currentValue, setCurrentValue] = useState<any>(null);
    useImperativeHandle(ref, () => ({}));

    useEffect(() => {
      const valueToSet = value ? formatDay(value) : null;
      if (valueToSet !== null && typeof valueToSet.getTime === 'function' && !isNaN(new Date(valueToSet.getTime()).getTime())) {
        setCurrentValue(dayjs(valueToSet));
      } else {
        setCurrentValue(null);
      }
    }, [value]);

    const formatDay = (date: Date | string) => {
      if (date) {
        if (typeof date === 'string') {
          if(date.includes("/")) {
            const parts = (date as string).split("/");
            let day = parseInt(parts[0], 10);
            let month = parseInt(parts[1], 10) - 1;
            let year = parseInt(parts[2], 10);
            return new Date(year, month, day);
          }
          return new Date(date);
        } else {
          return new Date(date);
        }
      }
      return null;
    };

    const handleDateChange = (newValue: any) => {
      if (onValue) {
        setCurrentValue(newValue);
        onValue(dayjs(new Date(1000 * dayjs(newValue).unix())).toISOString(), newValue !== null && !isNaN(new Date(newValue).getTime()));
      }
    };

    return (
      <Contenedor style={{ width: pickerWidth }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={label}
            value={currentValue}
            disabled={disabled}
            onChange={(newValue) => handleDateChange(newValue)}
            shouldDisableDate={(day) =>
              filterFunction ? filterFunction(day) : false 
            }
            format="DD/MM/YYYY"
          />
        </LocalizationProvider>
      </Contenedor>
    );
  }
);

export default DatePickerComponent;
