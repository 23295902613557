import styled from "styled-components";

export const AlertContainer = styled.p`
  position: relative;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
  background: #db282833 0% 0% no-repeat padding-box;
  border: 1px solid #db282899;
  border-radius: 4px;
`;

export const WarningContainerTitle = styled.span`
  font-family: "Lato";
  font-size: 18px;
  font-weight: bold;
`;

export const AlertContainerText = styled.span`
  font-family: "Regular";
  font-size: 14px;
`;
