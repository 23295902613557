import { faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, DropdownContent, DropdownContentOption, InputGrandeDrop, Span } from "./SearchDropdownStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";


interface Props {
    onValue: (data: any) => void;
    onFocusOut?: (data: any) => void;
    onFilterChange?: (item: string) => void;
    disabled?: boolean;
    options: Array<any>;
}

const SearchDropdown = ({ onValue, onFocusOut, onFilterChange, disabled, options }: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [filter, setFilter] = useState<any>(options[0]);
    const [option, setOption] = useState<string>('');
    
    const onkeypress = (event: any) => {
        if (event.key === "Enter") {
            onValue({filter: filter, searchValue: option});
        }
    };
    
    const emitOptionFocus = (event: any) => {
        onFocusOut && onFocusOut({filter: filter, searchValue: option});
      };
    
    const emitOption = () => {
        onValue({filter: filter, searchValue: option});
        setDropdownOpen(false);
      };
      
    const openDropDown = () => {
        if(!disabled) {
            setDropdownOpen(!dropdownOpen);
          }
      };

    const emitFilter = (item: string) => {
        onFilterChange && onFilterChange(item);
        setDropdownOpen(false);
        setFilter(item);
        setOption('');
      };

  return (
    <Dropdown>
        <InputGrandeDrop type="text" onKeyUp={onkeypress} onBlur={emitOptionFocus} value={option} onChange={(e) => setOption(e.target.value)} placeholder={`Buscar por ${filter ? filter.label : ''}`} />
        <Span style={{opacity: disabled ? '0.5' : '1'}}>
        <FontAwesomeIcon icon={faSearch} onClick={() => emitOption()} style={{marginRight: '10px'}}/>
        {options.length > 1 && <FontAwesomeIcon icon={faChevronDown} onClick={() => openDropDown()} style={{marginRight: '10px'}}/>}

            
        </Span>
        {dropdownOpen && options.length > 1 && <DropdownContent>
            {options.map((item: any, index: number) => (
                <DropdownContentOption key={uuidv4()} onClick={() => emitFilter(item)}>{item.label}</DropdownContentOption>
            ))}
    </DropdownContent>}
    </Dropdown>
  );
};

export default SearchDropdown;
