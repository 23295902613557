import styled from "styled-components";

export const NavBar = styled.div`
  color: white;
  text-align: center;
  padding: 10px 30px;
  background-color: #b7d32d;
  font-size: 21px;
  display: flex;
  justify-content: space-between;
`;

export const TextLabelNav = styled.label`
  font: Bold 16px/19px Lato;
  background: #262a33 0% 0% no-repeat padding-box;
  border: none;
  color: white;
  padding: 15px 19px;
  border-radius: 5px;
  text-align: center;
`;

export const ButtonBack = styled.button`
  font: Bold 16px/19px Lato;
  background: #262a33 0% 0% no-repeat padding-box;
  border: none;
  color: white;
  padding: 15px 19px;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
  &:hover {
    outline: none;
  }
`;

export const CreditosH = styled.div`
  margin: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextLabelC = styled.label`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ContenedorConCon = styled.div`
  @media (min-width: 2800px) {
    display: flex;
  }
`;

export const ContenedorCon = styled.div`
  @media (min-width: 2800px) {
    width: 1600px;
  }
`;

export const Contenedor = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 1500px) {
    flex-direction: column;
  }
  @media (max-width: 1000px) {
    display: flex;
  }
`;

export const BitacoraDiv = styled.div`
  margin: 0px 30px;
  width: calc(100% - 60px);
  @media (min-width: 2800px) {
    width: calc(100% - 1650px);
  }
`;

export const ContenedorTransferenciaDeCreditos = styled.div`
  display: flex;
  width: 670px;
  margin: 16px;
  position: relative;
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  height: fit-content;
  align-items: center;
  @media (max-width: 1500px) {
    flex-direction: column;
  }
  @media (max-width: 1000px) {
    width: calc(100vw - 71px);
  }
`;

export const ContenedorInformacionDeFacturacion = styled.div`
  width: 530px;
  margin: 16px;
  position: relative;
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  height: fit-content;
  @media (max-width: 1500px) {
    width: calc(100% - 60px);
  }
  @media (max-width: 1000px) {
    margin: 16px 16px 0px 16px;
  }
`;

export const SecondRowDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1500px) {
    flex-direction: row;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const Carrito = styled.div`
  width: 400px;
  margin: 16px;
  padding: 16px;
  height: fit-content;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  @media (max-width: 1500px) {
    width: calc(100% - 66px);
  }
`;

export const DivCreditosP = styled.div`
  display: flex;
  justify-content: center;
`;

export const CarritoTotalDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContenedorPlanes = styled.div`
  width: 400px;
  margin: 16px;
  position: relative;
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  height: 477px;
  overflow-y: scroll;
  @media (max-width: 1500px) {
    width: calc(50% - 60px);
    height: 490px;
  }
  @media (max-width: 1000px) {
    width: calc(100vw - 70px);
    height: 510px;
    margin: 16px 16px 0px 16px;
  }
`;

export const CartaCreditos = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  position: relative;
  color: #475259;
  font-family: "Manrope Regular";
  font-size: 16px;
  flex-direction: row;
  display: flex;
  margin-right: 0px;
  border-bottom: 0.5px solid black;
  &:last-child {
    border-bottom: 0px solid black;
  }
`;

export const CreditosY = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
`;

export const DivInfoPaquetes = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 20px;
`;

export const AplasamientoVigDiv = styled.div`
  width: calc(100% - 60px);
  margin: 16px;
  position: relative;
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  height: fit-content;
  @media (max-width: 1000px) {
    margin: 16px 16px 0px 16px;
  }
`;

export const CreditsNumber = styled.label`
  font-family: "Manrope Bold";
  font-size: 42px;
  color: #475259;
`;

export const CreditsText = styled.label`
  font-family: "Manrope Bold";
  font-size: 20px;
  color: #475259;
  text-align: center;
`;

export const MarginEndDDF = styled.input`
  width: calc(100% - 31px);
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-bottom: 43px;
  &:disabled {
    background-color: #f3f3f3;
  }
  @media (max-width: 1500px) {
    margin-bottom: 10px;
  }
`;

export const FirstRowDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1500px) {
    flex-direction: row;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const ContenedorDeEmpresas = styled.div`
  width: 400px;
  margin: 16px;
  padding: 16px;
  height: fit-content;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  @media (max-width: 1500px) {
    width: calc(50% - 60px);
  }
  @media (max-width: 1000px) {
    width: calc(100vw - 70px);
    padding: 16px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dededf;
    border-radius: 4px;
    margin: 16px 16px 0px 16px;
  }
`;

export const CarritoSmall = styled.div`
  width: 400px;
  margin: 16px;
  padding: 16px;
  height: fit-content;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  @media (max-width: 1500px) {
    width: calc(50% - 60px);
  }
  @media (max-width: 1000px) {
    width: calc(100vw - 70px);
    padding: 16px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dededf;
    border-radius: 4px;
    margin: 16px 16px 0px 16px;
  }
`;

export const PedidoDiv = styled.div`
  margin-top: 15px;
  height: 190px;
  overflow-y: scroll;
  padding-right: 10px;
  padding-top: 2px;
  @media (max-width: 1500px) {
    height: 252px;
  }
`;

export const PedidoElemento = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const DivTimes = styled.div``;

export const CarritoText = styled.label`
  font-family: "Manrope SemiBold";
  font-size: 16px;
  color: #475259;
  margin-right: 10px;
`;

export const PageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const PageContainerContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  @media (max-width: 2800px) {
    height: fit-content;
  }
`;
