import styled from "styled-components";

export const Contenedor = styled.div``;

export const ContenedorDeFormulas = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    `;

export const ContenedorDeKeys = styled.div`
    display: flex;
    flex-wrap: wrap;
    `;
export const KeyTag = styled.div`
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px;
    cursor: pointer;
    &:hover {
        background-color: #e0e0e0;
    }
    `;
export const ContenedorDeSimbolosDeOperacion = styled.div`
    display: flex;
    flex-wrap: wrap;
    `;
export const SimboloDeOperacion = styled.div`
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: #e0e0e0;
    }
    `;
export const Formula = styled.div`
border: 1px solid #f2f2f2;
padding: 10px;
border-radius: 5px;
display: flex;
gap: 10px;
justify-content: center;
align-items: center;
`;
