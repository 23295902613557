const replaceEmpresa = (newEmpresa) => {
    return {
      type: "REPLACEEMPRESA",
      newEmpresa,
    };
  };
  
  export default {
    replaceEmpresa
  };
  