const replaceCurrentTrabajador = (newCurrentTrabajador) => {
    return {
      type: "REPLACECURRENTTRABAJADOR",
      newCurrentTrabajador,
    };
  };

const resetCurrentTrabajador = () => {
    return {
      type: "RESETCURRENTTRABAJADOR",
    };
  };
  
  export default {
    replaceCurrentTrabajador,
    resetCurrentTrabajador,
  };
  