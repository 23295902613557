import styled from "styled-components";

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  border-radius: 4px;
`;

export const DropdownContent = styled.div`
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  background: #ffffff 0% 0% no-repeat padding-box;
  max-height: 223px;
  border: 1px solid #dededf;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow-y: auto;
  border-top: 0px;
  width: calc(100% - 2px);
  z-index: 4;
`;

export const DropdownContentOption = styled.div`
  color: black;
  padding: 5px 16px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-decoration: none;
  display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #f3f3f3;
`;

type DropdownSpanType = {
  disabledProp?: string;
};

export const DropdownSpan = styled.span<DropdownSpanType>`
position: absolute;
opacity: ${(props) => (props.disabledProp === "true" ? "0.5" : "1")};
top: 17px;
font-size: 13px;
color: #767676;
right: 20px;
`;

type InputGrandeDropType = {
  isOpen?: string;
  disabledProp?: string;
};

export const InputGrandeDrop = styled.div<InputGrandeDropType>`
  width: 100%;
  padding: 3px 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: ${(props) => (props.isOpen === "true" ? "0px" : "4px")};
  border-bottom-left-radius: ${(props) => (props.isOpen === "true" ? "0px" : "4px")};
  resize: vertical;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-color: ${(props) =>
    props.disabledProp === "true" ? "#F3F3F3" : "#FFFFFF"};
  padding-right: 40px;
  text-overflow: ellipsis;
  min-height: 45px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const SpanDivContainer = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
`;

type SpanTextType = {
  disabledProp?: string;
};

export const SpanText = styled.span<SpanTextType>`
padding: 6px 8px;
border-bottom-left-radius: 5px;
border-top-left-radius: 5px;
color: white;
background-color: ${(props) =>
  props.disabledProp === "true"
    ? "#000000"
    : "#B7D32D"};
`;

type SpanTimesType = {
  disabledProp?: string;
  isHover?: string;
};

export const SpanTimes = styled.span<SpanTimesType>`
color: white;
    border: 0px;
    border-left: 1px solid white;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    padding: 6px;
background-color: ${(props) =>
  props.disabledProp === "true"
    ? "#000000"
    : props.isHover === "true"
    ? "#b7d32d9a"
    : "#B7D32D"};
cursor: ${(props) =>
  props.disabledProp === "true" ? "not-allowed" : "pointer"};
`;
