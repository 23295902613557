import { useRef, useState } from "react";
import { ButtonPrimario, ButtonSecundario, ContenedorBotones, ContenedorBotonesExterior, DivTitulos, DivTitulosSubTitulo, DivTitulosTitulo, Flex1, Flex3, Flex3Center, FlexColumn, Horizontal, InputGrande, Obligatorio, TextLabel, TextLabelTitle } from "../../../communStyled";
import { Checkbox, Dropdown, SortingTable, Tabs, Warning } from "../../../../Components";
import { Subject } from "rxjs";
import { useSelector } from "react-redux";
import { ConenedorTabsButton, Contenedor, ContenedorDeConceptos, ContenedorDeConceptosChico, ContenedorForm, ContenedorTabs, SecondDiv, TabSpan } from "./ConfiguracionDeConceptosStyled";

export default function ConfiguracionDeConceptos() {
  const alertRef = useRef(null);
  const sortingTableRefPredefinidas = useRef<any>(null);
  const sortingTableRefAdicionales = useRef<any>(null);
  const empresa: any = useSelector((store: any) => store.empresa);
  const messageSubject = new Subject<string>();
  const successfulSubject = new Subject<string>();
  const [activeTab, setActiveTab] = useState('PERCEPCIONES');
  const [tabActive, setTabActive] = useState('PREDEFINIDAS');
  const [tabActivePercepciones, setTabActivePercepciones] = useState('PROPIEDADES');
  const [rowsArrayPredefinidas, setRowsArrayPredefinidas] = useState(empresa.nomina.conceptos.percepciones.predefinidas);
  const [rowsArrayAdicionales, setRowsArrayAdicionales] = useState(empresa.nomina.conceptos.percepciones.adicionales);

  const [clave, setClave] = useState('');
  const [claveSat, setClaveSat] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [integrarTodoSeguroSocial, setIntegrarTodoSeguroSocial] = useState(false);
  const [noIntegrarTodoSeguroSocial, setNoIntegrarTodoSeguroSocial] = useState(false);
  const [predefinidoSeguroSocial, setPredefinidoSeguroSocial] = useState(false);
  const [integrarTodoEstatal, setIntegrarTodoEstatal] = useState(false);
  const [noIntegrarTodoEstatal, setNoIntegrarTodoEstatal] = useState(false);
  const [predefinidoISR, setPredefinidoISR] = useState(false);
  const [gravarTodo, setGravarTodo] = useState(false);
  const [excentarTodo, setExcentarTodo] = useState(false);
  const [ignorar, setIgnorar] = useState(false);
  const [integrarComoPrevisionSocial, setIntegrarComoPrevisionSocial] = useState(false);

  const columnsArrayPredefinidas = [
    { label: 'Clave', valueLabel: 'clave', filtrable: true, widthPX: 100, type: 'String' },
    { label: 'Descripcion', valueLabel: 'descripcion', filtrable: true, widthPX: 380, type: 'String' },
  ];

  const columnsArrayAdicionales = [
    { label: 'Clave', valueLabel: 'clave', filtrable: true, widthPX: 100, type: 'String' },
    { label: 'Descripcion', valueLabel: 'descripcion', filtrable: true, widthPX: 380, type: 'String' },
  ];

  const submitForm = () => { };
  const cancel = () => { };

  const onSelectRowPredefinidas = (row: any) => { };
  const onSelectRowAdicionales = (row: any) => { };

  return (
    <div>
      <DivTitulos>
        <DivTitulosTitulo>NOMINA</DivTitulosTitulo>
        <DivTitulosSubTitulo>
          CONFIGURACION DE CONCEPTOS
        </DivTitulosSubTitulo>
      </DivTitulos>
      <div ref={alertRef} style={{margin: '0px 35px'}}>
        <Warning
          type={"Alert"}
          title={"Alerta"}
          warningSubject={messageSubject}
        />
        <Warning
          type={"Successful"}
          title={"Exito!"}
          warningSubject={successfulSubject}
        />
      </div>
      <ConenedorTabsButton>
        <TabSpan activeclass={activeTab === 'PERCEPCIONES' ? 'TabActive' : 'TabInActive'} onClick={() => setActiveTab('PERCEPCIONES')}>Percepciones</TabSpan>
        <TabSpan activeclass={activeTab === 'DEDUCCIONES' ? 'TabActive' : 'TabInActive'} onClick={() => setActiveTab('DEDUCCIONES')}>Deducciones</TabSpan>
      </ConenedorTabsButton>

      <Contenedor>
        {activeTab === "PERCEPCIONES" && <ContenedorTabs>
          <Tabs
            onValue={(tab: string) => setTabActive(tab)}
            Tabs={[{ label: 'PREDEFINIDAS', enable: true }, { label: 'ADICIONALES', enable: true }]}
          />
          <ContenedorDeConceptos>
            {tabActive === "PREDEFINIDAS" && <SortingTable
              ref={sortingTableRefPredefinidas}
              tableHeight={"613px"}
              iSortDescending={true}
              onSelectElement={(row: any) =>
                onSelectRowPredefinidas(row)
              }
              rowsArray={rowsArrayPredefinidas}
              columnsArray={columnsArrayPredefinidas}
              showElementsPerPage={10}
              multiselect={false}
            />}
            {tabActive === "ADICIONALES" && <SortingTable
              ref={sortingTableRefAdicionales}
              tableHeight={"613px"}
              iSortDescending={true}
              onSelectElement={(row: any) =>
                onSelectRowAdicionales(row)
              }
              rowsArray={rowsArrayAdicionales}
              columnsArray={columnsArrayAdicionales}
              showElementsPerPage={10}
              multiselect={false}
            />}
          </ContenedorDeConceptos>
          <TextLabelTitle>INFORMACIÓN GENERAL</TextLabelTitle>
          <ContenedorDeConceptos>
            <div>
              <Horizontal>
                <div>
                  <TextLabel>
                    Clave<Obligatorio>*</Obligatorio>
                  </TextLabel>
                  <InputGrande
                    type="text"
                    value={clave}
                    onChange={(e) => setClave(e.target.value)}
                    disabled={tabActive === "PREDEFINIDAS"}
                  />
                </div>
              </Horizontal>
              <TextLabel>
                Clave SAT<Obligatorio>*</Obligatorio>
              </TextLabel>
              <Dropdown
                onValue={(value: any) => setClaveSat(value)}
                value={claveSat}
                options={['Clave 1', 'otra']}
                disabled={tabActive === "PREDEFINIDAS"}
              />
              <TextLabel>
                Descripción<Obligatorio>*</Obligatorio>
              </TextLabel>
              <InputGrande
                type="text"
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
                disabled={tabActive === "PREDEFINIDAS"}
              />
            </div>
            <div style={{marginTop: "24px"}}>
              <Tabs
                onValue={(tab: string) => setTabActivePercepciones(tab)}
                Tabs={[{ label: 'PROPIEDADES', enable: true }, { label: 'CRITERIOS', enable: true }]}
              />
              {tabActivePercepciones === "PROPIEDADES" && <ContenedorDeConceptosChico>
                <SecondDiv>
                  <Flex3>
                    <TextLabelTitle>Integra Seguro Social</TextLabelTitle>
                    <FlexColumn>
                      <Flex1>
                        <Checkbox
                          checked={integrarTodoSeguroSocial}
                          handleClick={(value: boolean) => setIntegrarTodoSeguroSocial(value)
                          }
                          disabled={tabActive === "PREDEFINIDAS"}
                        />
                        <span style={{ marginLeft: '10px' }}>Integrar Todo</span>
                      </Flex1>
                      <Flex1>
                        <Checkbox
                          checked={noIntegrarTodoSeguroSocial}
                          handleClick={(value: boolean) => setNoIntegrarTodoSeguroSocial(value)
                          }
                          disabled={tabActive === "PREDEFINIDAS"}
                        />
                        <span style={{ marginLeft: '10px' }}>No Integrar</span>
                      </Flex1>
                      <Flex1>
                      <Checkbox
                          checked={predefinidoSeguroSocial}
                          handleClick={(value: boolean) => setPredefinidoSeguroSocial(value)
                          }
                          disabled={tabActive === "PREDEFINIDAS"}
                        />
                        <span style={{ marginLeft: '10px' }}>Predefinido</span>
                      </Flex1>
                    </FlexColumn>
                  </Flex3>
                  <Flex3Center>
                    <TextLabelTitle>Integra Estatal</TextLabelTitle>
                    <FlexColumn>
                      <Flex1>
                        <Checkbox
                          checked={integrarTodoEstatal}
                          handleClick={(value: boolean) => setIntegrarTodoEstatal(value)
                          }
                          disabled={tabActive === "PREDEFINIDAS"}
                        />
                        <span style={{ marginLeft: '10px' }}>Integrar Todo</span>
                      </Flex1>
                      <Flex1>
                        <Checkbox
                          checked={noIntegrarTodoEstatal}
                          handleClick={(value: boolean) => setNoIntegrarTodoEstatal(value)
                          }
                          disabled={tabActive === "PREDEFINIDAS"}
                        />
                        <span style={{ marginLeft: '10px' }}>No Integrar</span>
                      </Flex1>
                    </FlexColumn>
                  </Flex3Center>
                  <Flex3>
                    <TextLabelTitle>ISR</TextLabelTitle>
                    <FlexColumn>
                      <Flex1>
                        <Checkbox
                          checked={predefinidoISR}
                          handleClick={(value: boolean) => setPredefinidoISR(value)
                          }
                          disabled={tabActive === "PREDEFINIDAS"}
                        />
                        <span style={{ marginLeft: '10px' }}>Predefinido</span>
                      </Flex1>
                      <Flex1>
                        <Checkbox
                          checked={gravarTodo}
                          handleClick={(value: boolean) => setGravarTodo(value)
                          }
                          disabled={tabActive === "PREDEFINIDAS"}
                        />
                        <span style={{ marginLeft: '10px' }}>Gravar todo</span>
                      </Flex1>
                      <Flex1>
                        <Checkbox
                          checked={excentarTodo}
                          handleClick={(value: boolean) => setExcentarTodo(value)
                          }
                          disabled={tabActive === "PREDEFINIDAS"}
                        />
                        <span style={{ marginLeft: '10px' }}>Excentar todo</span>
                      </Flex1>
                      <Flex1>
                        <Checkbox
                          checked={ignorar}
                          handleClick={(value: boolean) => setIgnorar(value)
                          }
                          disabled={tabActive === "PREDEFINIDAS"}
                        />
                        <span style={{ marginLeft: '10px' }}>Ignorar</span>
                      </Flex1>
                      <Flex1>
                    <Checkbox
                      checked={integrarComoPrevisionSocial}
                      handleClick={(value: boolean) => setIntegrarComoPrevisionSocial(value)
                      }
                      disabled={tabActive === "PREDEFINIDAS"}
                    />
                    <span style={{ marginLeft: '10px' }}>Integrar como prevision social</span>
                  </Flex1>
                    </FlexColumn>
                  </Flex3>
                </SecondDiv>
              </ContenedorDeConceptosChico>}
            </div>
          </ContenedorDeConceptos>
        </ContenedorTabs>}
        {activeTab === "DEDUCCIONES" && <ContenedorForm>
        </ContenedorForm>}
      </Contenedor>


      <ContenedorBotonesExterior>
        <ContenedorBotones>
          <ButtonPrimario onClick={() => submitForm()}>Aceptar</ButtonPrimario>
          <ButtonSecundario onClick={() => cancel()}>Cancelar</ButtonSecundario>
        </ContenedorBotones>
      </ContenedorBotonesExterior>
    </div>
  )
}
