import { forwardRef, useImperativeHandle } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
interface Props {
  ChartObject: any;
}

const PieChartComponent = forwardRef(({ ChartObject }: Props, ref) => {
  useImperativeHandle(ref, () => ({}));
  const data = {
    labels: ChartObject.labels,
    datasets: [
      {
          data: ChartObject.data,
          backgroundColor: ChartObject.dataColors,
          hoverBackgroundColor: ChartObject.dataColors,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },

    },
  }

  return (
    <div>
      <Pie data={data} options={options} />
    </div>
  );
});

export default PieChartComponent;
