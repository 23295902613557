import "./App.css";
import { useNavigate, useRoutes } from "react-router-dom";
import { ControlDeCuenta, Login, Workarea } from "./Pages";
import Paths from "./paths";
import { useEffect, useState } from "react";
import { getToken } from "./Services/tokenStorage";

function App() {
  const history = useNavigate();

  const generateRoutes = (authToken: string | null) => {
    const commonRoutes = [
      { path: Paths.Base, element: <Login /> },
      { path: Paths.ControlDeCuenta, element: <ControlDeCuenta /> },
      { path: Paths.Workarea, element: <Workarea /> },
    ];

    if (authToken) {
      return commonRoutes;
    } else {
      return [{ path: "*", element: <Login /> }];
    }
  };

  const [authToken, setAuthToken] = useState(getToken());

  useEffect(() => {
    setAuthToken(getToken());
  }, [history]);

  return useRoutes(generateRoutes(authToken));
}

export default App;
