const replaceCurrentEmpresa = (newCurrentEmpresa) => {
  return {
    type: "REPLACECURRENTEMPRESA",
    newCurrentEmpresa,
  };
};

const cleanCurrentEmpresa = () => {
  return {
    type: "CLEANCURRENTEMPRESA",
  };
};

export default {
  replaceCurrentEmpresa,
  cleanCurrentEmpresa,
};
