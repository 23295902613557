import styled from "styled-components";

export const ContenedorD1 = styled.div`
 display: flex;
 justify-content: space-between;
 @media only screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const ContenedorD2 = styled.div`
width: 35%;
@media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const ContenedorD3 = styled.div`
display: flex;
@media only screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const ContenedorD4 = styled.div`
@media only screen and (max-width: 1000px) {
    width: 100%;
    display: flex;
    gap: 10px;
  }
  `;
