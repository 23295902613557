import { useEffect, useRef, useState } from "react";
import { ButtonPrimario, ButtonSecundario, ContenedorBotones, ContenedorBotonesExterior, ContenedorBotonesSides, DivTitulos, DivTitulosSubTitulo, DivTitulosSubTituloInnerDivs, DivTitulosTitulo, TextLabel } from "../../../../communStyled";
import { ConfirmationModal, Dropdown, Warning } from "../../../../../Components";
import { Subject } from "rxjs";
import { useDispatch, useSelector } from "react-redux";
import { TopButtonContainer, ColorDiv, ColorInfoDiv, Contenedor, ContenedorBotonesTop, DateTitle, EditIcon, HorarioRowTitleDiv, HorariosItalic, HorariosRowNormal, HorariosRowTitles, InputEditHorario, Tarjeta, TarjetaInfo, ContenedorBotonesTopHalf, ContenedorAccionesGenerales, TopButtonActionContainer } from "./ControldeAsistenciaStyled";
import { areSameDay, ccHoursBlur, ccHoursChange, clone, countFaltasInAny30Days, getFormatedDate, getHorarioEnMinutos, presentarNumero, presentarNumeroNullable, scrollIntoView, splitValues, splitValuesNullable, sumarMinutos } from "../../../../../Utils/sharedFunctions";
import { tiposDeDia } from "../../../../../Utils/constants";
import { faClose, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import paths from "../../../../../paths";
import { apiRequest, apiRoutes } from "../../../../../Api/services";
import { tipoDeDia } from "../../../../../types";
import actions from "../../../../../Redux/Actions";

export default function ControldeAsistencia() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const alertRef = useRef(null);
  const empresa: any = useSelector((store: any) => store.empresa);
  const currentTrabajador: any = useSelector((store: any) => store.currentTrabajador);
  const currentEmpresa: any = useSelector((store: any) => store.currentEmpresa);
  const messageSubject = new Subject<string>();
  const warningSubject = new Subject<string>();
  const successfulSubject = new Subject<string>();
  const [currentTrabajadorArray, setCurrentTrabajadorArray] = useState<any>(currentTrabajador?.horariosPlanificados ? Object.entries(currentTrabajador.horariosPlanificados) : []);
  const [currentTrabajadorArrayOriginal, setCurrentTrabajadorArrayOriginal] = useState<any>(currentTrabajador?.horariosPlanificados ? Object.entries(currentTrabajador.horariosPlanificados) : []);
  const [isEditHorarioValid, setIsEditHorarioValid] = useState<boolean>(false);
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const [restablecerHorarioIndex, setRestablecerHorarioIndex] = useState<number>(-1);
  const [restablecerTodosLosHorariosModal, setRestablecerTodosLosHorariosModal] = useState<boolean>(false);
  const [rellenarTodosLosHorariosModal, setRellenarTodosLosHorariosModal] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const permiteHorarios = ['Día de Descanso', 'Ordinario', 'Vacaciones', 'Descanso obligatorio', 'Retardo'];
  const noTieneHorarios = ['Permiso con goce de sueldo', 'Permiso sin goce de sueldo', 'Incapacidad por enfermedad general', 'Incapacidad por maternidad', 'Incapacidad por riesgo de trabajo', 'Falta injustificada', 'Suspensión'];

  useEffect(() => {
    currentTrabajador?.horariosPlanificados && setCurrentTrabajadorArrayOriginal(Object.entries(currentTrabajador?.horariosPlanificados));
  }, [currentTrabajador?.horariosPlanificados]);

  useEffect(() => {
    const causaDeRecisionPorFaltas = countFaltasInAny30Days(currentTrabajadorArrayOriginal);
    if (causaDeRecisionPorFaltas.causaDeRecisionPorFaltas) {
      warningSubject.next(`Este trabajador tiene ${causaDeRecisionPorFaltas.numeroDeFaltas}  faltas en los ultimos 30 dias, lo que puede ser causa de recisión.`);
    }
  }, [currentTrabajadorArrayOriginal]);

  const editHorario = (index: number) => {
    const temporalcurrentTrabajadorObject = clone(currentTrabajadorArray);
    if (
      temporalcurrentTrabajadorObject[index][1]
        .horarios
    ) {
      for (let horario of temporalcurrentTrabajadorObject[index][1].horarios) {
        horario.entradaTrabajada = presentarNumeroNullable(horario.entradaTrabajada);
        horario.salidaTrabajada = presentarNumeroNullable(horario.salidaTrabajada);
        horario.entrada = presentarNumeroNullable(horario.entrada);
        horario.salida = presentarNumeroNullable(horario.salida);
      }
    }
    setCurrentTrabajadorArray(temporalcurrentTrabajadorObject);
    setActiveIndex(index);
  }

  const agregarUnNuevoHorario = () => {
    const temporalcurrentTrabajadorObject = clone(currentTrabajadorArray);
    if (
      !temporalcurrentTrabajadorObject[
        activeIndex
      ][1].horarios
    ) {
      temporalcurrentTrabajadorObject[
        activeIndex
      ][1].horarios = [];
    }
    temporalcurrentTrabajadorObject[
      activeIndex
    ][1].horarios.push({
      entrada: "",
      entradaTrabajada: "",
      salida: "",
      salidaTrabajada: "",
    });
    setCurrentTrabajadorArray(temporalcurrentTrabajadorObject);
  }

  const eliminarUltimoHorario = () => {
    const temporalcurrentTrabajadorObject = clone(currentTrabajadorArray);
    if (
      temporalcurrentTrabajadorObject[
        activeIndex
      ][1].horarios
    ) {
      temporalcurrentTrabajadorObject[
        activeIndex
      ][1].horarios.pop();
    }
    setCurrentTrabajadorArray(temporalcurrentTrabajadorObject);
  }

  const autollenadoHorario = () => {
    const temporalcurrentTrabajadorObject = clone(currentTrabajadorArray);
    if (
      temporalcurrentTrabajadorObject[
        activeIndex
      ][1].horarios
    ) {
      temporalcurrentTrabajadorObject[
        activeIndex
      ][1].horarios = temporalcurrentTrabajadorObject[
        activeIndex
      ][1].horarios.map((intervalo: any) => {
        return {
          ...intervalo,
          entrada: intervalo.entrada,
          entradaTrabajada: intervalo.entradaTrabajada ? intervalo.entradaTrabajada : sumarMinutos(intervalo.entrada, empresa.empresa.horariosYControlDeAsistencia.controlDeAsistencia.entrada, true),
          salida: intervalo.salida,
          salidaTrabajada: intervalo.salidaTrabajada ? intervalo.salidaTrabajada : sumarMinutos(intervalo.salida, empresa.empresa.horariosYControlDeAsistencia.controlDeAsistencia.salida, true),
        }
      });
      setCurrentTrabajadorArray(temporalcurrentTrabajadorObject);
    }
  }
  const autollenadoTodosLosHorarios = () => {
    const temporalcurrentTrabajadorObject = clone(currentTrabajadorArray);
    for (let index in temporalcurrentTrabajadorObject) {
      if (temporalcurrentTrabajadorObject[index][1].horarios) {
        temporalcurrentTrabajadorObject[
          index
        ][1].horarios = temporalcurrentTrabajadorObject[
          index
        ][1].horarios.map((intervalo: any) => {
          return {
            ...intervalo,
            entrada: intervalo.entrada,
            entradaTrabajada: intervalo.entradaTrabajada ? intervalo.entradaTrabajada : sumarMinutos(intervalo.entrada, empresa.empresa.horariosYControlDeAsistencia.controlDeAsistencia.entrada, true),
            salida: intervalo.salida,
            salidaTrabajada: intervalo.salidaTrabajada ? intervalo.salidaTrabajada : sumarMinutos(intervalo.salida, empresa.empresa.horariosYControlDeAsistencia.controlDeAsistencia.salida, true),
          }
        });
      }
    }
    setCurrentTrabajadorArray(temporalcurrentTrabajadorObject);
    setRellenarTodosLosHorariosModal(false);
  }

  const restablecerHorario = async () => {
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.restablecerHorarioTrabajador,
      data: {
        trabajadorId: currentTrabajador.info.id,
        id: currentEmpresa.id,
        diaEspecifico: (restablecerHorarioIndex !== -1) ? currentTrabajadorArray[restablecerHorarioIndex][0] : null,
      },
    });

    if (error) {
      messageSubject.next("Hubo un error en el servidor");
      scrollIntoView(alertRef);
    }
    if (res) {
      let currentTrabajadorTOSend = clone(currentTrabajador);
      for (let trabajador of res.trabajadores) {
        if (trabajador.info.id === currentTrabajador.info.id) {
          currentTrabajadorTOSend.horariosPlanificados = trabajador.horariosPlanificados;
        }
      }
      dispatch(
        actions.currentTrabajador.replaceCurrentTrabajador(currentTrabajadorTOSend)
      );
      dispatch(
        actions.empresaActions.replaceEmpresa({
          ...empresa,
          trabajadores: {
            ...empresa.trabajadores,
            controlDePersonal: {
              ...empresa.trabajadores.controlDePersonal,
              trabajadores: [...res.trabajadores],
            }
          }
        }));
      setRestablecerHorarioIndex(-1);
      setActiveIndex(-1);
      setRestablecerTodosLosHorariosModal(false);
      setCurrentTrabajadorArrayOriginal(currentTrabajadorTOSend?.horariosPlanificados ? Object.entries(currentTrabajadorTOSend.horariosPlanificados) : []);
      setCurrentTrabajadorArray(currentTrabajadorTOSend?.horariosPlanificados ? Object.entries(currentTrabajadorTOSend.horariosPlanificados) : []);
    }
  }

  useEffect(() => {
    const isvalid = isEditHorarioValidFunction();
    setIsEditHorarioValid(isvalid);
  }, [activeIndex, currentTrabajadorArray, forceUpdate]);

  // const isEditHorarioValidFunction = () => {
  //   const temporalcurrentTrabajadorObject = clone(currentTrabajadorArray);
  //   if (activeIndex >= 0) {
  //     if (noTieneHorarios.includes(temporalcurrentTrabajadorObject[activeIndex][1].tipo)) { return true; }
  //     const diaAnterior = temporalcurrentTrabajadorObject[activeIndex - 1] && temporalcurrentTrabajadorObject[activeIndex - 1][1];
  //     const diaSiguiente = temporalcurrentTrabajadorObject[activeIndex + 1] && temporalcurrentTrabajadorObject[activeIndex + 1][1];
  //     const today = temporalcurrentTrabajadorObject[activeIndex];

  //     for (let index in today[1].horarios) {
  //       const currentHorario = today[1].horarios[index];
  //       const horarioAnterior = (parseInt(index) > 0 && today[1].horarios[parseInt(index) - 1]) ? today[1].horarios[parseInt(index) - 1] : null;
  //       const horarioSiguiente = (parseInt(index) < today[1].horarios.length - 1 && today[1].horarios[parseInt(index) + 1]) ? today[1].horarios[parseInt(index) + 1] : null;
  //       const esUltimaSalidaDelDia = parseInt(index) === today[1].horarios.length - 1;

  //       if (!currentHorario.entrada) { return false; }
  //       if (!currentHorario.salida) { return false; }
  //       if (!currentHorario.entradaTrabajada) { return false; }
  //       if (!currentHorario.salidaTrabajada) { return false; }

  //       const entradaEnMinutos = getHorarioEnMinutos(currentHorario.entrada);
  //       const salidaEnMinutos = getHorarioEnMinutos(currentHorario.salida);
  //       const entradaTrabajadaEnMinutos = getHorarioEnMinutos(currentHorario.entradaTrabajada);
  //       const salidaTrabajadaEnMinutos = getHorarioEnMinutos(currentHorario.salidaTrabajada);

  //       if (isNaN(entradaEnMinutos)) { return false; }
  //       if (isNaN(salidaEnMinutos)) { return false; }
  //       if (isNaN(entradaTrabajadaEnMinutos)) { return false; }
  //       if (isNaN(salidaTrabajadaEnMinutos)) { return false; }

  //       if (esUltimaSalidaDelDia) {
  //           today[1].fechaDeFinalizacion = new Date(new Date(
  //             new Date(today[0]).setDate(new Date(today[0]).getDate() + 1)
  //           ).setHours(0, 0, 0, 0));

  //           if (diaSiguiente && diaSiguiente.horarios) {
  //             const entradaSiguienteEnMinutos = getHorarioEnMinutos(diaSiguiente.horarios[0].entrada);
  //             if (isNaN(entradaSiguienteEnMinutos)) { return false; }
  //             if (entradaTrabajadaEnMinutos >= salidaTrabajadaEnMinutos) { 
  //               if (!areSameDay(currentTrabajadorArray[activeIndex][1]["fechaDeFinalizacion"], today[1].fechaDeFinalizacion)) {
  //                 setNewValueKey(activeIndex, "fechaDeFinalizacion", today[1].fechaDeFinalizacion);
  //               }
  //               if(salidaTrabajadaEnMinutos > entradaSiguienteEnMinutos){
  //                 return false;
  //               }}
  //           }
  //       } else {
  //         const entradaSiguienteEnMinutos = getHorarioEnMinutos(horarioSiguiente?.entrada);
  //         today[1].fechaDeFinalizacion = null;
  //         currentTrabajadorArray[activeIndex][1]["fechaDeFinalizacion"] !== null && setNewValueKey(activeIndex, "fechaDeFinalizacion", null);
  //         if (entradaSiguienteEnMinutos < salidaTrabajadaEnMinutos) { return false; }
  //         if(entradaTrabajadaEnMinutos > salidaTrabajadaEnMinutos) { return false; }
  //       }

  //       // if (entradaEnMinutos > entradaTrabajadaEnMinutos) { return false; }
  //       if (entradaTrabajadaEnMinutos > salidaEnMinutos) { return false; }

  //       if (horarioAnterior) {
  //         const salidaTrabajadaAnteriorEnMinutos = getHorarioEnMinutos(horarioAnterior.salidaTrabajada);
  //         if (isNaN(salidaTrabajadaAnteriorEnMinutos)) { return false; }
  //         if (salidaTrabajadaAnteriorEnMinutos > entradaEnMinutos) { return false; }
  //       }

  //       if (temporalcurrentTrabajadorObject[activeIndex][1].tipo === 'Día de Descanso') {
  //         if (diaAnterior && diaAnterior.horarios && diaAnterior.fechaDeFinalizacion && index === '0' && diaAnterior.horarios[diaAnterior.horarios.length - 1].salida) {
  //           const ultimaSalidaTrabajadaDiaAnteriorEnMinutos = getHorarioEnMinutos(diaAnterior.horarios[diaAnterior.horarios.length - 1].SalidaTrabajada);
  //           if (isNaN(ultimaSalidaTrabajadaDiaAnteriorEnMinutos)) { return false; }
  //           if (ultimaSalidaTrabajadaDiaAnteriorEnMinutos > entradaEnMinutos) { return false; }
  //         }
  //       }
  //     }
  //   }
  //   return true;
  // };

  const isEditHorarioValidFunction = () => {
    return true; // esta muy complicado
    const temporalcurrentTrabajadorObject = clone(currentTrabajadorArray);
    if (activeIndex >= 0) {
      if (noTieneHorarios.includes(temporalcurrentTrabajadorObject[activeIndex][1].tipo)) { return true; }
      const diaAnterior = temporalcurrentTrabajadorObject[activeIndex - 1] && temporalcurrentTrabajadorObject[activeIndex - 1][1] && temporalcurrentTrabajadorObject[activeIndex - 1][1].horarios && temporalcurrentTrabajadorObject[activeIndex - 1][1].horarios[temporalcurrentTrabajadorObject[activeIndex - 1][1].horarios.length - 1];
      const diaSiguiente = temporalcurrentTrabajadorObject[activeIndex + 1] && temporalcurrentTrabajadorObject[activeIndex + 1][1];
      const today = temporalcurrentTrabajadorObject[activeIndex];

      for (let index in today[1].horarios) {
        const currentHorario = today[1].horarios[index];
        const horarioAnterior = (parseInt(index) > 0 && today[1].horarios[parseInt(index) - 1]) ? today[1].horarios[parseInt(index) - 1] : null;
        const horarioSiguiente = (parseInt(index) < today[1].horarios.length - 1 && today[1].horarios[parseInt(index) + 1]) ? today[1].horarios[parseInt(index) + 1] : null;
        const esUltimaSalidaDelDia = parseInt(index) === today[1].horarios.length - 1;
        const esPrimeraEntradaDelDia = parseInt(index) === 0;
        let correspondeASalidaSiguiente = false;
        let correspondeAEntradaAnterior = false;

        if (!currentHorario.entrada) { return false; }
        if (!currentHorario.salida) { return false; }
        if (!currentHorario.entradaTrabajada) { return false; }
        if (!currentHorario.salidaTrabajada) { return false; }

        const entradaEnMinutos = getHorarioEnMinutos(currentHorario.entrada);
        const salidaEnMinutos = getHorarioEnMinutos(currentHorario.salida);
        const entradaTrabajadaEnMinutos = getHorarioEnMinutos(currentHorario.entradaTrabajada);
        const salidaTrabajadaEnMinutos = getHorarioEnMinutos(currentHorario.salidaTrabajada);

        if (isNaN(entradaEnMinutos)) { return false; }
        if (isNaN(salidaEnMinutos)) { return false; }
        if (isNaN(entradaTrabajadaEnMinutos)) { return false; }
        if (isNaN(salidaTrabajadaEnMinutos)) { return false; }

        // si es el primer horario del dia
        if (esUltimaSalidaDelDia) {

        }

        if (esPrimeraEntradaDelDia) {
          today[1].fechaDeInicio = new Date(new Date(
            new Date(today[0]).setDate(new Date(today[0]).getDate() - 1)
          ).setHours(0, 0, 0, 0));
          const fechaDeInicioFecha = new Date(currentTrabajadorArray[activeIndex][1]["fechaDeInicio"]) || null;
          if (diaAnterior) {
            const minutosEnUnDia = 1440;
            //entradaTrabajada no puede ser mas grande que la salida real anterior de la ultima salidaTrabajada del diaAnterior
            if (diaAnterior.salidaTrabajada && (entradaTrabajadaEnMinutos + minutosEnUnDia <= getHorarioEnMinutos(diaAnterior.salidaTrabajada))) { return false; }
            //entradaTrabajada no puede ser mas grande que la salida programada anterior de la ultima salidaTrabajada del diaAnterior
            if (diaAnterior.salida && (entradaTrabajadaEnMinutos + minutosEnUnDia <= getHorarioEnMinutos(diaAnterior.salida))) { return false; }
            correspondeAEntradaAnterior = true;
            // if (!areSameDay(fechaDeInicioFecha, today[1].fechaDeInicio) && diaAnterior.salidaTrabajada) {
            //   if (entradaEnMinutos < entradaTrabajadaEnMinutos) {
            //     setNewValueKey(activeIndex, "fechaDeInicio", today[1].fechaDeInicio);
            //   } else {
            //     setNewValueKey(activeIndex, "fechaDeInicio", null);
            //   }
            // }
          }
        }


        if (horarioAnterior) {
          //entradaTrabajada no puede ser mas grande que la salida real anterior
          if (entradaTrabajadaEnMinutos <= getHorarioEnMinutos(horarioAnterior.salidaTrabajada)) { return false; }
          //entradaTrabajada no puede ser mas grande que la salida programada anterior
          if (entradaTrabajadaEnMinutos <= getHorarioEnMinutos(horarioAnterior.salida)) { return false; }
        }

        //cuando la entrada trabajada corresponde al dia anterior se ignora
        if (!correspondeAEntradaAnterior) {
          //entradaTrabajada tampoco puede ser mas grande que la salida real del horario actual
          if (entradaTrabajadaEnMinutos >= salidaTrabajadaEnMinutos) { return false; }
          //entradaTrabajada tampoco puede ser mas grande que la salida programada del horario actual
          if (entradaTrabajadaEnMinutos >= salidaEnMinutos) { return false; }
        }



        if (temporalcurrentTrabajadorObject[activeIndex][1].tipo === 'Día de Descanso') {
          if (diaAnterior && diaAnterior.horarios && diaAnterior.fechaDeFinalizacion && index === '0' && diaAnterior.horarios[diaAnterior.horarios.length - 1].salida) {
            const ultimaSalidaTrabajadaDiaAnteriorEnMinutos = getHorarioEnMinutos(diaAnterior.horarios[diaAnterior.horarios.length - 1].SalidaTrabajada);
            if (isNaN(ultimaSalidaTrabajadaDiaAnteriorEnMinutos)) { return false; }
            if (ultimaSalidaTrabajadaDiaAnteriorEnMinutos > entradaEnMinutos) { return false; }
          }
        }
      }
    }
    return true;
  };


  const cancelEditHorario = () => {
    const temporalcurrentTrabajadorObject = clone(currentTrabajadorArray);
    const originalObject = currentTrabajadorArrayOriginal;
    temporalcurrentTrabajadorObject[
      activeIndex
    ] = originalObject[activeIndex];
    setCurrentTrabajadorArray(temporalcurrentTrabajadorObject);
    setActiveIndex(-1);
  }

  const submitEditHorario = () => {
    const temporalcurrentTrabajadorObject = clone(currentTrabajadorArray);
    for (let horario of temporalcurrentTrabajadorObject[activeIndex][1].horarios) {
      horario.entradaTrabajada = splitValuesNullable(horario.entradaTrabajada);
      horario.salidaTrabajada = splitValuesNullable(horario.salidaTrabajada);
      horario.entrada = splitValuesNullable(horario.entrada);
      horario.salida = splitValuesNullable(horario.salida);
    }
    submitEditHorarioFunction(temporalcurrentTrabajadorObject);
  }

  const submitEditHorarioFunction = async (currentTrabajadorObject: any) => {
    const currentTrabajadorTOSend = clone(currentTrabajador);
    currentTrabajadorTOSend.horariosPlanificados = {};
    for (let horario of currentTrabajadorObject) {
      currentTrabajadorTOSend.horariosPlanificados[horario[0]] = horario[1];
    }
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.editarTrabajador,
      data: {
        trabajador: currentTrabajadorTOSend,
        id: currentEmpresa.id,
      },
    });
    if (error) {
      messageSubject.next("Hubo un error al guardar los cambios");
      scrollIntoView(alertRef);
    } else {
      successfulSubject.next("Cambios guardados con éxito");
      setCurrentTrabajadorArrayOriginal(currentTrabajadorObject);
      setActiveIndex(-1);
    }
  }

  const setNewValue = (index: number, indexInner: number, key: string, value: string) => {
    const temporalcurrentTrabajadorObject = clone(currentTrabajadorArray);
    temporalcurrentTrabajadorObject[index][1].horarios[indexInner][key] = value;
    setCurrentTrabajadorArray(temporalcurrentTrabajadorObject);
  }

  const setNewValueKey = (index: number, key: string, value: string | null) => {
    const temporalcurrentTrabajadorObject = currentTrabajadorArray;
    temporalcurrentTrabajadorObject[index][1][key] = value;
    setCurrentTrabajadorArray(temporalcurrentTrabajadorObject);
    setForceUpdate(!forceUpdate);
  }

  const getTypoDeHorario = (tipo: tipoDeDia, horario: any): tipoDeDia => {
    if (tipo === "Ordinario") {
      const tolerancia = empresa.empresa.horariosYControlDeAsistencia.controlDeAsistencia.entrada;
      let isRetardo = false;
      horario.map((intervalo: any) => {
        const entradaEnMinutos = getHorarioEnMinutos(intervalo.entrada);
        const entradaTrabajadaEnMinutos = getHorarioEnMinutos(intervalo.entradaTrabajada);
        if ((entradaTrabajadaEnMinutos) > (entradaEnMinutos + tolerancia)) {
          isRetardo = true;
        }
      });
      return isRetardo ? "Retardo" : "Ordinario";
    }
    return tipo
  }


  return (
    <div>
      <DivTitulos>
        <DivTitulosTitulo>TRABAJADORES</DivTitulosTitulo>
        <DivTitulosSubTituloInnerDivs>
          <div>CONTROL DE ASISTENCIA</div>
          <div>{currentTrabajador && `(${currentTrabajador.datosPersonales.datosPersonales.nombre || ""} ${currentTrabajador.datosPersonales.datosPersonales.apellidoPaterno || ""} ${currentTrabajador.datosPersonales.datosPersonales.apellidoMaterno || ""})`}</div>
        </DivTitulosSubTituloInnerDivs>
      </DivTitulos>
      <div ref={alertRef} style={{ margin: '0px 35px' }}>
        <Warning
          type={"Alert"}
          title={"Alerta"}
          warningSubject={messageSubject}
        />
        <Warning
          type={"Warning"}
          title={"Advertencia"}
          warningSubject={warningSubject}
        />
        <Warning
          type={"Successful"}
          title={"Exito!"}
          warningSubject={successfulSubject}
        />
      </div>
      {currentTrabajador && <Contenedor>
        <ContenedorAccionesGenerales>
          <TopButtonActionContainer
            close={false}
            onClick={() => setRellenarTodosLosHorariosModal(true)}
          ><FontAwesomeIcon icon={faPlus} />
            Autollenado
          </TopButtonActionContainer>
          <TopButtonActionContainer
            close={true}
            onClick={() => setRestablecerTodosLosHorariosModal(true)}
          ><FontAwesomeIcon icon={faClose} />
            Restablecer
          </TopButtonActionContainer>
        </ContenedorAccionesGenerales>
        {currentTrabajadorArray.map((horario: any, index: number) => <Tarjeta>
          <HorarioRowTitleDiv>
            <DateTitle>{getFormatedDate(horario[0])} <ColorDiv title={getTypoDeHorario(horario[1].tipo, horario[1].horarios)} type={getTypoDeHorario(horario[1].tipo, horario[1].horarios)} /></DateTitle>
            {(horario[1].tipo === 'Día de Descanso' || tiposDeDia.includes(horario[1].tipo)) && activeIndex < 0 && <EditIcon><FontAwesomeIcon icon={faEdit} onClick={() => editHorario(index)} /></EditIcon>}
          </HorarioRowTitleDiv>
          {(!horario[1].horarios) && <div style={{ marginBottom: '10px' }}>{horario[1].tipo}</div>}
          {activeIndex === index && <ContenedorBotonesTop>
            <ContenedorBotonesTopHalf><TopButtonContainer
              close={false}
              onClick={() => agregarUnNuevoHorario()}
            ><FontAwesomeIcon icon={faPlus} />
              Agregar un nuevo Horario
            </TopButtonContainer>
              {currentTrabajadorArray[activeIndex]?.[1]?.horarios?.length !== 0 && <TopButtonContainer
                close={true}
                onClick={() => eliminarUltimoHorario()}
              ><FontAwesomeIcon icon={faClose} />
                Eliminar Ultimo Horario
              </TopButtonContainer>}
            </ContenedorBotonesTopHalf>
            {currentTrabajadorArray[activeIndex]?.[1]?.horarios?.length !== 0 && <ContenedorBotonesTopHalf>
              <TopButtonContainer
                close={false}
                onClick={() => autollenadoHorario()}
              ><FontAwesomeIcon icon={faPlus} />
                Autollenado
              </TopButtonContainer>
              <TopButtonContainer
                close={true}
                onClick={() => setRestablecerHorarioIndex(index)}
              ><FontAwesomeIcon icon={faClose} />
                Restablecer
              </TopButtonContainer>
            </ContenedorBotonesTopHalf>}
          </ContenedorBotonesTop>
          }
          {(horario[1].tipo === 'Día de Descanso' ||
            (tiposDeDia.includes(horario[1].tipo) && !noTieneHorarios.includes(horario[1].tipo))) && <div>
              {horario[1].horarios && <div>
                <HorariosRowTitles>
                  <div>Entrada Programada</div>
                  <div>Entrada Real</div>
                  <div>Salida Programada</div>
                  <div>Salida Real</div>
                </HorariosRowTitles>
                {horario[1].horarios.map((intervalo: any, indexInner: number) => <HorariosRowNormal>
                  {(activeIndex !== index || horario[1].tipo !== 'Día de Descanso') && <div>{intervalo.entrada && presentarNumero(intervalo.entrada)}</div>}
                  {(activeIndex === index && horario[1].tipo === 'Día de Descanso') && <InputEditHorario
                    type="text"
                    value={intervalo.entrada}
                    onBlur={(e: any) => setNewValue(index, indexInner, "entrada", ccHoursBlur(e.target.value))}
                    onChange={(e: any) =>
                      setNewValue(index, indexInner, "entrada", ccHoursChange(e.target.value))
                    }
                    placeholder="00:00"
                  />}
                  {activeIndex !== index && <div> {intervalo.entradaTrabajada && presentarNumero(intervalo.entradaTrabajada)}</div>}
                  {activeIndex === index && <InputEditHorario
                    type="text"
                    value={intervalo.entradaTrabajada}
                    onBlur={(e: any) => setNewValue(index, indexInner, "entradaTrabajada", ccHoursBlur(e.target.value))}
                    onChange={(e: any) =>
                      setNewValue(index, indexInner, "entradaTrabajada", ccHoursChange(e.target.value))
                    }
                    placeholder="00:00"
                  />}
                  {(activeIndex !== index || horario[1].tipo !== 'Día de Descanso') && <div>{intervalo.salida && presentarNumero(intervalo.salida)}</div>}
                  {(activeIndex === index && horario[1].tipo === 'Día de Descanso') && <InputEditHorario
                    type="text"
                    value={intervalo.salida}
                    onBlur={(e: any) => setNewValue(index, indexInner, "salida", ccHoursBlur(e.target.value))}
                    onChange={(e: any) =>
                      setNewValue(index, indexInner, "salida", ccHoursChange(e.target.value))
                    }
                    placeholder="00:00"
                  />}
                  {activeIndex !== index && <div>{intervalo.salidaTrabajada && presentarNumero(intervalo.salidaTrabajada)}</div>}
                  {activeIndex === index && <InputEditHorario
                    type="text"
                    value={intervalo.salidaTrabajada}
                    onBlur={(e: any) => setNewValue(index, indexInner, "salidaTrabajada", ccHoursBlur(e.target.value))}
                    onChange={(e: any) =>
                      setNewValue(index, indexInner, "salidaTrabajada", ccHoursChange(e.target.value))
                    }
                    placeholder="00:00"
                  />}
                </HorariosRowNormal>)}
                {horario[1].fechaDeFinalizacion && <HorariosItalic>
                  La última fecha de salida corresponde al
                  {getFormatedDate(horario[1].fechaDeFinalizacion)}
                </HorariosItalic>}
                {horario[1].fechaDeInicio && <HorariosItalic>
                  La primera fecha de entrada corresponde al
                  {getFormatedDate(horario[1].fechaDeInicio)}
                </HorariosItalic>}
              </div>}
            </div>}
          {activeIndex === index && <ContenedorBotonesSides>
            {tiposDeDia.includes(horario[1].tipo) && <div>
              <TextLabel>Tipo de Horario</TextLabel>
              <Dropdown
                value={horario[1].tipo}
                onValue={(value: any) => setNewValueKey(index, "tipo", value)}
                options={tiposDeDia}
              />
            </div>}
            <div>
              <ButtonPrimario disabled={!isEditHorarioValid} onClick={() => submitEditHorario()}>Aceptar</ButtonPrimario>
              <ButtonSecundario onClick={() => cancelEditHorario()}>Cancelar</ButtonSecundario>
            </div>
          </ContenedorBotonesSides>}
        </Tarjeta>)}
        <TarjetaInfo>
          <ColorInfoDiv><ColorDiv title="Día de Descanso" type="Día de Descanso" />  <span>Día de Descanso</span></ColorInfoDiv>
          <ColorInfoDiv><ColorDiv title="Definido sin horario o Secuencia" type="Definido sin horario o Secuencia" />  <span>Definido sin horario o Secuencia</span></ColorInfoDiv>
          <ColorInfoDiv><ColorDiv title="Ordinario" type="Ordinario" />  <span>Ordinario</span></ColorInfoDiv>
          <ColorInfoDiv><ColorDiv title="Permiso con goce de sueldo" type="Permiso con goce de sueldo" />  <span>Permiso con goce de sueldo</span></ColorInfoDiv>
          <ColorInfoDiv><ColorDiv title="Permiso sin goce de sueldo" type="Permiso sin goce de sueldo" />  <span>Permiso sin goce de sueldo</span></ColorInfoDiv>
          <ColorInfoDiv><ColorDiv title="Incapacidad por enfermedad general" type="Incapacidad por enfermedad general" />  <span>Incapacidad por enfermedad general</span></ColorInfoDiv>
          <ColorInfoDiv><ColorDiv title="Incapacidad por maternidad" type="Incapacidad por maternidad" />  <span>Incapacidad por maternidad</span></ColorInfoDiv>
          <ColorInfoDiv><ColorDiv title="Incapacidad por riesgo de trabajo" type="Incapacidad por riesgo de trabajo" />  <span>Incapacidad por riesgo de trabajo</span></ColorInfoDiv>
          <ColorInfoDiv><ColorDiv title="Falta injustificada" type="Falta injustificada" />  <span>Falta injustificada</span></ColorInfoDiv>
          <ColorInfoDiv><ColorDiv title="Vacaciones" type="Vacaciones" />  <span>Vacaciones</span></ColorInfoDiv>
          <ColorInfoDiv><ColorDiv title="Descanso obligatorio" type="Descanso obligatorio" />  <span>Descanso obligatorio</span></ColorInfoDiv>
          <ColorInfoDiv><ColorDiv title="Suspensión" type="Suspensión" />  <span>Suspensión</span></ColorInfoDiv>
          <ColorInfoDiv><ColorDiv title="Retardo" type="Retardo" />  <span>Retardo</span></ColorInfoDiv>
        </TarjetaInfo>
      </Contenedor>}
      <ContenedorBotonesExterior style={{ justifyContent: 'flex-start' }}>
        <ContenedorBotones>
          <ButtonSecundario onClick={() => history(paths.ControlDePersonal)}>Regresar</ButtonSecundario>
        </ContenedorBotones>
      </ContenedorBotonesExterior>
      <ConfirmationModal
        title={"¿Estas seguro que deseas restablecer este horaio?"}
        text={
          "La siguiente operacion es irreversible, se eliminaran todos los datos de este horario y se remplazaran con nuevos valores segun los ajustes seleccionados."
        }
        show={restablecerHorarioIndex >= 0}
        redButton={true}
        onCancel={() => setRestablecerHorarioIndex(-1)}
        onAccept={() => restablecerHorario()}
      />
      <ConfirmationModal
        title={"¿Estas seguro que deseas restablecer todos los horarios?"}
        text={
          "La siguiente operacion es irreversible, se eliminaran todos los datos de todos los horarios y se remplazaran con nuevos valores segun los ajustes seleccionados."
        }
        show={restablecerTodosLosHorariosModal}
        redButton={true}
        onCancel={() => setRestablecerTodosLosHorariosModal(false)}
        onAccept={() => restablecerHorario()}
      />
      <ConfirmationModal
        title={"¿Estas seguro que deseas autollenar todos los horarios?"}
        text={
          "La siguiente operacion es irreversible, se llenaran todos los horarios con los tolerancias indicadas en los ajustes."
        }
        show={rellenarTodosLosHorariosModal}
        redButton={true}
        onCancel={() => setRellenarTodosLosHorariosModal(false)}
        onAccept={() => autollenadoTodosLosHorarios()}
      />
    </div>
  )
}
