import styled from "styled-components";

export const Contenedor = styled.div`
  width: 50%;
  text-align: center;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const ContenedorRegistro = styled.div`
  height: 150px;
  overflow-y: auto;
  margin-right: 20px;
  margin-bottom: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
`;

export const Table = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;
interface FlexTableRowInterface {
  activeclass?: boolean;
}

export const FlexTableRow = styled.div<FlexTableRowInterface>`
  display: flex;
  width: 100%;
  background: ${(props) => (props.activeclass ? "#B7D32D33" : "#ffffff")};
  &:hover {
    background: #B7D32D33;
    cursor: pointer;
  }
`;

export const FlexTable = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f3f3f3;
  padding: 10px;
`;
