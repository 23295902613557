import styled from "styled-components";

export const Contenedor = styled.div`
display: flex;
  @media (max-width: 1340px) {
    display: inline-block;
  }
`;

export const ContenedorDeDomicilio = styled.div`
  width: 728px;
  margin: 16px;
  padding: 16px;
  margin-left: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;

  @media (max-width: 1340px) {
    position: relative;
    width: calc(100vw - 412px);
    margin-right: 45px;
  }

  @media (max-width: 1000px) {
    width: calc(100vw - 70px);
    margin-right: 16px;
    margin-left: 16px;
  }

  @media (max-width: 500px) {
    margin-top: 24px;
  }
`;

export const ContenedorDomicilioFiscal = styled.div`
  width: 728px;
  margin: 16px;
  margin-bottom: auto;
  position: relative;
  padding: 16px;
  margin-left: 45px;
  margin-right: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;

  @media (max-width: 1340px) {
    width: calc(100vw - 412px);
    position: relative;
    margin-bottom: 15px;
  }

  @media (max-width: 1000px) {
    margin: 16px;
    width: calc(100vw - 70px);
  }
`;

export const HorizontalContainerSwitch = styled.div`
  width: 100%;
  height: 54px;
`;

export const SwitchContainer = styled.div`
  overflow: hidden;
  height: 40px;
  float: right;
  padding-right: 2%;
  padding-bottom: 0px;
  padding-top: 0px;
`;

export const SwitchLabelDiv = styled.div`
  float: left;
  padding-right: 10px;
`;

export const SwitchDiv = styled.div`
  float: left;
`;
