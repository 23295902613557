import {
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useImperativeHandle, useState } from "react";
import useViewPortSize from "../../Hooks/useViewPortSize";

interface Props {
  toggleTab: () => void;
  tabOpen: boolean;
}

const ChevronTabIcon = forwardRef(({ toggleTab, tabOpen }: Props, Ref) => {
  const { smallViewPort } = useViewPortSize();

  useImperativeHandle(Ref, () => ({}));

  return smallViewPort ? (
    <div>
      <FontAwesomeIcon
        onClick={() => toggleTab()}
        icon={tabOpen ? faChevronCircleUp : faChevronCircleDown}
        style={{
          position: "absolute",
          top: "22px",
          right: "24px",
          fontSize: "24px",
          color: "black",
        }}
      />
    </div>
  ) : null;
});

export default ChevronTabIcon;
