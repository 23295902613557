import styled from "styled-components";

export const Contenedor = styled.div`
  display: flex;
  width: 100%;
  min-height: 200px;
  position: relative;
  margin: 24px 0px;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

export const CenterFlex = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 24px;
  padding-bottom: 24px;
  align-items: center;
`;

export const ContenedorSecundario = styled.div`
  width: 50%;
  text-align: center;
  max-width: 500px;
  @media (max-width: 1000px) {
    width: 100%;
    align-self: center;
    margin-bottom: 24px;
  }
`;

export const CheckBoxDiv = styled.div`
display: flex;
align-items: center;
`;