import {merge} from '../../Utils/sharedFunctions'
const defaultState = {
  creador: "",
  id: "",
  logo: "",
  nombre: "",
  vigencia: "",
  indexTable: "",
  nombreLogo: "",
  pageID: 0,
  permisos: {
    colaboradores: false,
    controlDePersonal: false,
    esquemaDeNomina: false,
    horariosYControlDeAsistencia: false,
    informacionDeLaEmpresaOPatron: false,
    bitacora: false,
    notificaciones: false,
    parametrosDeOperacion: false,
    prestacionesGenerales: false,
    registroDeDepartamentos: false,
    registroDePuestos: false,
    registroEstatalYDelIMSS: false,
    configuracionDeConceptos: false,
  }
}

const currentEmpresa = (
    state = defaultState,
    action
  ) => {
    switch (action.type) {
      case "REPLACECURRENTEMPRESA":
        const newState = merge(state, action.newCurrentEmpresa);
        return newState;
      case "CLEANCURRENTEMPRESA":
        return defaultState;
      default:
        return state;
    }
  };
  
  export default currentEmpresa;
  