import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  CenterFlex,
  CheckBoxDiv,
  Contenedor,
  ContenedorSecundario,
} from "./HorariosEspecialesStyled";
import SimpleTable from "../SimpleTable";
import {
  ContenedorIconos,
  FlexInlineMarginTextLabel,
  FlexInlineTextLabel,
  InputMini,
  TextLabel,
  TextLabelTitle,
} from "../../Pages/communStyled";
import { ccHoursBlur, ccHoursNoLimit, splitValues } from "../../Utils/sharedFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "../Checkbox";

interface Props {
  disabled: boolean;
  onValue: (value: any) => void;
  onAlert: () => void;
  inicialObject: any;
}

const HorariosEspeciales = forwardRef(({ disabled, onAlert, onValue, inicialObject }: Props, ref) => {
  useImperativeHandle(ref, () => ({}));
  const horarioEspecialRef = useRef(null);
  const [horariosEspecialesArray, setHorariosEspecialesArray] = useState<Array<any>>(inicialObject?.horariosEspecialesArray || []);
  const [trabajadas, setTrabajadas] = useState("");
  const [descanso, setDescanso] = useState("");
  const [pagarHorarioContinuoCheckBox, setPagarHorarioContinuoCheckBox] =
    useState(inicialObject?.pagarHorarioContinuoCheckBox || false);
  const [descansoAFinalDeJornadacheckbox, setDescansoAFinalDeJornadacheckbox] =
    useState(inicialObject?.descansoAFinalDeJornadacheckbox || false);
  const [descansoAFinalDeJornada, setDescansoAFinalDeJornada] = useState(inicialObject?.descansoAFinalDeJornada || "");
  const [alertAlreadyEmitted, setAlertAlreadyEmitted] = useState(false);
  const [active, setActive] = useState<number|null>(null);

  useEffect(() => {
    const arrayToEmit = {
      descansoAFinalDeJornada: descansoAFinalDeJornada,
      descansoAFinalDeJornadacheckbox: descansoAFinalDeJornadacheckbox,
      horariosEspecialesArray: horariosEspecialesArray,
      pagarHorarioContinuoCheckBox: pagarHorarioContinuoCheckBox,
    }
    onValue(arrayToEmit);
  }, [descansoAFinalDeJornada, descansoAFinalDeJornadacheckbox, horariosEspecialesArray, pagarHorarioContinuoCheckBox]);

  const addRegister = () => {
    if (trabajadas && descanso) {
      if (!alertAlreadyEmitted && splitValues(descanso)[0] === 0) {
        onAlert();
        setAlertAlreadyEmitted(true);
        setPagarHorarioContinuoCheckBox(true);
      }
      const newHorariosEspecialesArray = [...horariosEspecialesArray];
      if (active || active === 0) {
        newHorariosEspecialesArray[active] = { trabajadas, descanso };
      } else {
        newHorariosEspecialesArray.push({ trabajadas, descanso });
      }
      setHorariosEspecialesArray(newHorariosEspecialesArray);
      setTrabajadas('');
      setDescanso('');
      setActive(null);
      horarioEspecialRef?.current && (horarioEspecialRef.current as any).scrollIntoEndFunction();
    }
  }

  const selectValue = (index:number | null) => {
    setActive(index);
    setTrabajadas(index !== null ? horariosEspecialesArray[index].Trabajadas : "");
    setDescanso(index !== null  ? horariosEspecialesArray[index].Descanso : "");
  }

  const removeRegister = () => {
    if (active || active === 0) {
      const newHorariosEspecialesArray = [...horariosEspecialesArray];
      newHorariosEspecialesArray.splice(active, 1);
      setHorariosEspecialesArray(newHorariosEspecialesArray);
      setTrabajadas('');
      setDescanso('');
      setActive(null);
    }
  }

  return (
    <Contenedor>
      <SimpleTable
      ref={horarioEspecialRef}
        title={"Tabla de Horarios"}
        columns={[
          { label: "Horas Trabajadas", value: "trabajadas" },
          { label: "Horas de Descanso", value: "descanso" },
        ]}
        rows={horariosEspecialesArray}
        onSelect={(element: any, index: number | null) => selectValue(index)}
      />

      <ContenedorSecundario>
        <TextLabelTitle>Horas Trabajadas</TextLabelTitle>
        <CenterFlex>
          <ContenedorIconos>
            <FontAwesomeIcon
              icon={faMinusCircle}
              style={{ color: "#DB2828" }}
              onClick={() => removeRegister()}
            />
          </ContenedorIconos>
          <TextLabel style={{ width: "fit-content" }}>
            Horas Trabajadas
          </TextLabel>
          <InputMini
            type="text"
            value={trabajadas}
            onBlur={(e: any) => setTrabajadas(ccHoursBlur(e.target.value))}
            onChange={(e: any) => setTrabajadas(ccHoursNoLimit(e.target.value))}
            placeholder="00:00"
            disabled={disabled}
          />
          <TextLabel style={{ width: "fit-content" }}>
            Horas de Descanso
          </TextLabel>
          <InputMini
            type="text"
            value={descanso}
            onBlur={(e: any) => setDescanso(ccHoursBlur(e.target.value))}
            onChange={(e: any) => setDescanso(ccHoursNoLimit(e.target.value))}
            placeholder="00:00"
            disabled={disabled}
          />
          <ContenedorIconos>
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{ color: "#b7d32d" }}
              onClick={() => addRegister()}
            />
          </ContenedorIconos>
        </CenterFlex>
        <CheckBoxDiv>
          <Checkbox
            checked={pagarHorarioContinuoCheckBox}
            handleClick={(value: boolean) =>
              setPagarHorarioContinuoCheckBox(value)
            }
            disabled={disabled}
          />
          <FlexInlineMarginTextLabel>
            Pagar periodos de descanso menores a una hora
          </FlexInlineMarginTextLabel>
        </CheckBoxDiv>
        <CheckBoxDiv>
          <Checkbox
            checked={descansoAFinalDeJornadacheckbox}
            handleClick={(value: boolean) =>
              setDescansoAFinalDeJornadacheckbox(value)
            }
            disabled={disabled}
          />
          <FlexInlineTextLabel
            style={{ marginRight: "10px", marginLeft: "10px" }}
          >
            Agregar Descanso de
          </FlexInlineTextLabel>
          <InputMini
            type="text"
            value={descansoAFinalDeJornada}
            onBlur={(e: any) =>
              setDescansoAFinalDeJornada(ccHoursBlur(e.target.value))
            }
            onChange={(e: any) =>
              setDescansoAFinalDeJornada(ccHoursNoLimit(e.target.value))
            }
            placeholder="00:00"
            disabled={disabled}
            style={{ padding: "6px" }}
          />
          <FlexInlineTextLabel style={{ marginLeft: "10px" }}>
            al final de la Jornada laboral
          </FlexInlineTextLabel>
        </CheckBoxDiv>
      </ContenedorSecundario>
    </Contenedor>
  );
});

export default HorariosEspeciales;
