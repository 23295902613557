import { Subject } from "rxjs";
import {
  ChevronTabIcon,
  DomicilioFiscal,
  Dropdown,
  Switch,
  Warning,
} from "../../../../Components";
import {
  Alert,
  ButtonPrimario,
  ButtonSecundario,
  ContenedorBotones,
  ContenedorBotonesExterior,
  DivTitulos,
  DivTitulosSubTitulo,
  DivTitulosTitulo,
  InputGrande,
  Obligatorio,
  TextAreaGrande,
  TextLabel,
  TextLabelTitle,
} from "../../../communStyled";
import {
  Contenedor,
  ContenedorDeDomicilio,
  ContenedorDomicilioFiscal,
  HorizontalContainerSwitch,
  SwitchContainer,
  SwitchDiv,
  SwitchLabelDiv,
} from "./InformacionDeLaEmpresaOPatronStyled";
import { useEffect, useRef, useState } from "react";
import useViewPortSize from "../../../../Hooks/useViewPortSize";
import { apiRequest } from "../../../../Api/instance";
import { apiRoutes } from "../../../../Api/services";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../Redux/Actions";
import { scrollIntoView } from "../../../../Utils/sharedFunctions";
import { entidadesFederativasLiteral } from "../../../../Utils/constants";

export default function InformacionDeLaEmpresaOPatron() {
  const { smallViewPort } = useViewPortSize();
  const dispatch = useDispatch();
  const successfulSubject = new Subject<string>();
  const messageSubject = new Subject<string>();

  const empresa: any = useSelector((store: any) => store.empresa);
  const currentEmpresa: any = useSelector((store: any) => store.currentEmpresa);

  const domicilioFiscalFormRef = useRef<{
    cleanSubjects: any;
    patchValuesFunction: any;
    setCPValid: any;
  }>(null);
  const alertRef = useRef(null);

  const [tabOpenInformacion, setTabOpenInformacion] = useState(true);
  const [tabOpenDomicilioFiscal, setTabOpenDomicilioFiscal] = useState(true);
  const [personaMoralCheckBox, setPersonaMoralCheckBox] = useState(
    empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC
      .personaMoralCheckBox || true
  );
  const [rfc, setRFC] = useState(
    empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.rfc || ""
  );
  const [rfcIsInvalid, setRFCIsInvalid] = useState(false);
  const [rfcLengthIsInvalidMorales, setRFClengthIsInvalidMorales] =
    useState(false);
  const [rfcLengthIsInvalidNoMorales, setRFClengthIsInvalidNoMorales] =
    useState(false);
  const [rfcIsTouched, setRFCIsTouched] = useState(false);
  const [curp, setCURP] = useState(
    empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.curp || ""
  );
  const [curpIsInvalid, setCURPIsInvalid] = useState(false);
  const [curpLengthIsInvalid, setCURPlengthIsInvalid] = useState(false);
  const [curpIsTouched, setCURPIsTouched] = useState(false);
  const [nombre, setNombre] = useState(
    empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.nombre || ""
  );
  const [nombreIsInvalid, setNombreIsInvalid] = useState(false);
  const [primerApellido, setPrimerApellido] = useState(
    empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.primerApellido ||
      ""
  );
  const [primerApellidoIsInvalid, setPrimerApellidoIsInvalid] = useState(false);
  const [segundoApellido, setSegundoApellido] = useState(
    empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.segundoApellido ||
      ""
  );
  const [segundoApellidoIsInvalid, setSegundoApellidoIsInvalid] =
    useState(false);
  const [denominacion, setDenominacion] = useState(
    empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.denominacion || ""
  );
  const [denominacionInvalid, setDenominacionInvalid] = useState(false);
  const [regimenCapital, setRegimenCapital] = useState(
    empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.regimenCapital ||
      ""
  );
  const [regimenCapitalInvalid, setRegimenCapitalInvalid] = useState(false);
  const [regimenFiscal, setRegimenFiscal] = useState(
    empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.regimenFiscal || ""
  );
  const [regimenesFiscales, setRegimenesFiscales] = useState<string[]>([]);
  const [regimenesFiscalesFull, setRegimenesFiscalesFull] = useState<any[]>([]);
  const [apoderado, setApoderado] = useState(
    empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.apoderado || ""
  );
  const [apoderadoInvalid, setApoderadoInvalid] = useState(false);
  const [principalActividadTextArea, setPrincipalActividadTextArea] = useState(
    empresa.empresa.informacionDeLaEmpresaOPatron.principalActividad
      .principalActividadTextArea || ""
  );
  const [domicilioFiscalForm, setDomicilioFiscalForm] = useState<any>({
    codigoPostal:
      empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal
        .codigoPostal || "",
    municipio:
      empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal.municipio ||
      "",
    estado:
      empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal.estado ||
      "",
    colonia:
      empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal.colonia ||
      "",
    calle:
      empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal.calle || "",
    numeroExterior:
      empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal
        .numeroExterior || "",
    numeroInterior:
      empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal
        .numeroInterior || "",
  });

  const regex = /[^\w-@.\s]/g;

  useEffect(() => {
    const fetchData = async () => {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.regimenesFiscales,
      });
      if (data) {
        if (data.regimenFiscalArray) {
          setRegimenesFiscalesFull(data.regimenFiscalArray);
          setRegimenesFiscales(
            data.regimenFiscalArray.map((regimen: any) => regimen.descripcion)
          );
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    cancel();
  }, [empresa.empresa.informacionDeLaEmpresaOPatron]);

  const RFCFocusOut = () => {
    setCURP(
      rfc.substring(0, 10).toUpperCase() + curp.substring(10).toUpperCase()
    );
    setRFCIsTouched(true);
    setCURPIsTouched(true);
  };

  useEffect(() => {
    if (rfcIsTouched) {
      setRFCIsInvalid(rfc.length === 0);
      setRFClengthIsInvalidMorales(rfc.length !== 12 && personaMoralCheckBox);
      setRFClengthIsInvalidNoMorales(
        rfc.length !== 13 && !personaMoralCheckBox
      );
    }
  }, [rfc, personaMoralCheckBox, rfcIsTouched]);

  const CURPFocusOut = () => {
    setCURPIsTouched(true);
    setRFCIsTouched(true);
    const CURPValue =  curp.replace(/[^A-Z0-9]+/ig, "").toUpperCase();
    const CURPFirst10 = CURPValue.substring(0, 10);
    const RFCrest = rfc.substring(10).toUpperCase();
    setRFC(CURPFirst10 + RFCrest);
    setCURP(CURPValue);
    messageSubject.next('');
    const CURPValueChars = CURPValue.split('');
    if(CURPValue.length === 18){
      if(
        isNaN(+CURPValueChars[4]) ||
      isNaN(+CURPValueChars[5]) ||
      isNaN(+CURPValueChars[6]) ||
      isNaN(+CURPValueChars[7]) ||
      isNaN(+CURPValueChars[8]) ||
      isNaN(+CURPValueChars[9])
        ){
          messageSubject.next('Los caracteres del 5 al 10 en el CURP y R.F.C. representan la fecha de nacimiento y deben ser todos numeros');
          setCURPIsInvalid(true);
          return
        } 
        if(!(CURPValueChars[10] === 'H' || CURPValueChars[10] === 'M')){
          messageSubject.next('El caracter numero 11 representa el sexo y debe ser M o H');
          setCURPIsInvalid(true);
          return
        } 
        const newEntidadFederativa = CURPValueChars[11] + CURPValueChars[12] + '';
        const newLugarDeNacimiento = entidadesFederativasLiteral.hasOwnProperty(newEntidadFederativa) ? (entidadesFederativasLiteral as any)[newEntidadFederativa]() : undefined;
        if(!newLugarDeNacimiento) {
          messageSubject.next('Los caracteres 12 y 13 que representan la entidad federativa no son validos');
          setCURPIsInvalid(true);
          return
        }
        setCURPIsInvalid(false);
        return
    }
    setCURPIsInvalid(true);
  };

  useEffect(() => {
    if (curpIsTouched) {
      setCURPlengthIsInvalid(curp.length !== 18);
    }
  }, [curp, curpIsTouched]);

  const validateForm = () => {
    return (
      (personaMoralCheckBox
        ? !rfcIsInvalid &&
          denominacion &&
          regimenCapital &&
          regimenFiscal &&
          apoderado
        : !rfcIsInvalid &&
          !curpIsInvalid &&
          nombre &&
          primerApellido &&
          segundoApellido &&
          regimenFiscal) &&
      domicilioFiscalForm.codigoPostal &&
      domicilioFiscalForm.municipio &&
      domicilioFiscalForm.estado
    );
  };

  const submitForm = async () => {
    if (validateForm()) {
      messageSubject.next("");
      const informacionDeLaEmpresaOPatron = {
        nombreYRFC: {
          personaMoralCheckBox: personaMoralCheckBox,
          nombre: nombre,
          primerApellido: primerApellido,
          segundoApellido: segundoApellido,
          rfc: rfc,
          curp: curp,
          denominacion: denominacion,
          regimenCapital: regimenCapital,
          regimenFiscal: regimenFiscal,
          apoderado: apoderado,
        },
        domicilioFiscal: {
          codigoPostal: domicilioFiscalForm.codigoPostal || "",
          municipio: domicilioFiscalForm.municipio || "",
          estado: domicilioFiscalForm.estado || "",
          colonia: domicilioFiscalForm.colonia || "",
          calle: domicilioFiscalForm.calle || "",
          numeroExterior: domicilioFiscalForm.numeroExterior || "",
          numeroInterior: domicilioFiscalForm.numeroInterior || "",
        },
        principalActividad: {
          principalActividadTextArea: principalActividadTextArea,
        },
      };
      const [error, res] = await apiRequest({
        method: "post",
        url: apiRoutes.updateInfo,
        data: {
          area: "informacionDeLaEmpresaOPatron",
          data: informacionDeLaEmpresaOPatron,
          id: currentEmpresa.id,
          modulo: "empresa",
        },
      });
      if (res) {
        if (res.message !== "Proceed") {
          messageSubject.next(res.message);
        } else {
          dispatch(
            actions.empresaActions.replaceEmpresa({
              ...empresa,
              empresa: {
                ...empresa.empresa,
                informacionDeLaEmpresaOPatron: {
                  nombreYRFC: {
                    personaMoralCheckBox: personaMoralCheckBox,
                    nombre: nombre,
                    primerApellido: primerApellido,
                    segundoApellido: segundoApellido,
                    rfc: rfc,
                    curp: curp,
                    denominacion: denominacion,
                    regimenCapital: regimenCapital,
                    regimenFiscal: regimenFiscal,
                    apoderado: apoderado,
                  },
                  domicilioFiscal: {
                    codigoPostal: domicilioFiscalForm.codigoPostal || "",
                    municipio: domicilioFiscalForm.municipio || "",
                    estado: domicilioFiscalForm.estado || "",
                    colonia: domicilioFiscalForm.colonia || "",
                    calle: domicilioFiscalForm.calle || "",
                    numeroExterior: domicilioFiscalForm.numeroExterior || "",
                    numeroInterior: domicilioFiscalForm.numeroInterior || "",
                  },
                  principalActividad: {
                    principalActividadTextArea: principalActividadTextArea,
                  },
                },
              },
            })
          );
          await domicilioFiscalFormRef?.current?.cleanSubjects();
          domicilioFiscalFormRef.current?.setCPValid(false);
          successfulSubject.next(
            "La Informacion se agrego correctamente a la base de datos"
          );
        }
      } else if (error) {
        messageSubject.next("Hubo un error en el servidor");
      }
    } else {
      messageSubject.next("");
      if (
        domicilioFiscalForm.municipio === "" ||
        domicilioFiscalForm.estado === "" ||
        !domicilioFiscalForm.codigoPostal
      ) {
        messageSubject.next("El Codigo Postal no es valido.");
      } else {
        messageSubject.next(
          "Alguno de los campos requeridos no es valido o no contiene ninguna informacion"
        );
      }
    }
    scrollIntoView(alertRef);
  };

  const cancel = () => {
    setPersonaMoralCheckBox(
      empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC
        .personaMoralCheckBox || false
    );
    setNombre(
      empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.nombre || ""
    );
    setPrimerApellido(
      empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.primerApellido ||
        ""
    );
    setSegundoApellido(
      empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC
        .segundoApellido || ""
    );
    setRFC(empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.rfc || "");
    setCURP(
      empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.curp || ""
    );
    setDenominacion(
      empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.denominacion ||
        ""
    );
    setRegimenCapital(
      empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.regimenCapital ||
        ""
    );
    setRegimenFiscal(
      empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.regimenFiscal ||
        ""
    );
    setApoderado(
      empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.apoderado || ""
    );
    setPrincipalActividadTextArea(
      empresa.empresa.informacionDeLaEmpresaOPatron.principalActividad
        .principalActividadTextArea || ""
    );
    setDomicilioFiscalForm({
      codigoPostal:
        empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal
          .codigoPostal || "",
      municipio:
        empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal
          .municipio || "",
      estado:
        empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal.estado ||
        "",
      colonia:
        empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal.colonia ||
        "",
      calle:
        empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal.calle ||
        "",
      numeroExterior:
        empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal
          .numeroExterior || "",
      numeroInterior:
        empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal
          .numeroInterior || "",
    });
    scrollIntoView(alertRef);
  };

  return (
    <div>
      <DivTitulos>
        <DivTitulosTitulo>EMPRESA</DivTitulosTitulo>
        <DivTitulosSubTitulo>
          INFORMACION DE LA EMPRESA O PATRON
        </DivTitulosSubTitulo>
      </DivTitulos>
      <div ref={alertRef} style={{margin: '0px 35px'}}>
        <Warning
          type={"Alert"}
          title={"Alerta"}
          warningSubject={messageSubject}
        />
        <Warning
          type={"Successful"}
          title={"Exito!"}
          warningSubject={successfulSubject}
        />
      </div>

      <Contenedor>
        <ContenedorDeDomicilio>
          <TextLabelTitle>INFORMACION</TextLabelTitle>
          <ChevronTabIcon
            tabOpen={tabOpenInformacion}
            toggleTab={() => setTabOpenInformacion(!tabOpenInformacion)}
          />
          {(tabOpenInformacion || !smallViewPort) && (
            <>
              <HorizontalContainerSwitch>
                <SwitchContainer>
                  <SwitchLabelDiv>
                    <TextLabel>
                      {personaMoralCheckBox
                        ? "Persona Moral"
                        : "Persona Física"}
                    </TextLabel>
                  </SwitchLabelDiv>
                  <SwitchDiv>
                    <Switch
                      checked={personaMoralCheckBox}
                      handleClick={(value: boolean) =>
                        setPersonaMoralCheckBox(value)
                      }
                    />
                  </SwitchDiv>
                </SwitchContainer>
              </HorizontalContainerSwitch>

              <>
                <TextLabel>
                  R.F.C.<Obligatorio>*</Obligatorio>
                </TextLabel>
                <br></br>
                <InputGrande
                  value={rfc}
                  onChange={(e: any) =>
                    setRFC(e.target.value.replace(regex, ""))
                  }
                  style={{ textTransform: "uppercase" }}
                  type="text"
                  onBlur={() => RFCFocusOut()}
                />
                {rfcIsInvalid && <Alert>Este campo es requerido</Alert>}
                {rfcLengthIsInvalidMorales && (
                  <Alert>El R.F.C debe tener exactamente 12 caracteres</Alert>
                )}
                {rfcLengthIsInvalidNoMorales && (
                  <Alert>El R.F.C debe tener exactamente 13 caracteres</Alert>
                )}
              </>

              {!personaMoralCheckBox && (
                <>
                  <TextLabel>
                    C.U.R.P<Obligatorio>*</Obligatorio>
                  </TextLabel>
                  <InputGrande
                    value={curp}
                    style={{ textTransform: "uppercase" }}
                    onChange={(e: any) =>
                      setCURP(e.target.value.replace(regex, ""))
                    }
                    type="text"
                    onBlur={() => CURPFocusOut()}
                  />
                  {curpIsInvalid && <Alert>el CURP no es valido</Alert>}
                  {curpLengthIsInvalid && (
                    <Alert>El CURP debe tener exactamente 18 caracteres</Alert>
                  )}
                </>
              )}

              {!personaMoralCheckBox && (
                <>
                  <TextLabel>
                    Nombre(s)<Obligatorio>*</Obligatorio>
                  </TextLabel>
                  <InputGrande
                    value={nombre}
                    type="text"
                    autoComplete="given-name"
                    onChange={(e: any) => setNombre(e.target.value)}
                    onBlur={() => setNombreIsInvalid(!nombre)}
                  />
                  {nombreIsInvalid && <Alert>Este campo es requerido</Alert>}
                </>
              )}

              {!personaMoralCheckBox && (
                <>
                  <TextLabel>
                    Primer Apellido<Obligatorio>*</Obligatorio>
                  </TextLabel>
                  <InputGrande
                    value={primerApellido}
                    type="text"
                    autoComplete="family-name"
                    onChange={(e: any) => setPrimerApellido(e.target.value)}
                    onBlur={() => setPrimerApellidoIsInvalid(!primerApellido)}
                  />
                  {primerApellidoIsInvalid && (
                    <Alert>Este campo es requerido</Alert>
                  )}
                </>
              )}

              {!personaMoralCheckBox && (
                <>
                  <TextLabel>
                    Segundo Apellido<Obligatorio>*</Obligatorio>
                  </TextLabel>
                  <InputGrande
                    value={segundoApellido}
                    type="text"
                    autoComplete="nope"
                    onChange={(e: any) => setSegundoApellido(e.target.value)}
                    onBlur={() => setSegundoApellidoIsInvalid(!segundoApellido)}
                  />
                  {segundoApellidoIsInvalid && (
                    <Alert>Este campo es requerido</Alert>
                  )}
                </>
              )}

              {personaMoralCheckBox && (
                <>
                  <TextLabel>
                    Denominación<Obligatorio>*</Obligatorio>
                  </TextLabel>
                  <InputGrande
                    value={denominacion}
                    type="text"
                    onChange={(e: any) => setDenominacion(e.target.value)}
                    onBlur={() => setDenominacionInvalid(!denominacion)}
                  />
                  {denominacionInvalid && (
                    <Alert>Este campo es requerido</Alert>
                  )}
                </>
              )}

              {personaMoralCheckBox && (
                <>
                  <TextLabel>
                    Régimen Capital<Obligatorio>*</Obligatorio>
                  </TextLabel>
                  <InputGrande
                    value={regimenCapital}
                    type="text"
                    onChange={(e: any) => setRegimenCapital(e.target.value)}
                    onBlur={() => setRegimenCapitalInvalid(!regimenCapital)}
                  />
                  {regimenCapitalInvalid && (
                    <Alert>Este campo es requerido</Alert>
                  )}
                </>
              )}

              <div>
                <TextLabel>
                  Regimen Fiscal<Obligatorio>*</Obligatorio>
                </TextLabel>
                <Dropdown
                  onValue={(value: any) => setRegimenFiscal(value)}
                  value={regimenFiscal}
                  options={regimenesFiscales}
                />
              </div>

              {personaMoralCheckBox && (
                <>
                  <TextLabel>
                    Representante Legal<Obligatorio>*</Obligatorio>
                  </TextLabel>
                  <InputGrande
                    value={apoderado}
                    type="text"
                    onChange={(e: any) => setApoderado(e.target.value)}
                    onBlur={() => setApoderadoInvalid(!apoderado)}
                  />
                  {apoderadoInvalid && <Alert>Este campo es requerido</Alert>}
                </>
              )}

              <>
                <TextLabel>Principal Actividad</TextLabel>
                <TextAreaGrande
                  value={principalActividadTextArea}
                  onChange={(e: any) =>
                    setPrincipalActividadTextArea(e.target.value)
                  }
                />
              </>
            </>
          )}
        </ContenedorDeDomicilio>
        <ContenedorDomicilioFiscal>
          <TextLabelTitle>DOMICILIO FISCAL</TextLabelTitle>
          <br></br>
          <ChevronTabIcon
            tabOpen={tabOpenDomicilioFiscal}
            toggleTab={() => setTabOpenDomicilioFiscal(!tabOpenDomicilioFiscal)}
          />
          {(tabOpenDomicilioFiscal || !smallViewPort) && (
            <DomicilioFiscal
              obligatorio={true}
              ref={domicilioFiscalFormRef}
              onValuesChange={(values: any) => setDomicilioFiscalForm(values)}
              formValues={domicilioFiscalForm}
            />
          )}
        </ContenedorDomicilioFiscal>
      </Contenedor>

      <ContenedorBotonesExterior>
        <ContenedorBotones>
          <ButtonPrimario onClick={() => submitForm()}>Aceptar</ButtonPrimario>
          <ButtonSecundario onClick={() => cancel()}>Cancelar</ButtonSecundario>
        </ContenedorBotones>
      </ContenedorBotonesExterior>
    </div>
  );
}
