 const replaceInformacionGeneralDeUsuario = (newInformacionGeneralDeUsuario) => {
    return {
      type: "REPLACEINFORMACIONGENERALDEUSUARIO",
      newInformacionGeneralDeUsuario,
    };
  };
  
  export default {
    replaceInformacionGeneralDeUsuario
  };
  