import axios from "axios";
import {
  getToken,
  getRefreshToken,
  saveToken,
  saveRefreshToken,
  saveType,
  signOut,

} from "../Services/tokenStorage";

const envs = [
  {
    url: "localhost",
    api: "http://localhost:3001/",
  },
  {
    url: "sipmx.mx",
    api: "https://sipmx.mx:3010/",
  },
];

const hostname = window.location.hostname;
const currentEnv = envs.filter((env) => {
  return env.url === hostname;
});

const api = axios.create({
  baseURL: currentEnv[0]?.api || "",
  withCredentials: currentEnv[0]?.url !== "localhost",
});

api.defaults.headers.common["Content-Type"] = "Access-Control-Allow-Origin: *";

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response?.status;
    if (status === 500) {
      return Promise.reject({ Message: "Hubo un error en el servidor" });
    }
    if (status === 401) {
      if (error.response.data.token && error.response.data.refreshToken) {
        // Save tokens and type to your storage or context
        saveToken(error.response.data.token);
        saveRefreshToken(error.response.data.refreshToken);
        saveType(error.response.data.type);

        // Update the original request configuration with new headers
        error.config.headers.Authorization = error.response.data.token;
        error.config.headers.AuthorizationRefresh = error.response.data.refreshToken;

        // Retry the original request after updating headers
        try {
          const response = await axios(error.config);
          return response;
        } catch (retryError) {
          return Promise.reject(retryError);
        }
        
      } else {
        if (error.response.data.message === "Invalid Token") {
          signOut();
        }
      }
    }
    return Promise.reject(error);
  }
);

export const apiRequest = async ({ method, url, data = {}, headers = {} }) => {
  const token = getToken();
  const refreshToken = getRefreshToken();
  if (token) {
    headers = {
      ...headers,
      Authorization: token,
      AuthorizationRefresh: refreshToken,
    };
  }
  if (headers) {
    api.defaults.headers.common = {  ...headers };
  }

  let error = null;
  let response = null;

  try {
    response = await api[method.toLowerCase()](url, data, { headers });
    return [error, response ? response.data : response];
  } catch (e) {
    error = {
      error: e,
      message: e.response?.data.message,
      status: e.response?.status,
    };

    return [error, response];
  }
};
