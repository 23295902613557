import { forwardRef, useImperativeHandle, useState } from "react";
import { Obligatorio, TextLabel } from "../../Pages/communStyled";
import {
  Dropdown,
  DropdownContent,
  DropdownContentOption,
  DropdownSpan,
  InputGrandeDrop,
  SpanDivContainer,
  SpanText,
  SpanTimes,
} from "./MultiselectStyled";
import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface Props {
  label?: string;
  options: Array<string>;
  selectedOptions: Array<string>;
  disabled?: boolean;
  obligatorio?: boolean;
  onValue: (items: Array<string>) => void;
}

const Multiselect = forwardRef(
  (
    { label, obligatorio, options, selectedOptions, onValue, disabled }: Props,
    ref
  ) => {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [hoverIndex, setHoverIndex] = useState<number | null>(null);

    const emitOption = (item: string) => {
      onValue([...selectedOptions, item]);
      setDropdownOpen(false);
    };

    useImperativeHandle(ref, () => ({}));
    return (
      <div>
        {label && (
          <span>
            <TextLabel>
              {label}
              {obligatorio && <Obligatorio>*</Obligatorio>}
            </TextLabel>
          </span>
        )}
        <Dropdown>
          <InputGrandeDrop
            isOpen={disabled ? "false" : dropdownOpen && options.length !== 0 ? "true" : "false"}
            disabledProp={disabled ? "true" : "false"}
          >
            <SpanDivContainer>
            {selectedOptions.map((option, index) => (
              <div>
                <SpanText
                  disabledProp={disabled ? "true" : "false"}
                  key={option + index}
                >
                  {option}
                </SpanText>
                <SpanTimes
                  disabledProp={disabled ? "true" : "false"}
                  key={option + index + "SpanText"}
                  onClick={() =>
                    !disabled && onValue(selectedOptions.filter((item) => item !== option))
                  }
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  isHover={hoverIndex === index ? "true" : "false"}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </SpanTimes>
              </div>
            ))}
            </SpanDivContainer>
          </InputGrandeDrop>
          <DropdownSpan
            onClick={() => !disabled && setDropdownOpen(!dropdownOpen)}
            disabledProp={disabled ? "true" : "false"}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </DropdownSpan>
          {dropdownOpen && options.length !== 0 && (
            <DropdownContent>
              {options.map((item, index) =>
                !selectedOptions.includes(item) ? (
                  <DropdownContentOption onClick={() => emitOption(item)}>
                    {item}
                  </DropdownContentOption>
                ) : null
              )}
            </DropdownContent>
          )}
        </Dropdown>
      </div>
    );
  }
);

export default Multiselect;
