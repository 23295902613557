import { useEffect, useState } from "react";
import { Slider, SwitchDiv } from "./SwitchStyled";

interface Props {
  className?: string;
  checked: boolean;
  disabled?: boolean;
  handleClick: (state: boolean) => void;
}

const Switch = ({ className, checked, handleClick, disabled }: Props) => {
  const [checkedState, setCheckedState] = useState<boolean>(checked);
  const onHandleClick = (state: boolean) => {
    if(!disabled) {
      setCheckedState(state);
      handleClick(state);
    }
  };

  useEffect(() => {
    setCheckedState(checked);
  }, [checked]);
  return (
    <SwitchDiv
      className={className}
      onClick={() => onHandleClick(!checkedState)}
    >
      <Slider disabled={!!disabled}  checked={checkedState} className="round" />
    </SwitchDiv>
  );
};

export default Switch;
