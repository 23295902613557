import { useDispatch, useSelector } from "react-redux";
import {
  ButtonBig,
  DivTitulos,
  DivTitulosSubTitulo,
  DivTitulosTitulo,
  LabelEliminar,
  PEliminar,
  TextLabelTitle,
} from "../../../communStyled";
import { v4 as uuidv4 } from "uuid";
import {
  AddColaboradorDiv,
  AlertClose,
  AlertContainer,
  AlertContainerText,
  AlertContainerTitle,
  ButtonBigDiv,
  ButtonDocumentos,
  CenterFoto,
  CerrarSesionDiv,
  CheckboxContainer,
  Contenedor,
  ContenedorDeColaboradores,
  ContenedorDeEventos,
  ContenedorDeNotificaciones,
  ContenedorDeTabla,
  ContenedorHorizontal,
  ContenedorLogEmpresa,
  DivColaboradoresAreas,
  DivInfo,
  DivUserFoto,
  Dot,
  Hyperlink,
  TextInfo,
  TituloInfo,
} from "./PerfilStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { apiRequest, apiRoutes } from "../../../../Api/services";
import actions from "../../../../Redux/Actions";
import { signOut } from "../../../../Services/tokenStorage";
import {
  Checkbox,
  ChevronTabIcon,
  FilterInput,
  SearchDropdown,
  SortingTable,
  Warning,
} from "../../../../Components";
import { useEffect, useState } from "react";
import useViewPortSize from "../../../../Hooks/useViewPortSize";
import { formateDay } from "../../../../Utils/sharedFunctions";
import LogoIMG from "./../../../../assets/img/logo300200.png";
import { Subject } from "rxjs";

export default function Perfil() {
  const dispatch = useDispatch();
  const empresa: any = useSelector((store: any) => store.empresa);
  const currentEmpresa: any = useSelector((store: any) => store.currentEmpresa);
  const currentIMG: any = useSelector((store: any) => store.currentIMG);
  const { smallViewPort } = useViewPortSize();
  const alertTypes = ["alertasR", "alertasA", "alertasV"];
  const alertSubjectColaboradores = new Subject<string>();
  const [tabOpenNotificaciones, setTabOpenNotificaciones] = useState(true);
  const [tabOpenColaboradores, setTabOpenColaboradores] = useState(true);
  const [tabOpenBitacora, setTabOpenBitacora] = useState(true);
  const [rowsArrayCopy, setRowsArrayCopy] = useState(empresa.inicio.notificaciones);
  const [documentosPendientesI, setDocumentosPendientesI] = useState(0);
  const [colaboradorDisable, setColaboradorDisable] = useState(false);
  const defaultCheckboxObject = {
    notificaciones: true,
    colaboradores: true,
    bitacora: true,
    informacionDeLaEmpresaOPatron: true,
    esquemaDeNomina: true,
    registroEstatalYDelIMSS: true,
    horariosYControlDeAsistencia: true,
    prestacionesGenerales: true,
    parametrosDeOperacion: true,
    registroDeDepartamentos: true,
    registroDePuestos: true,
    controlDePersonal: true,
    configuracionDeConceptos: true,
    configuracionDeCriterios: true,
  };
  const [checkboxes, setCheckboxes] = useState<any>({
    ...defaultCheckboxObject,
  });
  const [usuarioSelecionado, setUsuarioSelecionado] = useState("");
  const [logCopyArray, setLogCopyArray] = useState<any>([]);
  const [arrayUsuarios, setArrayUsuarios] = useState<any>([]);
  const [invalidUsuariosArray, setInvalidUsuariosArray] = useState(false);

  const colaboradoresDisplayArray: any = [
    {
      title: "inicio",
      checkboxs: ["notificaciones", "colaboradores", "bitacora"],
    },
    {
      title: "empresa",
      checkboxs: [
        "Informacion de la empresa o patron",
        "Esquema de nomina",
        "Registro estatal y del IMSS",
        "Horarios y control de asistencia",
        "Prestaciones generales",
        "Parametros de operacion",
        "Registro de departamentos",
      ],
    },
    {
      title: "trabajadores",
      checkboxs: ["Registro de puestos", "Control de personal"],
    },
    {
      title: "nomina",
      checkboxs: ["Configuracion de criterios", "Configuracion de conceptos"],
    },
  ];

  const searchDropDownArray = [
    { label: "Fecha", valueLabel: "fecha" },
    { label: "Description", valueLabel: "description" },
  ];

  const searchDropDownArrayLog = [
    { label: "Fecha", valueLabel: "fecha", type: "Date" },
    { label: "Usuario", valueLabel: "usuario", type: "String" },
    { label: "Area", valueLabel: "area", type: "String" },
    { label: "Movimiento", valueLabel: "movimiento", type: "String" },
  ];

  const columnsArrayLog = [
    {
      label: "Fecha",
      valueLabel: "fecha",
      filtrable: true,
      widthPX: 200,
      type: "Date",
    },
    {
      label: "Usuario",
      valueLabel: "usuario",
      filtrable: true,
      widthPX: 200,
      type: "String",
    },
    {
      label: "Area",
      valueLabel: "area",
      filtrable: true,
      widthPX: 200,
      type: "String",
    },
    {
      label: "Movimiento",
      valueLabel: "movimiento",
      filtrable: true,
      widthPX: 500,
      type: "String",
    },
  ];

  const columnsArrayColaboradores = [
    {
      label: "Nombre",
      valueLabel: "colaborador",
      filtrable: true,
      widthPX: 400,
      type: "String",
    },
  ];

  const columnsArray = [
    {
      label: "Fecha",
      valueLabel: "fecha",
      filtrable: true,
      widthPX: 150,
      type: "Date",
    },
    {
      label: "Descripcion",
      valueLabel: "descripcion",
      filtrable: true,
      widthPX: 800,
      type: "String",
    },
  ];

  const colaboradoresCheckboxLiteral: {
    [key: string]: (value: boolean) => any;
  } = {
    notificaciones: (value: boolean) =>
      setCheckboxes({ ...checkboxes, notificaciones: value }),
    colaboradores: (value: boolean) =>
      setCheckboxes({ ...checkboxes, colaboradores: value }),
    bitacora: (value: boolean) =>
      setCheckboxes({ ...checkboxes, bitacora: value }),
    "Informacion de la empresa o patron": (value: boolean) =>
      setCheckboxes({ ...checkboxes, informacionDeLaEmpresaOPatron: value }),
    "Esquema de nomina": (value: boolean) =>
      setCheckboxes({ ...checkboxes, esquemaDeNomina: value }),
    "Registro estatal y del IMSS": (value: boolean) =>
      setCheckboxes({ ...checkboxes, registroEstatalYDelIMSS: value }),
    "Horarios y control de asistencia": (value: boolean) =>
      setCheckboxes({ ...checkboxes, horariosYControlDeAsistencia: value }),
    "Prestaciones generales": (value: boolean) =>
      setCheckboxes({ ...checkboxes, prestacionesGenerales: value }),
    "Parametros de operacion": (value: boolean) =>
      setCheckboxes({ ...checkboxes, parametrosDeOperacion: value }),
    "Registro de departamentos": (value: boolean) =>
      setCheckboxes({ ...checkboxes, registroDeDepartamentos: value }),
    "Registro de puestos": (value: boolean) =>
      setCheckboxes({ ...checkboxes, registroDePuestos: value }),
    "Control de personal": (value: boolean) =>
      setCheckboxes({ ...checkboxes, controlDePersonal: value }),
    "Configuracion de criterios": (value: boolean) =>
      setCheckboxes({ ...checkboxes, configuracionDeCriterios: value }),
    "Configuracion de conceptos": (value: boolean) =>
      setCheckboxes({ ...checkboxes, configuracionDeConceptos: value }),
  };

  const colaboradoresCheckboxValueLiteral: {
    [key: string]: () => string;
  } = {
    notificaciones: () => 'notificaciones',
    colaboradores: () => 'colaboradores',
    bitacora: () => 'bitacora',
    "Informacion de la empresa o patron": () =>
      'informacionDeLaEmpresaOPatron',
    "Esquema de nomina": () => 'esquemaDeNomina',
    "Registro estatal y del IMSS": () => 'registroEstatalYDelIMSS',
    "Horarios y control de asistencia": () =>
      'horariosYControlDeAsistencia',
    "Prestaciones generales": () => 'prestacionesGenerales',
    "Parametros de operacion": () => 'parametrosDeOperacion',
    "Registro de departamentos": () => 'registroDeDepartamentos',
    "Registro de puestos": () => 'registroDePuestos',
    "Control de personal": () => 'controlDePersonal',
    "Configuracion de criterios": () => 'configuracionDeCriterios',
    "Configuracion de conceptos": () => 'configuracionDeConceptos',
  };

  const closeWarning = async (payload: any) => {
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.eliminarNotificacion,
      data: {
        ...payload,
        id: currentEmpresa.id,
      },
    });
    if (data.message && data.message === "Proceed") {
      const empresaCopy = { ...empresa };
      const newAlert = [...empresa.inicio[payload.tipo]];
      delete newAlert[payload.index];
      empresaCopy.inicio[payload.tipo] = newAlert.filter(Boolean);
      dispatch(actions.empresaActions.replaceEmpresa({ ...empresaCopy }));
    }
  };

  const onValueSearchDropDown = (value: any) => {
    if (value.searchValue !== "") {
      setRowsArrayCopy(
        empresa.trabajadores.registroDePuestos.puestos.filter((row: any) => {
          return row[value.filter.valueLabel]
            .toString()
            .toUpperCase()
            .includes(value.searchValue.toString().toUpperCase());
        })
      );
    } else {
      setRowsArrayCopy([...empresa.trabajadores.registroDePuestos.puestos]);
    }
  };

  const eliminarColaborador = async () => {
    alertSubjectColaboradores.next("");
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.eliminarColaborador,
      data: {
        id: currentEmpresa.id,
        colaborador: usuarioSelecionado,
      },
    });
    if (data?.message) {
      alertSubjectColaboradores.next(data.message);
    } else {
      dispatch(
        actions.empresaActions.replaceEmpresa({
          inicio: {
            ...empresa.inicio,
            log: data.log,
            colaboradores: data.colaboradores,
          },
        })
      );
      setLogCopyArray([...data.log]);
    }
    cleanColaboradores();
  };

  const cleanColaboradores = () => {
    setCheckboxes({
      ...defaultCheckboxObject,
    });
    setUsuarioSelecionado("");
    setColaboradorDisable(false);
  };

  const onValueUsuarios = (value: any) => {
    setUsuarioSelecionado(value);
    setArrayUsuarios([]);
  };

  const getUsuariosList = async (text: string) => {
    setUsuarioSelecionado(text);
    if (text !== "") {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.usariosList + text,
      });
      if (error) {
        return { message: "Hubo un error en el servidor" };
      }
      setInvalidUsuariosArray(0 >= data.length);
      setArrayUsuarios(data || []);
    } else {
      setArrayUsuarios([]);
      setInvalidUsuariosArray(false);
    }
  };

  const agregarColaborador = async () => {
    alertSubjectColaboradores.next("");
    const payload = {
      permisos: {
        ...checkboxes,
      },
      id: currentEmpresa.id,
      colaborador: usuarioSelecionado,
    };
    if (colaboradorDisable) {
      const [error, data] = await apiRequest({
        method: "post",
        url: apiRoutes.editarColaborador,
        data: payload,
      });
      if (data?.message) {
        alertSubjectColaboradores.next(data.message);
      } else {
        dispatch(
          actions.empresaActions.replaceEmpresa({
            inicio: {
              ...empresa.inicio,
              log: data.log,
              colaboradores: data.colaboradores,
            },
          })
        );
        setLogCopyArray([...data.log]);
      }
    } else {
      const [error, data] = await apiRequest({
        method: "post",
        url: apiRoutes.agregarColaborador,
        data: payload,
      });
      if (data?.message) {
        alertSubjectColaboradores.next(data.message);
      } else {
        dispatch(
          actions.empresaActions.replaceEmpresa({
            inicio: {
              ...empresa.inicio,
              log: data.log,
              colaboradores: data.colaboradores,
            },
          })
        );
        setLogCopyArray([...data.log]);
      }
    }
    cleanColaboradores();
  };

  const onValueSearchDropDownLog = (value: {
    searchValue: string;
    filter: { type: string; valueLabel: string | number };
  }) => {
    if (value.searchValue !== "") {
      if (value.filter.type === "Date") {
        setLogCopyArray(
          empresa.inicio.log.filter((row: any) => {
            const date = new Date(row[value.filter.valueLabel]);
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            const dateS =
              month < 10
                ? `${day}/0${month}/${year}`
                : `${day}/${month}/${year}`;
            return dateS.includes(value.searchValue.toString());
          })
        );
      } else {
        setLogCopyArray(
          empresa.inicio.log.filter((row: any) => {
            return row[value.filter.valueLabel]
              .toString()
              .toUpperCase()
              .includes(value.searchValue.toString().toUpperCase());
          })
        );
      }
    } else {
      setLogCopyArray([...empresa.inicio.log]);
    }
  };

  const MoveToDocumentos = () => {};

  const onSelectColaborador = (value: any) => {
    if (value) {
      setCheckboxes({ ...value.permisos });
      setUsuarioSelecionado(value.colaborador);
      setColaboradorDisable(true);
    } else {
      cleanColaboradores();
    }
  };

  useEffect(() => {
    setLogCopyArray([...empresa.inicio.log]);
  }, [empresa.inicio.log]);

  return (
    <div>
      <DivTitulos>
        <DivTitulosTitulo>PERFIL</DivTitulosTitulo>
        <DivTitulosSubTitulo>INFORMACIÓN GENERAL</DivTitulosSubTitulo>
      </DivTitulos>
      {(empresa.inicio.alertasR.length > 0 ||
        empresa.inicio.alertasA.length > 0 ||
        empresa.inicio.alertasV.length > 0) &&
        (currentEmpresa.creador === "Propietario" ||
          currentEmpresa.permisos.notificaciones) && (
          <ContenedorDeNotificaciones>
            {alertTypes.map((alertType) =>
              empresa.inicio[alertType].map((alert: any, i: number) => (
                <AlertContainer
                key={uuidv4()}
                  style={
                    alertType === "alertasR"
                      ? {
                          background: "#DB282833 0% 0% no-repeat padding-box",
                          border: "1px solid #DB282899",
                        }
                      : alertType === "alertasA"
                      ? {
                          background: "#FBBD0833 0% 0% no-repeat padding-box",
                          border: "1px solid #FBBD0899",
                        }
                      : {
                          background: "#1CA55333 0% 0% no-repeat padding-box",
                          border: "1px solid #1CA55399",
                        }
                  }
                >
                  <AlertContainerTitle
                    style={{
                      color:
                        alertType === "alertasR"
                          ? "#DB2828"
                          : alertType === "alertasA"
                          ? "#FBBD08"
                          : "#1CA553",
                    }}
                  >
                    {alert.titulo}
                  </AlertContainerTitle>
                  <br></br>
                  <AlertContainerText
                    style={{
                      color:
                        alertType === "alertasR"
                          ? "#DB282899"
                          : alertType === "alertasA"
                          ? "#FBBD0899"
                          : "#1CA55399",
                    }}
                  >
                    {alert.texto}
                  </AlertContainerText>
                  <br></br>
                  <AlertClose
                    style={{
                      color:
                        alertType === "alertasR"
                          ? "#DB2828"
                          : alertType === "alertasA"
                          ? "#FBBD08"
                          : "#1CA553",
                    }}
                    onClick={() =>
                      closeWarning({ index: i, tipo: `${alertType}` })
                    }
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </AlertClose>
                </AlertContainer>
              ))
            )}
          </ContenedorDeNotificaciones>
        )}
      <Contenedor>
        <CerrarSesionDiv>
          <Hyperlink onClick={() => signOut()}>Cerrar sesión</Hyperlink>
        </CerrarSesionDiv>
        {(currentEmpresa.creador === "Propietario" ||
          currentEmpresa.permisos.notificaciones) && (
          <ContenedorHorizontal>
            {(tabOpenNotificaciones || !smallViewPort) && (
              <ContenedorDeTabla>
                <SearchDropdown
                  onValue={onValueSearchDropDown}
                  options={searchDropDownArray}
                />
                <SortingTable
                  multiselect={false}
                  rowsArray={rowsArrayCopy}
                  columnsArray={columnsArray}
                  tableHeight="395px"
                  onSelectElement={(element: any) => {}}
                />
              </ContenedorDeTabla>
            )}
            <ContenedorDeEventos>
              <TextLabelTitle style={{ color: "#767676" }}>
                NOTIFICACIÓNES
              </TextLabelTitle>
              <br />
              <ChevronTabIcon
                tabOpen={tabOpenNotificaciones}
                toggleTab={() =>
                  setTabOpenNotificaciones(!tabOpenNotificaciones)
                }
              />
              {(tabOpenNotificaciones || !smallViewPort) && (
                <>
                  <ButtonDocumentos onClick={() => MoveToDocumentos()}>
                    <Dot />
                    {documentosPendientesI} Documentos pendientes de impresión
                  </ButtonDocumentos>
                  <TextLabelTitle
                    style={{
                      color: "#767676",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    INFORMACION DE LA LICENCIA
                  </TextLabelTitle>
                  <>
                    <CenterFoto>
                      <DivUserFoto
                        style={{
                          backgroundImage: "url(" + (currentIMG
                            ? currentIMG
                            :  LogoIMG )+ ")"
                        }}
                      ></DivUserFoto>
                    </CenterFoto>
                    <DivInfo>
                      <TituloInfo>Empresa</TituloInfo>
                      <TextInfo>{empresa.info.nombre}</TextInfo>
                      <TituloInfo>Numero de Empleados</TituloInfo>
                      <TextInfo>
                        {
                          empresa.trabajadores.controlDePersonal.trabajadores
                            .length
                        }
                      </TextInfo>
                      <TituloInfo>Vigencia</TituloInfo>
                      <TextInfo>
                        {empresa.inicio.vigencia !== ""
                          ? formateDay(empresa.inicio.vigencia)
                          : ""}
                      </TextInfo>
                    </DivInfo>
                  </>
                </>
              )}
            </ContenedorDeEventos>
          </ContenedorHorizontal>
        )}
        {(currentEmpresa.creador === "Propietario" ||
          currentEmpresa.permisos.colaboradores) && (
          <ContenedorDeColaboradores>
            <Warning
              type={"Alert"}
              title={"Alerta"}
              warningSubject={alertSubjectColaboradores}
            />
            <div>
              <TextLabelTitle style={{ color: "#767676" }}>
                AGREGAR COLABORADORES
              </TextLabelTitle>
              {colaboradorDisable && (
                <PEliminar>
                  <LabelEliminar>Eliminar colaborador</LabelEliminar>
                  <FontAwesomeIcon
                    onClick={() => eliminarColaborador()}
                    icon={faTrashAlt}
                  />
                </PEliminar>
              )}
              <ChevronTabIcon
                tabOpen={tabOpenColaboradores}
                toggleTab={() => setTabOpenColaboradores(!tabOpenColaboradores)}
              />
            </div>
            {(tabOpenColaboradores || !smallViewPort) && (
              <>
                <AddColaboradorDiv>
                  <div style={{ width: "100%" }}>
                    <FilterInput
                      onValue={(value) => onValueUsuarios(value)}
                      onChange={(e) => getUsuariosList(e)}
                      options={arrayUsuarios}
                      option={usuarioSelecionado}
                      invalid={invalidUsuariosArray}
                      label="Colaborador"
                      disabled={colaboradorDisable}
                      obligatorio={true}
                    />
                  </div>
                  <ButtonBigDiv>
                    <ButtonBig onClick={() => agregarColaborador()}>
                      {colaboradorDisable && "Editar"}
                      {!colaboradorDisable && "Agregar"}
                    </ButtonBig>
                  </ButtonBigDiv>
                </AddColaboradorDiv>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <DivColaboradoresAreas>
                    {colaboradoresDisplayArray.map((element: any) => (
                      <div key={uuidv4()} style={{ margin: "0px 20px" }}>
                        <div style={{ margin: "20px", textTransform: 'capitalize' }}>
                          <TituloInfo>{element.title}</TituloInfo>
                        </div>
                        {element.checkboxs.map(
                          (checkbox: any) =>
                            colaboradoresCheckboxValueLiteral.hasOwnProperty(
                              checkbox
                            ) && (
                              <CheckboxContainer key={uuidv4()}>
                                <Checkbox
                                  checked={checkboxes[colaboradoresCheckboxValueLiteral[
                                    checkbox
                                  ]()]}
                                  handleClick={(value: boolean) =>
                                    colaboradoresCheckboxLiteral[checkbox](
                                      value
                                    )
                                  }
                                />
                                <span style={{marginLeft: '10px'}}>{checkbox}</span>
                              </CheckboxContainer>
                            )
                        )}
                      </div>
                    ))}
                  </DivColaboradoresAreas>
                </div>
                <SortingTable
                  multiselect={false}
                  rowsArray={
                    empresa.inicio.colaboradores
                      ? empresa.inicio.colaboradores
                      : []
                  }
                  columnsArray={columnsArrayColaboradores}
                  tableHeight="300px"
                  onSelectElement={(element: any) =>
                    onSelectColaborador(element)
                  }
                />
              </>
            )}
          </ContenedorDeColaboradores>
        )}

        {(currentEmpresa.creador === "Propietario" ||
          currentEmpresa.permisos.bitacora) && (
          <ContenedorLogEmpresa>
            <TextLabelTitle style={{ color: "#767676" }}>
              BITACORA
            </TextLabelTitle>
            <br></br>
            <ChevronTabIcon
              tabOpen={tabOpenBitacora}
              toggleTab={() => setTabOpenBitacora(!tabOpenBitacora)}
            />
            {(tabOpenBitacora || !smallViewPort) && (
              <div style={{ marginTop: "10px" }}>
                <SearchDropdown
                  onValue={onValueSearchDropDownLog}
                  options={searchDropDownArrayLog}
                />
                <SortingTable
                  multiselect={false}
                  iSortDescending={true}
                  rowsArray={logCopyArray}
                  columnsArray={columnsArrayLog}
                  hideCheckbox={true}
                />
              </div>
            )}
          </ContenedorLogEmpresa>
        )}
      </Contenedor>
    </div>
  );
}
