import { forwardRef, useImperativeHandle, useState } from "react";
import { TabsCard, TabsContainer } from "./TabsStyled";
import { v4 as uuidv4 } from "uuid";

interface Props {
  Tabs: Array<{ label: string; enable: boolean }>;
  onValue: (tab: string, index: number) => void;
}

const Tabs = forwardRef(({ Tabs, onValue }: Props, ref) => {
  const [activeTab, setActiveTab] = useState(0);

  useImperativeHandle(ref, () => ({}));

  const setAsActive = (
    index: number,
    tab: { label: string; enable: boolean }
  ) => {
    if (tab.enable) {
      setActiveTab(index);
      onValue(tab.label, index);
    }
  };
  return (
    <TabsContainer>
      {Tabs.map((tab, index) => (
        <TabsCard
          key={uuidv4()}
          active={activeTab === index ? "true" : "false"}
          enable={tab.enable ? "true" : "false"}
          onClick={() => setAsActive(index, tab)}
        >
          <label>{tab.label}</label>
        </TabsCard>
      ))}
    </TabsContainer>
  );
});

export default Tabs;
