import { Subject } from "rxjs";
import {
  ChevronTabIcon,
  DomicilioFiscal,
  SearchDropdown,
  SortingTable,
  Warning,
} from "../../../../Components";
import {
  ButtonPrimario,
  ButtonSecundario,
  ContenedorBotones,
  ContenedorBotonesExterior,
  DivTitulos,
  DivTitulosSubTitulo,
  DivTitulosTitulo,
  Flex1,
  FlexRow,
  InputGrande,
  LabelEliminar,
  Obligatorio,
  PEliminar,
  TextLabel,
  TextLabelTitle,
} from "../../../communStyled";
import {
  Contenedor,
  ContenedorDomicilioFiscal,
  ContenedorRegistro,
} from "./RegistroEstatalYDelIMSSStyled";
import { useEffect, useRef, useState } from "react";
import useViewPortSize from "../../../../Hooks/useViewPortSize";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { apiRequest } from "../../../../Api/instance";
import { apiRoutes } from "../../../../Api/services";
import { scrollIntoView } from "../../../../Utils/sharedFunctions";
import actions from "../../../../Redux/Actions";

export default function RegistroEstatalYDelIMSS() {
  const dispatch = useDispatch();
  const alertRef = useRef(null);
  const domicilioFiscalFormRef = useRef<{
    cleanSubjects: any;
    patchValuesFunction: any;
    setCPValid: any;
  }>(null);
  const empresa: any = useSelector((store: any) => store.empresa);
  const currentEmpresa: any = useSelector((store: any) => store.currentEmpresa);
  const { smallViewPort } = useViewPortSize();
  const successfulSubject = new Subject<string>();
  const messageSubject = new Subject<string>();
  const [tabOpenRegistro, setTabOpenRegistro] = useState(false);
  const [tabOpenAgregarRegistro, setTabOpenAgregarRegistro] = useState(false);
  const [rowsArrayCopy, setRowsArrayCopy] = useState<any>([
    ...empresa.empresa.registroEstatalYDelIMSS.registros,
  ]);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [numeroDelIMSS, setNumeroDelIMSS] = useState<string>("");
  const [registroEstatal, setRegistroEstatal] = useState<string>("");
  const [id, setID] = useState<string>("");
  const [domicilioFiscalForm, setDomicilioFiscalForm] = useState<any>({
    codigoPostal: "",
    municipio: "",
    estado: "",
    colonia: "",
    calle: "",
    numeroExterior: "",
    numeroInterior: "",
  });

  const searchDropDownArray = [
    { label: "ID", valueLabel: "id" },
    { label: "IMSS", valueLabel: "numeroDelIMSS" },
    { label: "Estatal", valueLabel: "registroEstatal" },
  ];
  const columnsArray = [
    {
      label: "ID",
      valueLabel: "id",
      filtrable: true,
      widthPX: 100,
      type: "String",
    },
    {
      label: "IMSS",
      valueLabel: "numeroDelIMSS",
      filtrable: true,
      widthPX: 100,
      type: "String",
    },
    {
      label: "Estatal",
      valueLabel: "registroEstatal",
      filtrable: true,
      widthPX: 100,
      type: "String",
    },
  ];

  const onValueSearchDropDown = (value: any) => {
    if (value.searchValue !== "") {
      setRowsArrayCopy(
        empresa.empresa.registroEstatalYDelIMSS.registros.filter((row: any) => {
          return row[value.filter.valueLabel]
            .toString()
            .toUpperCase()
            .includes(value.searchValue.toString().toUpperCase());
        })
      );
    } else {
      setRowsArrayCopy([...empresa.empresa.registroEstatalYDelIMSS.registros]);
    }
  };

  const onValueSelected = (value: any) => {
    setSelectedValue(value);
    if (value) {
      const registro = value;
      setNumeroDelIMSS(registro.numeroDelIMSS);
      setRegistroEstatal(registro.registroEstatal);
      setID(registro.id);
      domicilioFiscalFormRef.current?.patchValuesFunction({
        codigoPostal: registro.codigoPostal,
        municipio: registro.municipio,
        estado: registro.estado,
        colonia: registro.colonia,
        calle: registro.calle,
        numeroExterior: registro.numeroExterior,
        numeroInterior: registro.numeroInterior,
        telefono: registro.telefono,
      });
    } else {
      setNumeroDelIMSS("");
      setRegistroEstatal("");
      setID("");
      setDomicilioFiscalForm({
        codigoPostal: "",
        municipio: "",
        estado: "",
        colonia: "",
        calle: "",
        numeroExterior: "",
        numeroInterior: "",
      });
      domicilioFiscalFormRef.current?.patchValuesFunction({
        codigoPostal: "",
        municipio: "",
        estado: "",
        colonia: "",
        calle: "",
        numeroExterior: "",
        numeroInterior: "",
        telefono: "",
      });
      domicilioFiscalFormRef.current?.cleanSubjects();
    }
  };

  const cancel = () => {
    setRowsArrayCopy([...empresa.empresa.registroEstatalYDelIMSS.registros]);
    domicilioFiscalFormRef.current?.cleanSubjects();
    domicilioFiscalFormRef.current?.setCPValid(false);
    setID("");
    setNumeroDelIMSS("");
    setRegistroEstatal("");
    domicilioFiscalFormRef.current?.patchValuesFunction({
      codigoPostal: "",
      municipio: "",
      estado: "",
      colonia: "",
      calle: "",
      numeroExterior: "",
      numeroInterior: "",
      telefono: "",
    });
  };

  const agregar = async () => {
    if (numeroDelIMSS !== "" && id !== "") {
      let registrosCopy = [
        ...empresa.empresa.registroEstatalYDelIMSS.registros,
      ];
      const newRegistro = {
        ...domicilioFiscalForm,
        id: id,
        numeroDelIMSS: numeroDelIMSS,
        registroEstatal: registroEstatal,
      };
      if (selectedValue) {
        registrosCopy = registrosCopy.map((registro) =>
          registro.id === id ? newRegistro : registro
        );
      } else {
        if (
          registrosCopy.reduce((accumulator, registro) => {
            return accumulator || registro.id === id;
          }, false)
        ) {
          messageSubject.next(
            "El ID que intenta agregar ya existe, por favor verifique que el ID sea correcto"
          );
          scrollIntoView(alertRef);
          return;
        }
        registrosCopy.push(newRegistro);
      }
      await updateRegistros(registrosCopy);
    } else {
      messageSubject.next(
        "Alguno de los campos requeridos no es valido o no contiene ninguna informacion"
      );
    }
  };

  const eliminarRegistro = async () => {
    {
      const registrosCopy = [
        ...rowsArrayCopy.filter((row: any) => selectedValue.id !== row.id),
      ];
      successfulSubject.next(
        "La Informacion se agrego correctamente a la base de datos"
      );
      await updateRegistros(registrosCopy);
    }
  };

  const updateRegistros = async (registros: any) => {
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.updateInfo,
      data: {
        area: "registroEstatalYDelIMSS",
        data: { registros: registros },
        id: currentEmpresa.id,
        modulo: "empresa",
      },
    });
    if (res) {
      if (res.message !== "Proceed") {
        messageSubject.next(res.message);
      } else {
        successfulSubject.next(
          "La Informacion se agrego correctamente a la base de datos"
        );
        await domicilioFiscalFormRef.current?.cleanSubjects();
        domicilioFiscalFormRef.current?.setCPValid(false);
        setID("");
        setNumeroDelIMSS("");
        setRegistroEstatal("");
        domicilioFiscalFormRef.current?.patchValuesFunction({
          codigoPostal: "",
          municipio: "",
          estado: "",
          colonia: "",
          calle: "",
          numeroExterior: "",
          numeroInterior: "",
          telefono: "",
        });
        dispatch(
          actions.empresaActions.replaceEmpresa({
            ...empresa,
            empresa: {
              ...empresa.empresa,
              registroEstatalYDelIMSS: {
                registros: registros,
              },
            },
          })
        );
        setSelectedValue(null);
      }
    }
    if (error) {
      messageSubject.next("Hubo un error en el servidor");
    }
    scrollIntoView(alertRef);
    setRowsArrayCopy(registros);
  };

  useEffect(() => {
    setRowsArrayCopy([...empresa.empresa.registroEstatalYDelIMSS.registros]);
  }, [empresa.empresa.registroEstatalYDelIMSS.registros]);

  return (
    <div>
      <DivTitulos>
        <DivTitulosTitulo>EMPRESA</DivTitulosTitulo>
        <DivTitulosSubTitulo>REGISTRO ESTATAL Y DEL IMSS</DivTitulosSubTitulo>
      </DivTitulos>
      <div ref={alertRef} style={{margin: '0px 35px'}}>
        <Warning
          type={"Alert"}
          title={"Alerta"}
          warningSubject={messageSubject}
        />
        <Warning
          type={"Successful"}
          title={"Exito!"}
          warningSubject={successfulSubject}
        />
      </div>
      <Contenedor>
        <ContenedorRegistro>
          <TextLabelTitle>REGISTRO</TextLabelTitle>
          <ChevronTabIcon
            tabOpen={tabOpenRegistro}
            toggleTab={() => setTabOpenRegistro(!tabOpenRegistro)}
          />
          {(tabOpenRegistro || !smallViewPort) && (
            <>
              <SearchDropdown
                onValue={onValueSearchDropDown}
                options={searchDropDownArray}
              />
              <SortingTable
                tableHeight={"383px"}
                multiselect={false}
                showElementsPerPage={6}
                rowsArray={rowsArrayCopy}
                columnsArray={columnsArray}
                onSelectElement={(value: any) => onValueSelected(value)}
              />
            </>
          )}
        </ContenedorRegistro>
        <ContenedorDomicilioFiscal>
          <TextLabelTitle>AGREGAR REGISTRO</TextLabelTitle>
          <ChevronTabIcon
            tabOpen={tabOpenAgregarRegistro}
            toggleTab={() => setTabOpenAgregarRegistro(!tabOpenAgregarRegistro)}
          />
          {(tabOpenAgregarRegistro || !smallViewPort) && (
            <>
              {selectedValue && (
                <PEliminar onClick={() => eliminarRegistro()}>
                  <LabelEliminar>Eliminar registro</LabelEliminar>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </PEliminar>
              )}
              <FlexRow style={{ columnGap: "30px" }}>
                <Flex1>
                  <TextLabel>
                    ID<Obligatorio>*</Obligatorio>
                  </TextLabel>
                  <InputGrande
                    value={id}
                    onChange={(e) => setID(e.target.value)}
                    type="text"
                  />
                </Flex1>
                <Flex1>
                  <TextLabel>
                    Numero del IMSS<Obligatorio>*</Obligatorio>
                  </TextLabel>
                  <InputGrande
                    value={numeroDelIMSS}
                    onChange={(e) => setNumeroDelIMSS(e.target.value)}
                    type="text"
                  />
                </Flex1>
                <Flex1>
                  <TextLabel>Registro Estatal</TextLabel>
                  <InputGrande
                    value={registroEstatal}
                    onChange={(e) => setRegistroEstatal(e.target.value)}
                    type="text"
                  />
                </Flex1>
              </FlexRow>
              <DomicilioFiscal
                ref={domicilioFiscalFormRef}
                onValuesChange={(values: any) => setDomicilioFiscalForm(values)}
                formValues={domicilioFiscalForm}
              />
            </>
          )}
        </ContenedorDomicilioFiscal>
      </Contenedor>
      <ContenedorBotonesExterior>
        <ContenedorBotones>
          <ButtonPrimario onClick={() => agregar()}>
            {selectedValue ? "Editar" : "Agregar"}
          </ButtonPrimario>
          <ButtonSecundario onClick={() => cancel()}>Cancelar</ButtonSecundario>
        </ContenedorBotones>
      </ContenedorBotonesExterior>
    </div>
  );
}
