import informacionGeneralActions from './InformacionGeneralActions.js'
import informacionGeneralDeUsuarioActions from './InformacionGeneralDeUsuarioActions.js'
import empresaActions from './EmpresaActions.js'
import currentEmpresaActions from './CurrentEmpresaActions.js'
import currentIMGActions from './CurrentIMGActions.js'
import currentTrabajador from './CurrentTrabajadorActions.js'

const actions = {
    informacionGeneralActions,
    informacionGeneralDeUsuarioActions,
    empresaActions,
    currentEmpresaActions,
    currentIMGActions,
    currentTrabajador,
}

export default actions