import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ButtonQM,
  ButtonQM2,
  Contenedor,
  DivCeleste,
} from "./quantityManagerStyled";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

interface Props {
  quantity?: any;
  height?: number;
  Minimum: any;
  Maximum: any;
  onValue: (data: any) => void;
  disabled?: boolean;
}

const QuantityManager = ({
  quantity,
  height,
  Minimum,
  Maximum,
  onValue,
  disabled,
}: Props) => {


  const add = () => {
    onValue(quantity + 1 >= Maximum ? Maximum : quantity + 1);
  };

  const remove = () => {
    onValue(quantity - 1 <= Minimum ? Minimum : quantity - 1);
  };
  return (
    <Contenedor style={{ height: height ? height + "px" : "35px" }}>
      <ButtonQM onClick={() => !disabled && remove()} disabled={disabled}>
        <FontAwesomeIcon icon={faMinusCircle} />
      </ButtonQM>
      <DivCeleste>{quantity}</DivCeleste>
      <ButtonQM2 onClick={() => !disabled && add()} disabled={disabled}>
        <FontAwesomeIcon icon={faPlusCircle} />
      </ButtonQM2>
    </Contenedor>
  );
};

export default QuantityManager;
