import styled from "styled-components";

export const ConenedorTabsButton = styled.div`
    margin: 20px 0px;
    display: flex;
    justify-content: center;
`;

interface TabSpanInterface {
    activeclass?: string;
}

export const TabSpan = styled.span<TabSpanInterface>`
    color: ${({ activeclass }) => activeclass === 'TabActive' ? '#FFFFFF' : '#000000DE'};
    background-color: ${({ activeclass }) => activeclass === 'TabActive' ? '#B7D32D' : '#FFFFFF'};
    padding: 10px 20px;
    border: 1px solid #DEDFDF;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: 'Lato';
    font-weight: bold;
`;

export const Contenedor = styled.div`
  display: flex;
  @media (max-width: 1900px) {
    display: inline-block;
  }
`;

export const ContenedorTabs = styled.div`
  margin-left: 45px;
  @media (max-width: 1000px) {
    margin-right: 16px;
    margin-left: 16px;
  }
`;

export const ContenedorDeConceptos = styled.div`
    width: 728px;
    margin: 16px;
    padding: 16px;
    height: fit-content;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DEDEDF;
    border-radius: 4px;
    border-top-left-radius: 0px;
    z-index: 2;
    border-radius: 0 5px 5px 5px;
    box-shadow: 0 -2px 2px -2px rgb(0 0 0 / 50%);
    border-top: none;
    @media (max-width: 1900px) {
        width: calc(100vw - 412px);
        margin-right: 45px;
    }
    @media (max-width: 1000px) {
        width: calc(100vw - 70px);
    }
`;

export const ContenedorDeConceptosChico = styled.div`
    width: 728px;
    padding: 16px;
    height: fit-content;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DEDEDF;
    border-radius: 4px;
    border-top-left-radius: 0px;
    z-index: 2;
    border-radius: 0 5px 5px 5px;
    box-shadow: 0 -2px 2px -2px rgb(0 0 0 / 50%);
    border-top: none;
    @media (max-width: 1900px) {
        width: calc(100vw - 448px);
        margin-right: 45px;
    }
    @media (max-width: 1000px) {
        width: calc(100vw - 116px);
    }
`;

export const ContenedorForm = styled.div`
    @media (max-width: 1900px) {
        margin-left: 45px;
    }
    @media (max-width: 1000px) {
        margin-top: 20px;
        margin-left: 16px;
    }
`;

export const SecondDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 12px;
    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;