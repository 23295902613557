import { useRef, useState } from "react";
import { ButtonPrimario, ButtonSecundario, ContenedorBotones, ContenedorBotonesExterior, ContenedorSection, DivTitulos, DivTitulosSubTitulo, DivTitulosTitulo, InputGrande } from "../../../communStyled";
import { SortingTable, Warning } from "../../../../Components";
import { Subject } from "rxjs";
import { useSelector } from "react-redux";
import { ContenedorDeFormulas, ContenedorDeKeys, KeyTag, ContenedorDeSimbolosDeOperacion, SimboloDeOperacion, Formula } from "./ConfiguracionDeCriteriosStyled";

export default function ConfiguracionDeCriterios() {
  const alertRef = useRef(null);
  const sortingTableRefCriterios = useRef<any>(null);
  const empresa: any = useSelector((store: any) => store.empresa);
  const messageSubject = new Subject<string>();
  const successfulSubject = new Subject<string>();
  const [nombreDelCriterio, setNombreDelCriterio] = useState("");
  const [formula, setFormula] = useState("");
  const [periodicidad, setPeriodicidad] = useState("");
  const [descripcion, setDescripcion] = useState("");
  // const [rowsArrayCriterios, setRowsArrayCriterios] = useState(empresa.nomina.conceptos.percepciones.adicionales);
  const [rowsArrayCriterios, setRowsArrayCriterios] = useState([
    {
      nombre: "Criterio 1",
      formula: "Horario Real * CD",
      periodicidad: "Mensual",
      descripcion: "Criterio 1"
    },
    {
      nombre: "Criterio 2",
      formula: "Horario Real * CD",
      periodicidad: "Mensual",
      descripcion: "Criterio 2"
    },
    {
      nombre: "Criterio 3",
      formula: "Horario Real * CD",
      periodicidad: "Mensual",
      descripcion: "Criterio 3"
    },
  ]);

  const columnsCriterios = [
    { label: 'Nombre', valueLabel: 'nombre', filtrable: true, widthPX: 100, type: 'String' },
    { label: 'Formula', valueLabel: 'formula', filtrable: true, widthPX: 380, type: 'String' },
    { label: 'Periodicidad', valueLabel: 'periodicidad', filtrable: true, widthPX: 380, type: 'String' },
    { label: 'Descripcion', valueLabel: 'descripcion', filtrable: true, widthPX: 380, type: 'String' },
  ];

  const onSelectCriterio = (row: any) => {
    setNombreDelCriterio(row.nombre);
    setFormula(row.formula);
    setPeriodicidad(row.periodicidad);
    setDescripcion(row.descripcion);
  };

  const submitForm = () => { };
  const cancel = () => { };

  return (
    <div>
      <DivTitulos>
        <DivTitulosTitulo>NOMINA</DivTitulosTitulo>
        <DivTitulosSubTitulo>
          CONFIGURACION DE CRITERIOS
        </DivTitulosSubTitulo>
      </DivTitulos>
      <div ref={alertRef} style={{ margin: '0px 35px' }}>
        <Warning
          type={"Alert"}
          title={"Alerta"}
          warningSubject={messageSubject}
        />
        <Warning
          type={"Successful"}
          title={"Exito!"}
          warningSubject={successfulSubject}
        />
      </div>
      <ContenedorSection>
        <ContenedorDeFormulas>
          Nombre del criterio
          <InputGrande
            type="text"
            value={nombreDelCriterio}
            onChange={(e) => setNombreDelCriterio(e.target.value)}
          />
          Descripcion
          <InputGrande
            type="text"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
          />
          Periodicidad
          <InputGrande
            type="text"
            value={periodicidad}
            onChange={(e) => setPeriodicidad(e.target.value)}
          />
          Parametros aplicables a la formula
          <ContenedorDeKeys>
            <KeyTag>Horario Real</KeyTag>
            <KeyTag>CD</KeyTag>
            <KeyTag>SBC</KeyTag>
            <KeyTag>Pgravadas</KeyTag>
            <KeyTag>pexentas</KeyTag>
            <KeyTag>Salario</KeyTag>
            <KeyTag>Salario Neto</KeyTag>
            <KeyTag>Total Percepciones</KeyTag>
            <KeyTag>Dias del periodo</KeyTag>
            <KeyTag>Valor</KeyTag>
            <KeyTag>Valor del dia</KeyTag>
          </ContenedorDeKeys>
          Simbolos de operacion
          <ContenedorDeSimbolosDeOperacion>
            <SimboloDeOperacion>+</SimboloDeOperacion>
            <SimboloDeOperacion>-</SimboloDeOperacion>
            <SimboloDeOperacion>*</SimboloDeOperacion>
            <SimboloDeOperacion>/</SimboloDeOperacion>
            <SimboloDeOperacion>(</SimboloDeOperacion>
            <SimboloDeOperacion>)</SimboloDeOperacion>
          </ContenedorDeSimbolosDeOperacion>
          Formula
          <Formula>
            <KeyTag>Horario Real</KeyTag> <SimboloDeOperacion>*</SimboloDeOperacion> <KeyTag>CD</KeyTag>
          </Formula>
        </ContenedorDeFormulas>
      </ContenedorSection>
          <ContenedorBotonesExterior>
          <ContenedorBotones>
            <ButtonPrimario onClick={() => submitForm()}>Aceptar</ButtonPrimario>
            <ButtonSecundario onClick={() => cancel()}>Cancelar</ButtonSecundario>
          </ContenedorBotones>
        </ContenedorBotonesExterior>
      <ContenedorSection>
        <SortingTable
          ref={sortingTableRefCriterios}
          tableHeight={"300px"}
          iSortDescending={true}
          onSelectElement={(row: any) =>
            onSelectCriterio(row)
          }
          rowsArray={rowsArrayCriterios}
          columnsArray={columnsCriterios}
          showElementsPerPage={10}
          multiselect={false}
        />
      </ContenedorSection>
    </div>
  )
}
