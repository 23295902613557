import { useEffect, useState } from "react";
import { TermsAndConditions } from "../../Components";
import { useNavigate } from "react-router-dom";
import { Copyright, Footers, PrivacyNotice } from "../communStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faUserCircle,
  faChevronDown,
  faIndustry,
  faUsers,
  faMoneyCheckAlt,
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { Perfil } from "../index";
import { pageToRenderLiteral } from "./pageLiteral.js";
import {
  ChevronContainer,
  ChevronDiv,
  ChevronSpan,
  Contenedor,
  Dia,
  Fecha,
  Logo,
  MainMenu,
  MainMenuTitle,
  Menu,
  NavBarContainer,
  NavigationContainer,
  Overlay,
  Panel,
  RouterOutlet,
  ScrollMenu,
  SubMenu,
  SubMenuItem,
  Title,
  TitleSpan,
} from "./WorkareaStyled";
import LogoIMG from "./../../assets/img/Logo.png";
import paths from "../../paths";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../Redux/Actions";
import { diasSemana, meses } from "../../Utils/constants";

export default function Workarea() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const currentPath = window.location.pathname;
  const [menuOpen, setMenuOpen] = useState(false);
  const [termsAndConditionsModal, setTermsAndConditionsModal] = useState(false);
  const [fecha, setFecha] = useState("");
  const [dia, setDia] = useState("");
  const [activeRoute, setActiveRoute] = useState("");
  const [
    validInformacionDeLaEmpresaOPatron,
    setValidInformacionDeLaEmpresaOPatron,
  ] = useState(false);
  const [validEsquemaDeNomina, setValidEsquemaDeNomina] = useState(false);
  const [
    validRegistroEstatalYDelIMSS,
    setValidRegistroEstatalYDelIMSS,
  ] = useState(false);
  const [validHorariosYControlDeAsistencia, setValidHorariosYControlDeAsistencia] = useState(false);
  const [validRegistroDeDepartamentos, setValidRegistroDeDepartamentos] = useState(false);
  const [validRegistroDePuestos, setValidRegistroDePuestos] = useState(false);


  const currentEmpresa: any = useSelector((store: any) => store.currentEmpresa);
  const empresa: any = useSelector((store: any) => store.empresa);

  useEffect(() => {
    const validInformacionDeLaEmpresaOPatronBool =
      (empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC
        .personaMoralCheckBox
        ? empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.rfc &&
          empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC
            .denominacion &&
          empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC
            .regimenCapital &&
          empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC
            .regimenFiscal &&
          empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.apoderado
        : empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.rfc &&
          empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.curp &&
          empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC.nombre &&
          empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC
            .primerApellido &&
          empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC
            .segundoApellido &&
          empresa.empresa.informacionDeLaEmpresaOPatron.nombreYRFC
            .regimenFiscal) &&
      empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal
        .codigoPostal &&
      empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal.municipio &&
      empresa.empresa.informacionDeLaEmpresaOPatron.domicilioFiscal.estado;
    setValidInformacionDeLaEmpresaOPatron(
      validInformacionDeLaEmpresaOPatronBool
    );
    const validEsquemaDeNominaBool =
      (validInformacionDeLaEmpresaOPatronBool &&
      ((empresa.empresa.esquemaDeNomina.configuracion.semanalCheckBox &&
        empresa.empresa.esquemaDeNomina.configuracion.periodoInicialSemanal !==
          "") ||
        (empresa.empresa.esquemaDeNomina.configuracion.decenalCheckBox &&
          empresa.empresa.esquemaDeNomina.configuracion
            .periodoInicialDecenal !== "" &&
          empresa.empresa.esquemaDeNomina.configuracion.factorDePagoDecenal !==
            "") ||
        (empresa.empresa.esquemaDeNomina.configuracion.catorcenalCheckBox &&
          empresa.empresa.esquemaDeNomina.configuracion
            .periodoInicialCatorcenal !== "") ||
        (empresa.empresa.esquemaDeNomina.configuracion.quincenalCheckBox &&
          empresa.empresa.esquemaDeNomina.configuracion
            .periodoInicialQuincenal !== "" &&
          empresa.empresa.esquemaDeNomina.configuracion.factorDePagoQuincenal)));
    setValidEsquemaDeNomina(validEsquemaDeNominaBool);
    const validRegistroEstatalYDelIMSSBool =
      (validEsquemaDeNominaBool &&
      (empresa.empresa.registroEstatalYDelIMSS.registros.length > 0 ));
    setValidRegistroEstatalYDelIMSS(validRegistroEstatalYDelIMSSBool);
    const validHorariosYControlDeAsistenciaBool =
      (validRegistroEstatalYDelIMSSBool &&
      (empresa.empresa.horariosYControlDeAsistencia.horarios.length > 0));
    setValidHorariosYControlDeAsistencia(validHorariosYControlDeAsistenciaBool);
    const validRegistroDeDepartamentos = (validHorariosYControlDeAsistenciaBool && (empresa.empresa.registroDeDepartamentos.departamentos.length > 0));
    setValidRegistroDeDepartamentos(validRegistroDeDepartamentos);
    const validRegistroDePuestos = (validRegistroDeDepartamentos && (empresa.trabajadores.registroDePuestos.puestos.length > 0));
    setValidRegistroDePuestos(validRegistroDePuestos);
  }, [empresa]);

  useEffect(() => {
    const f = new Date();
    setFecha(
      f.getDate() + " de " + meses[f.getMonth()] + " de " + f.getFullYear()
    );
    setDia(diasSemana[f.getDay()]);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const munuArray = [
    {
      title: "Inicio",
      icon: faUserCircle,
      subMenu: [
        {
          title: "Blog",
          show: true,
          path: paths.Blog,
          disabled: false,
        },
        {
          title: "Perfil",
          show:
            currentEmpresa.creador === "Propietario" ||
            currentEmpresa.permisos.notificaciones ||
            currentEmpresa.permisos.colaboradores ||
            currentEmpresa.permisos.bitacora,
          path: paths.Perfil,
          disabled: false,
        },
        {
          title: "Control de Empresas",
          show: true,
          path: paths.Base,
          disabled: false,
        },
      ],
    },
    {
      title: "EMPRESA",
      icon: faIndustry,
      subMenu: [
        {
          title: "Informacion de la empresa/patron",
          show:
            currentEmpresa.creador === "Propietario" ||
            currentEmpresa.permisos.informacionDeLaEmpresaOPatron,
          path: paths.InformacionDeLaEmpresaOPatron,
          disabled: false,
        },
        {
          title: "Esquema de nomina",
          show:
            currentEmpresa.creador === "Propietario" ||
            currentEmpresa.permisos.esquemaDeNomina,
          path: paths.EsquemaDeNomina,
          disabled: !validInformacionDeLaEmpresaOPatron,
        },
        {
          title: "Registro estatal y del IMSS",
          show:
            currentEmpresa.creador === "Propietario" ||
            currentEmpresa.permisos.registroEstatalYDelIMSS,
          path: paths.RegistroEstatalYDelIMSS,
          disabled: !validEsquemaDeNomina,
        },
        {
          title: "Horarios y control de asistencia",
          show:
            currentEmpresa.creador === "Propietario" ||
            currentEmpresa.permisos.horariosYControlDeAsistencia,
          path: paths.HorarioYControlDeAsistencia,
          disabled: !validRegistroEstatalYDelIMSS,
        },
        {
          title: "Prestaciones Generales",
          show:
            currentEmpresa.creador === "Propietario" ||
            currentEmpresa.permisos.prestacionesGenerales,
          path: paths.PrestacionesGenerales,
          disabled: !validHorariosYControlDeAsistencia,
        },
        {
          title: "Parametros de operacion",
          show:
            currentEmpresa.creador === "Propietario" ||
            currentEmpresa.permisos.parametrosDeOperacion,
          path: paths.ParametrosDeOperacion,
          disabled: !validHorariosYControlDeAsistencia,
        },
        {
          title: "Regristro de departamentos",
          show:
            currentEmpresa.creador === "Propietario" ||
            currentEmpresa.permisos.registroDeDepartamentos,
          path: paths.RegistroDeDepartamentos,
          disabled: !validHorariosYControlDeAsistencia,
        },
      ],
    },
    {
      title: "TRABAJADORES",
      icon: faUsers,
      subMenu: [  {
        title: "Regristro de puestos",
        show:
          currentEmpresa.creador === "Propietario" ||
          currentEmpresa.permisos.registroDePuestos,
        path: paths.RegistroDePuestos,
        disabled: !validRegistroDeDepartamentos,
      },
      {
        title: "Control de personal",
        show:
          currentEmpresa.creador === "Propietario" ||
          currentEmpresa.permisos.controlDePersonal,
        path: paths.ControlDePersonal,
        disabled: !validRegistroDePuestos,
      }
    ],
    },
    {
      title: "NOMINA",
      icon: faMoneyCheckAlt,
      subMenu: [
        {
          title: "Configuracion de criterios",
          show:
            currentEmpresa.creador === "Propietario" ||
            currentEmpresa.permisos.configuracionDeCriterios,
          path: paths.ConfiguracionDeCriterios,
          disabled: !validRegistroDeDepartamentos,
        },
          {
        title: "Configuracion de conceptos",
        show:
          currentEmpresa.creador === "Propietario" ||
          currentEmpresa.permisos.configuracionDeConceptos,
        path: paths.ConfiguracionDeConceptos,
        disabled: !validRegistroDeDepartamentos,
      },
    ],
    },
  ];

  useEffect(() => {
    if (!currentEmpresa.id) {
      history(paths.Base);
    }
  }, [currentEmpresa]);

  const moveTo = (path: string) => {
    if (path === paths.Base) {
      dispatch(actions.currentEmpresaActions.cleanCurrentEmpresa());
    } else history(path);
    setMenuOpen(false);
  };


  return (
    <Contenedor>
      <NavBarContainer>
        <NavigationContainer menuopen={menuOpen ? "true" : "false"}>
          <MainMenu>
            <Logo src={LogoIMG} onClick={() => history(paths.Inicio)} />
            <ScrollMenu>
              {munuArray.map((element) => (
                <li key={uuidv4()}>
                  <MainMenuTitle
                    onClick={() =>
                      setActiveRoute(
                        activeRoute === element.title ? "" : element.title
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon
                      icon={element.icon}
                      style={{ fontSize: "20px" }}
                    />
                    <ChevronDiv>
                      <ChevronSpan>{element.title.toUpperCase()}</ChevronSpan>
                      <ChevronContainer
                        activeroute={activeRoute === element.title ? "true" : "false"}
                      >
                        <FontAwesomeIcon icon={faChevronDown} />
                      </ChevronContainer>
                    </ChevronDiv>
                  </MainMenuTitle>
                  <SubMenu activeroute={activeRoute === element.title ? "true" : "false"}>
                    {element.subMenu.map((subMeuItem: any) =>
                      subMeuItem.show ? (
                        <SubMenuItem
                          disabled={subMeuItem.disabled}
                          key={uuidv4()}
                          onClick={() =>
                            !subMeuItem.disabled && moveTo(subMeuItem.path)
                          }
                        >
                          {subMeuItem.title}
                        </SubMenuItem>
                      ) : null
                    )}
                  </SubMenu>
                </li>
              ))}
            </ScrollMenu>
          </MainMenu>
        </NavigationContainer>
      </NavBarContainer>

      <RouterOutlet>
        <Title>
          <Menu>
            <FontAwesomeIcon icon={faBars} onClick={toggleMenu} />
          </Menu>
          <TitleSpan>SISTEMA INTEGRAL DE PERSONAL</TitleSpan>
          <Fecha>{fecha}</Fecha>
          <Dia>{dia}</Dia>
        </Title>
        {menuOpen && (
          <Overlay style={menuOpen ? { display: "none" } : {}}></Overlay>
        )}
        <Panel>
          <div style={{ minHeight: "calc(100% - 65px)" }}>
            {pageToRenderLiteral.hasOwnProperty(currentPath) ? (
              pageToRenderLiteral[currentPath as any]()
            ) : (
              <Perfil />
            )}
          </div>
          <Footers>
            <Copyright style={{ color: "black" }}>
              Copyright © {new Date().getFullYear()} SIP. Todos los derechos
              reservados.
            </Copyright>
            <PrivacyNotice
              style={{ color: "black" }}
              onClick={() => setTermsAndConditionsModal(true)}
            >
              Aviso de Privacidad | FAQ’s
            </PrivacyNotice>
          </Footers>
        </Panel>
      </RouterOutlet>

      {termsAndConditionsModal && (
        <TermsAndConditions
          handleClose={() => setTermsAndConditionsModal(false)}
        />
      )}
    </Contenedor>
  );
}
