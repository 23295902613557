import { useEffect, useRef, useState } from "react";
import { ButtonPrimario, ButtonSecundario, ComplementaryFlex, ComplementaryFlexSmall, Contenedor, ContenedorBotones, ContenedorBotonesExterior, ContenedorSection, DivTitulos, DivTitulosSubTitulo, DivTitulosTitulo, Flex1, FlexCenter, FlexColumn, FlexRow, FlexRowSmall, InputMini, TextLabel, TextLabelTitle } from "../../../communStyled";
import { Checkbox, ChevronTabIcon, Dropdown, Modal, SortingTable, Warning } from "../../../../Components";
import { Subject } from "rxjs";
import { useDispatch, useSelector } from "react-redux";
import useViewPortSize from "../../../../Hooks/useViewPortSize";
import { ccNumberLimit, ccNumberLimitMaxMin, formatDay, scrollIntoView } from "../../../../Utils/sharedFunctions";
import { CheckboxsContainer, DiasDeAguinaldoDiasContainer, DiasDeDescansoColumnContainer, DiasDeDescansoSectionContainer, DiasDeVacacionesRowModal, DiasDeVacacionesRowModalColumn, DiasDeVacacionesRowModalColumnContainer, VerTablaContainer } from "./PrestacionesGeneralesStyled";
import { apiRequest, apiRoutes } from "../../../../Api/services";
import actions from "../../../../Redux/Actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import DatePickerComponent from "../../../../Components/DatePicker/DatePicker";

export default function PrestacionesGenerales() {
  const alertRef = useRef(null);
  const empresa: any = useSelector((store: any) => store.empresa);
  const currentEmpresa: any = useSelector((store: any) => store.currentEmpresa);
  const informacionGeneral: any = useSelector((store: any) => store.informacionGeneral);
  const messageSubject = new Subject<string>();
  const successfulSubject = new Subject<string>();
  const { smallViewPort } = useViewPortSize();
  const dispatch = useDispatch();

  const [verTablaLeyModal, setVerTablaLeyModal] = useState(false);
  const [tabOpenPrimaVacacional, setTabOpenPrimaVacacional] = useState(false);
  const [tabOpenAguinaldo, setTabOpenAguinaldo] = useState(false);
  const [porcentajeDePrimaVacacional, setPorcentajeDePrimaVacacional] = useState(
    empresa.empresa.prestacionesGenerales.primaVacacional.porcentajeDePrimaVacacional
  );
  const [descontarFaltas, setDescontarFaltas] = useState(
    empresa.empresa.prestacionesGenerales.primaVacacional.descontarFaltas
  );
  const [pagarPrimaAlCumplirAñosDeServicio, setPagarPrimaAlCumplirAñosDeServicio] = useState(
    empresa.empresa.prestacionesGenerales.primaVacacional.pagarPrimaAlCumplirAñosDeServicio
  );
  const [descontarIncapacidadesPorEnfermedad, setDescontarIncapacidadesPorEnfermedad] = useState(
    empresa.empresa.prestacionesGenerales.primaVacacional.descontarIncapacidadesPorEnfermedad
  );
  const [descontarIncapacidadesPorRiesgo, setDescontarIncapacidadesPorRiesgo] = useState(
    empresa.empresa.prestacionesGenerales.primaVacacional.descontarIncapacidadesPorRiesgo
  );
  const [incluirOtrasPrestaciones, setIncluirOtrasPrestaciones] = useState(
    empresa.empresa.prestacionesGenerales.primaVacacional.incluirOtrasPrestaciones
  );
  const [diasDeVacaciones, setDiasDeVacaciones] = useState(
    empresa.empresa.prestacionesGenerales.primaVacacional.diasDeVacaciones
  );
  const [verTablaArregloModal, setVerTablaArregloModal] = useState(false);

  const [arregloTablaDiasDeVacaciones, setArregloTablaDiasDeVacaciones] = useState(empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones);
  const [tablaDiasDeVacacionesPrimerAño, setTablaDiasDeVacacionesPrimerAño] = useState(empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones.length > 0 ? empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones[empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones.length - 1].segundoAño : 0);
  const [tablaDiasDeVacacionesSegundoAño, setTablaDiasDeVacacionesSegundoAño] = useState(empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones.length > 0 ? empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones[empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones.length - 1].segundoAño + 1 : 1);
  const [tablaDiasDeVacaciones, setTablaDiasDeVacaciones] = useState(0);
  const [tablaDiasDeVacacionesPrima, setTablaDiasDeVacacionesPrima] = useState(0);
  const [selectedElement, setSelectedElement] = useState<any>(null);

  const tablaColumnsArray = [
    {
      label: "De",
      valueLabel: "primerAño",
      filtrable: false,
      widthPX: 150,
      type: "String",
    },
    {
      label: "A",
      valueLabel: "segundoAño",
      filtrable: false,
      widthPX: 150,
      type: "String",
    },
    {
      label: "Días de Vacaciones",
      valueLabel: "diasDeVacaciones",
      filtrable: false,
      widthPX: 200,
      type: "String",
    },
    {
      label: "Prima %",
      valueLabel: "prima",
      filtrable: false,
      widthPX: 150,
      type: "String",
    },
  ];

  const leyColumnsArray = [
    {
      label: "Primer Año",
      valueLabel: "primerAño",
      filtrable: false,
      widthPX: 150,
      type: "String",
    },
    {
      label: "Segundo Año",
      valueLabel: "segundoAño",
      filtrable: false,
      widthPX: 150,
      type: "String",
    },
    {
      label: "Días de Vacaciones",
      valueLabel: "diasDeVacaciones",
      filtrable: false,
      widthPX: 150,
      type: "String",
    },
  ];

  const [tipoDeAguinaldo, setTipoDeAguinaldo] = useState(empresa.empresa.prestacionesGenerales.aguinaldo.tipoDeAguinaldo);
  const [diasDeAguinaldo, setDiasDeAguinaldo] = useState(empresa.empresa.prestacionesGenerales.aguinaldo.diasDeAguinaldo);
  const [permitirPagos, setPermitirPagos] = useState(empresa.empresa.prestacionesGenerales.aguinaldo.permitirPagos);
  const [numeroDePagos, setNumeroDePagos] = useState(empresa.empresa.prestacionesGenerales.aguinaldo.numeroDePagos);
  const [procesoDeNominaPorSeparado, setProcesoDeNominaPorSeparado] = useState(empresa.empresa.prestacionesGenerales.aguinaldo.procesoDeNominaPorSeparado);
  const [descontarFaltasAguinaldo, setDescontarFaltasAguinaldo] = useState(empresa.empresa.prestacionesGenerales.aguinaldo.descontarFaltas);
  const [descontarIncapacidadesPorEnfermedadAguinaldo, setDescontarIncapacidadesPorEnfermedadAguinaldo] = useState(empresa.empresa.prestacionesGenerales.aguinaldo.descontarIncapacidadesPorEnfermedad);
  const [descontarIncapacidadesPorRiesgoAguinaldo, setDescontarIncapacidadesPorRiesgoAguinaldo] = useState(empresa.empresa.prestacionesGenerales.aguinaldo.descontarIncapacidadesPorRiesgo);
  const [periodoParaAguinaldo, setPeriodoParaAguinaldo] = useState(empresa.empresa.prestacionesGenerales.aguinaldo.periodoParaAguinaldo);
  const [incluirOtrasPrestacionesAguinaldo, setIncluirOtrasPrestacionesAguinaldo] = useState(empresa.empresa.prestacionesGenerales.aguinaldo.incluirOtrasPrestaciones);


  const [tabOpenDiasDeDescanso, setTabOpenDiasDeDescanso] = useState(false);
  const [fechaDiasDeDescanso, setFechaDiasDeDescanso] = useState(null);
  const [primaDiasDeDescanso, setPrimaDiasDeDescanso] = useState(0);
  const [tipoDiasDeDescanso, setTipoDiasDeDescanso] = useState("Obligatorio");
  const [selectedElementDiasDeDescanso, setSelectedElementDiasDeDescanso] = useState<any>(null);
  const [porcentajeDePrimaDominical, setPorcentajeDePrimaDominical] = useState(empresa.empresa.prestacionesGenerales.diasDeDescansoYVacaciones.porcentajeDePrimaDominical);
  const [pagarPrimaDominicalEnDescansoLaborado, setPagarPrimaDominicalEnDescansoLaborado] = useState(empresa.empresa.prestacionesGenerales.diasDeDescansoYVacaciones.pagarPrimaDominicalEnDescansoLaborado);
  const [diasDeDescansoYVacacionesCopy, setDiasDeDescansoYVacacionesCopy] = useState(empresa.empresa.prestacionesGenerales.diasDeDescansoYVacaciones.diasDeDescansoYVacaciones);

  const diasDeDescansoObligatoriosArray = [
    {
      label: "Fecha",
      valueLabel: "fecha",
      filtrable: false,
      widthPX: 200,
      type: "Date",
    },
    {
      label: "Prima %",
      valueLabel: "porcentajeDePrima",
      filtrable: false,
      widthPX: 150,
      type: "String",
    },
    {
      label: "Tipo",
      valueLabel: "tipo",
      filtrable: false,
      widthPX: 150,
      type: "String",
    },
  ];

  useEffect(() => {
    const getDiasDeVacacionesLey = async () => {
      if (informacionGeneral.diasDeVacacionesLey.length === 0) {
        const [error, data] = await apiRequest({
          method: "get",
          url:
            apiRoutes.diasDeVacacionesLey,
        });
        if (error) {
          messageSubject.next("Error al obtener los días de vacaciones de la ley");
        }
        if (data) {
          dispatch(
            actions.informacionGeneralActions.replaceInformacionGeneral({
              diasDeVacacionesLey: data.diasDeVacacionesLey,
            })
          );
        }
      }
    }
    getDiasDeVacacionesLey();

  }, []);

  const addRegister = () => {
    const newRegister = {
      primerAño: tablaDiasDeVacacionesPrimerAño,
      segundoAño: tablaDiasDeVacacionesSegundoAño,
      diasDeVacaciones: tablaDiasDeVacaciones,
      prima: tablaDiasDeVacacionesPrima,
    };
    setArregloTablaDiasDeVacaciones([...arregloTablaDiasDeVacaciones, newRegister]);
    setTablaDiasDeVacacionesPrimerAño(tablaDiasDeVacacionesSegundoAño);
    const nextNumber = tablaDiasDeVacacionesSegundoAño + 1;
    setTablaDiasDeVacacionesSegundoAño(ccNumberLimit(nextNumber, 100));
    setTablaDiasDeVacaciones(0);
    setTablaDiasDeVacacionesPrima(0);
  }

  const removeRegister = () => {
    const newArray = arregloTablaDiasDeVacaciones.slice(0, arregloTablaDiasDeVacaciones.findIndex(
      (element: any) => element.primerAño === selectedElement?.primerAño
    ));
    setArregloTablaDiasDeVacaciones([...newArray]);
    setTablaDiasDeVacacionesPrimerAño(newArray.length > 0 ? newArray[newArray.length - 1].segundoAño : 0);
    setTablaDiasDeVacacionesSegundoAño(newArray.length > 0 ? newArray[newArray.length - 1].segundoAño + 1 : 1);
    setSelectedElement(null);
    setTablaDiasDeVacaciones(0);
    setTablaDiasDeVacacionesPrima(0);
  }

  const submitForm = async () => {
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.updateInfo,
      data: {
        area: "prestacionesGenerales",
        data: {
          ...empresa.empresa.prestacionesGenerales,
          primaVacacional: {
            ...empresa.empresa.prestacionesGenerales.primaVacacional,
            porcentajeDePrimaVacacional,
            descontarFaltas,
            pagarPrimaAlCumplirAñosDeServicio,
            descontarIncapacidadesPorEnfermedad,
            descontarIncapacidadesPorRiesgo,
            incluirOtrasPrestaciones,
            diasDeVacaciones,
            tablaDeDiasDeVacaiones: arregloTablaDiasDeVacaciones,
          },
          aguinaldo: {
            ...empresa.empresa.prestacionesGenerales.aguinaldo,
            tipoDeAguinaldo,
            diasDeAguinaldo,
            permitirPagos,
            numeroDePagos,
            procesoDeNominaPorSeparado,
            descontarFaltas: descontarFaltasAguinaldo,
            descontarIncapacidadesPorEnfermedad: descontarIncapacidadesPorEnfermedadAguinaldo,
            descontarIncapacidadesPorRiesgo: descontarIncapacidadesPorRiesgoAguinaldo,
            periodoParaAguinaldo,
            incluirOtrasPrestaciones: incluirOtrasPrestacionesAguinaldo,
          },
          diasDeDescansoYVacaciones: {
            ...empresa.empresa.prestacionesGenerales.diasDeDescansoYVacaciones,
            porcentajeDePrimaDominical,
            pagarPrimaDominicalEnDescansoLaborado,
            diasDeDescansoYVacaciones: diasDeDescansoYVacacionesCopy,
          },
        },
        id: currentEmpresa.id,
        modulo: "empresa",
      },
    });

    if (error) {
      messageSubject.next("Hubo un error en el servidor");
      scrollIntoView(alertRef);
    }
    if (res) {
      if (res.message !== "Proceed") {
        messageSubject.next(res.message);
        scrollIntoView(alertRef);
      } else {
        scrollIntoView(alertRef);
        setVerTablaArregloModal(false);
        dispatch(
          actions.empresaActions.replaceEmpresa({
            ...empresa,
            empresa: {
              ...empresa.empresa,
              prestacionesGenerales: {
                ...empresa.empresa.prestacionesGenerales,
                primaVacacional: {
                  ...empresa.empresa.prestacionesGenerales.primaVacacional,
                  porcentajeDePrimaVacacional,
                  descontarFaltas,
                  pagarPrimaAlCumplirAñosDeServicio,
                  descontarIncapacidadesPorEnfermedad,
                  descontarIncapacidadesPorRiesgo,
                  incluirOtrasPrestaciones,
                  diasDeVacaciones,
                  tablaDeDiasDeVacaiones: arregloTablaDiasDeVacaciones,
                },
                aguinaldo: {
                  ...empresa.empresa.prestacionesGenerales.aguinaldo,
                  tipoDeAguinaldo,
                  diasDeAguinaldo,
                  permitirPagos,
                  numeroDePagos,
                  procesoDeNominaPorSeparado,
                  descontarFaltas: descontarFaltasAguinaldo,
                  descontarIncapacidadesPorEnfermedad: descontarIncapacidadesPorEnfermedadAguinaldo,
                  descontarIncapacidadesPorRiesgo: descontarIncapacidadesPorRiesgoAguinaldo,
                  periodoParaAguinaldo,
                  incluirOtrasPrestaciones: incluirOtrasPrestacionesAguinaldo,
                },
                diasDeDescansoYVacaciones: {
                  ...empresa.empresa.prestacionesGenerales.diasDeDescansoYVacaciones,
                  porcentajeDePrimaDominical,
                  pagarPrimaDominicalEnDescansoLaborado,
                  diasDeDescansoYVacaciones: diasDeDescansoYVacacionesCopy,
                },
              }
            },
          })
        );
        successfulSubject.next(
          "La Informacion se agrego correctamente a la base de datos"
        );
      }
    }
  };
  const cancel = () => {
    setPorcentajeDePrimaVacacional(empresa.empresa.prestacionesGenerales.primaVacacional.porcentajeDePrimaVacacional);
    setDescontarFaltas(empresa.empresa.prestacionesGenerales.primaVacacional.descontarFaltas);
    setPagarPrimaAlCumplirAñosDeServicio(empresa.empresa.prestacionesGenerales.primaVacacional.pagarPrimaAlCumplirAñosDeServicio);
    setDescontarIncapacidadesPorEnfermedad(empresa.empresa.prestacionesGenerales.primaVacacional.descontarIncapacidadesPorEnfermedad);
    setDescontarIncapacidadesPorRiesgo(empresa.empresa.prestacionesGenerales.primaVacacional.descontarIncapacidadesPorRiesgo);
    setIncluirOtrasPrestaciones(empresa.empresa.prestacionesGenerales.primaVacacional.incluirOtrasPrestaciones);
    setDiasDeVacaciones(empresa.empresa.prestacionesGenerales.primaVacacional.diasDeVacaciones);
    setArregloTablaDiasDeVacaciones(empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones);
    setTablaDiasDeVacacionesPrimerAño(empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones.length > 0 ? empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones[empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones.length - 1].segundoAño : 0);
    setTablaDiasDeVacacionesSegundoAño(empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones.length > 0 ? empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones[empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones.length - 1].segundoAño + 1 : 1);
    setTablaDiasDeVacaciones(0);
    setTablaDiasDeVacacionesPrima(0);
    setTipoDeAguinaldo(empresa.empresa.prestacionesGenerales.aguinaldo.tipoDeAguinaldo);
    setDiasDeAguinaldo(empresa.empresa.prestacionesGenerales.aguinaldo.diasDeAguinaldo);
    setPermitirPagos(empresa.empresa.prestacionesGenerales.aguinaldo.permitirPagos);
    setNumeroDePagos(empresa.empresa.prestacionesGenerales.aguinaldo.numeroDePagos);
    setProcesoDeNominaPorSeparado(empresa.empresa.prestacionesGenerales.aguinaldo.procesoDeNominaPorSeparado);
    setDescontarFaltasAguinaldo(empresa.empresa.prestacionesGenerales.aguinaldo.descontarFaltas);
    setDescontarIncapacidadesPorEnfermedadAguinaldo(empresa.empresa.prestacionesGenerales.aguinaldo.descontarIncapacidadesPorEnfermedad);
    setDescontarIncapacidadesPorRiesgoAguinaldo(empresa.empresa.prestacionesGenerales.aguinaldo.descontarIncapacidadesPorRiesgo);
    setPeriodoParaAguinaldo(empresa.empresa.prestacionesGenerales.aguinaldo.periodoParaAguinaldo);
    setIncluirOtrasPrestacionesAguinaldo(empresa.empresa.prestacionesGenerales.aguinaldo.incluirOtrasPrestaciones);
    setPorcentajeDePrimaDominical(empresa.empresa.prestacionesGenerales.diasDeDescansoYVacaciones.porcentajeDePrimaDominical);
    setPagarPrimaDominicalEnDescansoLaborado(empresa.empresa.prestacionesGenerales.diasDeDescansoYVacaciones.pagarPrimaDominicalEnDescansoLaborado);
    setFechaDiasDeDescanso(null);
    setPrimaDiasDeDescanso(0);
    setTipoDiasDeDescanso("Obligatorio");
   };

  const submitTablaVacaciones = async () => {
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.updateInfo,
      data: {
        area: "prestacionesGenerales",
        data: {
          ...empresa.empresa.prestacionesGenerales, primaVacacional: {
            ...empresa.empresa.prestacionesGenerales.primaVacacional,
            tablaDeDiasDeVacaiones: arregloTablaDiasDeVacaciones,
          }
        },
        id: currentEmpresa.id,
        modulo: "empresa",
      },
    });
    if (error) {
      messageSubject.next("Hubo un error en el servidor");
      scrollIntoView(alertRef);
    }
    if (res) {
      if (res.message !== "Proceed") {
        messageSubject.next(res.message);
        scrollIntoView(alertRef);
      } else {
        scrollIntoView(alertRef);
        setVerTablaArregloModal(false);
        dispatch(
          actions.empresaActions.replaceEmpresa({
            ...empresa,
            empresa: {
              ...empresa.empresa,
              prestacionesGenerales: {
                ...empresa.empresa.prestacionesGenerales,
                primaVacacional: {
                  ...empresa.empresa.prestacionesGenerales.primaVacacional,
                  tablaDeDiasDeVacaiones: [...arregloTablaDiasDeVacaciones],
                },
              },
            },
          })
        );
        successfulSubject.next(
          "La Informacion se agrego correctamente a la base de datos"
        );
      }
    }
  };

  const cancelTablaVacaciones = () => {
    setVerTablaArregloModal(false);
    setArregloTablaDiasDeVacaciones(empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones);
    setTablaDiasDeVacacionesPrimerAño(empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones.length > 0 ? empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones[empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones.length - 1].segundoAño : 0);
    setTablaDiasDeVacacionesSegundoAño(empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones.length > 0 ? empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones[empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones.length - 1].segundoAño + 1 : 1);
    setTablaDiasDeVacaciones(0);
    setTablaDiasDeVacacionesPrima(0);
  };

  const addRegisterDiasDeDescanso = () => {
    const newRegister = {
      fecha: fechaDiasDeDescanso,
      porcentajeDePrima: primaDiasDeDescanso,
      tipo: tipoDiasDeDescanso,
    };
    if (selectedElementDiasDeDescanso) {
      const newArray = diasDeDescansoYVacacionesCopy.filter(
        (element: any) => element.fecha !== selectedElementDiasDeDescanso?.fecha
      );
      setDiasDeDescansoYVacacionesCopy([...newArray, newRegister]);
    } else setDiasDeDescansoYVacacionesCopy([...diasDeDescansoYVacacionesCopy, newRegister]);
    setFechaDiasDeDescanso(null);
    setPrimaDiasDeDescanso(0);
    setTipoDiasDeDescanso("Obligatorio");
  };
  const removeRegisterDiasDeDescanso = () => {
    const newArray = diasDeDescansoYVacacionesCopy.filter(
      (element: any) => element.fecha !== selectedElementDiasDeDescanso?.fecha
    );
    setDiasDeDescansoYVacacionesCopy([...newArray]);
    setSelectedElementDiasDeDescanso(null);
    setFechaDiasDeDescanso(null);
    setPrimaDiasDeDescanso(0);
    setTipoDiasDeDescanso("Obligatorio");
  };

  useEffect(() => {
    if (selectedElementDiasDeDescanso) {
      setFechaDiasDeDescanso(selectedElementDiasDeDescanso.fecha);
      setPrimaDiasDeDescanso(selectedElementDiasDeDescanso.porcentajeDePrima);
      setTipoDiasDeDescanso(selectedElementDiasDeDescanso.tipo);
    } else {
      setFechaDiasDeDescanso(null);
      setPrimaDiasDeDescanso(0);
      setTipoDiasDeDescanso("Obligatorio");
    }
  }, [selectedElementDiasDeDescanso]);

  return (
    <>
      <div>
        <DivTitulos>
          <DivTitulosTitulo>EMPRESA</DivTitulosTitulo>
          <DivTitulosSubTitulo>
            PRESTACIONES GENERALES
          </DivTitulosSubTitulo>
        </DivTitulos>
        <div ref={alertRef} style={{margin: '0px 35px'}}>
          <Warning
            type={"Alert"}
            title={"Alerta"}
            warningSubject={messageSubject}
          />
          <Warning
            type={"Successful"}
            title={"Exito!"}
            warningSubject={successfulSubject}
          />
        </div>
        <Contenedor>
          <ContenedorSection>
            <TextLabelTitle>Prima Vacacional</TextLabelTitle>
            <ChevronTabIcon
              tabOpen={tabOpenPrimaVacacional}
              toggleTab={() => setTabOpenPrimaVacacional(!tabOpenPrimaVacacional)}
            />
            {(tabOpenPrimaVacacional || !smallViewPort) && (
              <>
                <FlexRowSmall inverse='true'>
                  <Flex1>
                    <InputMini
                      value={porcentajeDePrimaVacacional}
                      onChange={(e) =>
                        setPorcentajeDePrimaVacacional(ccNumberLimit(parseInt(e.target.value), 999))
                      }
                      type="number"
                    />
                  </Flex1>
                  <ComplementaryFlexSmall>
                    % de Prima Vacacional
                  </ComplementaryFlexSmall>
                </FlexRowSmall>
                <CheckboxsContainer>
                  <Flex1>
                    <Checkbox
                      checked={descontarFaltas}
                      handleClick={(value: boolean) => setDescontarFaltas(value)
                      }
                    />
                    <span style={{ marginLeft: '10px' }}>Descontar faltas para prima vacacional</span>
                  </Flex1>
                  <Flex1>
                    <Checkbox
                      checked={pagarPrimaAlCumplirAñosDeServicio}
                      handleClick={(value: boolean) => setPagarPrimaAlCumplirAñosDeServicio(value)
                      }
                    />
                    <span style={{ marginLeft: '10px' }}>Pagar prima vacacional al cumplir años de servicio</span>
                  </Flex1>
                  <Flex1>
                    <Checkbox
                      checked={descontarIncapacidadesPorEnfermedad}
                      handleClick={(value: boolean) => setDescontarIncapacidadesPorEnfermedad(value)
                      }
                    />
                    <span style={{ marginLeft: '10px' }}>Descontar incapacidades por enfermedad general</span>
                  </Flex1>
                  <Flex1>
                    <Checkbox
                      checked={descontarIncapacidadesPorRiesgo}
                      handleClick={(value: boolean) => setDescontarIncapacidadesPorRiesgo(value)
                      }
                    />
                    <span style={{ marginLeft: '10px' }}>Descontar incapacidades por riesgos de trabajo</span>
                  </Flex1>
                  <Flex1>
                    <Checkbox
                      checked={incluirOtrasPrestaciones}
                      handleClick={(value: boolean) => setIncluirOtrasPrestaciones(value)
                      }
                    />
                    <span style={{ marginLeft: '10px' }}>Incluir otras prestaciones</span>
                  </Flex1>
                </CheckboxsContainer>
                <FlexRow>
                  <VerTablaContainer>
                    <div>
                      <TextLabel style={{ paddingTop: '0px' }}>Dias de vacaciones</TextLabel>
                      <Dropdown
                        value={diasDeVacaciones}
                        onValue={(value: any) => setDiasDeVacaciones(value)}
                        options={['Segun la Ley', 'Por Tabla']}
                      />
                    </div>
                    <ButtonPrimario onClick={() => diasDeVacaciones === "Segun la Ley" ? setVerTablaLeyModal(true) : setVerTablaArregloModal(true)} disabled={diasDeVacaciones === "Segun la Ley" && informacionGeneral.diasDeVacacionesLey.length === 0}>Ver Tabla</ButtonPrimario>
                  </VerTablaContainer>
                </FlexRow>
              </>
            )}
          </ContenedorSection>
          <ContenedorSection>
            <TextLabelTitle>Aguinaldo</TextLabelTitle>
            <ChevronTabIcon
              tabOpen={tabOpenAguinaldo}
              toggleTab={() => setTabOpenAguinaldo(!tabOpenAguinaldo)}
            />
            {(tabOpenAguinaldo || !smallViewPort) && (
              <>
                <FlexRow>
                  <VerTablaContainer>
                    <div>
                      <TextLabel style={{ paddingTop: '0px' }}>Dias de aquinaldo</TextLabel>
                      <Dropdown
                        value={tipoDeAguinaldo}
                        onValue={(value: any) => setTipoDeAguinaldo(value)}
                        options={['Fijo', 'Por Tabla']}
                      />
                    </div>
                    <DiasDeAguinaldoDiasContainer>
                      <InputMini
                        value={diasDeAguinaldo}
                        onChange={(e) =>
                          setDiasDeAguinaldo(ccNumberLimit(parseInt(e.target.value), 365))
                        }
                        type="number"
                      />
                      Dias
                    </DiasDeAguinaldoDiasContainer>
                  </VerTablaContainer>
                </FlexRow>
                <Flex1 style={{ marginTop: '10px' }}>
                  <Checkbox
                    checked={permitirPagos}
                    handleClick={(value: boolean) => setPermitirPagos(value)
                    }
                  />
                  <span style={{ marginLeft: '10px' }}>Permitir <InputMini
                    value={numeroDePagos}
                    style={{ padding: '4px' }}
                    onChange={(e) =>
                      setNumeroDePagos(ccNumberLimit(parseInt(e.target.value), 365))
                    }
                    type="number"
                  /> pago(s) de aquinaldo al año </span>
                </Flex1>
                <Flex1>
                  <Checkbox
                    checked={procesoDeNominaPorSeparado}
                    handleClick={(value: boolean) => setProcesoDeNominaPorSeparado(value)
                    }
                  />
                  <span style={{ marginLeft: '10px' }}>Pagar aquinaldo en proceso de nómina por separado</span>
                </Flex1>
                <Flex1>
                  <Checkbox
                    checked={descontarFaltasAguinaldo}
                    handleClick={(value: boolean) => setDescontarFaltasAguinaldo(value)
                    }
                  />
                  <span style={{ marginLeft: '10px' }}>Descontar faltas para el pago de aquinaldo</span>
                </Flex1>
                <Flex1>
                  <Checkbox
                    checked={descontarIncapacidadesPorEnfermedadAguinaldo}
                    handleClick={(value: boolean) => setDescontarIncapacidadesPorEnfermedadAguinaldo(value)
                    }
                  />
                  <span style={{ marginLeft: '10px' }}>Descontar incapacidades por enfermedad general</span>
                </Flex1>
                <Flex1>
                  <Checkbox
                    checked={descontarIncapacidadesPorRiesgoAguinaldo}
                    handleClick={(value: boolean) => setDescontarIncapacidadesPorRiesgoAguinaldo(value)
                    }
                  />
                  <span style={{ marginLeft: '10px' }}>Descontar incapacidades por riesgo de trabajo</span>
                </Flex1>
                <VerTablaContainer style={{ marginTop: '14px' }}>
                  <div>
                    <TextLabel style={{ paddingTop: '0px' }}>Periodo para aquinaldo</TextLabel>
                    <Dropdown
                      value={periodoParaAguinaldo}
                      onValue={(value: any) => setPeriodoParaAguinaldo(value)}
                      options={['Enero 1 - Diciembre 31', 'Diciembre 20 - Diciembre 19']}
                    />
                  </div>
                </VerTablaContainer>
                <Flex1 style={{ marginTop: '10px' }}>
                  <Checkbox
                    checked={incluirOtrasPrestacionesAguinaldo}
                    handleClick={(value: boolean) => setIncluirOtrasPrestacionesAguinaldo(value)
                    }
                  />
                  <span style={{ marginLeft: '10px' }}>Incluir otras prestaciones</span>
                </Flex1>
              </>
            )}
          </ContenedorSection>
          <ContenedorSection>
            <TextLabelTitle>Días de descanso obligatorios</TextLabelTitle>
            <ChevronTabIcon
              tabOpen={tabOpenDiasDeDescanso}
              toggleTab={() => setTabOpenDiasDeDescanso(!tabOpenDiasDeDescanso)}
            />
            {(tabOpenDiasDeDescanso || !smallViewPort) && (
              <>
                <DiasDeDescansoSectionContainer>
                  <Flex1>
                    <TextLabel ></TextLabel>
                    <FlexCenter>
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        style={{ fontSize: "24px", cursor: selectedElementDiasDeDescanso ? "pointer" : "not-allowed", color: selectedElementDiasDeDescanso ? "#f44336" : "black" }}
                        onClick={() => selectedElementDiasDeDescanso && removeRegisterDiasDeDescanso()}
                        title="Eliminar Registro"
                      />
                    </FlexCenter>
                  </Flex1>
                  <DiasDeDescansoColumnContainer>
                    <Flex1>
                      <FlexColumn style={{ alignItems: "center" }}>
                        <TextLabel style={{ textAlign: "center" }}>Fecha</TextLabel>
                        <DatePickerComponent
                          pickerWidth={"100%"}
                          value={fechaDiasDeDescanso}
                          label={""}
                          onValue={(value, isValid) =>
                            setFechaDiasDeDescanso(isValid ? value : null)
                          }
                        />
                      </FlexColumn>
                    </Flex1>
                    <Flex1>
                      <FlexColumn style={{ alignItems: "center" }}>
                        <TextLabel style={{ textAlign: "center" }}>Prima en %</TextLabel>
                        <InputMini
                          value={primaDiasDeDescanso}
                          style={{ width: "calc(100% - 20px)" }}
                          onChange={(e) =>
                            setPrimaDiasDeDescanso(ccNumberLimit(parseInt(e.target.value), 100))
                          }
                          type="number"
                        />
                      </FlexColumn>
                    </Flex1>

                    <Flex1>
                      <TextLabel>Tipo</TextLabel>
                      <Dropdown
                        onValue={(value: any) => setTipoDiasDeDescanso(value)}
                        value={tipoDiasDeDescanso}
                        options={['Obligatorio', 'Convencional']}
                      />
                    </Flex1>

                  </DiasDeDescansoColumnContainer>
                  <Flex1>
                    <TextLabel ></TextLabel>
                    <FlexCenter>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ fontSize: "24px", cursor: (fechaDiasDeDescanso && primaDiasDeDescanso) ? "pointer" : "not-allowed", color: (fechaDiasDeDescanso && primaDiasDeDescanso) ? "#b7d32d" : "black" }}
                        onClick={() => (fechaDiasDeDescanso && primaDiasDeDescanso) && addRegisterDiasDeDescanso()}
                        title="Agregar Registro"
                      />
                    </FlexCenter>
                  </Flex1>
                </DiasDeDescansoSectionContainer>
                <SortingTable
                  tableHeight={"474px"}
                  iSortDescending={false}
                  rowsArray={diasDeDescansoYVacacionesCopy}
                  columnsArray={diasDeDescansoObligatoriosArray}
                  showElementsPerPage={8}
                  multiselect={false}
                  onSelectElement={(element: any) => setSelectedElementDiasDeDescanso(element)}
                />
                <FlexRowSmall inverse='true'>
                  <Flex1>
                    <InputMini
                      value={porcentajeDePrimaDominical}
                      onChange={(e) =>
                        setPorcentajeDePrimaDominical(ccNumberLimit(parseInt(e.target.value), 999))
                      }
                      type="number"
                    />
                  </Flex1>
                  <ComplementaryFlexSmall>
                    % de Prima Dominical
                  </ComplementaryFlexSmall>
                </FlexRowSmall>
                <Flex1>
                  <Checkbox
                    checked={pagarPrimaDominicalEnDescansoLaborado}
                    handleClick={(value: boolean) => setPagarPrimaDominicalEnDescansoLaborado(value)
                    }
                  />
                  <span style={{ marginLeft: '10px' }}>Pagar prima dominical en descanso laborado</span>
                </Flex1>

              </>
            )}
          </ContenedorSection>
        </Contenedor>
        <ContenedorBotonesExterior>
          <ContenedorBotones>
            <ButtonPrimario onClick={() => submitForm()}>Aceptar</ButtonPrimario>
            <ButtonSecundario onClick={() => cancel()}>Cancelar</ButtonSecundario>
          </ContenedorBotones>
        </ContenedorBotonesExterior>
      </div>

      <Modal show={verTablaLeyModal}>
        <TextLabelTitle>REGISTRO DE SECUENCIA</TextLabelTitle>
        <SortingTable
          tableHeight={"474px"}
          iSortDescending={true}
          rowsArray={informacionGeneral.diasDeVacacionesLey}
          columnsArray={leyColumnsArray}
          showElementsPerPage={8}
          multiselect={false}
          hideCheckbox={true}
        />

        <ContenedorBotonesExterior
          style={{ marginBottom: "0px", paddingBottom: "0px", marginTop: "20px" }}
        >
          <ButtonSecundario onClick={() => setVerTablaLeyModal(false)}>
            Salir
          </ButtonSecundario>
        </ContenedorBotonesExterior>
      </Modal>

      <Modal show={verTablaArregloModal}>
        <TextLabelTitle>DIAS DE VACACIONES SEGUN AÑOS DE SERVICIO</TextLabelTitle>
        <DiasDeVacacionesRowModal>
          <Flex1>
            <TextLabel ></TextLabel>
            <FlexCenter>
              <FontAwesomeIcon
                icon={faCircleXmark}
                style={{ fontSize: "24px", cursor: selectedElement ? "pointer" : "not-allowed", color: selectedElement ? "#f44336" : "black" }}
                onClick={() => selectedElement && removeRegister()}
                title="Eliminar Registro"
              />
            </FlexCenter>
          </Flex1>
          <DiasDeVacacionesRowModalColumnContainer>
            <DiasDeVacacionesRowModalColumn>
              <Flex1>
                <FlexColumn style={{ alignItems: "center" }}>
                  <TextLabel style={{ textAlign: "center" }}>De</TextLabel>
                  <InputMini
                    disabled
                    value={tablaDiasDeVacacionesPrimerAño}
                    onChange={(e) =>
                      setTablaDiasDeVacacionesPrimerAño(ccNumberLimit(parseInt(e.target.value), 100))
                    }
                    type="number"
                  />
                </FlexColumn>
              </Flex1>
              <Flex1>
                <FlexColumn style={{ alignItems: "center" }}>
                  <TextLabel style={{ textAlign: "center" }}>A</TextLabel>
                  <InputMini
                    value={tablaDiasDeVacacionesSegundoAño}
                    onChange={(e) =>
                      setTablaDiasDeVacacionesSegundoAño(ccNumberLimit(parseInt(e.target.value), 100))
                    }
                    onBlur={(e) =>
                      setTablaDiasDeVacacionesSegundoAño(ccNumberLimitMaxMin(parseInt(e.target.value), 100, tablaDiasDeVacacionesPrimerAño + 1))
                    }
                    type="number"
                  />
                </FlexColumn>
              </Flex1>
            </DiasDeVacacionesRowModalColumn>
            <DiasDeVacacionesRowModalColumn>

              <Flex1>
                <FlexColumn style={{ alignItems: "center" }}>
                  <TextLabel style={{ textAlign: "center" }}>Dias de Vacaciones</TextLabel>
                  <InputMini
                    value={tablaDiasDeVacaciones}
                    onChange={(e) =>
                      setTablaDiasDeVacaciones(ccNumberLimit(parseInt(e.target.value), 365))
                    }
                    type="number"
                  />
                </FlexColumn>
              </Flex1>
              <Flex1>
                <FlexColumn style={{ alignItems: "center" }}>
                  <TextLabel style={{ textAlign: "center" }}>Prima %</TextLabel>
                  <InputMini
                    value={tablaDiasDeVacacionesPrima}
                    onChange={(e) =>
                      setTablaDiasDeVacacionesPrima(ccNumberLimit(parseInt(e.target.value), 999))
                    }
                    type="number"
                  />
                </FlexColumn>
              </Flex1>
            </DiasDeVacacionesRowModalColumn>

          </DiasDeVacacionesRowModalColumnContainer>
          <Flex1>
            <TextLabel ></TextLabel>
            <FlexCenter>
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "24px", cursor: !selectedElement ? "pointer" : "not-allowed", color: !selectedElement ? "#b7d32d" : "black" }}
                onClick={() => !selectedElement && addRegister()}
                title="Agregar Registro"
              />
            </FlexCenter>
          </Flex1>
        </DiasDeVacacionesRowModal>
        <SortingTable
          tableHeight={"383px"}
          iSortDescending={true}
          rowsArray={arregloTablaDiasDeVacaciones}
          columnsArray={tablaColumnsArray}
          showElementsPerPage={6}
          multiselect={false}
          onSelectElement={(element: any) => setSelectedElement(element)}
        />
        <ContenedorBotonesExterior style={{ marginBottom: '0px' }}>
          <ButtonPrimario onClick={() => submitTablaVacaciones()}>Guardar</ButtonPrimario>
          <ButtonSecundario onClick={() => cancelTablaVacaciones()}>Cancelar</ButtonSecundario>
        </ContenedorBotonesExterior>

      </Modal>
    </>
  )
}
