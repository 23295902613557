import { useEffect, useRef, useState } from "react";
import { ButtonPrimario, ButtonSecundario, Contenedor, ContenedorBotones, ContenedorBotonesExterior, ContenedorSection, DivTitulos, DivTitulosSubTitulo, DivTitulosTitulo, InputGrande, LabelEliminar, Obligatorio, PEliminar, TextLabel, TextLabelTitle } from "../../../communStyled";
import { Dropdown, SearchDropdown, SortingTable, Warning } from "../../../../Components";
import { Subject } from "rxjs";
import { useDispatch, useSelector } from "react-redux";
import { apiRequest, apiRoutes } from "../../../../Api/services";
import { getRandomID, scrollIntoView } from "../../../../Utils/sharedFunctions";
import actions from "../../../../Redux/Actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export default function RegistroDeDepartamentos() {
  const alertRef = useRef(null);
  const dispatch = useDispatch();
  const empresa: any = useSelector((store: any) => store.empresa);
  const currentEmpresa: any = useSelector((store: any) => store.currentEmpresa);
  const messageSubject = new Subject<string>();
  const successfulSubject = new Subject<string>();
  const [departamentosCopyArray, setDepartamentosCopyArray] = useState(empresa.empresa.registroDeDepartamentos.departamentos);
  const [nombreDeDepartamento, setNombreDeDepartamento] = useState("");
  const [IMSSorEstatal, setIMSSorEstatal] = useState("");
  const [dependeDe, setDependeDe] = useState(empresa.empresa.registroDeDepartamentos.departamentos.lenght > 0 ? empresa.empresa.registroDeDepartamentos.departamentos[0].id : "");
  const [selectedDepartamento, setSelectedDepartamento] = useState<any>(null);

  const columnArray = [{ label: 'Departamento', valueLabel: 'nombreDeDepartamento', Filtrable: true, widthPX: 300, type: 'String' }];

  const generateNewID = () => {
    const id = getRandomID();
    const departamentos = empresa.empresa.registroDeDepartamentos.departamentos.map((departamento: {
      id: string;
      nombreDeDepartamento: string;
      IMSSorEstatal: string;
      dependeDe: string;
    }) => {
      return departamento.id
    })
    if (departamentos.includes(id)) {
      generateNewID()
    } else {
      return id
    }
  }

  const submitForm = async () => {
    let newDepartamentos = []
    if(selectedDepartamento){
      newDepartamentos = empresa.empresa.registroDeDepartamentos.departamentos.map((departamento: any) => {
        if(departamento.id === selectedDepartamento.id){
          return {
            ...departamento,
            nombreDeDepartamento,
            IMSSorEstatal,
            dependeDe
          }
        }else{
          return departamento
        }
      })
    } else {
      const newID = generateNewID()
      newDepartamentos = [
        ...empresa.empresa.registroDeDepartamentos.departamentos,
        {
          nombreDeDepartamento,
          IMSSorEstatal,
          dependeDe,
          id: newID
        }
      ]
    }
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.updateInfo,
      data: {
        area: "registroDeDepartamentos",
        data: {
          ...empresa.empresa.registroDeDepartamentos,
          departamentos: newDepartamentos
        },
        id: currentEmpresa.id,
        modulo: "empresa",
      },
    });

    if (error) {
      messageSubject.next("Hubo un error en el servidor");
      scrollIntoView(alertRef);
    }
    if (res) {
      if (res.message !== "Proceed") {
        messageSubject.next(res.message);
        scrollIntoView(alertRef);
      } else {
        scrollIntoView(alertRef);
        dispatch(
          actions.empresaActions.replaceEmpresa({
            ...empresa,
            empresa: {
              ...empresa.empresa,
              registroDeDepartamentos: {
                ...empresa.empresa.registroDeDepartamentos,
                departamentos: newDepartamentos
              },
            },
          })
        );
        setDepartamentosCopyArray([...newDepartamentos]);
        setNombreDeDepartamento("");
        setIMSSorEstatal("");
        setDependeDe("");
        successfulSubject.next(
          "La Informacion se agrego correctamente a la base de datos"
        );
      }
    }
  };

  const cancel = () => {
    setNombreDeDepartamento("");
    setIMSSorEstatal("");
    setDependeDe("");
  };

  const onValueSearchDropDownDepartamentos = (value: {
    searchValue: string;
    filter: {valueLabel: string};
  }) => {
    if (value.searchValue !== "") {
      setDepartamentosCopyArray(
        empresa.empresa.registroDeDepartamentos.departamentos.filter((row: any) => {
          return row[value.filter.valueLabel]
            .toString()
            .toUpperCase()
            .includes(value.searchValue.toString().toUpperCase());
        })
      );
    } else {
      setDepartamentosCopyArray([...empresa.empresa.registroDeDepartamentos.departamentos]);
    }
  };

  const eliminarDepartamento = async () => {
    const newDepartamentos = [...empresa.empresa.registroDeDepartamentos.departamentos.filter((departamento: any) => departamento.id !== selectedDepartamento.id)];
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.updateInfo,
      data: {
        area: "registroDeDepartamentos",
        data: {
          ...empresa.empresa.registroDeDepartamentos,
          departamentos: newDepartamentos
        },
        id: currentEmpresa.id,
        modulo: "empresa",
      },
    });

    if (error) {
      messageSubject.next("Hubo un error en el servidor");
      scrollIntoView(alertRef);
    }
    if (res) {
      if (res.message !== "Proceed") {
        messageSubject.next(res.message);
        scrollIntoView(alertRef);
      } else {
        scrollIntoView(alertRef);
        dispatch(
          actions.empresaActions.replaceEmpresa({
            ...empresa,
            empresa: {
              ...empresa.empresa,
              registroDeDepartamentos: {
                ...empresa.empresa.registroDeDepartamentos,
                departamentos: newDepartamentos
              },
            },
          })
        );
        setDepartamentosCopyArray([...newDepartamentos]);
        successfulSubject.next(
          "La Informacion se agrego correctamente a la base de datos"
        );
      }
    }
  }

  useEffect(() => {
    setNombreDeDepartamento(selectedDepartamento ? selectedDepartamento.nombreDeDepartamento : "");
    setIMSSorEstatal(selectedDepartamento ? selectedDepartamento.IMSSorEstatal : "");
    setDependeDe(selectedDepartamento ? selectedDepartamento.dependeDe : "");
  }, [selectedDepartamento]);

  return (
    <div>
      <DivTitulos>
        <DivTitulosTitulo>EMPRESA</DivTitulosTitulo>
        <DivTitulosSubTitulo>
          REGISTRO DE DEPARTAMENTOS
        </DivTitulosSubTitulo>
      </DivTitulos>
      <div ref={alertRef} style={{margin: '0px 35px'}}>
        <Warning
          type={"Alert"}
          title={"Alerta"}
          warningSubject={messageSubject}
        />
        <Warning
          type={"Successful"}
          title={"Exito!"}
          warningSubject={successfulSubject}
        />
      </div>
      <Contenedor>
        <ContenedorSection>
          <TextLabelTitle>LISTA DE DEPARTAMENTOS</TextLabelTitle>
          <SearchDropdown
            onValue={onValueSearchDropDownDepartamentos}
            options={columnArray}
          />
          <SortingTable
            multiselect={false}
            rowsArray={departamentosCopyArray}
            columnsArray={columnArray}
            tableHeight="395px"
            onSelectElement={(element: any) => setSelectedDepartamento(element)}
          />
        </ContenedorSection>
        <ContenedorSection>
          <TextLabelTitle>DESCRIPCION DEL DEPARTAMENTO</TextLabelTitle>
          {selectedDepartamento && (
                <PEliminar onClick={() => eliminarDepartamento()}>
                  <LabelEliminar>Eliminar departamento</LabelEliminar>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </PEliminar>
              )}
          <TextLabel>Nombre del Departamento<Obligatorio>*</Obligatorio></TextLabel>
          <InputGrande
            type="text"
            value={nombreDeDepartamento}
            onChange={(e) => setNombreDeDepartamento(e.target.value)}
          />
          <TextLabel>IMSS</TextLabel>
          <Dropdown
            value={IMSSorEstatal}
            onValue={(value: any) => setIMSSorEstatal(value)}
            options={empresa.empresa.registroEstatalYDelIMSS.registros.map((registro: any) => registro.id)}
          />
          <TextLabel>Depende de</TextLabel>
          <Dropdown
            value={dependeDe}
            onValue={(value: any) => setDependeDe(value)}
            disabled={empresa.empresa.registroDeDepartamentos.departamentos.length === 0}
            options={empresa.empresa.registroDeDepartamentos.departamentos.map((departamento: any) => departamento.nombreDeDepartamento)}
          />
        </ContenedorSection>
      </Contenedor>
      <ContenedorBotonesExterior>
        <ContenedorBotones>
          <ButtonPrimario disabled={!nombreDeDepartamento} onClick={() => submitForm()}>Aceptar</ButtonPrimario>
          <ButtonSecundario onClick={() => cancel()}>Cancelar</ButtonSecundario>
        </ContenedorBotones>
      </ContenedorBotonesExterior>
    </div>
  )
}
