import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DropdownContent,
  DropdownContentOption,
  Span,
  DropdownContainer,
} from "./DropdownStyled";
import { faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { InputGrande } from "../../Pages/communStyled";
import { v4 as uuidv4 } from "uuid";

interface Props {
  value: string;
  onValue: (data: any) => void;
  disabled?: boolean;
  options: Array<any>;
}

const Dropdown = ({ value, onValue, disabled, options }: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [option, setOption] = useState<string>(value);

  useEffect(() => {
    setOption(value);
  }, [value]);

  const openDropDown = () => {
    if (!disabled) {
      setDropdownOpen(!dropdownOpen);
    }
  };
  
  const setOptionFunction = (item: string) => {
    if (!disabled) {
      setOption(item);
      item && onValue(item);
    }
  };

  const emitFilter = (item: string) => {
    setDropdownOpen(false);
    setOption(item);
    setOptionFunction(item);
  };
  return (
    <DropdownContainer>
      <div style={{ position: "relative"}}>
        <InputGrande
          type="text"
          value={option}
          onChange={(e) => setOptionFunction(e.target.value)}
          disabled={true}
          style={{  backgroundColor: disabled ? "#f3f3f3" : "transparent"  }}
          onClick={() => openDropDown()}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            cursor: disabled ? "not-allowed" : "pointer",
          }}
          onClick={() => openDropDown()}
        />
      </div>
      <Span style={{ opacity: disabled ? "0.5" : "1", cursor: disabled ? "not-allowed" : "pointer" }}>
        {options?.length > 0 && (
          <FontAwesomeIcon
            icon={faChevronDown}
            onClick={() => openDropDown()}
            style={{ marginRight: "5px" }}
          />
        )}
      </Span>
      {dropdownOpen && options?.length > 0 && (
        <DropdownContent>
          {options.map((item: any, index: number) => (
            <DropdownContentOption key={uuidv4()} onClick={() => emitFilter(item)}>
              {item}
            </DropdownContentOption>
          ))}
        </DropdownContent>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
