import styled from "styled-components";

interface StyledCheckboxIconInterface {
  disabled?: boolean;
}

interface StyledCheckboxInterface {
  checked: boolean;
  disabled?: boolean;
  removemargin?: string;
}

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = styled.svg<StyledCheckboxIconInterface>`
  fill: none;
  stroke: ${(props) => props.disabled ? "#000" : "#B7D32D" };
  stroke-width: 2px;
`;
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  
`;
export const StyledCheckbox = styled.div<StyledCheckboxInterface>`
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background: ${(props) => (props.disabled ? "#f3f3f3" : props.checked ? "transparent" : "white")};
  margin-right: ${(props) => (props.removemargin === "true" ? "10px" : "0px")};
  border: ${(props) =>
    props.checked
      ? "0px solid #B7D32D"
      : "1px solid #d7d7d7"};
  border-radius: ${(props) =>
    props.checked ? "16px" : "3px"};
  transition: all 150ms;

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;
