import { Subject } from "rxjs";
import {
  ButtonPrimario,
  ButtonSecundario,
  Flex1,
  Flex2,
  ContenedorBotones,
  ContenedorBotonesExterior,
  DivTitulos,
  DivTitulosSubTitulo,
  DivTitulosTitulo,
  InputGrande,
  LinkColor,
  LinkColorRed,
  Row,
  RowTitle,
  TextLabel,
  TextLabelTitle,
  FlexRow,
  HorizontalRow,
  HorizontalRowTitle,
  FlexInlineMarginTextLabel,
} from "../../../communStyled";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Checkbox,
  ChevronTabIcon,
  ConfirmationModal,
  Dropdown,
  Modal,
  Warning,
} from "../../../../Components";
import {
  AgregarNuevaCuentaLink,
  Contenedor,
  ContenedorCuentasBancarias,
  ContenedorDeConfiguracion,
  CuentaOptions,
  CuentasContainer,
  CuentasModalContainer,
  WarningLabel,
} from "./EsquemaDeNominaStyled";
import useViewPortSize from "../../../../Hooks/useViewPortSize";
import { useDispatch, useSelector } from "react-redux";
import DatePickerComponent from "../../../../Components/DatePicker/DatePicker";
import { scrollIntoView } from "../../../../Utils/sharedFunctions";
import { apiRequest } from "../../../../Api/instance";
import { apiRoutes } from "../../../../Api/services";
import actions from "../../../../Redux/Actions";
import { listaDeBancos } from "../../../../Utils/constants";

interface BankAccount {
  nombreDelBanco: string;
  tipoDeCuenta: string;
  descripcion: string;
  numeroDeCuenta: string;
  cuentaContable: string;
}

export default function EsquemaDeNomina() {
  const { smallViewPort } = useViewPortSize();
  const dispatch = useDispatch();
  const successfulSubject = new Subject<string>();
  const messageSubject = new Subject<string>();

  const empresa: any = useSelector((store: any) => store.empresa);
  const currentEmpresa: any = useSelector((store: any) => store.currentEmpresa);

  const alertRef = useRef(null);

  const [tabOpenConfiguracion, setTabOpenConfiguracion] = useState(true);
  const [tabOpenCuentasBancarias, setTabOpenCuentasBancarias] = useState(true);
  const [semanalCheckBox, setSemanalCheckBox] = useState(
    empresa.empresa.esquemaDeNomina.configuracion.semanalCheckBox
  );
  const [periodoInicialSemanal, setPeriodoInicialSemanal] =
    useState<Date | null>(
      empresa.empresa.esquemaDeNomina.configuracion.periodoInicialSemanal
    );
  const [decenalCheckBox, setDecenalCheckBox] = useState(
    empresa.empresa.esquemaDeNomina.configuracion.decenalCheckBox
  );
  const [periodoInicialDecenal, setPeriodoInicialDecenal] =
    useState<Date | null>(
      empresa.empresa.esquemaDeNomina.configuracion.periodoInicialDecenal
    );
  const [factorDePagoDecenal, setFactorDePagoDecenal] = useState(
    empresa.empresa.esquemaDeNomina.configuracion.factorDePagoDecenal
  );
  const [catorcenalCheckBox, setCatorcenalCheckBox] = useState(
    empresa.empresa.esquemaDeNomina.configuracion.catorcenalCheckBox
  );
  const [periodoInicialCatorcenal, setPeriodoInicialCatorcenal] =
    useState<Date | null>(
      empresa.empresa.esquemaDeNomina.configuracion.periodoInicialCatorcenal
    );
  const [quincenalCheckBox, setQuincenalCheckBox] = useState(
    empresa.empresa.esquemaDeNomina.configuracion.quincenalCheckBox
  );
  const [periodoInicialQuincenal, setPeriodoInicialQuincenal] =
    useState<Date | null>(
      empresa.empresa.esquemaDeNomina.configuracion.periodoInicialQuincenal
    );
  const [factorDePagoQuincenal, setFactorDePagoQuincenal] = useState(
    empresa.empresa.esquemaDeNomina.configuracion.factorDePagoQuincenal
  );
  const [cuentasBancarias, setCuentasBancarias] = useState<BankAccount[]>(
    empresa.empresa.esquemaDeNomina.cuentasBancariasParaPagoDeNomina
      .cuentasBancarias
  );
  const [active, setActive] = useState(-1);
  const [openModalConceptosAguinaldo, setOpenModalConceptosAguinaldo] =
    useState(false);
  const [nombreDelBanco, setNombreDelBanco] = useState("");
  const [tipoDeCuenta, setTipoDeCuenta] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [numeroDeCuenta, setNumeroDeCuenta] = useState("");
  const [cuentaContable, setCuentaContable] = useState("");

  const [semanalPrimeraVez, setSemanalPrimeraVez] = useState(
    empresa.empresa.esquemaDeNomina.configuracion.semanalPrimeraVez
  );

  useEffect(() => {
    setSemanalPrimeraVez(
      empresa.empresa.esquemaDeNomina.configuracion.semanalCheckBox
    );
  }, [empresa.empresa.esquemaDeNomina.configuracion.semanalCheckBox]);

  const [decenalPrimeraVez, setDecenalPrimeraVez] = useState(
    empresa.empresa.esquemaDeNomina.configuracion.decenalPrimeraVez
  );

  useEffect(() => {
    setDecenalPrimeraVez(
      empresa.empresa.esquemaDeNomina.configuracion.decenalCheckBox
    );
  }, [empresa.empresa.esquemaDeNomina.configuracion.decenalCheckBox]);

  const [catorcenalPrimeraVez, setCatorcenalPrimeraVez] = useState(
    empresa.empresa.esquemaDeNomina.configuracion.catorcenalPrimeraVez
  );

  useEffect(() => {
    setCatorcenalPrimeraVez(
      empresa.empresa.esquemaDeNomina.configuracion.catorcenalCheckBox
    );
  }, [empresa.empresa.esquemaDeNomina.configuracion.catorcenalCheckBox]);

  const [quincenalPrimeraVez, setQuincenalPrimeraVez] = useState(
    empresa.empresa.esquemaDeNomina.configuracion.quincenalPrimeraVez
  );

  useEffect(() => {
    setQuincenalPrimeraVez(
      empresa.empresa.esquemaDeNomina.configuracion.quincenalCheckBox
    );
  }, [empresa.empresa.esquemaDeNomina.configuracion.quincenalCheckBox]);

  const [openConfirmationPrimeraVezModal, setOpenConfirmationPrimeraVezModal] =
    useState(false);

  const quincenaArray = ["15", "15.208"];
  const decenaArray = ["10", "10.138"];
  
  const validateForm = () => {
    if (semanalCheckBox && !periodoInicialSemanal) {
      return false;
    }
    if (
      decenalCheckBox &&
      (!periodoInicialDecenal || factorDePagoDecenal === "")
    ) {
      return false;
    }
    if (catorcenalCheckBox && !periodoInicialCatorcenal) {
      return false;
    }
    if (
      quincenalCheckBox &&
      (!periodoInicialQuincenal || factorDePagoQuincenal === "")
    ) {
      return false;
    }
    return true;
  };

  const submitForm = () => {
    if (validateForm()) {
      setOpenConfirmationPrimeraVezModal(true);
    } else {
      messageSubject.next(
        "Porfavor verifique que tenga al menos un esquema de nómina y que todos los marcados esten completos"
      );
      scrollIntoView(alertRef);
    }
  };

  const agregarPrimeraVez = async () => {
    const esquemaDeNomina = {
      cuentasBancariasParaPagoDeNomina: {
        cuentasBancarias: cuentasBancarias,
      },
      configuracion: {
        semanalPrimeraVez: semanalPrimeraVez
          ? semanalPrimeraVez
          : semanalCheckBox,
        semanalCheckBox: semanalPrimeraVez
          ? empresa.empresa.esquemaDeNomina.configuracion.semanalCheckBox
          : semanalCheckBox,
        periodoInicialSemanal: semanalPrimeraVez
          ? empresa.empresa.esquemaDeNomina.configuracion.periodoInicialSemanal
          : periodoInicialSemanal,
        decenalPrimeraVez: decenalPrimeraVez
          ? decenalPrimeraVez
          : decenalCheckBox,
        decenalCheckBox: decenalPrimeraVez
          ? empresa.empresa.esquemaDeNomina.configuracion.decenalCheckBox
          : decenalCheckBox,
        periodoInicialDecenal: decenalPrimeraVez
          ? empresa.empresa.esquemaDeNomina.configuracion.periodoInicialDecenal
          : periodoInicialDecenal,
        factorDePagoDecenal: decenalPrimeraVez
          ? empresa.empresa.esquemaDeNomina.configuracion.factorDePagoDecenal
          : factorDePagoDecenal,
        catorcenalPrimeraVez: catorcenalPrimeraVez
          ? catorcenalPrimeraVez
          : catorcenalCheckBox,
        catorcenalCheckBox: catorcenalPrimeraVez
          ? empresa.empresa.esquemaDeNomina.configuracion.catorcenalCheckBox
          : catorcenalCheckBox,
        periodoInicialCatorcenal: catorcenalPrimeraVez
          ? empresa.empresa.esquemaDeNomina.configuracion
              .periodoInicialCatorcenal
          : periodoInicialCatorcenal,
        quincenalPrimeraVez: quincenalPrimeraVez
          ? quincenalPrimeraVez
          : quincenalCheckBox,
        quincenalCheckBox: quincenalPrimeraVez
          ? empresa.empresa.esquemaDeNomina.configuracion.quincenalCheckBox
          : quincenalCheckBox,
        periodoInicialQuincenal: quincenalPrimeraVez
          ? empresa.empresa.esquemaDeNomina.configuracion
              .periodoInicialQuincenal
          : periodoInicialQuincenal,
        factorDePagoQuincenal: quincenalPrimeraVez
          ? empresa.empresa.esquemaDeNomina.configuracion.factorDePagoQuincenal
          : factorDePagoQuincenal,
      },
    };
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.updateInfo,
      data: {
        area: "esquemaDeNomina",
        data: esquemaDeNomina,
        id: currentEmpresa.id,
        modulo: "empresa",
      },
    });
    if (error) {
      messageSubject.next("Hubo un error en el servidor");
      scrollIntoView(alertRef);
    }
    if (res) {
      if (res.message !== "Proceed") {
        messageSubject.next(res.message);
      } else {
        dispatch(
          actions.empresaActions.replaceEmpresa({
            ...empresa,
            empresa: {
              ...empresa.empresa,
              esquemaDeNomina: esquemaDeNomina,
            },
          })
        );
        successfulSubject.next(
          "La Informacion se agrego correctamente a la base de datos"
        );
      }
    }
    setOpenConfirmationPrimeraVezModal(false);
    scrollIntoView(alertRef);
  };

  const cancel = () => {
    messageSubject.next("");
    successfulSubject.next("");
    setSemanalCheckBox(
      empresa.empresa.esquemaDeNomina.configuracion.semanalCheckBox
    );
    setPeriodoInicialSemanal(
      empresa.empresa.esquemaDeNomina.configuracion.periodoInicialSemanal
    );
    setDecenalCheckBox(
      empresa.empresa.esquemaDeNomina.configuracion.decenalCheckBox
    );
    setPeriodoInicialDecenal(
      empresa.empresa.esquemaDeNomina.configuracion.periodoInicialDecenal
    );
    setFactorDePagoDecenal(
      empresa.empresa.esquemaDeNomina.configuracion.factorDePagoDecenal
    );
    setCatorcenalCheckBox(
      empresa.empresa.esquemaDeNomina.configuracion.catorcenalCheckBox
    );
    setPeriodoInicialCatorcenal(
      empresa.empresa.esquemaDeNomina.configuracion.periodoInicialCatorcenal
    );
    setQuincenalCheckBox(
      empresa.empresa.esquemaDeNomina.configuracion.quincenalCheckBox
    );
    setPeriodoInicialQuincenal(
      empresa.empresa.esquemaDeNomina.configuracion.periodoInicialQuincenal
    );
    setFactorDePagoQuincenal(
      empresa.empresa.esquemaDeNomina.configuracion.factorDePagoQuincenal
    );
    setCuentasBancarias(
      empresa.empresa.esquemaDeNomina.cuentasBancariasParaPagoDeNomina
        .cuentasBancarias
    );
    setActive(-1);
    cancelCuenta();
    scrollIntoView(alertRef);
  };

  const removeCuentaBancaria = () => {
    setCuentasBancarias(
      cuentasBancarias.filter((cuenta, index) => index !== active)
    );
    setActive(-1);
  };

  const editModalConceptosAguinaldo = () => {
    setCuentasBancarias(
      cuentasBancarias.map((cuenta, index) => {
        if (index === active) {
          return {
            ...cuenta,
            numeroDeCuenta,
            descripcion,
            tipoDeCuenta,
            cuentaContable,
            nombreDelBanco,
          };
        } else {
          return cuenta;
        }
      })
    );
    setActive(-1);
  };

  useEffect(() => {
    setNumeroDeCuenta(
      active !== -1 ? cuentasBancarias[active].numeroDeCuenta : ""
    );
    setDescripcion(active !== -1 ? cuentasBancarias[active].descripcion : "");
    setTipoDeCuenta(active !== -1 ? cuentasBancarias[active].tipoDeCuenta : "");
    setNombreDelBanco(
      active !== -1 ? cuentasBancarias[active].nombreDelBanco : ""
    );
    setCuentaContable(
      active !== -1 ? cuentasBancarias[active].cuentaContable : ""
    );
  }, [active]);

  useEffect(() => {
    setNumeroDeCuenta("");
    setDescripcion("");
    setTipoDeCuenta("");
    setNombreDelBanco("");
    setCuentaContable("");
    setActive(-1);
  }, [openModalConceptosAguinaldo]);

  const submitFormCuenta = () => {
    setCuentasBancarias([
      ...cuentasBancarias,
      {
        numeroDeCuenta,
        descripcion,
        tipoDeCuenta,
        cuentaContable,
        nombreDelBanco,
      },
    ]);
    cancelCuenta();
  };

  const cancelCuenta = () => {
    setNumeroDeCuenta("");
    setDescripcion("");
    setTipoDeCuenta("");
    setNombreDelBanco("");
    setCuentaContable("");
    setOpenModalConceptosAguinaldo(false);
  };

  const formaCuentasVancarias = () => {
    return (
      <>
        <FlexRow>
          <Flex1>
            <TextLabel>Numero de cuenta</TextLabel>
            <InputGrande
              value={numeroDeCuenta}
              onChange={(e) => setNumeroDeCuenta(e.target.value)}
              type="text"
            />
          </Flex1>
          <Flex2>
            <TextLabel>Descripción</TextLabel>
            <InputGrande
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              type="text"
            />
          </Flex2>
        </FlexRow>
        <FlexRow>
          <Flex1>
            <TextLabel>Nombre del Banco</TextLabel>
            <Dropdown
              onValue={(value: any) => setNombreDelBanco(value)}
              value={nombreDelBanco}
              options={listaDeBancos.map((banco) => banco.nombreDelBanco)}
            />
          </Flex1>
          <Flex1>
            <TextLabel>Tipo de cuenta</TextLabel>
            <InputGrande
              value={tipoDeCuenta}
              onChange={(e) => setTipoDeCuenta(e.target.value)}
              type="text"
            />
          </Flex1>
          <Flex1>
            <TextLabel>Cuenta contable</TextLabel>
            <InputGrande
              value={cuentaContable}
              onChange={(e) => setCuentaContable(e.target.value)}
              type="text"
            />
          </Flex1>
        </FlexRow>
      </>
    );
  };

  return (
    <div>
      <DivTitulos>
        <DivTitulosTitulo>EMPRESA</DivTitulosTitulo>
        <DivTitulosSubTitulo>ESQUEMA DE NOMINA</DivTitulosSubTitulo>
      </DivTitulos>
      <div ref={alertRef} style={{margin: '0px 35px'}}>
        <Warning
          type={"Alert"}
          title={"Alerta"}
          warningSubject={messageSubject}
        />
        <Warning
          type={"Successful"}
          title={"Exito!"}
          warningSubject={successfulSubject}
        />
      </div>
      <Contenedor>
        <ContenedorDeConfiguracion>
          <TextLabelTitle>CONFIGURACION</TextLabelTitle>
          <ChevronTabIcon
            tabOpen={tabOpenConfiguracion}
            toggleTab={() => setTabOpenConfiguracion(!tabOpenConfiguracion)}
          />
          {(tabOpenConfiguracion || !smallViewPort) && (
            <div>
              {!smallViewPort && (
                <FlexRow
                  style={{ columnGap: "30px", rowGap: "0px" }}
                  underline="true"
                >
                  <Flex1>
                    <TextLabelTitle>Periodo</TextLabelTitle>
                  </Flex1>
                  <Flex2>
                    <TextLabelTitle>
                      Siguiente Período (Día inicial)
                    </TextLabelTitle>
                  </Flex2>
                  <Flex2>
                    <TextLabelTitle>Factor de Pago</TextLabelTitle>
                  </Flex2>
                </FlexRow>
              )}
              <FlexRow
                style={{ columnGap: "30px", rowGap: "0px" }}
                underline="true"
              >
                <Flex1>
                  <label>
                    <Checkbox
                      checked={semanalCheckBox}
                      handleClick={(value: boolean) =>
                        setSemanalCheckBox(value)
                      }
                      disabled={semanalPrimeraVez}
                    />
                     <FlexInlineMarginTextLabel>
                     Semanal
                      </FlexInlineMarginTextLabel>
                  </label>
                </Flex1>
                <Flex2>
                  {smallViewPort && (
                    <TextLabel>Siguiente Período (Día inicial)</TextLabel>
                  )}
                  <DatePickerComponent
                    pickerWidth={"100%"}
                    value={periodoInicialSemanal}
                    label={""}
                    onValue={(value, isValid) =>
                      setPeriodoInicialSemanal(isValid ? value : null)
                    }
                    disabled={!semanalCheckBox || semanalPrimeraVez}
                  />
                </Flex2>
                <Flex2 />
              </FlexRow>
              <FlexRow
                style={{ columnGap: "30px", rowGap: "0px" }}
                underline="true"
              >
                <Flex1>
                  <label>
                    <Checkbox
                      checked={decenalCheckBox}
                      handleClick={(value: boolean) =>
                        setDecenalCheckBox(value)
                      }
                      disabled={decenalPrimeraVez}
                    />
                    <FlexInlineMarginTextLabel>Decenal</FlexInlineMarginTextLabel>
                  </label>
                </Flex1>
                <Flex2>
                  {smallViewPort && (
                    <TextLabel>Siguiente Período (Día inicial)</TextLabel>
                  )}
                  <DatePickerComponent
                    pickerWidth={"100%"}
                    value={periodoInicialDecenal}
                    label={""}
                    onValue={(value) => setPeriodoInicialDecenal(value)}
                    disabled={!decenalCheckBox || decenalPrimeraVez}
                    filterFunction={(d): boolean => {
                      const date = new Date(d);
                      return !(
                        date.getDate() === 1 ||
                        date.getDate() === 11 ||
                        date.getDate() === 21
                      );
                    }}
                  />
                </Flex2>
                <Flex2>
                  {smallViewPort && <TextLabel>Factor de pago</TextLabel>}
                  <Dropdown
                    onValue={(value: any) => setFactorDePagoDecenal(value)}
                    value={factorDePagoDecenal}
                    options={decenaArray}
                    disabled={!decenalCheckBox || decenalPrimeraVez}
                  />
                </Flex2>
              </FlexRow>
              <FlexRow
                style={{ columnGap: "30px", rowGap: "0px" }}
                underline="true"
              >
                <Flex1>
                  <label>
                    <Checkbox
                      checked={catorcenalCheckBox}
                      handleClick={(value: boolean) =>
                        setCatorcenalCheckBox(value)
                      }
                      disabled={catorcenalPrimeraVez}
                    />
                    <FlexInlineMarginTextLabel>Catorcenal</FlexInlineMarginTextLabel>
                  </label>
                </Flex1>
                <Flex2>
                  {smallViewPort && (
                    <TextLabel>Siguiente Período (Día inicial)</TextLabel>
                  )}
                  <DatePickerComponent
                    pickerWidth={"100%"}
                    value={periodoInicialCatorcenal}
                    label={""}
                    onValue={(value) => setPeriodoInicialCatorcenal(value)}
                    disabled={!catorcenalCheckBox || catorcenalPrimeraVez}
                  />
                </Flex2>
                <Flex2 />
              </FlexRow>
              <FlexRow
                style={{ columnGap: "30px", rowGap: "0px" }}
                underline="true"
              >
                <Flex1>
                  <label>
                    <Checkbox
                      checked={quincenalCheckBox}
                      handleClick={(value: boolean) =>
                        setQuincenalCheckBox(value)
                      }
                      disabled={quincenalPrimeraVez}
                    />
                    <FlexInlineMarginTextLabel>Quincenal</FlexInlineMarginTextLabel>
                  </label>
                </Flex1>
                <Flex2>
                  {smallViewPort && (
                    <TextLabel>Siguiente Período (Día inicial)</TextLabel>
                  )}
                  <DatePickerComponent
                    pickerWidth={"100%"}
                    value={periodoInicialQuincenal}
                    label={""}
                    onValue={(value) => setPeriodoInicialQuincenal(value)}
                    disabled={!quincenalCheckBox || quincenalPrimeraVez}
                    filterFunction={(d): boolean => {
                      const date = new Date(d);
                      return !(date.getDate() === 1 || date.getDate() === 16);
                    }}
                  />
                </Flex2>
                <Flex2>
                  {smallViewPort && <TextLabel>Factor de pago</TextLabel>}
                  <Dropdown
                    onValue={(value: any) => setFactorDePagoQuincenal(value)}
                    value={factorDePagoQuincenal}
                    options={quincenaArray}
                    disabled={!quincenalCheckBox || quincenalPrimeraVez}
                  />
                </Flex2>
              </FlexRow>
              <WarningLabel>
                Despues de agregar un periodo inicial este no puede ser
                modificado.
              </WarningLabel>
            </div>
          )}
        </ContenedorDeConfiguracion>
        <ContenedorCuentasBancarias>
          <TextLabelTitle>CUENTAS BANCARIAS PARA PAGO DE NOMINA</TextLabelTitle>

          <ChevronTabIcon
            tabOpen={tabOpenCuentasBancarias}
            toggleTab={() =>
              setTabOpenCuentasBancarias(!tabOpenCuentasBancarias)
            }
          />
          {(tabOpenCuentasBancarias || !smallViewPort) && (
            <div>
              {!smallViewPort && (
                <AgregarNuevaCuentaLink
                  onClick={() => setOpenModalConceptosAguinaldo(true)}
                >
                  + Agregar nueva cuenta
                </AgregarNuevaCuentaLink>
              )}
              {!smallViewPort && (
                <FlexRow>
                  <Flex1>
                    <TextLabelTitle>Banco</TextLabelTitle>
                  </Flex1>
                  <Flex1>
                    <TextLabelTitle>Tipo</TextLabelTitle>
                  </Flex1>
                  <Flex2>
                    <TextLabelTitle>Descripción</TextLabelTitle>
                  </Flex2>
                </FlexRow>
              )}
              <CuentasContainer>
                {cuentasBancarias.map((cuenta: BankAccount, index: number) => (
                  <div key={uuidv4()}>
                    <FlexRow
                      onClick={() => setActive(active !== index ? index : -1)}
                      activeclass={active === index ? "true" : "false"}
                      hover="true"
                      underline="true"
                    >
                      <Flex1>
                        <HorizontalRow>
                          {smallViewPort && (
                            <HorizontalRowTitle>Banco: </HorizontalRowTitle>
                          )}
                          <TextLabel style={{ cursor: "pointer" }}>
                            {cuenta.nombreDelBanco}
                          </TextLabel>
                        </HorizontalRow>
                      </Flex1>
                      <Flex1>
                        <HorizontalRow>
                          {smallViewPort && (
                            <HorizontalRowTitle>
                              tipoDeCuenta:{" "}
                            </HorizontalRowTitle>
                          )}
                          <TextLabel style={{ cursor: "pointer" }}>
                            {cuenta.tipoDeCuenta}
                          </TextLabel>
                        </HorizontalRow>
                      </Flex1>
                      <Flex2>
                        <HorizontalRow>
                          {smallViewPort && (
                            <HorizontalRowTitle>
                              descripcion:{" "}
                            </HorizontalRowTitle>
                          )}
                          <TextLabel style={{ cursor: "pointer" }}>
                            {cuenta.descripcion}
                          </TextLabel>
                        </HorizontalRow>
                      </Flex2>
                    </FlexRow>
                    {active === index && formaCuentasVancarias()}
                    {active === index && (
                      <CuentaOptions>
                        <LinkColor
                          onClick={() => editModalConceptosAguinaldo()}
                        >
                          Editar Cuenta
                        </LinkColor>
                        <LinkColorRed
                          style={{ marginLeft: "15px" }}
                          onClick={() => removeCuentaBancaria()}
                        >
                          Eliminar Cuenta
                        </LinkColorRed>
                      </CuentaOptions>
                    )}
                  </div>
                ))}
              </CuentasContainer>
            </div>
          )}
        </ContenedorCuentasBancarias>
      </Contenedor>

      <ContenedorBotonesExterior>
        <ContenedorBotones>
          <ButtonPrimario onClick={() => submitForm()}>Aceptar</ButtonPrimario>
          <ButtonSecundario onClick={() => cancel()}>Cancelar</ButtonSecundario>
        </ContenedorBotones>
      </ContenedorBotonesExterior>
      <Modal show={openModalConceptosAguinaldo}>
        <CuentasModalContainer>
          {formaCuentasVancarias()}
          <ContenedorBotonesExterior
            style={{ marginTop: "auto", marginBottom: "0px" }}
          >
            <ContenedorBotones style={{ marginTop: "30px" }}>
              <ButtonPrimario
                disabled={
                  !numeroDeCuenta ||
                  !descripcion ||
                  !tipoDeCuenta ||
                  !cuentaContable ||
                  !nombreDelBanco
                }
                onClick={() => submitFormCuenta()}
              >
                Aceptar
              </ButtonPrimario>
              <ButtonSecundario onClick={() => cancelCuenta()}>
                Cancelar
              </ButtonSecundario>
            </ContenedorBotones>
          </ContenedorBotonesExterior>
        </CuentasModalContainer>
      </Modal>

      <ConfirmationModal
        title={"¿Estas seguro que deseas agregar un periodo inicial?"}
        text={
          "La siguiente operacion es irreversible, una vez agregado un periodo inicial este no puede ser modificado."
        }
        show={openConfirmationPrimeraVezModal}
        redButton={true}
        onCancel={() => setOpenConfirmationPrimeraVezModal(false)}
        onAccept={() => agregarPrimeraVez()}
      />
    </div>
  );
}
