import { InputGrande } from "../../Pages/communStyled";

interface Props {
    maxNum: any;
    maxValue?: number;
    inputValue: string;
    onChange: (data: any) => void
    onEnter: () => void
    disabled?: boolean;
}

const NumericMaxlengthInput = ({ maxNum, maxValue, inputValue, disabled, onChange, onEnter }: Props) => {

  const handleInputChange = (event: any) => {
    let inputValueSubstring = event.target.value.substring(0, maxNum).replace(/[^0-9.]+/g, '');

    if (maxValue) {
      const parsedValue = parseInt(inputValueSubstring, 10);

      if (!isNaN(parsedValue) && parsedValue >= maxValue) {
        onChange(`${maxValue}`);
      } else {
        onChange(inputValueSubstring);
      }
    } else {
      onChange(inputValueSubstring);
    }
  };

  const handleInputKeyChange = (event: any) => {
    if (event.key === "Enter" && onEnter) {
        onEnter();
      }
  };

  return (
    <InputGrande
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      onKeyUp={handleInputKeyChange}
      disabled={disabled}
    />
  );
};

export default NumericMaxlengthInput;