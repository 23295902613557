import styled from "styled-components";

export const CheckboxsContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const VerTablaContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    column-gap: 10px;
`;

export const DiasDeVacacionesRowModal = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
`;

export const DiasDeVacacionesRowModalColumn = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    column-gap: 10px;
    `;

export const DiasDeVacacionesRowModalColumnContainer = styled.div`
    display: flex;
    width: 80%;
    justify-content: flex-start;
    align-items: flex-end;
    column-gap: 10px;
    @media (max-width: 500px) {
        flex-direction: column;
        width: 100%;
      }
`;

export const DiasDeAguinaldoDiasContainer = styled.div`
display: flex;
column-gap: 10px;
justify-content: center;
align-items: center;
`;


export const DiasDeDescansoColumnContainer = styled.div`
    display: flex;
    width: 80%;
    justify-content: flex-start;
    align-items: flex-end;
    column-gap: 10px;
    @media (max-width: 500px) {
        flex-direction: column;
        width: 100%;
      }
`;

export const DiasDeDescansoSectionContainer = styled.div`
display: flex;
width: 100%;
justify-content: center;
align-items: center;
column-gap: 10px;
`;