import React from 'react';
import { InputGrande } from '../../Pages/communStyled';

interface PhoneInputProps {
  inputValue: string;
  onChange: (value: string) => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ inputValue, onChange }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let number = event.target.value;
    number = number.replace(/\D/g, '');
    number = number.substring(0, 10);
    const size = number.length;

    if (size === 0) {
      number = number;
    } else if (size < 3) {
      number = `(${number}`;
    } else if (size < 7) {
      number = `(${number.substring(0, 2)}) ${number.substring(2, 6)}`;
    } else {
      number = `(${number.substring(0, 2)}) ${number.substring(2, 6)} - ${number.substring(6, 10)}`;
    }

    onChange(number);
  };

  return (
    <InputGrande
      type="text"
      value={inputValue}
      onChange={handleInputChange}
    />
  );
};

export default PhoneInput;