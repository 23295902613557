import { useEffect, useState } from "react";
import {
  CheckboxContainer,
  HiddenCheckbox,
  Icon,
  StyledCheckbox,
} from "./checkboxStyled";

interface Props {
  className?: string;
  checked: boolean;
  disabled?: boolean;
  handleClick: (state: boolean) => void;
  removeMargin?: boolean;
}
const Checkbox = ({
  className,
  checked,
  handleClick,
  disabled,
  removeMargin,
}: Props) => {
  const [checkedState, setCheckedState] = useState<boolean>(checked);
  const onHandleClick = (state: boolean) => {
    if (!disabled) {
      setCheckedState(state);
      handleClick(state);
    }
  };

  useEffect(() => {
    setCheckedState(checked);
  }, [checked]);

  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checkedState} onChange={() => {}} />
      <StyledCheckbox
        removemargin={removeMargin ? "true" : "false"}
        checked={checkedState}
        disabled={disabled}
        onClick={() => onHandleClick(!checkedState)}
      >
        <Icon viewBox="0 0 24 24" disabled={disabled}>
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default Checkbox;
