import styled from "styled-components";

export const Contenedor = styled.div`
  height: 100%;
  width: 100%;
  @media (max-width: 1050px) {
    background: #ffffff 0% 0% no-repeat padding-box;
  }
`;

export const TitleDiv = styled.div`
  padding: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #b7d32d;
  color: white;
  border-radius: 4px;
`;

export const Titulo = styled.label`
  font-size: 18px;
  font-family: "Manrope ExtraBold";
`;

export const ButtonDiv = styled.div`
  float: right;
  padding: 20px;
`;
