import { useRef, useState } from "react";
import { ButtonPrimario, ButtonSecundario, ComplementaryFlexSmall, Contenedor, ContenedorBotones, ContenedorBotonesExterior, ContenedorSection, DivTitulos, DivTitulosSubTitulo, DivTitulosTitulo, Flex1, FlexColumn, FlexRow, FlexRowSmall, InputMini, TextLabelTitle } from "../../../communStyled";
import { Checkbox, Dropdown, Warning } from "../../../../Components";
import { Subject } from "rxjs";
import { useDispatch, useSelector } from "react-redux";
import { ccNumberLimit, scrollIntoView } from "../../../../Utils/sharedFunctions";
import { apiRequest, apiRoutes } from "../../../../Api/services";
import actions from "../../../../Redux/Actions";

export default function ParametrosDeOperacion() {
  const alertRef = useRef(null);
  const dispatch = useDispatch();
  const empresa: any = useSelector((store: any) => store.empresa);
  const currentEmpresa: any = useSelector((store: any) => store.currentEmpresa);
  const [contratos, setContratos] = useState(empresa.empresa.parametrosDeOperacion.generacionAutomaticaDeDocumentos.contratos);
  const [constanciaDeVacaciones, setConstanciaDeVacaciones] = useState(empresa.empresa.parametrosDeOperacion.generacionAutomaticaDeDocumentos.constanciaDeVacaciones);
  const [descuentoAlSalario, setDescuentoAlSalario] = useState(empresa.empresa.parametrosDeOperacion.generacionAutomaticaDeDocumentos.descuentoAlSalario);
  const [calculoDeImpuestos, setCalculoDeImpuestos] = useState(empresa.empresa.parametrosDeOperacion.generacionAutomaticaDeDocumentos.calculoDeImpuestos);

  const [incluirEnFondoDeAhorro, setIncluirEnFondoDeAhorro] = useState(empresa.empresa.parametrosDeOperacion.parametrosAdicionales.incluirEnFondoDeAhorro);
  const [ajusteDeCentavos, setAjusteDeCentavos] = useState(empresa.empresa.parametrosDeOperacion.parametrosAdicionales.ajusteDeCentavos);
  const [insertarDPA, setInsertarDPA] = useState(empresa.empresa.parametrosDeOperacion.parametrosAdicionales.insertarDPA);

  const messageSubject = new Subject<string>();
  const successfulSubject = new Subject<string>();

  const submitForm = async () => {
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.updateInfo,
      data: {
        area: "parametrosDeOperacion",
        data: {
          ...empresa.empresa.parametrosDeOperacion,
          generacionAutomaticaDeDocumentos: {
            ...empresa.empresa.parametrosDeOperacion.generacionAutomaticaDeDocumentos,
            contratos,
            constanciaDeVacaciones,
            descuentoAlSalario,
            calculoDeImpuestos,
          },
          parametrosAdicionales: {
            ...empresa.empresa.parametrosDeOperacion.parametrosAdicionales,
            incluirEnFondoDeAhorro,
            ajusteDeCentavos,
            insertarDPA,
          }
        },
        id: currentEmpresa.id,
        modulo: "empresa",
      },
    });

    if (error) {
      messageSubject.next("Hubo un error en el servidor");
      scrollIntoView(alertRef);
    }
    if (res) {
      if (res.message !== "Proceed") {
        messageSubject.next(res.message);
        scrollIntoView(alertRef);
      } else {
        scrollIntoView(alertRef);
        dispatch(
          actions.empresaActions.replaceEmpresa({
            ...empresa,
            empresa: {
              ...empresa.empresa,
              parametrosDeOperacion: {
                ...empresa.empresa.parametrosDeOperacion,
                generacionAutomaticaDeDocumentos: {
                  ...empresa.empresa.parametrosDeOperacion.generacionAutomaticaDeDocumentos,
                  contratos,
                  constanciaDeVacaciones,
                  descuentoAlSalario,
                  calculoDeImpuestos,
                },
                parametrosAdicionales: {
                  ...empresa.empresa.parametrosDeOperacion.parametrosAdicionales,
                  incluirEnFondoDeAhorro,
                  ajusteDeCentavos,
                  insertarDPA,
                }
              },
            },
          })
        );
        successfulSubject.next(
          "La Informacion se agrego correctamente a la base de datos"
        );
      }
    }
  };

  const cancel = () => {
    setContratos(empresa.empresa.parametrosDeOperacion.generacionAutomaticaDeDocumentos.contratos);
    setConstanciaDeVacaciones(empresa.empresa.parametrosDeOperacion.generacionAutomaticaDeDocumentos.constanciaDeVacaciones);
    setDescuentoAlSalario(empresa.empresa.parametrosDeOperacion.generacionAutomaticaDeDocumentos.descuentoAlSalario);
    setCalculoDeImpuestos(empresa.empresa.parametrosDeOperacion.generacionAutomaticaDeDocumentos.calculoDeImpuestos);

    setIncluirEnFondoDeAhorro(empresa.empresa.parametrosDeOperacion.parametrosAdicionales.incluirEnFondoDeAhorro);
    setAjusteDeCentavos(empresa.empresa.parametrosDeOperacion.parametrosAdicionales.ajusteDeCentavos);
    setInsertarDPA(empresa.empresa.parametrosDeOperacion.parametrosAdicionales.insertarDPA);
  };

  return (
    <div>
      <DivTitulos>
        <DivTitulosTitulo>EMPRESA</DivTitulosTitulo>
        <DivTitulosSubTitulo>
          PARAMETROS DE OPERACION
        </DivTitulosSubTitulo>
      </DivTitulos>
      <div ref={alertRef} style={{margin: '0px 35px'}}>
        <Warning
          type={"Alert"}
          title={"Alerta"}
          warningSubject={messageSubject}
        />
        <Warning
          type={"Successful"}
          title={"Exito!"}
          warningSubject={successfulSubject}
        />
      </div>
      <Contenedor>
        <ContenedorSection>
          <TextLabelTitle>Generacion automatica de documentos</TextLabelTitle>
          <FlexColumn>
            <Flex1>
              <Checkbox
                checked={contratos}
                handleClick={(value: boolean) => setContratos(value)
                }
              />
              <span style={{ marginLeft: '10px' }}>contratos</span>
            </Flex1>
            <Flex1>
              <Checkbox
                checked={constanciaDeVacaciones}
                handleClick={(value: boolean) => setConstanciaDeVacaciones(value)
                }
              />
              <span style={{ marginLeft: '10px' }}>Constancia de vacaciones</span>
            </Flex1>
          </FlexColumn>
          <FlexColumn>
            <TextLabelTitle style={{ paddingBottom: '0px' }}>Tope máximo de descuentos al salario</TextLabelTitle>
            <FlexRowSmall inverse='true'>
              <Flex1 style={{ marginTop: '0px' }}>
                <InputMini
                  value={descuentoAlSalario}
                  onChange={(e) =>
                    setDescuentoAlSalario(ccNumberLimit(parseInt(e.target.value), 100))
                  }
                  type="number"
                />
              </Flex1>
              <ComplementaryFlexSmall>
                % sobre excedente del S.M.Z
              </ComplementaryFlexSmall>
            </FlexRowSmall>
          </FlexColumn>
          <FlexColumn>
            <TextLabelTitle style={{ paddingTop: '0px' }}>Calculo de impuesto</TextLabelTitle>
            <Dropdown
              value={calculoDeImpuestos}
              onValue={(value: any) => setCalculoDeImpuestos(value)}
              options={['Ajuste en último período del mes', 'Según proyeccón acumulada mensual']}
            />
          </FlexColumn>
        </ContenedorSection>
        <ContenedorSection>
          <TextLabelTitle>Generacion automatica de documentos</TextLabelTitle>
          <Flex1>
            <Checkbox
              checked={incluirEnFondoDeAhorro}
              handleClick={(value: boolean) => setIncluirEnFondoDeAhorro(value)
              }
            />
            <span style={{ marginLeft: '10px' }}>Incluir en el saldo del fondo de ahorro las aportaciones efectuadas por el patron.</span>
          </Flex1>
          <Flex1>
            <Checkbox
              checked={ajusteDeCentavos}
              handleClick={(value: boolean) => setAjusteDeCentavos(value)
              }
            />
            <span style={{ marginLeft: '10px' }}>Efectuar ajuste de centavos para recibos de Nomina.</span>
          </Flex1>
          <Flex1>
            <Checkbox
              checked={insertarDPA}
              handleClick={(value: boolean) => setInsertarDPA(value)
              }
            />
            <span style={{ marginLeft: '10px' }}>Insertar conceptos de DPA (Diferencia en Periodo Anterior) en las corridas de nómina.</span>
          </Flex1>
        </ContenedorSection>
      </Contenedor  >
      <ContenedorBotonesExterior>
        <ContenedorBotones>
          <ButtonPrimario onClick={() => submitForm()}>Aceptar</ButtonPrimario>
          <ButtonSecundario onClick={() => cancel()}>Cancelar</ButtonSecundario>
        </ContenedorBotones>
      </ContenedorBotonesExterior>
    </div>
  )
}
