import styled from "styled-components";

export const Contenedor = styled.div`
  width: 145px;
  display: flex;
`;

export const DivCeleste = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebebf9;
  width: 87px;
  color: #000000;
`;

export const ButtonQM = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.disabled ? "#000" : "#1ca553" };
  width: 34px;
  border-radius: 4px 0px 0px 4px;
  color: #ffffff;
  cursor: ${(props) => props.disabled ? "not-allowed" : "pointer" };
  border: none;
  box-shadow: none;
`;

export const ButtonQM2 = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.disabled ? "#000" : "#1ca553" };
  width: 34px;
  border-radius: 0px 4px 4px 0px;
  color: #ffffff;
  cursor: ${(props) => props.disabled ? "not-allowed" : "pointer" };
  border: none;
  box-shadow: none;
`;
