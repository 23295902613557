import { forwardRef, useImperativeHandle } from "react";
import {
  Alert,
  Button,
  ButtonSecundary,
  Obligatorio,
  TextDiv,
  TextLabel,
  TextoNormal,
} from "../../Pages/communStyled";
import {
  ButtonDiv,
  Contenedor,
  TitleDiv,
  Titulo,
} from "./ConfirmationModalStyled";
import Modal from "../Modal";

interface Props {
  title: string;
  text: string;
  redButton?: boolean;
  show: boolean;
  cancelLabel?: string;
  acceptlLabel?: string;
  onCancel: () => void;
  onAccept: () => void;
}

const FilterInput = forwardRef(
  (
    {
      title,
      text,
      show,
      redButton = false,
      cancelLabel = "Cancelar",
      acceptlLabel = "Continuar",
      onCancel,
      onAccept,
    }: Props,
    Ref
  ) => {
    useImperativeHandle(Ref, () => ({}));

    return (
      <Modal show={show}>
        <Contenedor>
          <TitleDiv>
            <Titulo>{title}</Titulo>
          </TitleDiv>

          <TextDiv>
            <TextoNormal>{text}</TextoNormal>
          </TextDiv>

          <ButtonDiv>
            <ButtonSecundary onClick={() => onCancel()}>
              {cancelLabel}
            </ButtonSecundary>
            <Button
              style={redButton ? { backgroundColor: "#DB2828" } : {}}
              onClick={() => onAccept()}
            >
              {acceptlLabel}
            </Button>
          </ButtonDiv>
        </Contenedor>
      </Modal>
    );
  }
);

export default FilterInput;
