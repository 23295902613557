import styled, { keyframes } from "styled-components";

export const Obligatorio = styled.span`
  color: #db2828;
  margin-left: 5px;
`;

export const ButtonBig = styled.button`
  background: #b7d32d 0% 0% no-repeat padding-box;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  margin-left: 10px;
  height: 40px;
  padding: 25px 32px;
  font-size: 16px;
  font-family: "Manrope Bold";
  border: none;
  color: white;
  text-align: center;
  cursor: pointer;

  &:disabled {
    background: #ebebf9 0% 0% no-repeat padding-box;
    box-shadow: none;
    color: #434169;
    opacity: 0.38;
    cursor: default;
  }
`;

export const ButtonSmall = styled.button`
  margin-top: 5px;
  letter-spacing: 0;
  background: #b7d32d 0% 0% no-repeat padding-box;
  border: none;
  color: white;
  padding: 6px 22px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    background-color: #e0e1e2;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const ButtonSmallSecundario = styled.button`
  margin-top: 5px;
  letter-spacing: 0;
  background: #262a33 0% 0% no-repeat padding-box;
  border: none;
  color: white;
  padding: 6px 22px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    background-color: #e0e1e2;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
interface ButtonInterface {
  secundario?: string;
  expand?: string;
}

export const Button = styled.button<ButtonInterface>`
  background: ${(props) =>props.secundario === "true" ? "#262a33 0% 0% no-repeat padding-box" : "#b7d32d 0% 0% no-repeat padding-box"};
  border-radius: 4px;
  opacity: 1;
  font-size: 14px;
  font-family: "Manrope Bold";
  border: none;
  padding: 8px 15px;
  color: white;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;

  &:disabled {
    background: ${(props) =>props.secundario === "true" ? "#E0E1E2 0% 0% no-repeat padding-box" : "#E0E1E2 0% 0% no-repeat padding-box"};
    box-shadow: none;
    color: #B0B0B0;
    opacity: 0.38;
    cursor: default;
  }

  @media only screen and (max-width: 1000px) {
    width: ${(props) =>props.expand === "true" ? "100%" : "fit-content"};
    margin-left: ${(props) =>props.expand === "true" ? "0px" : "10px"};
  }
`;

export const ButtonPrimario = styled.button`
  font: Bold 16px/19px Lato;
  letter-spacing: 0;
  background: #b7d32d 0% 0% no-repeat padding-box;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    background-color: #e0e1e2;
    cursor: not-allowed;
  }
`;

export const ButtonSecundario = styled.button`
  font: Bold 16px/19px Lato;
  letter-spacing: 0;
  background: #262a33 0% 0% no-repeat padding-box;
  border: none;
  color: white;
  margin-left: 10px;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    background-color: #e0e1e2;
    cursor: not-allowed;
  }
`;

export const SecondButtonStyleList = styled.button`
  border-radius: 4px;
  opacity: 1;
  font-size: 14px;
  font-family: "Manrope Bold";
  border: none;
  padding: 8px 15px;
  color: white;
  text-align: center;
  cursor: pointer;
  margin-left: 0px;
  margin-top: 10px;
  width: 300px;
  background-color: #262a33;

  &:disabled {
    background: #ebebf9 0% 0% no-repeat padding-box;
    box-shadow: none;
    color: #434169;
    opacity: 0.38;
    cursor: default;
  }
`;

export const Footers = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  margin-top: auto;
  z-index: 1;

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 30px;
  }
`;

export const FootersBlack = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  margin-top: auto;
  z-index: 1;
  padding: 40px 0px 30px 0px;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 30px;
  }
`;

export const Copyright = styled.label`
  padding-top: 12px;
  display: inline-block;
  font-size: 14px;
  font-family: "Manrope Regular";
  color: #ffffff;
  margin-left: 85px;

  @media (max-width: 1300px) {
    margin-left: 0px;
  }
`;

export const CopyrightBlack = styled.label`
  padding-top: 12px;
  display: inline-block;
  font-size: 14px;
  font-family: "Manrope Regular";
  color: #000000;
  margin-left: 85px;

  @media (max-width: 1000px) {
    margin-left: 0px;
  }
`;

export const PrivacyNotice = styled.label`
  padding-top: 12px;
  display: inline-block;
  font-size: 14px;
  font-family: "Manrope Regular";
  color: #ffffff;
  margin-right: 85px;
  cursor: pointer;

  @media (max-width: 1300px) {
    margin-right: 0px;
  }
`;

export const PrivacyNoticeBlack = styled.label`
  padding-top: 12px;
  display: inline-block;
  font-size: 14px;
  font-family: "Manrope Regular";
  color: #000000;
  margin-right: 85px;
  cursor: pointer;
  @media (max-width: 1000px) {
    margin-right: 0px;
  }
`;

export const TextLabel = styled.label`
  padding: 16px 0px;
  display: inline-block;
  font-family: "Manrope Regular";
  color: #475259;
  width: 100%;
  text-align: left;
`;

export const TextLabelObligatorio = styled.label`
  padding: 16px 0px;
  display: inline-block;
  font-family: "Manrope Regular";
  color: #475259;
  &::after {
    color: #db2828;
    content: " *";
  }
`;

export const Alert = styled.p`
  padding: 20px;
  margin-right: 5px;
  background-color: #f44336;
  color: white;
`;

export const AlertNoBackground = styled.p`
  font-family: "Manrope Regular";
  color: #db282899;
  font-size: 12px;
  margin: 0px;
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ButtonSecundary = styled.button`
  background: #262a33 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #0000003d;
  border-radius: 4px;
  opacity: 1;
  font-size: 16px;
  font-family: "Manrope Bold";
  border: none;
  padding: 8px 15px;
  color: white;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
  &:disabled {
    background: #ebebf9 0% 0% no-repeat padding-box;
    box-shadow: none;
    color: #434169;
    opacity: 0.38;
    cursor: default;
  }
`;

export const DivTitulos = styled.p`
  height: 50px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-left: 45px;
  margin-right: 45px;
  @media (max-width: 1000px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const DivTitulosTitulo = styled.span`
  font-size: 24px;
  font-weight: bold;
  font-family: "Lato";
`;

export const DivTitulosSubTitulo = styled.span`
  top: 35px;
  left: 45px;
  font-size: 18px;
  font-weight: bold;
  font-family: "Lato";
  color: #767676;
`;

export const DivTitulosSubTituloInnerDivs = styled.span`
  top: 35px;
  left: 45px;
  font-size: 18px;
  font-weight: bold;
  font-family: "Lato";
  color: #767676;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  right: 45px;
`;

export const Hyperlink = styled.label`
  margin-left: 5px;
  font-family: "Manrope Regular";
  font-size: 14px;
  color: #2185d0;
  text-decoration: underline #2185d0;
  cursor: pointer;
  padding-top: 12px;
  display: inline-block;
`;

export const HyperlinkSpan = styled.span`
  margin-left: 5px;
  font-family: "Manrope Regular";
  font-size: 14px;
  color: #2185d0;
  text-decoration: underline #2185d0;
  cursor: pointer;
  padding-top: 12px;
  display: inline-block;
`;

export const TextLabelTitle = styled.label`
  padding: 16px 0px;
  display: inline-block;
  font-family: "Lato";
  font-weight: bold;
  font-size: 16px;
  @media (max-width: 1000px) {
    margin-right: 40px;
  }
`;

export const InputGrande = styled.input`
  width: calc(100% - 26px);
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background: #ffffff 0% 0% no-repeat padding-box;
  &:disabled {
    background-color: #f3f3f3;
  }
`;

export const InputMini = styled.input`
  width: 45px;
  cursor: pointer;
  text-align: center;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background: #ffffff 0% 0% no-repeat padding-box;
  &:disabled {
    background-color: #f3f3f3;
    cursor: default;
  }
`;

export const TextAreaGrande = styled.textarea`
  width: calc(100% - 26px);
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background: #ffffff 0% 0% no-repeat padding-box;
  max-height: 200px;
  min-height: 100px;
  margin-bottom: 10px;
  &:disabled {
    background-color: #f3f3f3;
  }
`;

export const Horizontal = styled.div`
  display: inline-flex;
  align-items: flex-end;
  width: 100%;
  > div:first-child {
    width: 50%;
  }
  > div:nth-child(2) {
    margin-left: 25px;
    width: 50%;
  }
`;

export const PEliminar = styled.span`
  display: flex;
  color: red;
  float: right;
  right: 20px;
  position: absolute;
  cursor: pointer;
`;

export const EliminarRow = styled.div`
  display: flex;
  color: red;
  float: right;
  right: 20px;
  cursor: pointer;
`;

export const LabelEliminar = styled.span`
  padding-right: 10px;
`;

export const ContenedorBotonesExterior = styled.div`
  margin-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  margin-right: 45px;
  margin-left: 45px;
`;

export const ContenedorBotones = styled.div`
`;

export const ContenedorBotonesSides = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 24px;
`;

export const RowTitle = styled.div`
  width: 100%;
  display: flex;
`;

export const Column1Title = styled.div`
  float: left;
  width: 26%;
  padding: 10px;
`;

export const Column2Title = styled.div`
  float: left;
  width: 37%;
  padding: 10px;
`;

export const Column3Title = styled.div`
  float: left;
  width: 37%;
  padding: 10px;
`;

interface RowInterface {
  activeclass?: boolean;
}

export const Row = styled.div<RowInterface>`
  border-top: 1px solid
    ${(props) => (props.activeclass ? "#E0E1E2" : "#e0e1e2")};
  border-bottom: 1px solid #f3f3f3;
  background-color: ${(props) =>
    props.activeclass ? "#B7D32D33" : "transparent"};
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
  height: 65px;
`;

interface FlexRowInterface {
  activeclass?: string;
  underline?: string;
  hover?: string;
  invalid?: string;
  inverse?: string;
}

export const FlexRow = styled.div<FlexRowInterface>`
  border-top: ${(props) =>
    props.underline === "true" ? "1px solid #f3f3f3" : "0px"};
  border-bottom: ${(props) =>
    props.underline === "true" ? "1px solid #f3f3f3" : "0px"};
  background-color: ${(props) =>
    props.activeclass === "true" ? "#B7D32D33" : "transparent"};
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  column-gap: 10px;
  width: 100%;
  @media (max-width: 1375px) {
    padding: 0px;
    flex-wrap: wrap;
    flex-direction: ${(props) =>props.inverse === "true" ? "column-reverse" : "column"};
    row-gap: 10px;
  }
  &:hover {
    background-color: ${(props) =>
      props.hover === "true" ? "#B7D32D33" : "transparent"};
  }
`;

export const FlexRowSmall = styled.div<FlexRowInterface>`
  border-top: ${(props) =>
    props.underline === "true" ? "1px solid #f3f3f3" : "0px"};
  border-bottom: ${(props) =>
    props.underline === "true" ? "1px solid #f3f3f3" : "0px"};
  background-color: ${(props) =>
    props.activeclass === "true" ? "#B7D32D33" : "transparent"};
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  column-gap: 10px;
  @media (max-width: 500px) {
    padding: 0px;
    flex-wrap: wrap;
    flex-direction: ${(props) =>props.inverse === "true" ? "column-reverse" : "column"};
    row-gap: 10px;
  }
  &:hover {
    background-color: ${(props) =>
      props.hover === "true" ? "#B7D32D33" : "transparent"};
  }
`;

export const FlexRowInline = styled.div<FlexRowInterface>`
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  background-color: ${(props) =>
    props.invalid === "true" ? "#DB282833" : "transparent"};
  display: flex;
  align-items: center;
  padding: 10px 0px;
  column-gap: 10px;
  flex-direction: row;
  @media (max-width: 1375px) {
    flex-direction: column;
  }
`;

export const FlexColumn = styled.div<FlexRowInterface>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FlexCenter = styled.div<FlexRowInterface>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const FlexInlineTextLabel = styled.label`
  padding: 16px 0px;
  display: inline-block;
  font-family: "Manrope Regular";
  color: #475259;
  width: fit-content;
  text-align: center;
`;

export const FlexInlineMarginTextLabel = styled.label`
  margin-left: 10px;
  padding: 16px 0px;
  display: inline-block;
  font-family: "Manrope Regular";
  color: #475259;
  width: fit-content;
  text-align: center;
`;

export const Flex1Inline = styled.div`
  flex: 1;
  @media (max-width: 1375px) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Flex2Inline = styled.div`
  flex: 2;
  @media (max-width: 1375px) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Flex3Inline = styled.div`
  flex: 3;
  @media (max-width: 1375px) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Column1 = styled.div`
  float: left;
  width: 26%;
  padding-left: 10px;
`;

export const Column2 = styled.div`
  float: left;
  width: 37%;
  display: flex;
`;

export const Column3 = styled.div`
  float: left;
  width: 37%;
  padding: 10px;
`;

export const Column4 = styled.div`
  float: left;
  width: 68%;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Flex1 = styled.div`
  flex: 1;
  margin: 5px 0px;
  @media (max-width: 1375px) {
    width: 100%;
  }
`;

export const Flex2 = styled.div`
  flex: 2;
  @media (max-width: 1375px) {
    flex: 1;
    width: 100%;
  }
`;

export const Flex3 = styled.div`
  flex: 3;
  @media (max-width: 1375px) {
    flex: 1;
    width: 100%;
  }
`;

export const Flex3Center = styled.div`
  flex: 3;
  margin: 0px 20px;
  padding: 0px 20px;
  height: 100%;
  border-right: 1px dotted #B0B0B0;
  border-left: 1px dotted #B0B0B0;
  @media (max-width: 1375px) {
    border-right: none;
    border-left: none;
    margin: 10px 0px;
    padding: 0px;
    flex: 1;
    width: 100%;
  }
`;

export const Flex8 = styled.div`
  flex: 8;
  @media (max-width: 1375px) {
    flex: 1;
    width: 100%;
  }
`;

export const ComplementaryFlex = styled.div`
  width: 100%;
  margin: 0px 5px;
  @media (max-width: 1375px) {
    flex: 1;
    margin: 10px 0px;
  }
`;

export const ComplementaryFlexSmall = styled.div`
  width: 100%;
  margin: 0px 5px;
  @media (max-width: 500px) {
    flex: 1;
    margin: 10px 0px;
  }
`;

export const HorizontalRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    height: 21px;
  }
`;

export const HorizontalRowTitle = styled.b`
  width: 300px;
`;

export const LinkColor = styled.span`
  color: #2185d0;
  cursor: pointer;
`;

export const LinkColorRed = styled.span`
  color: #db2828;
  cursor: pointer;
`;

export const ContenedorIconos = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    cursor: pointer;
    font-size: 18px;
  }
`;

export const NormalText = styled.div`
  margin: 20px 0px;
`;

export const Contenedor = styled.div`
  display: flex;
  justify-content: space-evenly;
  display: inline-block;
`;

export const ContenedorPadding = styled.p`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin: 30px 45px;
  @media (max-width: 1000px) {
    margin: 16px;
  }
`;

export const ContenedorSection = styled.div`
  margin: 16px;
  padding: 16px;
  margin-left: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  position: relative;
  height: auto;
  width: calc(100vw - 412px);
  margin-right: 45px;
  margin-bottom: 15px;
  @media (max-width: 1000px) {
    width: calc(100vw - 70px);
    margin-right: 16px;
    margin-left: 16px;
  }
  `;

  export const TextoNormal = styled.label`
  font-family: "Manrope Regular";
  font-size: 16px;
  display: block;
  text-align: justify;
  padding: 20px;
`;

export const TextDiv = styled.div`
  height: fit-content;
  width: 555px;
  overflow-y: auto;
  max-height: 500px;
  margin-bottom: 30px;
  @media (max-width: 1050px) {
    width: calc(100% - 0px);
    height: 70%;
  }
`;

export const Titulo = styled.label`
  font-size: 18px;
  font-family: "Manrope ExtraBold";
  font-weight: bold;
`;

export const ButtonDiv = styled.div`
  float: right;
  padding: 20px;
`;

