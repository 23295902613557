import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Subject } from "rxjs";
import {
  AlertContainer,
  AlertContainerText,
  WarningContainerTitle,
} from "./WarningStyled";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";

interface Props {
  type: string;
  title: string;
  closeable?: boolean;
  multiAlert?: boolean;
  warningSubject: Subject<string>;
}

const Warning = forwardRef(
  (
    {
      type,
      title,
      closeable = true,
      multiAlert = false,
      warningSubject,
    }: Props,
    Ref
  ) => {
    const [alerts, setAlerts] = useState<any>([]);

    useImperativeHandle(Ref, () => ({}));
    
    useEffect(() => {
      warningSubject.subscribe((alert: string) => {
        setAlerts(alert === "" ? [] : multiAlert ? [...alerts, alert] : [alert]);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warningSubject]);

    const closeWarning = () => {
      setAlerts([]);
    };

    return alerts.length !== 0 ? (
      <AlertContainer
        style={
          type === "Alert"
            ? {
                background: "#DB282833 0% 0% no-repeat padding-box",
                border: "1px solid #DB282899",
              }
            : type === "Successful"
            ? {
                background: "#1CA55333 0% 0% no-repeat padding-box",
                border: "1px solid #1CA55399",
              }
            : {
                background: "#FBBD0833 0% 0% no-repeat padding-box",
                border: "1px solid #FBBD0899",
              }
        }
      >
        <WarningContainerTitle
          style={{
            color:
              type === "Alert"
                ? "#DB2828"
                : type === "Successful"
                ? "#1CA553"
                : "#FBBD08",
          }}
        >
          {title}
        </WarningContainerTitle>
        <br></br>
        {alerts.map((alert: string) => {
          return (
            <AlertContainerText key={uuidv4()}
              style={{
                color:
                  type === "Alert"
                    ? "#DB282899"
                    : type === "Successful"
                    ? "#1CA55399"
                    : "#FBBD0899",
              }}
            >
              {alert}
            </AlertContainerText>
          );
        })}
        {closeable && (
          <FontAwesomeIcon
            onClick={() => closeWarning()}
            icon={faTimes}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color:
                type === "Alert"
                  ? "#DB2828"
                  : type === "Successful"
                  ? "#1CA553"
                  : "#FBBD08",
            }}
          />
        )}
      </AlertContainer>
    ) : null;
  }
);

export default Warning;
