const routes = {
    login: "session/login",
    registro: "session/registro",
    cargarEmpresas: "empresa/cargarEmpresas/",
    cambiarContraseñaConToken: "password/changePasswordWithToken",
    recuperarContraseñaToken: "password/recoverPasswordToken",
    removeIMG: "images/removeIMGCompanie",
    postIMG: "images/uploadIMG",
    crearEmpresa: "empresa/crearEmpresa",
    editarEmpresa: "empresa/editarEmpresa",
    cargarEmpresa: "empresa/cargarEmpresa/",
    eliminarEmpresa: "empresa/eliminarEmpresa",
    transferirEmpresa: "empresa/transferirEmpresa",
    getCPInfo: "CP/getCPInfo/",
    usariosList: "typeaheads/usariosList/",
    renunciarAColaborador: "colaboradores/renunciarAColaborador",
    eliminarCuenta: "session/eliminarCuenta",
    transferirCreditos: "stripe/transferirCreditos",
    uma: "constants/UMA/",
    aplicarCargo: "stripe/aplicarCargo",
    chargeStripe: "stripe/chargeStripe",
    eliminarNotificacion: "notifications/eliminarNotificacion",
    agregarColaborador: "colaboradores/agregarColaborador",
    editarColaborador: "colaboradores/editarColaborador",
    eliminarColaborador: "colaboradores/eliminarColaborador",
    regimenesFiscales: "constants/regimenesFiscales/",
    updateInfo: "empresa/updateInfo",
    diasDeVacacionesLey: "constants/diasDeVacacionesLey/",
    crearTrabajador: "trabajadores/crearTrabajador/",
    editarTrabajador: "trabajadores/editarTrabajador/",
    restablecerHorarioTrabajador: "trabajadores/restablecerHorarioTrabajador/",
  }

  export default routes;