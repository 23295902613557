const replaceInformacionGeneral = (newInformacionGeneral) => {
    return {
      type: "REPLACEINFORMACIONGENERAL",
      newInformacionGeneral,
    };
  };
  
  export default {
    replaceInformacionGeneral
  };
  