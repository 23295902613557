import { diasSemana, meses } from "./constants";

/* Combina 2 objetos con las mismas keys manteniendo las variables no nulas */
export function merge(obj1, obj2) {
  const object = { ...obj1 };
  for (const key in obj2) {
    if (
      object[key] !== undefined ||
      object[key] !== null ||
      object[key].length !== 0
    )
      object[key] = obj2[key];
  }
  return object;
}

/* genera un ID aleatorio */
export function getRandomID() {
  return "_" + Math.random().toString(36).substr(2, 9).toUpperCase();
}

/* convierte los numeros en texto para despliegar en formato 00:00 */
export function presentarNumero(numero) {
  if (numero && numero.length !== 0) {
    if (typeof numero === "string") {
      numero = splitValues(numero);
    }
    const [numeroHoras, numeroMinutos] = numero;
    let numeroHorasString = numeroHoras === 0 ? "00" : numeroHoras.toString();
    numeroHorasString =
      numeroHorasString.length === 1
        ? "0" + numeroHorasString
        : numeroHorasString;
    let numeroMinutosString =
      numeroMinutos === 0 ? "00" : numeroMinutos.toString();
    numeroMinutosString =
      numeroMinutosString.length === 1
        ? "0" + numeroMinutosString
        : numeroMinutosString;
    return numeroHorasString + ":" + numeroMinutosString;
  }
  return "00:00";
}
/* convierte los numeros en texto para despliegar en formato 00:00 */
export function presentarNumeroNullable(numero) {
  if (numero && numero.length !== 0) {
    if (typeof numero === "string") {
      numero = splitValues(numero);
    }
    const [numeroHoras, numeroMinutos] = numero;
    let numeroHorasString = numeroHoras === 0 ? "00" : numeroHoras.toString();
    numeroHorasString =
      numeroHorasString.length === 1
        ? "0" + numeroHorasString
        : numeroHorasString;
    let numeroMinutosString =
      numeroMinutos === 0 ? "00" : numeroMinutos.toString();
    numeroMinutosString =
      numeroMinutosString.length === 1
        ? "0" + numeroMinutosString
        : numeroMinutosString;
    return numeroHorasString + ":" + numeroMinutosString;
  }
  return null;
}

/* desconvierte los numeros en formato texto 00:00 a numeros en formato [0,0] */
export function splitValues(horario) {
  if (horario) {
    const Horario = typeof horario === "string" ? horario.split(":") : horario;
    let HorarioHoras = parseInt(Horario[0]);
    let HorarioMinutos = parseInt(Horario[1]);
    HorarioHoras = isNaN(HorarioHoras) ? 0 : HorarioHoras;
    HorarioMinutos = isNaN(HorarioMinutos) ? 0 : HorarioMinutos;

    return [HorarioHoras, HorarioMinutos];
  }
  return [0, 0];
}

/* desconvierte los numeros en formato texto 00:00 a numeros en formato [0,0] */
export function splitValuesNullable(horario) {
  if (horario) {
    const Horario = typeof horario === "string" ? horario.split(":") : horario;
    let HorarioHoras = parseInt(Horario[0]);
    let HorarioMinutos = parseInt(Horario[1]);
    HorarioHoras = isNaN(HorarioHoras) ? 0 : HorarioHoras;
    HorarioMinutos = isNaN(HorarioMinutos) ? 0 : HorarioMinutos;

    return [HorarioHoras, HorarioMinutos];
  }
  return null;
}

export function formateDay(date) {
  date = new Date(date);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return month < 10 ? `${day}/0${month}/${year}` : `${day}/${month}/${year}`;
}

export function dayFormat(date) {
  const splitday = date.split("/");
  return new Date(splitday[2], splitday[1] - 1, splitday[0]);
}

export function getDifferenceInDays(D1, D2) {
  const date1 = new Date(D1);
  const date2 = new Date(D2);
  const d1Y = date1.getFullYear();
  const d2Y = date2.getFullYear();
  const d1M = date1.getMonth();
  const d2M = date2.getMonth();
  // To calculate the time difference of two dates
  const Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  const Difference_In_Weeks = Difference_In_Time / (1000 * 3600 * 24 * 7);
  const Difference_In_Months = d2M + 12 * d2Y - (d1M + 12 * d1Y);
  const Difference_In_Years = date2.getFullYear() - date1.getFullYear();
  return {
    days: Difference_In_Days,
    weeks: Difference_In_Weeks,
    months: Difference_In_Months,
    years: Difference_In_Years,
  };
}

export const getHorarioEnMinutos = (horario) => {
  if (!horario) { return 0; }
  if ( typeof horario !== "string") {
    horario = presentarNumero(horario.toString());
  }
  let [horarioHoras, horarioMinutos] =
    typeof horario === "string" ? horario.split(":") : horario;
  horarioHoras = parseInt(horarioHoras);
  horarioMinutos = parseInt(horarioMinutos);
  return parseInt(horarioHoras * 60 + horarioMinutos);
};

export const clone = (items) => JSON.parse(JSON.stringify(items));

export const obtenerAñoDeNacimiento = (curp) => {
  let año;
  const añoDigitos = curp.substr(4, 2);
  const primerDigito = curp.charAt(16);

  if (parseInt(primerDigito, 10) >= 0 && parseInt(primerDigito, 10) <= 9) {
    año = `19${añoDigitos}`;
  } else {
    año = `20${añoDigitos}`;
  }

  return año;
};

export const scrollIntoView = (ref) => {
  ref?.current &&
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
};

export const scrollIntoEnd = (ref) => {
  ref?.current &&
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
};

export const sumatoria = (arreglo) => {
  let sumatoriaTotal = [0, 0];
  for (let horario of arreglo) {
    const [horarioHoras, horarioMinutos] = horario["diferencia"];
    const [sumatoriaHoras, sumatoriaMinutos] = sumatoriaTotal;
    let resultadoHoras = horarioHoras + sumatoriaHoras;
    let resultadoMinutos = horarioMinutos + sumatoriaMinutos;
    resultadoHoras = resultadoHoras + Math.floor(resultadoMinutos / 60);
    resultadoMinutos = resultadoMinutos % 60;
    sumatoriaTotal = [resultadoHoras, resultadoMinutos];
  }

  for (let horario of arreglo) {
    if (horario["diferenciaCheckmark"]) {
      const [horarioHoras, horarioMinutos] = horario["diferenciaContinuo"];
      const [sumatoriaHoras, sumatoriaMinutos] = sumatoriaTotal;
      let resultadoHoras = horarioHoras + sumatoriaHoras;
      let resultadoMinutos = horarioMinutos + sumatoriaMinutos;
      resultadoHoras = resultadoHoras + Math.floor(resultadoMinutos / 60);
      resultadoMinutos = resultadoMinutos % 60;
      sumatoriaTotal = [resultadoHoras, resultadoMinutos];
    }
  }

  const returnValue = sumatoriaTotal;
  return returnValue;
};

export const sumatoriaDeTiposDeHorario = (arreglo) => {
  let sumatoriaTotalDiurno = [0, 0];
  let sumatoriaTotalNocturno = [0, 0];
  for (let horario of arreglo) {
    const [horarioDiurnoHoras, horarioDiurnoMinutos] =
      horario["horarioNormalDiurno"];
    const [sumatoriaHorasDiurno, sumatoriaMinutosDiurno] = sumatoriaTotalDiurno;
    let resultadoHorasDiurno = horarioDiurnoHoras + sumatoriaHorasDiurno;
    let resultadoMinutosDiurno = horarioDiurnoMinutos + sumatoriaMinutosDiurno;
    resultadoHorasDiurno =
      resultadoHorasDiurno + Math.floor(resultadoHorasDiurno / 60);
    resultadoMinutosDiurno = resultadoMinutosDiurno % 60;
    sumatoriaTotalDiurno = [resultadoHorasDiurno, resultadoMinutosDiurno];
    const [horarioNocturnoHoras, horarioNocturnoMinutos] =
      horario["horarioNormalNocturno"];
    const [sumatoriaHorasNocturno, sumatoriaMinutosNocturno] =
      sumatoriaTotalNocturno;
    let resultadoHorasNocturno = horarioNocturnoHoras + sumatoriaHorasNocturno;
    let resultadoMinutosNocturno =
      horarioNocturnoMinutos + sumatoriaMinutosNocturno;
    resultadoHorasNocturno =
      resultadoHorasNocturno + Math.floor(resultadoMinutosNocturno / 60);
    resultadoMinutosNocturno = resultadoMinutosNocturno % 60;
    sumatoriaTotalNocturno = [resultadoHorasNocturno, resultadoMinutosNocturno];
  }

  for (let horario of arreglo) {
    if (horario["diferenciaCheckmark"]) {
      const [horarioDiurnoHoras, horarioDiurnoMinutos] =
        horario["horarioContinuoDiurno"];
      const [sumatoriaHorasDiurno, sumatoriaMinutosDiurno] =
        sumatoriaTotalDiurno;
      let resultadoHorasDiurno = horarioDiurnoHoras + sumatoriaHorasDiurno;
      let resultadoMinutosDiurno =
        horarioDiurnoMinutos + sumatoriaMinutosDiurno;
      resultadoHorasDiurno =
        resultadoHorasDiurno + Math.floor(resultadoMinutosDiurno / 60);
      resultadoMinutosDiurno = resultadoMinutosDiurno % 60;
      sumatoriaTotalDiurno = [resultadoHorasDiurno, resultadoMinutosDiurno];
      const [horarioNocturnoHoras, horarioNocturnoMinutos] =
        horario["horarioContinuoNocturno"];
      const [sumatoriaHorasNocturno, sumatoriaMinutosNocturno] =
        sumatoriaTotalNocturno;
      let resultadoHorasNocturno =
        horarioNocturnoHoras + sumatoriaHorasNocturno;
      let resultadoMinutosNocturno =
        horarioNocturnoMinutos + sumatoriaMinutosNocturno;
      resultadoHorasNocturno =
        resultadoHorasNocturno + Math.floor(resultadoMinutosNocturno / 60);
      resultadoMinutosNocturno = resultadoMinutosNocturno % 60;
      sumatoriaTotalNocturno = [
        resultadoHorasNocturno,
        resultadoMinutosNocturno,
      ];
    }
  }

  const returnValue = {
    diurno: sumatoriaTotalDiurno,
    nocturno: sumatoriaTotalNocturno,
  };
  return returnValue;
};

export const getTipoDeHorario = (Horarios, HoraDeEntrada) => {
  const objectToReturn = {
    tipoDeHorario: "",
    acomuladoDeHorasAlaSemana: "",
  };
  const [horarioDiurnoHoras, horarioDiurnoMinutos] = Horarios.diurno;
  const [horarioNocturnoHoras, horarioNocturnoMinutos] = Horarios.nocturno;
  if (horarioDiurnoHoras !== 0 || horarioDiurnoMinutos !== 0) {
    if (horarioNocturnoHoras !== 0 || horarioNocturnoMinutos !== 0) {
      objectToReturn.tipoDeHorario = "mixto";
      objectToReturn.acomuladoDeHorasAlaSemana = "mixto";
    } else {
      objectToReturn.tipoDeHorario = "diurno";
      objectToReturn.acomuladoDeHorasAlaSemana = "diurno";
    }
  } else if (horarioNocturnoHoras !== 0 || horarioNocturnoMinutos !== 0) {
    objectToReturn.tipoDeHorario = "nocturno";
    objectToReturn.acomuladoDeHorasAlaSemana = "nocturno";
  } else {
    objectToReturn.tipoDeHorario = "";
    objectToReturn.acomuladoDeHorasAlaSemana = "";
  }

  if (horarioNocturnoHoras !== 0 && (horarioDiurnoHoras !== 0 || horarioDiurnoMinutos !== 0)) {
    if (horarioNocturnoHoras >= 3) {
      if (horarioNocturnoHoras === 3 && horarioNocturnoMinutos < 30) {
        objectToReturn.tipoDeHorario = "mixto";
        objectToReturn.acomuladoDeHorasAlaSemana = "mixto";
      } else {
        objectToReturn.tipoDeHorario = "nocturno";
        objectToReturn.acomuladoDeHorasAlaSemana = "nocturno";
      }
    }
  }
  if (horarioNocturnoHoras !== 0 || horarioNocturnoMinutos !== 0) {
    if (
      HoraDeEntrada[0] >= 6 &&
      HoraDeEntrada[0] <= 12 &&
      horarioDiurnoHoras >= 8
    ) {
      objectToReturn.acomuladoDeHorasAlaSemana = "diurno";
    }
  }
  return objectToReturn;
};

export const getHorasExtra = (newValue, limiteDeHoras) => {
  limiteDeHoras = splitValues(limiteDeHoras);
  let horasExtra = [0, 0];
  let total = newValue;
  if (
    total[0] > limiteDeHoras[0] ||
    (total[0] === limiteDeHoras[0] && total[1] > limiteDeHoras[1])
  ) {
    const horasTotalesEnMinutos = total[0] * 60 + total[1];
    const limiteDeHorasEnMinutos = limiteDeHoras[0] * 60 + limiteDeHoras[1];
    let horasExtra = [
      Math.floor((horasTotalesEnMinutos - limiteDeHorasEnMinutos) / 60),
      (horasTotalesEnMinutos - limiteDeHorasEnMinutos) % 60,
    ];
    if (horasExtra[0] > 0 || horasExtra[1] > 0) {
      total = limiteDeHoras;
    }
    return {
      horasExtra,
      total,
    };
  } else return { horasExtra, total };
};

export const ccHoursBlur = (hours) => {
  let number = hours;
  if (!number) {
    number = "0000";
  }
  number = number.replace(/\D/g, "");
  if (number.length <= 2) {
    number = number.replace(/^0+/, "");
  }
  number =
    number >= 10
      ? number + "0000"
      : number.length <= 2
        ? "0" + number + "000"
        : number + "000";
  number = number.substring(0, 2) + ":" + number.substring(2, 4);
  return number;
};

export const ccHoursChange = (hours) => {
  let number = hours;
  number = number.replace(/\D/g, "");
  number = number.substring(0, 4);
  const size = number.length;
  if (size <= 2) {
    number = number;
    if (number > 23) {
      number = 23;
    }
  } else if (size > 2) {
    let hoursString = number.substring(0, 2);
    let minutesString = number.substring(2);
    let hours = parseInt(number.substring(0, 2));
    let Minutes = parseInt(number.substring(2));
    if (hoursString.substring(0, 1) !== "0") {
      if (hours >= 23) {
        hours = 23;
        hoursString = hours.toString();
      }
    }
    if (minutesString.substring(0, 1) !== "0") {
      if (parseInt(minutesString.substring(0, 1)) > 5) {
        Minutes = 59;
        minutesString = Minutes.toString();
      }
    }
    number = hoursString + ":" + minutesString;
  }
  return number;
};

export const ccHoursNoLimit = (hours) => {
  let number = hours;
  number = number.replace(/\D/g, "");
  number = number.substring(0, 4);
  const size = number.length;
  if (size <= 2) {
    number = number;
  } else if (size > 2) {
    let hoursString = number.substring(0, 2);
    let minutesString = number.substring(2);
    let Minutes = parseInt(number.substring(2));
    if (minutesString.substring(0, 1) !== "0") {
      if (Minutes > 59) {
        Minutes = 59;
        minutesString = Minutes.toString();
      }
    }
    number = hoursString + ":" + minutesString;
  }
  return number;
};

export const cruzaCero = (primerEntrada, ultimaSalida) => {
  let [entradaHora, entradaMinutos] = primerEntrada;
  let [ultimaSalidaHora, ultimaSalidaMinutos] = ultimaSalida;
  const entradaEnMinutos = entradaHora * 60 + entradaMinutos;
  const salidaEnMinutos = ultimaSalidaHora * 60 + ultimaSalidaMinutos;
  return entradaEnMinutos >= salidaEnMinutos;
};

export const esHorarioValido = (
  arreglo,
  index,
  nuevoValorInicial,
  nuevoValorFinal,
  diaAnterior
) => {
  if (arreglo.length !== 0 && !(arreglo.length === 1 && index === 0)) {
    const [UltimaSalida, SiguienteEntrada] = getAnteriorYSiguiente(
      arreglo,
      index
    );
    let [entradaHora, entradaMinutos] = nuevoValorInicial;
    let [ultimaSalidaHora, ultimaSalidaMinutos] = UltimaSalida;
    let [salidaHora, salidaMinutos] = nuevoValorFinal;
    let [siguienteEntradaHora, siguienteEntradaMinutos] = SiguienteEntrada;
    if (entradaHora === 0) {
      entradaHora = 24;
    }
    if (salidaHora === 0) {
      salidaHora = 24;
    }
    if (siguienteEntradaHora === 0) {
      siguienteEntradaHora = 24;
    }
    if (ultimaSalidaHora === 0) {
      ultimaSalidaHora = 24;
    }
    const limit = siguienteEntradaHora + 24;
    const isLastIndex = arreglo.length === index;
    const newEntradaHora = sumarAEntradaEnHoras(
      entradaHora,
      siguienteEntradaHora,
      ultimaSalidaHora,
      ultimaSalidaMinutos,
      entradaMinutos,
      siguienteEntradaMinutos
    );
    const newSalidaHora = sumarASalidaEnHoras(
      entradaHora,
      siguienteEntradaHora,
      ultimaSalidaHora,
      ultimaSalidaMinutos,
      salidaHora,
      salidaMinutos,
      siguienteEntradaMinutos,
      entradaMinutos
    );
    const newUltimaSalidaHora = sumarAUltimaSalidaEnHoras(
      siguienteEntradaHora,
      ultimaSalidaHora,
      siguienteEntradaMinutos,
      ultimaSalidaMinutos
    );
    return !!validarSecuencia(
      newSalidaHora,
      salidaHora,
      salidaMinutos,
      limit,
      siguienteEntradaHora,
      siguienteEntradaMinutos,
      entradaHora,
      newEntradaHora,
      entradaMinutos,
      ultimaSalidaHora,
      newUltimaSalidaHora,
      ultimaSalidaMinutos,
      isLastIndex
    );
  } else {
    let [entradaHora, entradaMinutos] = nuevoValorInicial;
    if (entradaHora === 0) {
      entradaHora = 24;
    }
    const EntradaEnMinutosHoy = entradaHora * 60 + entradaMinutos;
    const ultimaSalidaEnMinutosDiaAnterior =
      diaAnterior.Horarios && diaAnterior.Horarios.length !== 0
        ? diaAnterior.Horarios[diaAnterior.Horarios.length - 1].salida[0] * 60 +
        diaAnterior.Horarios[diaAnterior.Horarios.length - 1].salida[1]
        : null;
    return (
      !ultimaSalidaEnMinutosDiaAnterior ||
      (diaAnterior.cruzaCero &&
        EntradaEnMinutosHoy > ultimaSalidaEnMinutosDiaAnterior) ||
      !diaAnterior.cruzaCero
    );
  }
};

const sumarAEntradaEnHoras = (
  entradaHora,
  siguienteEntradaHora,
  ultimaSalidaHora,
  ultimaSalidaMinutos,
  entradaMinutos,
  siguienteEntradaMinutos
) => {
  return entradaHora < siguienteEntradaHora ||
    (entradaHora === siguienteEntradaHora &&
      entradaMinutos < siguienteEntradaMinutos) ||
    ((entradaHora > siguienteEntradaHora ||
      (entradaHora > siguienteEntradaHora &&
        entradaMinutos > siguienteEntradaMinutos)) &&
      (entradaHora < ultimaSalidaHora ||
        (entradaHora === ultimaSalidaHora &&
          entradaMinutos < ultimaSalidaMinutos)))
    ? entradaHora + 24
    : entradaHora;
};

const sumarASalidaEnHoras = (
  entradaHora,
  siguienteEntradaHora,
  ultimaSalidaHora,
  ultimaSalidaMinutos,
  salidaHora,
  salidaMinutos,
  siguienteEntradaMinutos,
  entradaMinutos
) => {
  return salidaHora < siguienteEntradaHora ||
    (salidaHora === siguienteEntradaHora &&
      salidaMinutos < siguienteEntradaMinutos) ||
    ((salidaHora > siguienteEntradaHora ||
      (salidaHora === siguienteEntradaHora &&
        salidaMinutos > siguienteEntradaMinutos)) &&
      (salidaHora < ultimaSalidaHora ||
        (salidaHora === ultimaSalidaHora &&
          salidaMinutos < ultimaSalidaMinutos))) ||
    entradaHora < siguienteEntradaHora ||
    (entradaHora === siguienteEntradaHora &&
      entradaMinutos < siguienteEntradaMinutos) ||
    ((entradaHora > siguienteEntradaHora ||
      (entradaHora > siguienteEntradaHora &&
        entradaMinutos > siguienteEntradaMinutos)) &&
      (entradaHora < ultimaSalidaHora ||
        (entradaHora === ultimaSalidaHora &&
          entradaMinutos < ultimaSalidaMinutos)))
    ? salidaHora + 24
    : salidaHora;
};

const sumarAUltimaSalidaEnHoras = (
  siguienteEntradaHora,
  ultimaSalidaHora,
  siguienteEntradaMinutos,
  ultimaSalidaMinutos
) => {
  return ultimaSalidaHora < siguienteEntradaHora ||
    (ultimaSalidaHora === siguienteEntradaHora &&
      ultimaSalidaMinutos < siguienteEntradaMinutos)
    ? ultimaSalidaHora + 24
    : ultimaSalidaHora;
};

const validarSecuencia = (
  newSalidaHora,
  salidaHora,
  salidaMinutos,
  limit,
  siguienteEntradaHora,
  siguienteEntradaMinutos,
  entradaHora,
  newEntradaHora,
  entradaMinutos,
  ultimaSalidaHora,
  newUltimaSalidaHora,
  ultimaSalidaMinutos,
  isLastIndex
) => {
  return (
    (newSalidaHora < limit ||
      (salidaHora === siguienteEntradaHora &&
        salidaMinutos < siguienteEntradaMinutos) ||
      isLastIndex) &&
    (newEntradaHora < limit ||
      (entradaHora === siguienteEntradaHora &&
        entradaMinutos < siguienteEntradaMinutos)) &&
    (newEntradaHora > newUltimaSalidaHora ||
      (entradaHora === ultimaSalidaHora &&
        entradaMinutos > ultimaSalidaMinutos)) &&
    (newEntradaHora < newSalidaHora ||
      (entradaHora === salidaHora && entradaMinutos < salidaMinutos))
  );
};

const getAnteriorYSiguiente = (array, index) => {
  if (array[index - 1]) {
    return array[index + 1]
      ? [array[index - 1]["salida"], array[index + 1]["entrada"]]
      : [array[index - 1]["salida"], array[0]["entrada"]];
  } else if (array[index + 1]) {
    return [array[array.length - 1]["salida"], array[index + 1]["entrada"]];
  }
  return [
    [0, 0],
    [0, 0],
  ];
};

export const verificarHorarioAnteriorYSiguiente = (
  index,
  nuevoValorInicial,
  nuevoValorFinal,
  cruzaCero,
  diaAnterior,
  diaSiguiente
) => {
  let valido = true;
  let [entradaHora, entradaMinutos] = nuevoValorInicial;
  let [salidaHora, salidaMinutos] = nuevoValorFinal;
  const EntradaEnMinutosDiaAnterior =
    diaAnterior.Horarios && diaAnterior.Horarios.length !== 0
      ? diaAnterior.Horarios[0].entrada[0] * 60 +
      diaAnterior.Horarios[0].entrada[1]
      : null;
  const ultimaSalidaEnMinutosDiaAnterior =
    diaAnterior.Horarios && diaAnterior.Horarios.length !== 0
      ? diaAnterior.Horarios[diaAnterior.Horarios.length - 1].salida[0] * 60 +
      diaAnterior.Horarios[diaAnterior.Horarios.length - 1].salida[1]
      : null;
  const entradaEnMinutosDiaSiguiente =
    diaSiguiente.Horarios && diaSiguiente.Horarios.length !== 0
      ? diaSiguiente.Horarios[0].entrada[0] * 60 +
      diaSiguiente.Horarios[0].entrada[1]
      : null;

  //si el dia anterior crusa 0
  if (
    ultimaSalidaEnMinutosDiaAnterior <= EntradaEnMinutosDiaAnterior &&
    cruzaCero
  ) {
    // si es el primero
    if (index === 0) {
      if (
        ultimaSalidaEnMinutosDiaAnterior &&
        ultimaSalidaEnMinutosDiaAnterior >= entradaHora * 60 + entradaMinutos
      ) {
        valido = false;
      }
    }
    //si la nueva entrada crusa 0 y es mas grande o igual que la primer entrada del siguiente dia
    if (
      entradaEnMinutosDiaSiguiente &&
      entradaEnMinutosDiaSiguiente <= salidaHora * 60 + salidaMinutos
    ) {
      valido = false;
    }
  }
  return valido;
};

export const sacarDiferencia = (entrada, salida) => {
  const [valorInicialHoras, valorInicialMinutos] = entrada;
  let [valorFinalHoras, valorFinalMinutos] = salida;
  let resultadoHoras;
  let resultadoMinutos;
  if (
    valorFinalHoras < valorInicialHoras ||
    (valorFinalHoras === valorInicialHoras &&
      valorFinalMinutos <= valorInicialMinutos)
  ) {
    valorFinalHoras = valorFinalHoras + 24;
  }
  if (valorFinalMinutos <= valorInicialMinutos) {
    valorFinalHoras = valorFinalHoras - 1;
    valorFinalMinutos = 60 + valorFinalMinutos;
  }
  resultadoHoras = valorFinalHoras - valorInicialHoras;
  resultadoMinutos = valorFinalMinutos - valorInicialMinutos;
  if (resultadoMinutos === 60) {
    resultadoMinutos = 0;
    resultadoHoras = resultadoHoras + 1;
  }
  return [resultadoHoras, resultadoMinutos];
};

export const getHorasPorTipo = (salida, diferencia) => {
  let [salidaHoras, salidaMinutos] = salida;
  let [diferenciaHoras, diferenciaMinutos] = diferencia;
  let horasDiurnas = 0;
  let minutosDiurnos = 0;
  let horasNocuturnas = 0;
  let minutosNocturnos = 0;
  if (salidaHoras >= 6 && salidaHoras < 20) {
    const horasDisponibles = salidaHoras - 6;
    if (diferenciaHoras > horasDisponibles) {
      horasDiurnas = horasDisponibles;
      minutosDiurnos = salidaMinutos;
      if (diferenciaMinutos < salidaMinutos) {
        diferenciaMinutos = diferenciaMinutos + 60;
        diferenciaHoras = diferenciaHoras - 1;
      }
      horasNocuturnas = diferenciaHoras - horasDisponibles;
      minutosNocturnos = diferenciaMinutos - salidaMinutos;
      if (horasNocuturnas > 10) {
        horasDiurnas = horasDiurnas + (horasNocuturnas - 10);
        horasNocuturnas = 10;
        minutosNocturnos = 0;
        minutosDiurnos = diferenciaMinutos;
        if (minutosDiurnos === 60) {
          minutosDiurnos = 0;
          horasDiurnas = horasDiurnas + 1;
        }
      } else if (minutosNocturnos < 0) {
        horasNocuturnas = horasNocuturnas - 1;
        minutosNocturnos = minutosNocturnos * -1;
      }
    } else {
      horasDiurnas = diferenciaHoras;
      minutosDiurnos = diferenciaMinutos;
    }
  } else {
    if (salidaHoras <= 6) {
      salidaHoras = salidaHoras + 24;
    }
    const horasDisponibles = salidaHoras - 20;
    if (diferenciaHoras >= horasDisponibles) {
      horasNocuturnas = horasDisponibles;
      minutosNocturnos = salidaMinutos;
      if (diferenciaMinutos < salidaMinutos) {
        diferenciaMinutos = diferenciaMinutos + 60;
        diferenciaHoras = diferenciaHoras - 1;
      }
      horasDiurnas = diferenciaHoras - horasDisponibles;
      minutosDiurnos = diferenciaMinutos - salidaMinutos;
      if (horasDiurnas > 14) {
        horasNocuturnas = horasNocuturnas + (horasDiurnas - 14);
        horasDiurnas = 14;
        minutosNocturnos = diferenciaMinutos;
        minutosDiurnos = 0;
        if (minutosNocturnos === 60) {
          minutosNocturnos = 0;
          horasNocuturnas = horasNocuturnas + 1;
        }
      } else if (minutosDiurnos < 0) {
        minutosDiurnos = minutosDiurnos - 1;
        minutosDiurnos = minutosDiurnos * -1;
      }
    } else {
      horasNocuturnas = diferenciaHoras;
      minutosNocturnos = diferenciaMinutos;
    }
  }
  return [
    [horasDiurnas, minutosDiurnos],
    [horasNocuturnas, minutosNocturnos],
  ];
};

export const ccNumberLimit = (number, limit) => {
  return number > limit ? limit : number;
}

export const ccNumberLimitMaxMin = (number, max, min) => {
  return number > max ? max : number > min ? number : min;
}

export const formatDay = (date) => {
  if (date) {
    if (typeof date === 'string') {
      const parts = (date).split("/");
      let day = parseInt(parts[0], 10);
      let month = parseInt(parts[1], 10) - 1;
      let year = parseInt(parts[2], 10);
      date = new Date(year, month, day);
      day = date.getDate();
      month = date.getMonth() + 1;
      year = date.getFullYear();
      return month < 10
        ? `${day}/0${month}/${year}`
        : `${day}/${month}/${year}`;
    } else {
      date = new Date(date);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return month < 10
        ? `${day}/0${month}/${year}`
        : `${day}/${month}/${year}`;
    }
  }
  return "";
};

export const formatDayInner = (date) => {
  if (date) {
    if (typeof date === 'string') {
      const parts = (date).split("/");
      let day = parseInt(parts[0], 10);
      let month = parseInt(parts[1], 10) - 1;
      let year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    } else {
      return new Date(date);
    }
  }
  return null;
};

export function deepCopy(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  // Handle arrays separately
  if (Array.isArray(obj)) {
    return obj.map(item => deepCopy(item));
  }

  // Check if obj is a Date object
  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  const copy = Object.create(Object.getPrototypeOf(obj));
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepCopy(obj[key]);
    }
  }

  return copy;
}

export function getUniqueElements(array) {
  const uniqueElements = [];
  const seen = {};

  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    if (!seen[item]) {
      uniqueElements.push(item);
      seen[item] = true;
    }
  }

  return uniqueElements;
}

export function getFormatedDate(date) {
  const dateToUse = new Date(date);
  const fecha =
    dateToUse.getDate() +
    " de " +
    meses[dateToUse.getMonth()] +
    " de " +
    dateToUse.getFullYear();
  const Dia = diasSemana[dateToUse.getDay()];
  return `${Dia} ${fecha}`;
}

export function areSameDay(date1, date2){
  if (date1 === null && date2 === null) {
    return true; // Return true if both date is null
}

  if (date1 === null || date2 === null) {
    return false; // Return false if either date is null
}

  // Ensure both arguments are valid Date objects
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
      throw new Error("Both arguments must be valid Date objects.");
  }

  return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
  );
};

export function countFaltasInAny30Days(data) {
  // Ordenar el arreglo por fecha
  data.sort(([dateStringA], [dateStringB]) => new Date(dateStringA) - new Date(dateStringB));

  for (let i = 0; i < data.length; i++) {
    let count = 0;

    for (let j = i; j < data.length; j++) {
      const dateA = new Date(data[i][0]);
      const dateB = new Date(data[j][0]);
      const diffInDays = (dateB - dateA) / (1000 * 60 * 60 * 24);

      if (diffInDays > 30) break;

      if (data[j][1].tipo === 'Falta injustificada') {
        count++;
      }

      if (count > 3) return {causaDeRecisionPorFaltas: true, numeroDeFaltas: count};
    }
  }

  return {causaDeRecisionPorFaltas: false, numeroDeFaltas: 0};
}

export function sumarMinutos(hora, minutosASumar, aleatorio=false) {
  let [horas, minutos] = splitValues(hora);
  let totalMinutos = horas * 60 + minutos;
  totalMinutos += aleatorio ? Math.floor(Math.random() * (minutosASumar - 0) + 0) : minutosASumar;
  horas = Math.floor(totalMinutos / 60);
  minutos = totalMinutos % 60;
  return presentarNumero([horas, minutos]);
}