import styled from "styled-components";

export const ModalMain = styled.div`
  position: fixed;
  background: white;
  max-width: 100%;
  width: auto !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
`;

export const ModalButtonCloseDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  min-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalButtonClose = styled.button`
  background-color: transparent;
  margin: 10px;
  height: 26px;
  width: 26px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  font-size: 20px;
  color: red;
  cursor: pointer;
`;

export const ModalTitle = styled.h3`
  margin: 20px;
  font-family: Roboto, sans-serif;
  color: #343a40;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
`;

export const ModalContainer = styled.div`
  width: auto !important;
  max-width: 100%;
  overflow-y: auto;
  border: 1px solid white;
`;

export const BackgroundModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #000000ba;
  z-index: 900;
  width: 100%;
`;

export const ModalBodyTop = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const ModalBody = styled.div`
  padding: 20px;
  background: #fff;
  max-height: 820px;
  overflow-y: scroll;
  margin-top: 50px;
  border-radius: 5px;
`;
