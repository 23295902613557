import styled from "styled-components";

export const TabsContainer = styled.div`
  display: flex;
`;

interface TabsInterface {
  active: string;
  enable: string;
}

export const TabsCard = styled.div<TabsInterface>`
  padding: 16px;
  padding-bottom: 10px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 0px;
  position: relative;
  display: inline-block;
  font-family: "Lato";
  font-size: 16px;
  font-weight: ${(props) => (props.active === "true" ? "bold" : "normal")};
  z-index: ${(props) => (props.active === "true" ? "3" : "2")};
  cursor: ${(props) => (props.enable === "true" ? "default" : "no-drop")};
  color: ${(props) => (props.enable === "true" ? "black" : "red")};
  * {
    cursor: ${(props) => (props.enable === "true" ? "default" : "no-drop")};
  }
`;
