const TOKEN = "token";
const REFRESHTOKEN = "refreshToken";
const TYPE = "type";

export function signOut() {
  window.sessionStorage.clear();
  window.location.reload(false);
  window.location.href = "/";
}

export function NavigateOut() {
  window.location.reload(false);
  window.location.href = "/";
}

export function saveToken(token) {
  window.sessionStorage.removeItem(TOKEN);
  window.sessionStorage.setItem(TOKEN, token);
}

export function getToken() {
  return sessionStorage.getItem(TOKEN);
}

export function saveRefreshToken(refreshToken) {
  window.sessionStorage.removeItem(REFRESHTOKEN);
  window.sessionStorage.setItem(REFRESHTOKEN, refreshToken);
}

export function getRefreshToken() {
  return sessionStorage.getItem(REFRESHTOKEN);
}

export function saveType(Type) {
  window.sessionStorage.removeItem(TYPE);
  window.sessionStorage.setItem(TYPE, Type);
}

export function getType() {
  return sessionStorage.getItem(TYPE);
}
