import styled from "styled-components";

export const Contenedor = styled.div`
  height: 100%;
  width: 100%;
  @media (width < 1500px) {
    background: #ffffff 0% 0% no-repeat padding-box;
  }
`;

export const Titulo = styled.label`
  font-size: 18px;
  font-family: "Manrope ExtraBold";
`;

export const TermsDiv = styled.div`
  height: 500px;
  width: 700px;
  overflow-y: auto;
  margin-top: 10px;
  @media (width < 1000px) {
    width: 100%;
  }
`;
export const ButtonDiv = styled.div`
  float: right;
  padding: 20px;
`;

export const TitleDiv = styled.div`
  padding: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #b7d32d;
  color: white;

  @media (width < 1500px) {
    width: calc(100% - 40px);
  }
`;
