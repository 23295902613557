import {
  DivTitulos,
  DivTitulosSubTitulo,
  DivTitulosTitulo,
} from "../../../communStyled";

export default function Blog() {
  return (
    <DivTitulos>
      <DivTitulosTitulo>BLOGS</DivTitulosTitulo>
      <DivTitulosSubTitulo>INFORMACIÓN GENERAL</DivTitulosSubTitulo>
    </DivTitulos>
  );
}
