import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { AlertNoBackground, Obligatorio, TextLabel } from "../../Pages/communStyled";
import { Filter, FilterContent, InputGrandeDrop, MenuOption } from "./FilterInputStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

interface Props {
  label?: string;
  option: string;
  options: Array<string>;
  disabled?: boolean;
  invalid?: boolean;
  obligatorio?: boolean;
  onValue: (item: string) => void;
  onChange: (item: string) => void;
  onClick?: () => void;
}

const FilterInput = forwardRef(
  (
    {
      label,
      option,
      options,
      disabled=false,
      invalid=false,
      obligatorio=false,
      onValue,
      onChange,
      onClick,
    }: Props,
    Ref
  ) => {
    const [openStatus, setOpenStatus] = useState<boolean>(false);
    const [statusOpen, setStatusOpen] = useState<boolean>(false);
    const [inputTextValue, setInputTextValue] = useState<string>(option);
    const [index, setIndex] = useState<number>(0);

    useImperativeHandle(Ref, () => ({
      resetValue: () => {
        setInputTextValue("");
        setIndex(0);
      },
    }));

    const closeMenu = () => {
      setTimeout(() => {
        setStatusOpen(false);
      }, 200);
    };

    useEffect(() => {
      setOpenStatus(options && statusOpen);
    }, [options, statusOpen]);

    useEffect(() => {
      setInputTextValue(option);
    }, [option]);

    const emitOption = (item: string) => {
      setInputTextValue(item);
      onValue(item);
      setStatusOpen(false);
    };

    const onKeyUp = (event: { key: string }) => {
      setStatusOpen(true);
      if (event.key === "ArrowDown") {
        setIndex(
          (index + 1) >= (options.length - 1) ?  index + 1 : options.length - 1
        );
      }
      if (event.key === "ArrowUp") {
        setIndex((index - 1) <= 0 ? 0 : index - 1);
      }
      if (event.key === "Enter") {
        emitOption(options[index]);
      }
    };

    useEffect(() => {
        onChange(inputTextValue ? inputTextValue.trim() : '');
    }, [inputTextValue]);

    const omitSpecialChar = (event: any) => {
      const k = event.keyCode;
      // Check if the character code is within the allowed range
      if (
        !((k > 64 && k < 91) || // A-Z
        (k > 96 && k < 123) || // a-z
        k === 8 || // Backspace
        k === 32 || // Space
        (k >= 48 && k <= 57) || // 0-9
        k === 241 || // ñ
        k === 209 // Ñ
      )) {
        event.preventDefault(); // Prevent the input of the disallowed character
      }
    };

    return (
      <div>
        {label && (
          <span>
            <TextLabel>
              {label}
              {obligatorio && <Obligatorio>*</Obligatorio>}
            </TextLabel>
            <br></br>
          </span>
        )}
        <Filter onBlur={() => closeMenu()}>
          <InputGrandeDrop
            type="text"
            disabled={disabled}
            onKeyDown={omitSpecialChar}
            onChange={(e: any) => setInputTextValue(e.target.value)}
            onClick={() => onClick && onClick()}
            onKeyUp={onKeyUp}
            value={inputTextValue}
            style={{
              backgroundColor: disabled ? "#E0E1E2" : "#FFFFFF",
              borderBottom: invalid ? "2px solid #E72264" : "1px solid #434169",
            }}
          />
          {invalid && (
            <AlertNoBackground>
              Este campo es requerido
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </AlertNoBackground>
          )}
          {openStatus && (
            <FilterContent>
              {options.map((item: any, i: number) => (
                <MenuOption
                  onClick={() => emitOption(item)}
                  style={
                     { backgroundColor: i === index ? "#EBEBF9" :'#ffffff' }
                  }
                >
                  {item}
                </MenuOption>
              ))}
            </FilterContent>
          )}
        </Filter>
      </div>
    );
  }
);

export default FilterInput;
