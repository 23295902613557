import styled from "styled-components";

export const PaginationDiv = styled.div`
  display: flex;
  margin-top: 10px;
  @media (max-width: 680px) {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  height: calc(100% - 25px);
  border: 1px solid #dededf;
  border-radius: 5px;
  overflow-x: auto;
  background-color: white;
  @media (max-width: 680px) {
    height: calc(100% - 50px);
  }
`;

export const TableDiv = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export const PaginationNumber = styled.input`
  background-color: #ffffff;
  border-radius: 4px;
  padding: 3px;
  border: 0px;
  width: 30px;
  text-align: center;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ButtonTable = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  color: #cfcece;
  &:hover {
    outline: none;
    border: none;
  }
  &:focus {
    outline: none;
    border: none;
  }
`;

export const PaginationIcon = styled.span`
  margin-right: 5px;
  margin-left: 5px;
`;

export const Titles = styled.div`
  display: flex;
  height: 40px;
  background-color: white;
`;

export const FirstColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  max-width: 100px;
`;

export const TitleColumns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 auto;
`;

export const TextLabelRowTitle = styled.span`
  padding: 12px 12px 12px 0;
  display: inline-block;
  font-family: "Lato";
  font-weight: bold;
  font-size: 16px;
  color: black;
`;

export const DivIconsTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #cfcece;
  margin-right: 15px;
  cursor: pointer;
`;

export const TableRowContainer = styled.div`
  overflow: auto;
  height: calc(100% - 40px);
  width: fit-content;
  min-width: 100%;
  overflow-x: hidden;
  background-color: white;
`;

interface GenericRowInterface {
  showhover?: string;
  inactivo?: string;
}

export const GenericRow = styled.div<GenericRowInterface>`
  display: flex;
  height: 50px;
  align-items: center;
  border-top: 1px solid #e0e1e2;
  justify-content: flex-start;
  background-color: ${(props) =>
    props.inactivo === "true" ? "#f7f7f7" : "white"};
  &:hover {
    background-color: ${(props) =>
      props.showhover === "true"
        ? "#e9ffee"
        : props.inactivo === "true"
        ? "#f7f7f7"
        : "white"};
  }
`;

export const ContainerTable = styled.label`
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 23px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

export const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const GenericColumn = styled.div`
  display: flex;
  align-items: center;
  color: #707070;
  flex: 1 0 auto;
`;

export const TextLabelRow = styled.label`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 6px;
`;
