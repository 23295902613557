import styled from "styled-components";

export const LoginContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  display: flex;
  flex: 1;
  height: fit-content;
  min-height: 100vh;
  background-color: #262a33;
`;

export const ContenedorDeCartaYLogo = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  @media (max-width: 1300px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: 0px;
  }
`;

export const ContenedorLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 600px;
  @media (max-width: 1300px) {
    margin-bottom: 56px;
    width: 100%;
  }
`;

export const ContenedorCarta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  box-shadow: 1px 1px 3px #00000033;
`;

export const FormClass = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  align-content: center;
  height: fit-content;
  background-color: #ffffff;
  padding: 20px;
  min-height: 307px;
  @media (max-width: 1300px) {
    width: unset;
    max-width: 600px;
  }
`;

export const TitleFormDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContenedorDeContenedor = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex: 1;
  flex-direction: column;
`;

export const BotonesCarta = styled.div`
  color: white;
  text-align: center;
  padding: 23px;
  background-color: #b7d32d;
  font-size: 21px;
`;

export const TitleForm = styled.div`
  font-size: 18px;
  font-family: "Manrope ExtraBold";
  color: #434169;
`;

export const Logo = styled.img`
  width: 160px;
`;

export const Titulo = styled.label`
  font-size: 28px;
  font-family: "Manrope ExtraBold";
  text-align: center;
`;

export const SubTitlo = styled.label`
  font-size: 18px;
  text-align: center;
  margin-top: 14px;
  padding: 0px 50px;
`;

export const ButtonEye = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  color: gray;
`;

export const IniciarHyperLinkDIV = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  width: 100%;
`;

export const HyperlinkIniciar = styled.label`
  margin-left: 5px;
  font-family: "Manrope Regular";
  font-size: 14px;
  color: #2185d0;
  text-decoration: underline #2185d0;
  cursor: pointer;
  padding-top: 12px;
  display: inline-block;
  width: 120px;
  text-align: center;
`;

export const ButtonDiv = styled.div`
  float: right;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;

export const CheckboxHipperLinkContainer = styled.div`
  margin-top: 16px;
  font-size: 12px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
`;

export const TextLabelMin = styled.label`
  font-family: "Manrope Regular";
  font-size: 12px;
  color: #434169;
  height: 22px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
`;

export const TextLabelCheckBox = styled.label`
  margin-left: 4px;
  font-family: "Manrope Regular";
  font-size: 12px;
  color: #2185d0;
  text-decoration: underline #2185d0;
  cursor: pointer;
`;

export const ContenedorConLinea = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1011px;
  width: unset;
  @media (max-width: 680px) {
    width: 100%;
  }
`;

export const ButtonControlCuentaDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  @media (max-width: 680px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;

export const CartaEmpresas = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 3px #00000033;
  opacity: 1;
  width: 935px;
  padding: 23px 3px 35px 30px;
  color: #434169;
  @media (max-width: 1050px) {
    width: fit-content;
    width: 100%;
    background-color: #262a33;
    box-shadow: unset;
    flex-direction: column;
    padding: 0px;
  }
`;

export const EmpresasbuttonsDiv = styled.div`
  height: 516px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (max-width: 1050px) {
    height: fit-content;
    margin-top: 25px;
    margin-bottom: 30px;
    background-color: white;
    padding: 20px;
  }
`;

export const DivUserFoto = styled.div`
  width: 300px;
  height: 175px;
  background-color: #262a33;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
  opacity: 1;
`;

export const Inputfile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  &:focus + label,
  & + label:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }
`;

export const MarginTable = styled.div`
  margin-right: 34px;
  @media (max-width: 1050px) {
    margin-right: 0px;
    padding: 20px;
    background-color: white;
  }
`;

export const ButtonColumDiv = styled.div`
  height: 315px;
  overflow-y: scroll;
  padding: 20px;
  overflow-x: hidden;
`;

export const InputGrande = styled.input`
  width: calc(100% - 15px);
  font-size: 16px;
  font-family: "Manrope Regular";
  border-radius: 0px;
  padding: 6px;
  color: #475259;
  border: 0px solid #ccc;
  resize: vertical;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #434169;
  &:focus {
    border-bottom: 2px solid #5d60cb;
  }
`;

export const Alert = styled.p`
  font-family: "Manrope Regular";
  color: #db282899;
  font-size: 12px;
  margin: 0px;
  margin-top: 5px;
`;
