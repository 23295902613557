import {merge} from '../../Utils/sharedFunctions'
const empresa = (
    state = {
        info: {
          nombre: '',
          logo: '',
          creador: '',
          pageID: '',
        },
        inicio: {
          notificaciones: [],
          vigencia: '',
          prorogas: 3,
          alertasV: [],
          alertasA: [],
          alertasR: [],
          colaboradores: [],
          log: [],
        },
        empresa: {
          informacionDeLaEmpresaOPatron: {
            domicilioFiscal: {
              calle: '',
              codigoPostal: null,
              colonia: '',
              numero: '',
              telefono: '',
              estado: '',
              municipio: '',
            },
            nombreYRFC: {
              apoderado: '',
              curp: '',
              nombre: '',
              primerApellido: '',
              segundoApellido: '',
              denominacion: '',
              regimenCapital: '',
              personaMoralCheckBox: true,
              rfc: '',
              regimenFiscal: ""
            },
            principalActividad: {
              principalActividadTextArea: ''
            }
          },
          esquemaDeNomina: {
            configuracion: {
              semanalCheckBox: false,
              semanalPrimeraVez: false,
              periodoInicialSemanal: null,
              decenalCheckBox: false,
              decenalPrimeraVez: false,
              periodoInicialDecenal: null,
              factorDePagoDecenal: '',
              catorcenalCheckBox: false,
              catorcenalPrimeraVez: false,
              periodoInicialCatorcenal: null,
              quincenalCheckBox: false,
              quincenalPrimeraVez: false,
              periodoInicialQuincenal: null,
              factorDePagoQuincenal: ''
            },
            cuentasBancariasParaPagoDeNomina: {
              cuentasBancarias: []
            }
          },
          registroEstatalYDelIMSS: {
            registros: []
          },
          horariosYControlDeAsistencia: {
            tiempoExtraordinario: {
              pagarTiempoExtraordinario: true,
              minutosDeMargenTiempoExtra: 0
            },
            duracionDeLaJornada: {
              diurna: '08:00',
              mixto: '07:30',
              nocturna: '07:00'
            },
            controlDeAsistencia: {
              entrada: 0,
              salida: 0,
              llenadoDeAsistencia: 'Automatico',
            },
            horarios: [],
            secuencias: [],
          },
          prestacionesGenerales: {
            primaVacacional: {
              porcentajeDePrimaVacacional: 25,
              descontarFaltas: false,
              diasDeVacaciones: 'Segun la Ley',
              pagarPrimaAlCumplirAñosDeServicio: false,
              descontarIncapacidadesPorEnfermedad: false,
              descontarIncapacidadesPorRiesgo: false,
              incluirOtrasPrestaciones: false,
              conceptosAdicionalesParaPrimaVacacional: [
                { concepto: 'FA99: PRESTAMO FONDO DE AHORRO', estado: false },
                { concepto: 'PP13: COMISIONES', estado: false },
                { concepto: 'PP16: DESPENSA', estado: false },
                { concepto: 'PP17: PREMIO DE ASISTENCIA', estado: false },
                { concepto: 'PP18: PREMIO DE PUNTUALIDAD', estado: false },
                { concepto: 'PP22: FONDO DE AHORRO (LIQUIDACION)', estado: false },
              ],
              tablaDeDiasDeVacaiones: []
            },
            aguinaldo: {
              tipoDeAguinaldo: 'Fijo',
              diasDeAguinaldo: 15,
              tablaDeAguinaldo: [],
              proximoPago: '',
              permitirPagos: false,
              numeroDePagos: 0,
              procesoDeNominaPorSeparado: true,
              descontarFaltas: false,
              descontarIncapacidadesPorEnfermedad: false,
              descontarIncapacidadesPorRiesgo: false,
              periodoParaAguinaldo: 'Enero 1 - Diciembre 31',
              incluirOtrasPrestaciones: false,
              conceptosAdicionalesParaAguinaldo: [
                { concepto: 'FA99: PRESTAMO FONDO DE AHORRO', estado: false },
                { concepto: 'PP13: COMISIONES', estado: false },
                { concepto: 'PP16: DESPENSA', estado: false },
                { concepto: 'PP17: PREMIO DE ASISTENCIA', estado: false },
                { concepto: 'PP18: PREMIO DE PUNTUALIDAD', estado: false },
                { concepto: 'PP22: FONDO DE AHORRO (LIQUIDACION)', estado: false },
                { concepto: 'PP24: DEVOLUCION DEL I.S.R', estado: false },
                { concepto: 'PP25: FONDO DE AHORRO (PATRON)', estado: false },
                { concepto: 'PP26: INDEMINZACIONES O GRATIFICACIONES', estado: false }
              ]
            },
            diasDeDescansoYVacaciones: {
              porcentajeDePrimaDominical: 25,
              pagarPrimaDominicalEnDescansoLaborado: false,
              diasDeDescansoYVacaciones: [
              ],
              conceptosAdicionalesParaPrimaDominical: [
                { concepto: 'FA99: PRESTAMO FONDO DE AHORRO', estado: false },
                { concepto: 'PP13: COMISIONES', estado: false },
                { concepto: 'PP16: DESPENSA', estado: false },
                { concepto: 'PP17: PREMIO DE ASISTENCIA', estado: false },
                { concepto: 'PP18: PREMIO DE PUNTUALIDAD', estado: false },
                { concepto: 'PP22: FONDO DE AHORRO (LIQUIDACION)', estado: false },
                { concepto: 'PP24: DEVOLUCION DEL I.S.R', estado: false },
                { concepto: 'PP25: FONDO DE AHORRO (PATRON)', estado: false },
                { concepto: 'PP26: INDEMINZACIONES O GRATIFICACIONES', estado: false }
              ],
            }
          },
          parametrosDeOperacion: {
            generacionAutomaticaDeDocumentos: {
              contratos: true,
              // addendumsAContratos: false,
              constanciaDeVacaciones: true,
              // credenciales: false,
              descuentoAlSalario: 30,
              calculoDeImpuestos: ''
            },
            parametrosAdicionales: {
              incluirEnFondoDeAhorro: false,
              ajusteDeCentavos: false,
              insertarDPA: true,
              // rellenarNumeroDeNomina: '',
            },
          },
          registroDeDepartamentos: {
            departamentos: []
          }
    
        },
        trabajadores: {
          controlDePersonal: {
            trabajadores: []
          },
          registroDePuestos: {
            puestos: []
          }
        },
        nomina: {
          conceptos: {
            percepciones: {
              predefinidas: [],
              adicionales: [],
            },
            deducciones: {
              predefinidas: [],
              adicionales: [],
            },
          },
        },
      },
    action
  ) => {
    switch (action.type) {
      case "REPLACEEMPRESA":
        const newState = merge(state, action.newEmpresa);
        return newState;
      default:
        return state;
    }
  };
  
  export default empresa;
  