import { useEffect, useRef, useState } from "react";
import { ButtonPrimario, ButtonSecundario, Contenedor, ContenedorBotones, ContenedorBotonesExterior, ContenedorSection, DivTitulos, DivTitulosSubTitulo, DivTitulosTitulo, InputGrande, LabelEliminar, Obligatorio, PEliminar, TextAreaGrande, TextLabel, TextLabelTitle } from "../../../communStyled";
import { SearchDropdown, SortingTable, Warning } from "../../../../Components";
import { Subject } from "rxjs";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { getRandomID, scrollIntoView } from "../../../../Utils/sharedFunctions";
import { apiRequest, apiRoutes } from "../../../../Api/services";
import actions from "../../../../Redux/Actions";

export default function RegistroDePuestos() {
  const alertRef = useRef(null);
  const dispatch = useDispatch();
  const empresa: any = useSelector((store: any) => store.empresa);
  const currentEmpresa: any = useSelector((store: any) => store.currentEmpresa);
  const messageSubject = new Subject<string>();
  const successfulSubject = new Subject<string>();
  const [puestosCopyArray, setPuestosCopyArray] = useState(empresa.trabajadores.registroDePuestos.puestos);
  const [nombreDePuesto, setNombreDePuesto] = useState("");
  const [descripcionDeFunciones, setDescripcionDeFunciones] = useState("");
  const [selectedPuesto, setSelectedPuesto] = useState<any>(null);

  const columnArray = [
    { label: 'Puesto', valueLabel: 'nombreDePuesto', Filtrable: true, widthPX: 300, type: 'String' },
  ];

  const generateNewID = () => {
    const id = getRandomID();
    const puestos = empresa.trabajadores.registroDePuestos.puestos.map((departamento: {
      id: string;
      nombreDePuesto: string;
      IMSSorEstatal: string;
      dependeDe: string;
    }) => {
      return departamento.id
    })
    if (puestos.includes(id)) {
      generateNewID()
    } else {
      return id
    }
  }

  const submitForm = async () => {
    let newPuestos = []
    if(selectedPuesto){
      newPuestos = empresa.trabajadores.registroDePuestos.puestos.map((puesto: any) => {
        if(puesto.id === selectedPuesto.id){
          return {
            ...puesto,
            nombreDePuesto,
            descripcionDeFunciones
          }
        }else{
          return puesto
        }
      })
    } else {
      const newID = generateNewID()
      newPuestos = [
        ...empresa.trabajadores.registroDePuestos.puestos,
        {
          nombreDePuesto,
          descripcionDeFunciones,
          id: newID
        }
      ]
    }
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.updateInfo,
      data: {
        area: "registroDePuestos",
        data: {
          ...empresa.trabajadores.registroDePuestos,
          puestos: newPuestos
        },
        id: currentEmpresa.id,
        modulo: "trabajadores",
      },
    });

    if (error) {
      messageSubject.next("Hubo un error en el servidor");
      scrollIntoView(alertRef);
    }
    if (res) {
      if (res.message !== "Proceed") {
        messageSubject.next(res.message);
        scrollIntoView(alertRef);
      } else {
        scrollIntoView(alertRef);
        dispatch(
          actions.empresaActions.replaceEmpresa({
            ...empresa,
            trabajadores: {
              ...empresa.trabajadores,
              registroDePuestos: {
                ...empresa.trabajadores.registroDePuestos,
                puestos: newPuestos
              },
            },
          })
        );
        setPuestosCopyArray([...newPuestos]);
        setNombreDePuesto("");
        setDescripcionDeFunciones("");
        successfulSubject.next(
          "La Informacion se agrego correctamente a la base de datos"
        );
      }
    }
  };

  const cancel = () => {
    setNombreDePuesto("");
    setDescripcionDeFunciones("");
  };

  const onValueSearchDropDownPuestos = (value: {
    searchValue: string;
    filter: { valueLabel: string };
  }) => {
    if (value.searchValue !== "") {
      setPuestosCopyArray(
        empresa.trabajadores.registroDePuestos.puestos.filter((row: any) => {
          return row[value.filter.valueLabel]
            .toString()
            .toUpperCase()
            .includes(value.searchValue.toString().toUpperCase());
        })
      );
    } else {
      setPuestosCopyArray([...empresa.trabajadores.registroDePuestos.puestos]);
    }
  };

  const eliminarPuesto = async () => {
    const newPuestos = [...empresa.trabajadores.registroDePuestos.puestos.filter((departamento: any) => departamento.id !== selectedPuesto.id)];
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.updateInfo,
      data: {
        area: "registroDePuestos",
        data: {
          ...empresa.trabajadores.registroDePuestos,
          puestos: newPuestos
        },
        id: currentEmpresa.id,
        modulo: "trabajadores",
      },
    });

    if (error) {
      messageSubject.next("Hubo un error en el servidor");
      scrollIntoView(alertRef);
    }
    if (res) {
      if (res.message !== "Proceed") {
        messageSubject.next(res.message);
        scrollIntoView(alertRef);
      } else {
        scrollIntoView(alertRef);
        dispatch(
          actions.empresaActions.replaceEmpresa({
            ...empresa,
            trabajadores: {
              ...empresa.trabajadores,
              registroDePuestos: {
                ...empresa.trabajadores.registroDePuestos,
                puestos: newPuestos
              },
            },
          })
        );
        setPuestosCopyArray([...newPuestos]);
        successfulSubject.next(
          "La Informacion se agrego correctamente a la base de datos"
        );
      }
    }
  }

  useEffect(() => {
    setNombreDePuesto(selectedPuesto ? selectedPuesto.nombreDePuesto : "");
    setDescripcionDeFunciones(selectedPuesto ? selectedPuesto.descripcionDeFunciones : "");
  }, [selectedPuesto]);

  return (
    <div>
      <DivTitulos>
        <DivTitulosTitulo>TRABAJADORES</DivTitulosTitulo>
        <DivTitulosSubTitulo>
          REGISTRO DE PUESTOS
        </DivTitulosSubTitulo>
      </DivTitulos>
      <div ref={alertRef} style={{margin: '0px 35px'}}>
        <Warning
          type={"Alert"}
          title={"Alerta"}
          warningSubject={messageSubject}
        />
        <Warning
          type={"Successful"}
          title={"Exito!"}
          warningSubject={successfulSubject}
        />
      </div>
      <Contenedor>
        <ContenedorSection>
          <TextLabelTitle>LISTA DE PUESTOS</TextLabelTitle>
          <SearchDropdown
            onValue={onValueSearchDropDownPuestos}
            options={columnArray}
          />
          <SortingTable
            multiselect={false}
            rowsArray={puestosCopyArray}
            columnsArray={columnArray}
            tableHeight="395px"
            onSelectElement={(element: any) => setSelectedPuesto(element)}
          />
        </ContenedorSection>
        <ContenedorSection>
          <TextLabelTitle>DESCRIPCION DEL DEPARTAMENTO</TextLabelTitle>
          {selectedPuesto && (
            <PEliminar onClick={() => eliminarPuesto()}>
              <LabelEliminar>Eliminar departamento</LabelEliminar>
              <FontAwesomeIcon icon={faTrashAlt} />
            </PEliminar>
          )}
          <TextLabel>Nombre del Puesto<Obligatorio>*</Obligatorio></TextLabel>
          <InputGrande
            type="text"
            value={nombreDePuesto}
            onChange={(e) => setNombreDePuesto(e.target.value)}
          />
          <TextLabel>Descripción de funciones</TextLabel>
          <TextAreaGrande
            value={descripcionDeFunciones}
            onChange={(e: any) =>
              setDescripcionDeFunciones(e.target.value)
            }
          />

        </ContenedorSection>
      </Contenedor>
      <ContenedorBotonesExterior>
        <ContenedorBotones>
          <ButtonPrimario onClick={() => submitForm()}>Aceptar</ButtonPrimario>
          <ButtonSecundario onClick={() => cancel()}>Cancelar</ButtonSecundario>
        </ContenedorBotones>
      </ContenedorBotonesExterior>
    </div>
  )
}
