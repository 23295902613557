import styled from "styled-components";

export const IDDiv = styled.span`
  float: right;
`;

export const IDLabel = styled.span`
  font-weight: bold;
`;

export const ChartsContainer = styled.div`
display: flex;
justify-content: space-around;
padding: 20px 0px;
`;
