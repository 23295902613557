import styled from "styled-components";

export const ContenedorSteps = styled.div`
display: flex;
justify-content: center;
`;

interface StepInterface {
  activeclass?: boolean;
}

export const Step = styled.div<StepInterface>`
    color: ${(props) => (props.activeclass ? "#FFFFFF" : "#000000DE")};
    background-color: ${(props) => (props.activeclass ? "#B7D32D" : "#FFFFFF")};
    padding: 10px 20px;
    border: 1px solid #DEDFDF;
    border-radius: 4px 0px 0px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: 'Lato';
    font-weight: bold;
`;

export const ContenedorNumeroDeNomina = styled.div<StepInterface>`
    display: flex;
    justify-content: flex-start;
    margin: 20px 45px;
    width: calc(100% - 90px);
    @media (max-width: 1000px) {
      width: calc(100% - 40px);
      margin: 20px 20px;
        flex-direction: column;
      }
`;

export const InputGrandeNomina = styled.input`
  width: 130px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background: #ffffff 0% 0% no-repeat padding-box;
  &:disabled {
    background-color: #f3f3f3;
  }
  @media (max-width: 1000px) {
    width: calc(100% - 22px);
  }
  `;

export const TextLabelNomina = styled.label`
  padding: 16px 0px;
  display: inline-block;
  font-family: "Manrope Regular";
  color: #475259;
  width: 110px;
  text-align: left;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const Inputfile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  &:focus + label,
  & + label:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }
`;

export const DivUserFoto = styled.div`
  width: 200px;
  height: 200px;
  background-color: antiquewhite;
  margin-top: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; 
  border: 1px solid #CFDAE6;
  border-radius: 5px;
  opacity: 1;
`;

export const DivFotoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const DivNominaTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  @media (max-width: 1375px) {
    flex-direction: column;
  }
  `;

export const DivNominaTopContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1375px) {
    width: calc(100% - 80px);
    gap: 10px;
  }
  @media (max-width: 1000px) {
    width: calc(100% - 70px);
  }
`;

export const DivNominaTopRight = styled.div`
  width: calc(40% - 55px);
  gap: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1375px) {
    width: 100%;
  }
`;

export const DivNominaTopLeft = styled.div`
  width: calc(60% - 55px);
  @media (max-width: 1375px) {
    width: 100%;
  }
`;

export const SiglasDiv = styled.div`
  display: flex;
  gap: 10px;
`;

export const ContenedorSectionInner = styled.div`
  margin: 0px;
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  width: calc(100% - 32px);
  @media (max-width: 1375px) {
    position: relative;
    height: auto;
    margin-right: 45px;
    margin-left: 45px;
    width: calc(100vw - 412px);
  }
  @media (max-width: 1000px) {
    width: calc(100vw - 70px);
    margin-right: 16px;
    margin-left: 16px;
  }
  `;

export const NominaContenedorTabs = styled.div`
  margin-left: 45px;
  margin-top: 20px;
  @media (max-width: 1000px) {
    margin-right: 16px;
    margin-left: 16px;
  }
  `;

export const ContenedorNominaInferior = styled.div`
  width: 728px;
  margin: 16px;
  padding: 16px;
  height: fit-content;
  position: relative;
  margin-top: 0px;
  margin-left: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  border-top-left-radius: 0px;
  z-index: 2;
  border-radius: 0 5px 5px 5px;
  box-shadow: 0 -2px 2px -2px rgb(0 0 0 / 50%);
  border-top: none;
  @media (max-width: 1900px) {
    width: calc(100vw - 412px);
    margin-right: 45px;
  }
  @media (max-width: 1000px) {
    width: calc(100vw - 70px);
  }
`;

export const SideButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    column-gap: 10px;
`;

export const Titulo = styled.label`
  font-size: 18px;
  font-family: "Manrope ExtraBold";
  font-weight: bold;
`;

export const ButtonDiv = styled.div`
  float: right;
  padding: 20px;
`;

export const TopContainer = styled.div`
  margin: 16px;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  margin: 20px 45px;
  width: calc(100% - 90px);
`;

export const TopArrowsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;