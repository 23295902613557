import { useEffect, useRef, useState } from "react";
import { ContenedorPadding, DivTitulos, DivTitulosSubTitulo, DivTitulosTitulo, FlexInlineMarginTextLabel, Button, } from "../../../communStyled";
import { ButtonDropdown, Checkbox, ConfirmationModal, SearchDropdown, SortingTable, Warning } from "../../../../Components";
import { Subject } from "rxjs";
import { useDispatch, useSelector } from "react-redux";
import { ContenedorD1, ContenedorD2, ContenedorD3, ContenedorD4 } from "./ControlDePersonalStyled";
import paths from "../../../../paths";
import { useNavigate } from "react-router-dom";
import actions from "../../../../Redux/Actions";
import { deepCopy, scrollIntoView } from "../../../../Utils/sharedFunctions";
import { apiRequest } from "../../../../Api/instance";
import { apiRoutes } from "../../../../Api/services";

export default function ControlDePersonal() {
  const alertRef = useRef(null);
  const history = useNavigate();
  const dispatch = useDispatch();
  const empresa: any = useSelector((store: any) => store.empresa);
  const currentEmpresa: any = useSelector((store: any) => store.currentEmpresa);
  const messageSubject = new Subject<string>();
  const successfulSubject = new Subject<string>();
  const [rowsArrayCopy, setRowsArrayCopy] = useState(empresa.trabajadores.controlDePersonal.trabajadores);
  const [selectedTrabajador, setSelectedTrabajador] = useState<any>(null);
  const [ocultarTrabajadoresInactivos, setOcultarTrabajadoresInactivos] = useState(true);
  const [modalBajaDeEmpleadoOpen, setModalBajaDeEmpleadoOpen] = useState(false);

  const columnsArray = [
    { label: 'Nomina', valueLabel: 'nomina', filtrable: true, widthPX: 150, type: 'Number' },
    { label: 'Nombre', valueLabel: 'nombre', filtrable: true, widthPX: 380, type: 'String' },
    { label: 'Puesto', valueLabel: 'puesto', filtrable: true, widthPX: 300, type: 'String' },
    { label: 'Departamento', valueLabel: 'departamento', filtrable: true, widthPX: 300, type: 'String' },
    { label: 'Status', valueLabel: 'status', filtrable: true, widthPX: 300, type: 'String' },
  ];

  const getPuesto = (puesto: any) => {
    const puestos = empresa.trabajadores.registroDePuestos.puestos.filter((registro: any) => registro.id === puesto || registro.nombreDePuesto === puesto);
    return puestos[0].nombreDePuesto
  }

  const getDepartamento = (departamento : any) => {
    const departamentos = empresa.empresa.registroDeDepartamentos.departamentos.filter((registro: any) => registro.id === departamento || registro.nombreDeDepartamento === departamento);
    return departamentos[0].nombreDeDepartamento
  }

  useEffect(() => {
    setRowsArrayCopy(empresa.trabajadores.controlDePersonal.trabajadores.map((trabajador: any) => {
      return {
        nomina: trabajador.info.numeroDeNomina,
        nombre: trabajador.datosPersonales.datosPersonales.nombre + ' ' + trabajador.datosPersonales.datosPersonales.apellidoPaterno + ' ' + trabajador.datosPersonales.datosPersonales.apellidoMaterno,
        puesto: getPuesto(trabajador.contrato.puestoActual),
        departamento: getDepartamento(trabajador.contrato.departamento),
        inactivo: trabajador.info.status !== "ACTIVO",
        status: trabajador.info.status,
        id: trabajador.info.id,
        data: trabajador
      }
    }));
  }, [empresa.trabajadores.controlDePersonal.trabajadores]);

  const onValueSearchDropDown = (value: string) => {
    const rowsArray = empresa.trabajadores.controlDePersonal.trabajadores.filter((row: any) => {
      return row[value].toString().toLowerCase().includes(value.toLowerCase())
    })
    setRowsArrayCopy(rowsArray)
  }

  const verTrabajador = () => {
    dispatch(
      actions.currentTrabajador.replaceCurrentTrabajador(selectedTrabajador ? selectedTrabajador.data : null)
    );
    history(paths.NuevoEmpleado);
  }

  const accionesPersonalLiteral: any = {
    'Conceptos de Nómina': () => { console.log('Conceptos de Nómina', selectedTrabajador) },
    'Control de Asistencia': () => { moveToAcctionPage('ControlDeAsistencia') },
    'Acumulados': () => { console.log('Acumulados', selectedTrabajador) },
    'Credito INFONAVIT': () => { console.log('Credito INFONAVIT', selectedTrabajador) },
    'Incapacidades': () => { console.log('Incapacidades', selectedTrabajador) },
    'Baja de Empleado': () => { bajaDeEmpleado() },
  }

  const bajaDeEmpleado = () => {
    setModalBajaDeEmpleadoOpen(true);
  }

  const moveToAcctionPage = (page: string) => {
    dispatch(
      actions.currentTrabajador.replaceCurrentTrabajador(selectedTrabajador ? selectedTrabajador.data : null)
    );
    history(paths.ControldeAsistencia);
  }

  const bajaDeEmpleadoConfirmada = async () => {
      if (selectedTrabajador) {
        const newTrabajadorToSend = deepCopy(selectedTrabajador.data);
        newTrabajadorToSend.info.status = 'INACTIVO';
        newTrabajadorToSend.contrato.fechaDeIngreso = null;
        newTrabajadorToSend.contrato.fechaDeReconocimientoDeAntiguedad = null;
        newTrabajadorToSend.contrato.historialDeBajasYReIngresos.push({ typo: 'Baja', fecha: new Date() });
        const [error, res] = await apiRequest({
          method: "post",
          url: apiRoutes.editarTrabajador,
          data: {
            trabajador: newTrabajadorToSend,
            id: currentEmpresa.id,
          },
        });
    
        if (error) {
          messageSubject.next("Hubo un error en el servidor");
          scrollIntoView(alertRef);
        }
        if (res) {
          if (res.message !== "Proceed") {
            messageSubject.next(res.message);
            scrollIntoView(alertRef);
          } else {
            messageSubject.next('');
            successfulSubject.next('La Informacion se agrego correctamente a la base de datos');
            dispatch(
              actions.currentTrabajador.replaceCurrentTrabajador(null)
            );
            const newTrabadores = empresa.trabajadores.controlDePersonal.trabajadores.map((trabajador: any) => {
              if (trabajador.info.numeroDeNomina === res.trabajador.info.numeroDeNomina) {
                return res.trabajador;
              }
              return trabajador;
            });
            dispatch(
              actions.empresaActions.replaceEmpresa({
                ...empresa,
                trabajadores: {
                  ...empresa.trabajadores,
                  controlDePersonal: {
                    ...empresa.trabajadores.controlDePersonal,
                    trabajadores: [...newTrabadores]
                  }
                }
              }));
            history(paths.ControlDePersonal);
          }
        }
      }
      setModalBajaDeEmpleadoOpen(false);
    }
  return (
    <div>
      <DivTitulos>
        <DivTitulosTitulo>TRABAJADORES</DivTitulosTitulo>
        <DivTitulosSubTitulo>
          CONTROL DE PERSONAL
        </DivTitulosSubTitulo>
      </DivTitulos>
      <div ref={alertRef} style={{margin: '0px 35px'}}>
        <Warning
          type={"Alert"}
          title={"Alerta"}
          warningSubject={messageSubject}
        />
        <Warning
          type={"Successful"}
          title={"Exito!"}
          warningSubject={successfulSubject}
        />
      </div>
      <ContenedorPadding>

        <ContenedorD1>
          <ContenedorD2>
            <SearchDropdown
              onValue={onValueSearchDropDown}
              options={columnsArray}
            />
          </ContenedorD2>
          <ContenedorD3>
            <ContenedorD4>
              <Button
                expand="true"
                style={{
                  height: "40px",
                  padding: "10px 32px",
                  fontSize: "14px",
                }}
                onClick={() => history(paths.NuevoEmpleado)}
              >
                Nuevo Empleado
              </Button>
              <Button
                disabled={!selectedTrabajador}
                expand="true"
                secundario="true"
                style={{
                  height: "40px",
                  padding: "10px 32px",
                  fontSize: "14px",
                }}
                onClick={() => verTrabajador()}
              >
                Ver
              </Button>
            </ContenedorD4>
            <ButtonDropdown
              disabled={!selectedTrabajador}
              label={"Acciones"}
              onValue={(value: any) => accionesPersonalLiteral[value.label]()}
              options={[
                { label: 'Conceptos de Nómina' },
                { label: 'Control de Asistencia' },
                { label: 'Acumulados' },
                { label: 'Credito INFONAVIT' },
                { label: 'Incapacidades' },
                { label: 'Baja de Empleado', color: '#ff0000' },
              ]}
            />
          </ContenedorD3>
        </ContenedorD1>
        <SortingTable
          multiselect={false}
          rowsArray={rowsArrayCopy}
          columnsArray={columnsArray}
          onSelectElement={(element: any) => setSelectedTrabajador(element)}
          showHidden={!ocultarTrabajadoresInactivos}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <label>
            <Checkbox
              checked={ocultarTrabajadoresInactivos}
              handleClick={(value: boolean) =>
                setOcultarTrabajadoresInactivos(value)
              }
            />
            <FlexInlineMarginTextLabel>
              Ocultar trabajadores inactivos
            </FlexInlineMarginTextLabel>
          </label>
        </div>
      </ContenedorPadding>
      <ConfirmationModal
        title={"¿Estas seguro que deseas dar de baja al trabajador?"}
        text={
          "Se agregara una baja del sistema al trabajador, siempre podras consultar su registro"
        }
        show={modalBajaDeEmpleadoOpen}
        redButton={true}
        onCancel={() => setModalBajaDeEmpleadoOpen(false)}
        onAccept={() => bajaDeEmpleadoConfirmada()}
      />
    </div>
  )
}
