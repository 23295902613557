import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useViewPortSize from "../../Hooks/useViewPortSize";
import {
  Button,
  Horizontal,
  InputGrande,
  Obligatorio,
  TextLabel,
} from "../../Pages/communStyled";
import NumericMaxlengthInput from "../NumericMaxlengthInput";
import { apiRequest, apiRoutes } from "../../Api/services";
import { Subject } from "rxjs";
import PhoneInput from "../PhoneInput";
import Warning from "../Warning";
import Dropdown from "../Dropdown";
import { CodigoPostalContainer } from "./DomicilioFiscalStyled";

interface Props {
  obligatorio?: boolean;
  onValuesChange: (data: any) => void;
  formValues: any;
}

const DomicilioFiscal = forwardRef(
  ({ obligatorio, onValuesChange, formValues }: Props, ref) => {
    useImperativeHandle(ref, () => ({
      patchValuesFunction,
      cleanSubjects,
      setCPValid,
    }));
    const { smallViewPort } = useViewPortSize();
    const alertSubject = new Subject<string>();
    const warningSubject = new Subject<string>();
    const [codigoPostal, setCodigoPostal] = useState<string>(formValues.codigoPostal || "");
    const [colonias, setColonias] = useState<string[]>([]);
    const [municipio, setMunicipio] = useState<string>(formValues.municipio || "");
    const [estado, setEstado] = useState<string>(formValues.estado || "");
    const [colonia, setColonia] = useState<string>(formValues.colonia || "");
    const [calle, setCalle] = useState<string>(formValues.calle || "");
    const [numero, setNumero] = useState<string>(formValues.numero || "");
    const [telefono, setTelefono] = useState<string>(formValues.telefono || "");
    const [CPValid, setCPValid] = useState<boolean>(false);
    const [disableFields, setDisableFields] = useState<boolean>(false);
    const [justPatched, setJustPatched] = useState<boolean>(true);

    const cleanSubjects = async () => {
      alertSubject.next("");
      warningSubject.next("");
    };

    const patchValuesFunction = (values: any) => {
      patchValues(values);
    };

    useEffect(() => {
      if(formValues.codigoPostal !== codigoPostal || formValues.municipio !== municipio || formValues.estado !== estado || formValues.colonia !== colonia || formValues.calle !== calle || formValues.numero !== numero || formValues.telefono !== telefono || formValues.codigoPostal !== codigoPostal){ 
      !justPatched && onValuesChange({
        municipio: municipio || "",
        estado: estado || "",
        colonia: colonia || "",
        calle: calle || "",
        numero: numero || "",
        telefono: telefono || "",
        codigoPostal: codigoPostal || "",
      });
    }
    }, [municipio, estado, colonia, calle, numero, telefono]);

    useEffect(() => {
      setCPValid(!!(codigoPostal?.length === 5 && municipio && estado));
    }, [codigoPostal]);

    const patchValues = (values: any) => {
      setJustPatched(true);
      values.codigoPostal !== codigoPostal && setCodigoPostal(values.codigoPostal);
      values.municipio !== municipio && setMunicipio(values.municipio);
      values.estado !== estado && setEstado(values.estado);
      values.colonia !== colonia && setColonia(values.colonia);
      values.calle !== calle && setCalle(values.calle);
      values.numero !== numero && setNumero(values.numero);
      values.telefono !== telefono && setTelefono(values.telefono);
    };

    useEffect(() => {
      if (justPatched) {
        setTimeout(() => {
          setJustPatched(false);
        }, 500);
      }
    }, [justPatched]);

    const codigoPostalApoderadoIsInvalid = async () => {
      alertSubject.next("");
      warningSubject.next("");
      if (codigoPostal && codigoPostal !== "") {
        const [error, info] = await apiRequest({
          method: "get",
          url:
            apiRoutes.getCPInfo +
            (codigoPostal ? codigoPostal.substring(0, 5) : null),
        });
        if (error) {
          patchValues({
            municipio: "",
            estado: "",
            colonia: "",
            calle: "",
            numero: "",
            telefono: "",
          });
          setColonias([]);
          setCPValid(codigoPostal.length === 5);
          codigoPostal.length !== 5
            ? alertSubject.next("El Codigo Postal No Es Valido")
            : warningSubject.next("No fue posible validar el codigo postal");
          setDisableFields(false);
        }
        if (info) {
          if (codigoPostal.length === 5 && !info.municipio && !info.estado) {
            setMunicipio("");
            setEstado("");
            setColonia("");
            setCalle("");
            setNumero("");
            setTelefono("");
            setColonias([]);
            warningSubject.next("No se Encontro informacion del codigo postal");
            setCPValid(true);
            setDisableFields(false);
          } else {
            setColonias(info.colonias || []);
            (!info.estado || !info.municipio) &&
              alertSubject.next("El Codigo Postal No Es Valido");
            setCPValid(!!(info.estado && info.municipio));
            setMunicipio(info.municipio || "");
            setEstado(info.estado || "");
            setDisableFields(true);
          }
        }
      }
    };

    return (
      <div>
        {!smallViewPort && (
          <>
            <Horizontal>
              <CodigoPostalContainer>
                <TextLabel>
                  Codigo Postal{obligatorio && <Obligatorio>*</Obligatorio>}
                </TextLabel>
                <NumericMaxlengthInput
                  maxNum={5}
                  inputValue={codigoPostal}
                  onChange={(value: string) => setCodigoPostal(value)}
                  onEnter={() => codigoPostalApoderadoIsInvalid()}
                />
              </CodigoPostalContainer>
              <Button
                style={{
                  marginLeft: "10px",
                  height: "40px",
                  padding: "10px 32px",
                  fontSize: "14px",
                }}
                onClick={() => codigoPostalApoderadoIsInvalid()}
              >
                Validar C.P
              </Button>
            </Horizontal>

            {CPValid && (
              <div>
                <Horizontal>
                  <div>
                    <TextLabel>
                      Municipio{obligatorio && <Obligatorio>*</Obligatorio>}
                    </TextLabel>
                    <InputGrande
                      type="text"
                      value={municipio}
                      onChange={(e) => setMunicipio(e.target.value)}
                      disabled={disableFields}
                    />
                  </div>
                  <div>
                    <TextLabel>
                      Estado{obligatorio && <Obligatorio>*</Obligatorio>}
                    </TextLabel>
                    <InputGrande
                      type="text"
                      value={estado}
                      onChange={(e) => setEstado(e.target.value)}
                      disabled={disableFields}
                    />
                  </div>
                </Horizontal>

                <Horizontal>
                  <div>
                    <TextLabel>Colonia</TextLabel>
                    <Dropdown
                      value={colonia}
                      onValue={(value: any) => setColonia(value)}
                      options={colonias}
                    />
                  </div>
                  <div>
                    <TextLabel>Teléfono</TextLabel>
                    <PhoneInput
                      inputValue={telefono}
                      onChange={(value: string) => setTelefono(value)}
                    />
                  </div>
                </Horizontal>

                <Horizontal>
                  <div>
                    <TextLabel>Calle</TextLabel>
                    <InputGrande
                      type="text"
                      value={calle}
                      onChange={(e) => setCalle(e.target.value)}
                    />
                  </div>
                  <div>
                    <TextLabel>Numero</TextLabel>
                    <InputGrande
                      type="text"
                      value={numero}
                      onChange={(e) => setNumero(e.target.value)}
                    />
                  </div>
                </Horizontal>
              </div>
            )}
          </>
        )}

        {smallViewPort && (
          <>
            <TextLabel>Codigo Postal</TextLabel>
            <NumericMaxlengthInput
              maxNum={5}
              inputValue={codigoPostal}
              onChange={(value: string) => setCodigoPostal(value)}
              onEnter={() => codigoPostalApoderadoIsInvalid()}
            />
            <Button
              style={{
                marginLeft: "0px",
                height: "40px",
                marginTop: "10px",
                width: "99%",
                padding: "10px 32px",
                fontSize: "14px",
              }}
              onClick={() => codigoPostalApoderadoIsInvalid()}
            >
              Validar C.P
            </Button>

            {CPValid && (
              <div>
                <TextLabel>municipio</TextLabel>
                <InputGrande
                  type="text"
                  value={municipio}
                  onChange={(e) => setMunicipio(e.target.value)}
                  disabled={disableFields}
                />
                <TextLabel>estado</TextLabel>
                <InputGrande
                  type="text"
                  value={estado}
                  onChange={(e) => setEstado(e.target.value)}
                  disabled={disableFields}
                />

                {/* <app-drop-down [Label]="'colonia'" [Options]="this.colonias" (onValue)="onValue($event)"
              [Option]="this.DomicilioFiscalForm.get('colonia').value">
          </app-drop-down> */}

                <TextLabel>Teléfono</TextLabel>
                <PhoneInput
                  inputValue={telefono}
                  onChange={(value: string) => setTelefono(value)}
                />

                <TextLabel>calle</TextLabel>
                <InputGrande
                  type="text"
                  value={calle}
                  onChange={(e) => setCalle(e.target.value)}
                />
                <TextLabel>numero</TextLabel>
                <InputGrande
                  type="text"
                  value={numero}
                  onChange={(e) => setNumero(e.target.value)}
                />
              </div>
            )}
          </>
        )}
        <Warning
          type={"Warning"}
          title={"Advertencia"}
          warningSubject={warningSubject}
        />
        <Warning
          type={"Alert"}
          title={"Warning"}
          warningSubject={alertSubject}
        />
      </div>
    );
  }
);

export default DomicilioFiscal;
