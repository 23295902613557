import styled from "styled-components";

export const Contenedor = styled.div`
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 1500px) {
    display: inline-block;
  }
`;

export const ContenedorRegistro = styled.div`
  width: 410px;
  height: 478px;
  margin: 16px;
  margin-bottom: auto;
  padding: 16px;
  margin-left: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  @media (max-width: 1500px) {
    position: relative;
    height: auto;
    width: calc(100vw - 412px);
    margin-right: 45px;
    margin-bottom: 15px;
  }
  @media (max-width: 1000px) {
    width: calc(100vw - 70px);
    margin-right: 16px;
    margin-left: 16px;
  }
`;

export const ContenedorDomicilioFiscal = styled.div`
  width: 976px;
  margin: 16px;
  margin-bottom: auto;
  position: relative;
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-right: 45px;
  @media (max-width: 1500px) {
    width: calc(100vw - 412px);
    margin-right: 45px;
    margin-left: 45px;
  }
  @media (max-width: 1000px) {
    width: calc(100vw - 70px);
    margin-right: 16px;
    margin-left: 16px;
  }
`;
