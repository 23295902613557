const paths = {
  Base: "/",
  ControlDeCuenta: "/ControlDeCuenta",
  Workarea: "/Workarea/*",
  Inicio: "/Workarea/Inicio",
  Perfil: "/Workarea/Inicio/Perfil",
  Blog: "/Workarea/Inicio/Blog",
  InformacionDeLaEmpresaOPatron: "/Workarea/Empresa/InformacionDeLaEmpresaOPatron",
  EsquemaDeNomina: "/Workarea/Empresa/EsquemaDeNomina",
  RegistroEstatalYDelIMSS: "/Workarea/Empresa/RegistroEstatalYDelIMSS",
  HorarioYControlDeAsistencia: "/Workarea/Empresa/HorarioYControlDeAsistencia",
  PrestacionesGenerales: "/Workarea/Empresa/PrestacionesGenerales",
  ParametrosDeOperacion: "/Workarea/Empresa/ParametrosDeOperacion",
  RegistroDeDepartamentos: "/Workarea/Empresa/RegistroDeDepartamentos",
  RegistroDePuestos: "/Workarea/Trabajadores/RegistroDePuestos",
  ControlDePersonal: "/Workarea/Trabajadores/ControlDePersonal",
  NuevoEmpleado: "/Workarea/Trabajadores/ControlDePersonal/NuevoEmpleado",
  ConfiguracionDeConceptos: "/Workarea/Nomina/ConfiguracionDeConceptos",
  ControldeAsistencia: "/Workarea/Trabajadores/ControlDePersonal/ControldeAsistencia",
  ConfiguracionDeCriterios: "/Workarea/Nomina/ConfiguracionDeCriterios",
};

export default paths;
