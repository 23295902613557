import { merge } from "../../Utils/sharedFunctions";
const informacionGeneral = (
  state = {
    uma: 0,
    smg: 0,
    smf: 0,
    umi: 0,
    diasDeVacacionesLey: [],
  },
  action
) => {
  switch (action.type) {
    case "REPLACEINFORMACIONGENERAL":
      const newState = merge(state, action.newInformacionGeneral);
      return newState;
    default:
      return state;
  }
};

export default informacionGeneral;
