import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Modal from "../Modal";
import {
  ButtonPrimario,
  ButtonSecundario,
  ContenedorBotones,
  ContenedorBotonesExterior,
  Flex1,
  Flex3,
  Flex8,
  FlexRow,
  InputGrande,
  Obligatorio,
  TextLabel,
  TextLabelTitle,
} from "../../Pages/communStyled";
import Dropdown from "../Dropdown";
import { IDDiv, IDLabel } from "./RegistroDeSecuenciaStyled";
import { getRandomID, scrollIntoView } from "../../Utils/sharedFunctions";
import { horarioCompleto, secuenciaCompleta, secuencia } from "../../types";
import useViewPortSize from "../../Hooks/useViewPortSize";
import Warning from "../Warning";
import { Subject } from "rxjs";
import QuantityManager from "../QuantityManager";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SortingTable from "../SortingTable";
interface Props {
  modalState: boolean;
  horariosArray: horarioCompleto[];
  secuenciasArray: secuenciaCompleta[];
  secuenciaSelected: secuenciaCompleta;
  onClose: () => void;
  onAccept: (newSecuencia: secuenciaCompleta) => void;
}

const RegistroDeSecuencia = forwardRef(
  (
    {
      modalState,
      secuenciasArray,
      secuenciaSelected,
      horariosArray,
      onAccept,
      onClose,
    }: Props,
    ref
  ) => {
    const { viewPortWidth } = useViewPortSize();
    const [copyNumber, setCopyNumber] = useState(0);
    const alertRef = useRef(null);
    const formaIncompletaSubject = new Subject<string>();
    const generateNewSecuencia = () => {
      const id = getRandomID();
      const horarios = secuenciasArray.map((horario: { id: any }) => {
        return horario.id;
      });
      if (horarios.includes(id)) {
        generateNewSecuencia();
      } else {
        return id;
      }
    };

    const [id, setId] = useState(generateNewSecuencia() as string);
    const [clave, setClave] = useState("");
    const [disableAll, setDisableAll] = useState(
      secuenciaSelected?.inactivo || false
    );
    const [descripcion, setDescripcion] = useState("");
    const [horarioSeleccionado, setHorarioSeleccionado] = useState("");
    const [dias, setDias] = useState(1);
    const [secuencia, setSecuencia] = useState<secuencia | null>(null);
    const [secuencias, setSecuencias] = useState<secuencia[]>([]);

    const columnsArray = [
      {
        label: "Periodo",
        valueLabel: "periodo",
        filtrable: true,
        widthPX: 100,
        type: "Number",
      },
      {
        label: "Clave de Horario",
        valueLabel: "clave",
        filtrable: false,
        widthPX: 195,
        type: "String",
      },
      {
        label: "No.De Dias",
        valueLabel: "dias",
        filtrable: false,
        widthPX: 215,
        type: "String",
      },
    ];

    useImperativeHandle(ref, () => ({}));

    const agregarHorario = () => {
      let secuenciasCopy = [...secuencias];
      if (secuencia) {
        secuenciasCopy = secuenciasCopy.map((secuenciaItem: secuencia) => {
          if (secuenciaItem.periodo === secuencia.periodo) {
            secuenciaItem.dias = dias;
            secuenciaItem.clave = horarioSeleccionado;
            secuenciaItem.periodo = secuenciaItem.periodoInicial + " - " + (secuenciaItem.periodoInicial + dias);
            secuenciaItem.periodoFinal = secuenciaItem.periodoInicial + dias;
          }
          return secuenciaItem;
        });
        secuenciasCopy = secuenciasCopy.map(
          (secuenciaItem: secuencia, index: number) => {
            secuenciaItem.periodo = secuenciasCopy[index - 1]
              ? secuenciasCopy[index - 1].periodoFinal +
                " - " +
                (secuenciasCopy[index - 1].periodoFinal + secuenciaItem.dias)
              : "0 - " + secuenciaItem.dias;
            secuenciaItem.periodoInicial = secuenciasCopy[index - 1]
              ? secuenciasCopy[index - 1].periodoFinal
              : 0;
            secuenciaItem.periodoFinal = secuenciasCopy[index - 1]
              ? secuenciasCopy[index - 1].periodoFinal + secuenciaItem.dias
              : secuenciaItem.dias;
            return secuenciaItem;
          }
        );
      } else {
        const periodoInicial = getTotalDeDias() !== 0 ? getTotalDeDias() : 0;
        const periodoFinal = periodoInicial + dias;
        secuenciasCopy.push({
          dias: dias,
          clave: horarioSeleccionado,
          periodo: periodoInicial + " - " + periodoFinal,
          periodoInicial: periodoInicial,
          periodoFinal: periodoFinal,
        });
      }
      setSecuencias([...secuenciasCopy]);
      setDias(1);
      setSecuencia(null);
      setHorarioSeleccionado(horariosArray[0].clave);
    };

    const getTotalDeDias = () => {
      let total = 0;
      for (const sec of secuencias as secuencia[]) {
        total = total + sec.dias;
      }
      return total;
    };

    const eliminarHorario = () => {
      if (secuencia) {
        let secuenciasCopy = [...secuencias];
        secuenciasCopy = secuenciasCopy.filter(
          (secuenciaItem: secuencia) =>
            secuenciaItem.periodo !== secuencia.periodo
        );
        secuenciasCopy = secuenciasCopy.map(
          (secuenciaItem: secuencia, index: number) => {
            secuenciaItem.periodo = secuenciasCopy[index - 1]
              ? secuenciasCopy[index - 1].periodoFinal +
                " - " +
                (secuenciasCopy[index - 1].periodoFinal + secuenciaItem.dias)
              : "0 - " + secuenciaItem.dias;
            secuenciaItem.periodoInicial = secuenciasCopy[index - 1]
              ? secuenciasCopy[index - 1].periodoFinal
              : 0;
            secuenciaItem.periodoFinal = secuenciasCopy[index - 1]
              ? secuenciasCopy[index - 1].periodoFinal + secuenciaItem.dias
              : secuenciaItem.dias;
            return secuenciaItem;
          }
        );
        setSecuencias([...secuenciasCopy]);
        setSecuencia(null);
      }
    };

    useEffect(() => {
      if (horariosArray.length > 0) {
        setHorarioSeleccionado(horariosArray[0].clave);
      }
    }, [horariosArray]);

    const aceptar = () => {
      if (formaIncompleta()) {
        formaIncompletaSubject.next("Verifique que todos los campos requeridos estén completos y que tenga al menos un horario en la secuencia.");
        scrollIntoView(alertRef);
      } else {
        const newSecuencia: secuenciaCompleta = {
          id: id,
          clave: clave,
          descripcion: descripcion,
          inactivo: disableAll,
          secuencia: secuencias,
          copyNumber: secuenciaSelected?.copyNumber || 0,
          empleados: []
        };
        onAccept(newSecuencia);
        onClose();
      }
    };

    const formaIncompleta = () => {
      return (clave === "" || descripcion === "" || secuencias.length === 0)
    }

    const onCloseFunction = () => {
      resetAll();
      onClose();
    };

    const resetAll = () => {
      setId(generateNewSecuencia() as string);
      setClave("");
      setDescripcion("");
      setHorarioSeleccionado(horariosArray[0] ? horariosArray[0].clave : "");
      setDisableAll(false);
      setDias(1);
      setSecuencias([]);
    };

    useEffect(() => {
      if (secuencia) {
        setHorarioSeleccionado(secuencia.clave);
        setDias(secuencia.dias);
      } else {
        setHorarioSeleccionado(horariosArray[0] ? horariosArray[0].clave : "");
        setDias(1);
      }
    }, [secuencia]);

    useEffect(() => {
      if (secuenciaSelected) {
        setId(secuenciaSelected.id);
        setClave(secuenciaSelected.clave);
        setDescripcion(secuenciaSelected.descripcion);
        setDisableAll(secuenciaSelected.inactivo);
        setSecuencias(secuenciaSelected.secuencia);
        setCopyNumber(secuenciaSelected.copyNumber);
      } else {
        resetAll();
      }
    }, [secuenciaSelected]);

    return (
      <Modal show={modalState}>
        <TextLabelTitle>REGISTRO DE SECUENCIA</TextLabelTitle>
        <IDDiv>
          <IDLabel>ID:</IDLabel>
          {id} - {copyNumber}
        </IDDiv>
        <div style={{ width: viewPortWidth >= 1375 ? "1026px" : "90vw" }}>
          <div ref={alertRef}>
            <Warning
              type={"Alert"}
              title={"Warning"}
              warningSubject={formaIncompletaSubject}
            />
          </div>
          <FlexRow>
            <Flex1>
              <TextLabel>
                Clave<Obligatorio>*</Obligatorio>
              </TextLabel>
              <InputGrande
                value={clave}
                onChange={(e) => setClave(e.target.value)}
                type="text"
                disabled={disableAll}
              />
            </Flex1>
            <Flex3>
              <TextLabel>
                Descipcion<Obligatorio>*</Obligatorio>
              </TextLabel>
              <InputGrande
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
                type="text"
                disabled={disableAll}
              />
            </Flex3>
          </FlexRow>
          <FlexRow>
            <Flex8>
              <TextLabel>Horario</TextLabel>
              <Dropdown
                onValue={(value: any) => setHorarioSeleccionado(value)}
                value={horarioSeleccionado}
                options={horariosArray.filter((horario) => (!horario.inactivo && Object.keys(horario.horarioEspecial).length === 0)).map((horario) => horario.clave)}
                disabled={disableAll}
              />
            </Flex8>
            <Flex1>
              <TextLabel>Numero de Días</TextLabel>
              <QuantityManager
                Minimum={1}
                quantity={dias}
                Maximum={365}
                height={43}
                onValue={(value: number) => setDias(value)}
                disabled={disableAll}
              />
            </Flex1>
            <Flex1 style={{ marginTop: "auto" }}>
              {secuencia && (
                <TextLabel
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => eliminarHorario()}
                >
                  Eliminar Horario
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ marginLeft: "5px" }}
                  />
                </TextLabel>
              )}
              <ButtonPrimario
                style={{ height: "43px", padding: "10px 32px", width: "120px"}}
                onClick={() => agregarHorario()}
                disabled={disableAll}
              >
                {secuencia ? "Editar":"Agregar"}
              </ButtonPrimario>
            </Flex1>
          </FlexRow>
          <SortingTable
            tableHeight={"383px"}
            iSortDescending={true}
            onSelectElement={(secuencia: any) => setSecuencia(secuencia)}
            rowsArray={secuencias}
            columnsArray={columnsArray}
            showElementsPerPage={6}
            multiselect={false}
            hideCheckbox={disableAll}
          />

          <ContenedorBotonesExterior
            style={{ marginBottom: "0px", paddingBottom: "0px" }}
          >
            <ContenedorBotones style={{ marginRight: "0px" }}>
              {!disableAll && (
                <ButtonPrimario onClick={() => aceptar()}>
                  Aceptar
                </ButtonPrimario>
              )}
              <ButtonSecundario onClick={() => onCloseFunction()}>
                {disableAll ? "Salir" : "Cancelar"}
              </ButtonSecundario>
            </ContenedorBotones>
          </ContenedorBotonesExterior>
        </div>
      </Modal>
    );
  }
);

export default RegistroDeSecuencia;
