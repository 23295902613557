import { merge } from "../../Utils/sharedFunctions";
const currentTrabajador = (
  state = null,
  action
) => {
  switch (action.type) {
    case "REPLACECURRENTTRABAJADOR":
      const newState = merge(state, action.newCurrentTrabajador);
      return newState;
    case "RESETCURRENTTRABAJADOR":
      return null;
    default:
      return state;
  }
};

export default currentTrabajador;
