import {merge} from '../../Utils/sharedFunctions'
const informacionGeneralDeUsuario = (
    state = {
        empresas: [],
        usuario: '',
        creditos: 0,
        sToken: {},
        estadoDeCuenta: [],
        informacionDeFacturacion: {
          calle: "",
          codigoPostal: "",
          colonia: "",
          correoElectronico: "",
          estado: "",
          municipio: "",
          nombre: "",
          numero: "",
          rfc: "",
          referencias: "",
          telefono: "",
        }
    },
    action
  ) => {
    switch (action.type) {
      case "REPLACEINFORMACIONGENERALDEUSUARIO":
        const newState = merge(state, action.newInformacionGeneralDeUsuario);
        return newState;
      default:
        return state;
    }
  };
  
  export default informacionGeneralDeUsuario;
  