import { Button, TextoNormal } from "../../Pages/communStyled";
import {
  ButtonDiv,
  Contenedor,
  TermsDiv,
  TitleDiv,
  Titulo,
} from "./termsAndConditionsStyled";

interface Props {
  handleClose: () => void;
}

const Modal = ({ handleClose }: Props) => {
  return (
    <Contenedor>
      <TitleDiv>
        <Titulo>Términos y Condiciones</Titulo>
      </TitleDiv>

      <TermsDiv>
        <TextoNormal>TermsAndConditions</TextoNormal>
      </TermsDiv>

      <ButtonDiv>
        <Button onClick={handleClose}>CERRAR</Button>
      </ButtonDiv>
    </Contenedor>
  );
};

export default Modal;
