import { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { ButtonDiv, CardInfo, Checkout, Title } from "./stripeStyled";
import { Button } from "../../Pages/communStyled";

interface Props {
  disabled?: boolean;
  onValue: (token: any) => void;
}
const Stripe = ({ disabled, onValue }: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [showError, setShowError] = useState<any>("");

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement("card");
    if (cardElement) {
      const { token, error } = await stripe.createToken(cardElement);

      if (error) {
        setShowError(error.message);
      } else {
        onValue && onValue(token);
      }
    }
  };

  return (
    <Checkout>
      <div>
        <Title>Información del pago</Title>
        <CardInfo>
          <CardElement />
        </CardInfo>
      </div>
      <div>{showError && showError}</div>
      <ButtonDiv>
        <Button onClick={(event) => handleSubmit(event)} disabled={disabled}>
          PAGAR CREDITOS
        </Button>
      </ButtonDiv>
    </Checkout>
  );
};

export default Stripe;
