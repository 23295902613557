import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  ButtonPrimario,
  ButtonSecundario,
  ContenedorBotones,
  ContenedorBotonesExterior,
  Flex1,
  Flex3,
  FlexRow,
  InputGrande,
  Obligatorio,
  TextLabel,
  TextLabelTitle,
} from "../../Pages/communStyled";
import Modal from "../Modal";
import { ChartsContainer, IDDiv, IDLabel } from "./RegistroDeHorariosStyled";
import useViewPortSize from "../../Hooks/useViewPortSize";
import Warning from "../Warning";
import { Subject } from "rxjs";
import Dropdown from "../Dropdown";
import Multiselect from "../Multiselect";
import HorariosEspeciales from "../HorariosEspeciales";
import { getRandomID, scrollIntoView } from "../../Utils/sharedFunctions";
import PieChart from "../PieChart";
import FilaDeHorarioNormal from "../FilaDeHorarioNormal";
import { dia, horarioCompleto, horariosEspeciales } from "../../types";

interface Props {
  modalState: boolean;
  horariosArray: horarioCompleto[];
  duracionDeLaJornada: any;
  horarioSelected: horarioCompleto;
  onClose: () => void;
  onAccept: (newHorario: horarioCompleto) => void;
}

const RegistroDeHorarios = forwardRef(
  ({ modalState, horariosArray, duracionDeLaJornada, horarioSelected, onClose, onAccept }: Props, ref) => {
    const alertRef = useRef(null);
    const { viewPortWidth } = useViewPortSize();
    const formaIncompletaSubject = new Subject<string>();
    const PeriodosMenoresAUnaHoraSubject = new Subject<string>();

    const generateNewHorario = () => {
      const id = getRandomID();
      const horarios = horariosArray.map((horario: { id: any }) => {
        return horario.id;
      });
      if (horarios.includes(id)) {
        generateNewHorario();
      } else {
        return id;
      }
    };

    const [id, setId] = useState(generateNewHorario() as string);
    const [inactivo, setInactivo] = useState(false);
    const [copyNumber, setCopyNumber] = useState(0);
    const [clave, setClave] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [calculoDeHorario, setCalculoDeHorario] = useState(
      "Por diá según horario establecido"
    );
    const [tipo, setTipo] = useState("Horario Normal");
    const [diasDeDescansoSemanal, setDiasDeDescansoSemanal] = useState<
      Array<string>
    >(["domingo"]);
    const [horarioEspecial, setHorarioEspecial] = useState<
      horariosEspeciales | object
    >({});
    const [totalDeHorasAlaSemana, setTotalDeHorasAlaSemana] = useState(0);
    const [totalDeHorasExtraAlaSemana, setTotalDeHorasExtraAlaSemana] =
      useState({
        dobles: 0,
        triples: 0,
      });
    const [empleados, setEmpleados] = useState<any>([]);
    const [lunes, setLunes] = useState<dia | object>({});
    const [martes, setMartes] = useState<dia | object>({});
    const [miercoles, setMiercoles] = useState<dia | object>({});
    const [jueves, setJueves] = useState<dia | object>({});
    const [viernes, setViernes] = useState<dia | object>({});
    const [sabado, setSabado] = useState<dia | object>({});
    const [domingo, setDomingo] = useState<dia | object>({});
    const [disableAll, setDisableAll] = useState(horarioSelected?.inactivo || false);
    const [alertAlreadyEmitted, setAlertAlreadyEmitted] = useState(false);
    const [pieChartObject, setPieChartObject] = useState<any>({
      labels: ["Horas Laborales", "Horas disponibles"],
      data: [],
      dataColors: ["#B7D32D", "#E0E1E2"],
      title: "Horas de trabajo",
    });
    const [pieChartObjectExtra, setPieChartObjectExtra] = useState<any>({
      labels: ["Horas Dobles", "Horas Triples", "Horas disponibles"],
      data: [],
      dataColors: ["#FBBD08", "#DB2828", "#E0E1E2"],
      title: "Horas Extra",
    });
    const diasDeLaSemana = [
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
      "Domingo",
    ];

    useImperativeHandle(ref, () => ({}));

    const onAlert = () => {
      PeriodosMenoresAUnaHoraSubject.next(
        "La Ley obliga a pagar los intervalos de descanso Menores a una hora, marcamos esta opcion para cumplir con la ley si deceas aun puedes desmarcar la casilla"
      );
      setAlertAlreadyEmitted(true);
    };

    const onValueDate = (dia: string, value: any) => {
      patchLiteral[dia](value);
    };

    useEffect(() => {
      calcularTotalDeHorasPorSemana();
    }, [lunes, martes, miercoles, jueves, viernes, sabado, domingo]);

    const patchLiteral: any = {
      Lunes: (value: any) => setLunes(value),
      Martes: (value: any) => setMartes(value),
      Miercoles: (value: any) => setMiercoles(value),
      Jueves: (value: any) => setJueves(value),
      Viernes: (value: any) => setViernes(value),
      Sabado: (value: any) => setSabado(value),
      Domingo: (value: any) => setDomingo(value),
    };

    const calcularTotalDeHorasPorSemana = () => {
      const horasAlaSemana: Array<number> = [
        lunes,
        martes,
        miercoles,
        jueves,
        viernes,
        sabado,
        domingo,
      ].reduce((acumulador: Array<number>, objeto: any) => {
        if (acumulador.length === 0) acumulador = [0, 0];
        const horasConvertidas = calcularHorasAlaSemana(
          objeto,
          objeto.tipoDeHorario
        );
        acumulador = horasConvertidas
          ? [
              acumulador[0] + horasConvertidas[0],
              acumulador[1] + horasConvertidas[1],
            ]
          : acumulador;
        return acumulador;
      }, []);

      let horasActuale = 0;
      let nuevasHoras =
        horasActuale + horasAlaSemana[0] + horasAlaSemana[1] / 60;
      if (nuevasHoras) {
        const totalDobles =
          nuevasHoras - 48 > 0
            ? nuevasHoras - 48 <= 9
              ? nuevasHoras - 48
              : 9
            : 0;
        const totalTriples = nuevasHoras - 57 > 0 ? nuevasHoras - 57 : 0;
        setTotalDeHorasAlaSemana(nuevasHoras);
        setTotalDeHorasExtraAlaSemana({
          dobles: totalDobles,
          triples: totalTriples,
        });
        const horasExtraRestantes = 66 - nuevasHoras > 0 ? 66 - nuevasHoras : 0;
        const horasTriplesMaximas = totalTriples > 9 ? 9 : totalTriples;
        setPieChartObjectExtra({
          ...pieChartObjectExtra,
          data: [totalDobles, horasTriplesMaximas, horasExtraRestantes],
        });
        const horasRestantes = 48 - nuevasHoras > 0 ? 48 - nuevasHoras : 0;
        setPieChartObject({
          ...pieChartObject,
          data: [nuevasHoras, horasRestantes],
        });
      }
      if (
        !(lunes as dia).checkboxFlag &&
        !(martes as dia).checkboxFlag &&
        !(miercoles as dia).checkboxFlag &&
        !(jueves as dia).checkboxFlag &&
        !(viernes as dia).checkboxFlag &&
        !(sabado as dia).checkboxFlag &&
        !(domingo as dia).checkboxFlag
      ) {
        setPieChartObjectExtra({
          ...pieChartObjectExtra,
          data: [0, 0, 66],
        });
        setPieChartObject({
          ...pieChartObject,
          data: [0, 48],
        });
      }
    };

    const calcularHorasAlaSemana = (array: any, tipoDeHorario: string) => {
      const horas = array?.acomuladoDeHorasAlaSemana?.total;
      if (horas?.length) {
        // checa tipo de horario y convertir todo a diurnas, saca porcentajes de minutos para agregarlo a las horas
        const equivalenteHorasNocturnas = 1.142857143;
        const equivalenteHorasMixtas = 1.066666667;
        let horasConvertidas = horas[0];
        let minutosConvertidos = horas[1];
        if (tipoDeHorario === "mixto") {
          horasConvertidas = horas[0] * equivalenteHorasMixtas;
          minutosConvertidos = horas[1] * equivalenteHorasMixtas;
        }
        if (tipoDeHorario === "nocturno") {
          horasConvertidas = horas[0] * equivalenteHorasNocturnas;
          minutosConvertidos = horas[1] * equivalenteHorasNocturnas;
        }

        return [horasConvertidas, minutosConvertidos];
      }
      return null;
    };

    const invalidAcomuladoDeHorasALaSemana = () => {
      return (
        calculoDeHorario === "Por acumulado de horas a la semana" &&
        ((totalDeHorasAlaSemana > 40 && diasDeDescansoSemanal.length > 1) ||
          (totalDeHorasAlaSemana > 32 && diasDeDescansoSemanal.length > 2) ||
          (totalDeHorasAlaSemana > 24 && diasDeDescansoSemanal.length > 3) ||
          (totalDeHorasAlaSemana > 16 && diasDeDescansoSemanal.length > 4) ||
          (totalDeHorasAlaSemana > 8 && diasDeDescansoSemanal.length > 5))
      );
    };

    const validateForm = () => {
      return (
        clave &&
        descripcion &&
        tipo &&
        calculoDeHorario &&
        ((tipo === "Horario Especial" &&
          (horarioEspecial as horariosEspeciales)?.horariosEspecialesArray
            ?.length !== 0) ||
          (tipo === "Horario Normal" &&
            (((lunes as dia).horarios?.length !== 0 &&
              (lunes as dia).checkboxFlag) ||
              ((martes as dia).horarios?.length !== 0 &&
                (martes as dia).checkboxFlag) ||
              ((miercoles as dia).horarios?.length !== 0 &&
                (miercoles as dia).checkboxFlag) ||
              ((jueves as dia).horarios?.length !== 0 &&
                (jueves as dia).checkboxFlag) ||
              ((viernes as dia).horarios?.length !== 0 &&
                (viernes as dia).checkboxFlag) ||
              ((sabado as dia).horarios?.length !== 0 &&
                (sabado as dia).checkboxFlag) ||
              ((domingo as dia).horarios?.length !== 0 &&
                (domingo as dia).checkboxFlag))))
      );
    };

    const aceptar = () => {
      if (!invalidAcomuladoDeHorasALaSemana()) {
        formaIncompletaSubject.next("");
        if (validateForm()) {
          let horarioValidName = true;
          for(const horario of horariosArray){
            if(!horario.inactivo && horario.clave === clave) {
              formaIncompletaSubject.next(
                "No Pueden existir dos horarios activos con la misma clave"
              );
              horarioValidName = false;
            }
          }
          if(horarioValidName || horarioSelected){
            onAccept({
              id,
              clave,
              descripcion,
              calculoDeHorario,
              tipo,
              diasDeDescansoSemanal,
              horarioEspecial,
              totalDeHorasAlaSemana,
              totalDeHorasExtraAlaSemana,
              lunes,
              martes,
              miercoles,
              jueves,
              viernes,
              sabado,
              domingo,
              inactivo,
              copyNumber,
              empleados,
            });
            resetAll();
          } else {
            formaIncompletaSubject.next(
              "No Pueden existir dos horarios activos con la misma clave"
            );
          }
        } else {
          formaIncompletaSubject.next(
            "Verifique que todos los campos requeridos estén completos y que tenga al menos un horario"
          );
        }
      } else {
        formaIncompletaSubject.next(
          "Este horario requiere menos días de descanso"
        );
      }
      scrollIntoView(alertRef);
    };

    const onCloseFunction = () => {
      resetAll();
      onClose();
    }

    const resetAll = () => {
      setClave("");
      setDescripcion("");
      setCalculoDeHorario("Por diá según horario establecido");
      setTipo("Horario Normal");
      setDiasDeDescansoSemanal(["domingo"]);
      setHorarioEspecial({});
      setTotalDeHorasAlaSemana(0);
      setTotalDeHorasExtraAlaSemana({
        dobles: 0,
        triples: 0,
      });
      setLunes({});
      setMartes({});
      setMiercoles({});
      setJueves({});
      setViernes({});
      setSabado({});
      setDomingo({});
      setDisableAll(false);
      setAlertAlreadyEmitted(false);
      setInactivo(false);
      setId(generateNewHorario() as string);
      setCopyNumber(0);
      setEmpleados([]);
      setPieChartObject({
        labels: ["Horas Laborales", "Horas disponibles"],
        data: [],
        dataColors: ["#B7D32D", "#E0E1E2"],
        title: "Horas de trabajo",
      });
      setPieChartObjectExtra({
        labels: ["Horas Dobles", "Horas Triples", "Horas disponibles"],
        data: [],
        dataColors: ["#FBBD08", "#DB2828", "#E0E1E2"],
        title: "Horas Extra",
      });
      formaIncompletaSubject.next("");
      PeriodosMenoresAUnaHoraSubject.next("");
      setDisableAll(false);
    }

    useEffect(() => {
      if (horarioSelected) {
        setId(horarioSelected.id);
        setClave(horarioSelected.clave);
        setDescripcion(horarioSelected.descripcion);
        setCalculoDeHorario(horarioSelected.calculoDeHorario);
        setTipo(horarioSelected.tipo);
        setDiasDeDescansoSemanal(horarioSelected.diasDeDescansoSemanal);
        setHorarioEspecial(horarioSelected.horarioEspecial);
        setTotalDeHorasAlaSemana(horarioSelected.totalDeHorasAlaSemana);
        setTotalDeHorasExtraAlaSemana(horarioSelected.totalDeHorasExtraAlaSemana);
        setLunes(horarioSelected.lunes);
        setMartes(horarioSelected.martes);
        setMiercoles(horarioSelected.miercoles);
        setJueves(horarioSelected.jueves);
        setViernes(horarioSelected.viernes);
        setSabado(horarioSelected.sabado);
        setDomingo(horarioSelected.domingo);
        setInactivo(horarioSelected.inactivo);
        setCopyNumber(horarioSelected.copyNumber);
        setEmpleados(horarioSelected.empleados);
      }
    }, [horarioSelected]);

    useEffect(() => {
      if(horarioSelected?.inactivo) {
        setDisableAll(true);
      }
    }, [horarioSelected?.inactivo]);

    return (
      <Modal show={modalState}>
        <TextLabelTitle>REGISTRO DE HORARIO</TextLabelTitle>
        <IDDiv>
          <IDLabel>ID:</IDLabel>
          {id} - {copyNumber}
        </IDDiv>
        <div style={{ width: viewPortWidth >= 1375 ? "1026px" : "100%" }}>
          <div ref={alertRef}>
            <Warning
              type={"Alert"}
              title={"Warning"}
              warningSubject={formaIncompletaSubject}
            />
          </div>
          <FlexRow>
            <Flex1>
              <TextLabel>
                Clave<Obligatorio>*</Obligatorio>
              </TextLabel>
              <InputGrande
                value={clave}
                onChange={(e) => setClave(e.target.value)}
                type="text"
                disabled={disableAll}
              />
            </Flex1>
            <Flex3>
              <TextLabel>
                Descipcion<Obligatorio>*</Obligatorio>
              </TextLabel>
              <InputGrande
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
                type="text"
                disabled={disableAll}
              />
            </Flex3>
          </FlexRow>
          <FlexRow
            style={{
              paddingBottom:
                calculoDeHorario === "Por acumulado de horas a la semana"
                  ? "0px"
                  : "20px",
            }}
          >
            <Flex1>
              <TextLabel>
                Tipo<Obligatorio>*</Obligatorio>
              </TextLabel>
              <Dropdown
                onValue={(value: any) => setTipo(value)}
                value={tipo}
                options={["Horario Normal", "Horario Especial"]}
              />
            </Flex1>
            {tipo === "Horario Normal" && (
              <Flex1>
                <TextLabel>
                  Calculo de horario<Obligatorio>*</Obligatorio>
                </TextLabel>
                <Dropdown
                  onValue={(value: any) => setCalculoDeHorario(value)}
                  value={calculoDeHorario}
                  options={[
                    "Por diá según horario establecido",
                    "Por acumulado de horas a la semana",
                    "Pagar horas extra solo fuera de horario",
                  ]}
                />
              </Flex1>
            )}
          </FlexRow>
          {tipo === "Horario Normal" && (
            <div>
              {calculoDeHorario === "Por acumulado de horas a la semana" && (
                <ChartsContainer>
                  <PieChart ChartObject={pieChartObject} />
                  <PieChart ChartObject={pieChartObjectExtra} />
                </ChartsContainer>
              )}
              <div></div>
              {viewPortWidth > 1375 && (
                <FlexRow>
                  <Flex1>Dia</Flex1>
                  <Flex3 style={{ justifyContent: "center", display: "flex" }}>
                    Horario
                  </Flex3>
                  <Flex1 style={{ justifyContent: "center", display: "flex" }}>
                    Continuo
                  </Flex1>
                  {calculoDeHorario === "Por diá según horario establecido" && (
                    <Flex1
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      Horas
                    </Flex1>
                  )}
                  {calculoDeHorario === "Por diá según horario establecido" && (
                    <Flex1
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      Horas Extra
                    </Flex1>
                  )}
                  {calculoDeHorario !== "Por diá según horario establecido" && (
                    <Flex1
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      Totales
                    </Flex1>
                  )}
                  {/* {calculoDeHorario !== 'Por diá según horario establecido' && <Flex1>Laborales</Flex1>} */}
                  {/* {calculoDeHorario !== 'Por diá según horario establecido' && <Flex1>Extras</Flex1>} */}
                </FlexRow>
              )}
              <FilaDeHorarioNormal
                labelDay={"Lunes"}
                onValue={(values: any) => onValueDate("Lunes", values)}
                onAlert={() => onAlert()}
                disabled={disableAll || diasDeDescansoSemanal.includes("Lunes")}
                calculoDeHorario={calculoDeHorario}
                duracionDeLaJornada={duracionDeLaJornada}
                diaAnterior={"Domingo"}
                diaSiguiente={"Martes"}
                inicialObject={lunes}
              />
              <FilaDeHorarioNormal
                labelDay={"Martes"}
                onValue={(values: any) => onValueDate("Martes", values)}
                onAlert={() => onAlert()}
                disabled={
                  disableAll || diasDeDescansoSemanal.includes("Martes")
                }
                calculoDeHorario={calculoDeHorario}
                duracionDeLaJornada={duracionDeLaJornada}
                diaAnterior={"Lunes"}
                diaSiguiente={"Miercoles"}
                inicialObject={martes}
              />
              <FilaDeHorarioNormal
                labelDay={"Miercoles"}
                onValue={(values: any) => onValueDate("Miercoles", values)}
                onAlert={() => onAlert()}
                disabled={
                  disableAll || diasDeDescansoSemanal.includes("Miercoles")
                }
                calculoDeHorario={calculoDeHorario}
                duracionDeLaJornada={duracionDeLaJornada}
                diaAnterior={"Martes"}
                diaSiguiente={"Jueves"}
                inicialObject={miercoles}
              />
              <FilaDeHorarioNormal
                labelDay={"Jueves"}
                onValue={(values: any) => onValueDate("Jueves", values)}
                onAlert={() => onAlert()}
                disabled={
                  disableAll || diasDeDescansoSemanal.includes("Jueves")
                }
                calculoDeHorario={calculoDeHorario}
                duracionDeLaJornada={duracionDeLaJornada}
                diaAnterior={"Miercoles"}
                diaSiguiente={"Viernes"}
                inicialObject={jueves}
              />
              <FilaDeHorarioNormal
                labelDay={"Viernes"}
                onValue={(values: any) => onValueDate("Viernes", values)}
                onAlert={() => onAlert()}
                disabled={
                  disableAll || diasDeDescansoSemanal.includes("Viernes")
                }
                calculoDeHorario={calculoDeHorario}
                duracionDeLaJornada={duracionDeLaJornada}
                diaAnterior={"Jueves"}
                diaSiguiente={"Sabado"}
                inicialObject={viernes}
              />
              <FilaDeHorarioNormal
                labelDay={"Sabado"}
                onValue={(values: any) => onValueDate("Sabado", values)}
                onAlert={() => onAlert()}
                disabled={
                  disableAll || diasDeDescansoSemanal.includes("Sabado")
                }
                calculoDeHorario={calculoDeHorario}
                duracionDeLaJornada={duracionDeLaJornada}
                diaAnterior={"Viernes"}
                diaSiguiente={"Domingo"}
                inicialObject={sabado}
              />
              <FilaDeHorarioNormal
                labelDay={"Domingo"}
                onValue={(values: any) => onValueDate("Domingo", values)}
                onAlert={() => onAlert()}
                disabled={
                  disableAll || diasDeDescansoSemanal.includes("Domingo")
                }
                calculoDeHorario={calculoDeHorario}
                duracionDeLaJornada={duracionDeLaJornada}
                diaAnterior={"Sabado"}
                diaSiguiente={"Lunes"}
                inicialObject={domingo}
              />
            </div>
          )}
          {tipo === "Horario Especial" && (
            <HorariosEspeciales
              disabled={disableAll}
              onValue={(horarioEspecial: any) =>
                setHorarioEspecial(horarioEspecial)
              }
              onAlert={() => onAlert()}
              inicialObject={horarioEspecial}
            />
          )}

          <Multiselect
            label="Dias de descanso semanal:"
            options={diasDeLaSemana}
            selectedOptions={diasDeDescansoSemanal}
            disabled={disableAll}
            onValue={(diasArray: Array<string>) =>
              setDiasDeDescansoSemanal(diasArray)
            }
          />

          <Warning
            type={"Alert"}
            title={"Warning"}
            warningSubject={PeriodosMenoresAUnaHoraSubject}
          />

          <ContenedorBotonesExterior
            style={{ marginBottom: "0px", paddingBottom: "0px" }}
          >
            <ContenedorBotones style={{ marginRight: "0px" }}>
              {!disableAll && <ButtonPrimario onClick={() => aceptar()}>Aceptar</ButtonPrimario>}
              <ButtonSecundario onClick={() => onCloseFunction()}>
                {disableAll ? "Salir" : "Cancelar"}
              </ButtonSecundario>
            </ContenedorBotones>
          </ContenedorBotonesExterior>
        </div>
      </Modal>
    );
  }
);

export default RegistroDeHorarios;
