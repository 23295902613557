import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button, Dropdown, DropdownContent, DropdownContentOption } from "./ButtonDropdownStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";

interface Props {
  label: string;
  onValue: (data: any) => void;
  disabled?: boolean;
  options: Array<any>;
}

const ButtonDropdown = forwardRef(({label, onValue, disabled, options }: Props, ref) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({}));

  const openDropDown = () => {
    if (!disabled) {
      setDropdownOpen(!dropdownOpen);
    }
  };

  const emitFilter = (item: string) => {
    setDropdownOpen(false);
    onValue(item);
  };
    return (
       <Dropdown>
    <Button disabled={disabled} onClick={() => {}}>{label}
           <FontAwesomeIcon
           icon={faCaretDown}
           onClick={() => openDropDown()}
           style={{ marginLeft: "10px", cursor: "pointer" }}
         />
    </Button>
      {dropdownOpen && options.length > 0 && (
        <DropdownContent>
          {options.map((item: any, index: number) => (
            <DropdownContentOption key={uuidv4()} onClick={() => emitFilter(item)} style={{color: item.color || "black"}}>
              {item.label}
            </DropdownContentOption>
          ))}
        </DropdownContent>
      )}
    </Dropdown>
    );
});
  
export default ButtonDropdown;
