const currentIMG = (
    state = null,
    action
  ) => {
    switch (action.type) {
      case "REPLACECURRENTIMG":
        const newState = action.newCurrentIMG;
        return newState;
      default:
        return state;
    }
  };
  
  export default currentIMG;
  