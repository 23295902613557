import styled from "styled-components";

export const Contenedor = styled.div`
  display: flex;
  @media (max-width: 1900px) {
    display: inline-block;
  }
`;

export const ContenedorDeHorarios = styled.div`
  width: 728px;
  margin: 16px;
  padding: 16px;
  height: fit-content;
  position: relative;
  margin-top: 0px;
  margin-left: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  border-top-left-radius: 0px;
  z-index: 2;
  border-radius: 0 5px 5px 5px;
  box-shadow: 0 -2px 2px -2px rgb(0 0 0 / 50%);
  border-top: none;
  @media (max-width: 1900px) {
    width: calc(100vw - 412px);
    margin-right: 45px;
  }
  @media (max-width: 1000px) {
    width: calc(100vw - 70px);
  }
`;

export const ContenedorTiempoExtraordinario = styled.div`
  width: 728px;
  margin: 16px;
  position: relative;
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  height: fit-content;
  @media (max-width: 1900px) {
    width: calc(100vw - 412px);
    margin-bottom: auto;
    margin-right: 45px;
    margin-left: 45px;
    margin-bottom: 15px;
  }
  @media (max-width: 1000px) {
    width: calc(100vw - 70px);
    margin-right: 16px;
    margin-left: 16px;
  }
`;

export const ContenedorTabs = styled.div`
  margin-left: 45px;
  @media (max-width: 1000px) {
    margin-right: 16px;
    margin-left: 16px;
  }
`;


export const ContenedorBotonesExteriorHorarios = styled.div`
  display: flex;
  margin-top: 14px;
  flex-direction: column;
  row-gap: 10px;
  @media (max-width: 1000px) {
    display: inline-block;
    margin-top: 10px;
    width: 100%;
  }
`;

export const ContenedorBotonesHorarios = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1500px) {
    flex-direction: column;
  }
`;

export const ContenedorBotonesLeft = styled.div`
  display: flex;
  column-gap: 10px;
  @media (max-width: 1500px) {
    flex-direction: column;
  }
`;

export const DuracionDeJornadaRow = styled.div`
  display: flex;
  @media (max-width: 700px) {
    justify-content: space-between;
    margin: 5px 0px;
  }
`;

export const DuracionDeJornadaRowLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 15px;
  @media (max-width: 700px) {
    margin-left: 0px;
  }
`;

export const FlexRowDuracionDeJornada = styled.div`
  display: flex;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
