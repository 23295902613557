import styled from 'styled-components';

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  width: fit-content;
  border-radius: 4px;

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const DropdownContent = styled.div`
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 180px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  max-height: 223px;
  border: 1px solid #D9D9DA;
  overflow-y: auto;
  width: 100%;
  z-index: 1;
  margin-top: 5px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
  right: 0;
`;

export const DropdownContentOption = styled.p`
color: black;
padding: 8px 16px;
margin-bottom: 0px;
margin-top: 0px;
text-decoration: none;
display: block;
background: #FFFFFF 0% 0% no-repeat padding-box;
border-bottom: 1px solid #F3F3F3;
font-size: 14px;

&:hover {
    background-color: #f1f1f1;
    font-weight: bold;
}
`;

export const Button = styled.button`
  font: Bold 16px/19px Lato;
  letter-spacing: 0;
  background: #262A33 0% 0% no-repeat padding-box;
  border: none;
  color: white;
  margin-left: 10px;
  padding: 10px 22px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;

  &:disabled {
    background-color: #E0E1E2;
    color: #B0B0B0;
    opacity: 0.38;
  }

  @media only screen and (max-width: 1000px) {
    width: 100%;
    margin-left: 0px;
  }
`;

export const ChevronIcon = styled.span`
  margin-left: 5px;
`;
