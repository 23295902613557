import styled from "styled-components";

export const SwitchDiv = styled.div`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

interface SliderInterface {
  checked: boolean;
  disabled: boolean;
}

export const Slider = styled.div<SliderInterface>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => (props.disabled ? "black" : props.checked ? "#B7D32D" : "#ccc")};
  transition: 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    transform: ${(props) =>
      props.checked ? "translateX(26px)" : "translateX(0px)"};
  }

  /* Rounded sliders */
  &.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
`;
