import styled from "styled-components";

export const Contenedor = styled.div``;

export const ContenedorDeNotificaciones = styled.div`
  margin-bottom: 45px;
  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }
`;

export const AlertContainer = styled.p`
  position: relative;
  padding: 20px;
  margin-left: 45px;
  margin-right: 45px;
  background: #db282833 0% 0% no-repeat padding-box;
  border: 1px solid #db282899;
  border-radius: 4px;
`;

export const AlertContainerTitle = styled.span`
  position: relative;
  font-family: "Lato";
  font-size: 18px;
  font-weight: bold;
  color: #db2828;
`;

export const AlertContainerText = styled.span`
  font-family: "Regular";
  font-size: 14px;
  color: #db282899;
`;

export const AlertClose = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #db2828;
`;

export const CerrarSesionDiv = styled.div`
  position: relative;
  width: calc(100vw - 381px);
  margin-right: 45px;
  margin-left: 45px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0px;
  z-index: 2;
  @media (max-width: 1000px) {
    width: calc(100vw - 90px);
  }
  @media (max-width: 680px) {
    width: calc(100vw - 65px);
  }
`;

export const Hyperlink = styled.span`
  margin-left: 5px;
  font-family: "Manrope Regular";
  font-size: 14px;
  color: #2185d0;
  text-decoration: underline #2185d0;
  cursor: pointer;
  padding-top: 12px;
  display: inline-block;
`;

export const ContenedorHorizontal = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1790px) {
    flex-direction: column-reverse;
  }
`;

export const ContenedorDeEventos = styled.div`
  width: 600px;
  margin-top: -35px;
  margin-bottom: auto;
  position: relative;
  padding-right: 45px;
  @media (max-width: 1790px) {
    position: relative;
    width: calc(100vw - 412px);
    margin: 16px;
    margin-bottom: auto;
    position: relative;
    padding: 16px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dededf;
    border-radius: 4px;
    margin-right: 45px;
    margin-left: 45px;
    margin-bottom: 15px;
  }
  @media (max-width: 1000px) {
    position: relative;
    width: calc(100vw - 122px);
    margin: 16px;
    margin-bottom: auto;
    position: relative;
    padding: 16px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dededf;
    border-radius: 4px;
    margin-right: 45px;
    margin-left: 45px;
    margin-bottom: 15px;
  }
  @media (max-width: 680px) {
    width: calc(100vw - 67px);
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const ContenedorDeTabla = styled.div`
  width: 100%;
  height: 457px;
  margin: 16px;
  padding: 16px;
  margin-left: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  @media (max-width: 1790px) {
    position: relative;
    width: calc(100vw - 412px);
    margin: 16px;
    padding: 16px;
    margin-right: 45px;
    margin-left: 45px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dededf;
    border-radius: 4px;
    max-height: unset;
  }
  @media (max-width: 1000px) {
    position: relative;
    width: calc(100vw - 122px);
    margin: 16px;
    padding: 16px;
    margin-right: 45px;
    margin-left: 45px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dededf;
    border-radius: 4px;
    max-height: unset;
  }
  @media (max-width: 680px) {
    width: calc(100vw - 67px);
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const ButtonDocumentos = styled.button`
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  width: 100%;
  text-align: inherit;
  display: flex;
  padding-left: 15px;
  align-items: center;
  margin-top: 10px;
`;

export const Dot = styled.span`
  height: 7px;
  width: 7px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
`;

export const CenterFoto = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

export const DivUserFoto = styled.div`
  width: 300px;
  height: 175px;
  background-color: #262a33;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
  opacity: 1;
`;

export const DivInfo = styled.div`
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  width: calc(100% - 32px);
  flex-direction: column;
  display: flex;
  height: 147px;
`;

export const TituloInfo = styled.span`
  font-weight: bold;
`;

export const TextInfo = styled.span`
  margin-bottom: 5px;
`;

export const ContenedorDeColaboradores = styled.div`
  position: relative;
  width: calc(100vw - 412px);
  margin: 16px;
  margin-bottom: auto;
  position: relative;
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-right: 45px;
  margin-left: 45px;
  margin-bottom: 15px;
  @media (max-width: 1000px) {
    width: calc(100vw - 122px);
    max-width: 2040px;
  }
  @media (max-width: 680px) {
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100vw - 67px);
  }
`;

export const AddColaboradorDiv = styled.div`
  display: flex;
  align-items: flex-end;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const ButtonBigDiv = styled.div`
  @media (max-width: 1000px) {
    margin-top: 10px;
  }
`;

export const DivColaboradoresAreas = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const ContenedorLogEmpresa = styled.div`
  position: relative;
  width: calc(100vw - 412px);
  margin: 16px;
  margin-bottom: auto;
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-right: 45px;
  margin-left: 45px;
  margin-bottom: 15px;
  @media (max-width: 1000px) {
    width: calc(100vw - 122px);
  }
  @media (max-width: 680px) {
    width: calc(100vw - 67px);
    margin-left: 16px;
    margin-right: 16px;
  }
`;
