import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useViewPortSize from "../../Hooks/useViewPortSize";
import {
  ContenedorIconos,
  Flex,
  Flex1Inline,
  Flex3Inline,
  FlexInlineTextLabel,
  FlexRowInline,
  InputMini,
  TextLabel,
} from "../../Pages/communStyled";
import Checkbox from "../Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  ccHoursBlur,
  ccHoursChange,
  cruzaCero,
  esHorarioValido,
  getHorasExtra,
  getHorasPorTipo,
  getTipoDeHorario,
  presentarNumero,
  sacarDiferencia,
  splitValues,
  sumatoria,
  sumatoriaDeTiposDeHorario,
  verificarHorarioAnteriorYSiguiente,
} from "../../Utils/sharedFunctions";
import { ContenedorLables } from "./FilaDeHorarioNormalStyled";
interface Props {
  labelDay: string;
  onValue: (value: any) => void;
  onAlert: () => void;
  disabled: boolean;
  calculoDeHorario: string;
  duracionDeLaJornada: any;
  diaAnterior: string;
  diaSiguiente: string;
  inicialObject: any;
}

const FilaDeHorarioNormal = forwardRef(
  (
    {
      labelDay,
      onValue,
      onAlert,
      disabled,
      calculoDeHorario,
      duracionDeLaJornada,
      diaAnterior,
      diaSiguiente,
      inicialObject,
    }: Props,
    ref
  ) => {
    const { viewPortWidth } = useViewPortSize();
    const [checkboxFlag, setCheckboxFlag] = useState(false);
    const [indexArray, setIndexArray] = useState(0);
    const [inicial, setInicial] = useState<string>("");
    const [final, setFinal] = useState<string>("");
    const [continuoCheckBox, setContinuoCheckBox] = useState(false);
    const [valid, setValid] = useState(true);
    const [array, setArray] = useState<any>([]);
    const [alertAlreadyEmitted, setAlertAlreadyEmitted] = useState(false);
    const [arrayToEmit, setArrayToEmit] = useState<any>({
      tipoDeHorario: inicialObject.tipoDeHorario || "",
      total: inicialObject.total || [],
      horarios: inicialObject.horarios || [],
      horariosPorTipo: inicialObject.horariosPorTipo || {},
      checkboxFlag: inicialObject.checkboxFlag || false,
      cruzaCero: inicialObject.cruzaCero || false,
      acomuladoDeHorasAlaSemana: inicialObject.acomuladoDeHorasAlaSemana || {
        total: [],
        horasExtra: [],
        tipoDeHorario: "",
      },
    });

    useImperativeHandle(ref, () => ({}));

    const regresarUnRegistro = () => {
      if (checkboxFlag) {
        const index = indexArray - 1;
        setIndexArray(index);
        setInicial(presentarNumero(array[index]["entrada"]));
        setFinal(presentarNumero(array[index]["salida"]));
        setContinuoCheckBox(array[index]["diferenciaCheckmark"]);
        setValid(true);
      }
    };

    const adelantarUnRegistro = () => {
      if (checkboxFlag) {
        const index = indexArray + 1;
        setIndexArray(index);
        if (index < array.length) {
          setInicial(presentarNumero(array[index]["entrada"]));
          setFinal(presentarNumero(array[index]["salida"]));
          setContinuoCheckBox(array[index]["diferenciaCheckmark"]);
        } else {
          setInicial("");
          setFinal("");
          setContinuoCheckBox(false);
        }
        setValid(true);
      }
    };

    const removeRegister = () => {
      if (checkboxFlag) {
        const index = indexArray - 1;
        const newArray = array.splice(index);
        setArray(newArray);
        setIndexArray(index);
        const newValue = sumatoria(newArray);
        const horariosPorTipo = sumatoriaDeTiposDeHorario(newArray);
        const horaDeEntrada = newArray[0]["entrada"];
        const tipoDeHorario = getTipoDeHorario(horariosPorTipo, horaDeEntrada);
        setInicial(presentarNumero(newArray[index]["entrada"]));
        setFinal(presentarNumero(newArray[index]["salida"]));
        setContinuoCheckBox(newArray[index]["diferenciaCheckmark"]);

        const horasExtra = getHorasExtra(
          newValue,
          tipoDeHorario.acomuladoDeHorasAlaSemana === "mixto"
            ? duracionDeLaJornada["mixto"]
            : tipoDeHorario.acomuladoDeHorasAlaSemana === "nocturno"
            ? duracionDeLaJornada["nocturna"]
            : duracionDeLaJornada["diurna"]
        );
        const arrayToEmitNow = {
          ...arrayToEmit,
          horariosPorTipo,
          horarios: [...newArray],
          checkboxFlag: checkboxFlag,
          tipoDeHorario: tipoDeHorario.tipoDeHorario,
          total: horasExtra.total,
          acomuladoDeHorasAlaSemana: {
            total: newValue,
            horasExtra: horasExtra.horasExtra,
            tipoDeHorario: tipoDeHorario.acomuladoDeHorasAlaSemana,
          },
        };
        setArrayToEmit(arrayToEmitNow);
        onValue(arrayToEmitNow);
      }
    };

    const addRegister = () => {
      if (checkboxFlag) {
        const arrayCopy = [...array];
        let entrada = inicial || [0, 0];
        entrada = entrada && splitValues(entrada);
        let salida = final || [0, 0];
        salida = salida && splitValues(salida);
        const cruzaCeroBool = cruzaCero(
          arrayCopy.length > 0 ? arrayCopy[0]["entrada"] : entrada,
          salida
        );
        if (
          entrada &&
          salida &&
          esHorarioValido(
            arrayCopy,
            indexArray,
            entrada,
            salida,
            diaAnterior
          ) &&
          verificarHorarioAnteriorYSiguiente(
            indexArray,
            entrada,
            salida,
            cruzaCeroBool,
            diaAnterior,
            diaSiguiente
          )
        ) {
          setValid(true);
          const [resultadoHoras, resultadoMinutos] = sacarDiferencia(
            entrada,
            salida
          );
          const diferencia =
            resultadoHoras === 0 && resultadoMinutos === 0
              ? [24, 0]
              : [resultadoHoras, resultadoMinutos];

          let diferenciaContinuo, diferenciaCheckmark, horarioContinuoPorTipo;
          ///////////
          // if (arrayCopy[indexArray + 1]) {
          //   const diferenciaContinuoTop = sacarDiferencia(
          //     salida,
          //     arrayCopy[indexArray + 1]["entrada"]
          //   );
          //   if (diferenciaContinuoTop[0] === 0 && !arrayCopy[indexArray + 1].diferenciaCheckmark) {
          //     if (!alertAlreadyEmitted) {
          //       arrayCopy[indexArray + 1].diferenciaCheckmark = true;
          //       onAlert();
          //       setAlertAlreadyEmitted(true);
          //     }
          //   }

          // }
          ////////
          if (arrayCopy[indexArray - 1]) {
            diferenciaContinuo = sacarDiferencia(
              arrayCopy[indexArray - 1]["salida"],
              entrada
            );
            diferenciaCheckmark = continuoCheckBox;
            if (diferenciaContinuo[0] === 0 && !diferenciaCheckmark) {
              if (!alertAlreadyEmitted) {
                onAlert();
                setAlertAlreadyEmitted(true);
                diferenciaCheckmark = true;
              }
            }
            horarioContinuoPorTipo = getHorasPorTipo(
              entrada,
              diferenciaContinuo
            );
          } else {
            diferenciaContinuo = [0, 0];
            horarioContinuoPorTipo = [
              [0, 0],
              [0, 0],
            ];
            diferenciaCheckmark = false;
          }
          const horarioNormalPorTipo = getHorasPorTipo(salida, diferencia);

          if (arrayCopy.length === indexArray) {
            arrayCopy.push({
              entrada,
              salida,
              diferencia,
              diferenciaContinuo,
              diferenciaCheckmark,
              horarioNormalDiurno: horarioNormalPorTipo[0],
              horarioNormalNocturno: horarioNormalPorTipo[1],
              horarioContinuoDiurno: horarioContinuoPorTipo[0],
              horarioContinuoNocturno: horarioContinuoPorTipo[1],
            });
            setArray([...arrayCopy]);
          } else {
            arrayCopy[indexArray] = {
              entrada,
              salida,
              diferencia,
              diferenciaContinuo,
              diferenciaCheckmark,
              horarioNormalDiurno: horarioNormalPorTipo[0],
              horarioNormalNocturno: horarioNormalPorTipo[1],
              horarioContinuoDiurno: horarioContinuoPorTipo[0],
              horarioContinuoNocturno: horarioContinuoPorTipo[1],
            };
            setArray([...arrayCopy]);
          }
          setInicial("");
          setFinal("");
          setContinuoCheckBox(false);
          setIndexArray(indexArray + 1);
          const horariosPorTipo = sumatoriaDeTiposDeHorario(arrayCopy);
          const horaDeEntrada = arrayCopy[0]["entrada"];
          const tipoDeHorario = getTipoDeHorario(
            horariosPorTipo,
            horaDeEntrada
          );
          const newValue = sumatoria(arrayCopy);
          const horasExtra = getHorasExtra(
            newValue,
            tipoDeHorario.acomuladoDeHorasAlaSemana === "mixto"
              ? duracionDeLaJornada["mixto"]
              : tipoDeHorario.acomuladoDeHorasAlaSemana === "nocturno"
              ? duracionDeLaJornada["nocturna"]
              : duracionDeLaJornada["diurna"]
          );
          const arrayToEmitNow = {
            ...arrayToEmit,
            horariosPorTipo,
            horarios: arrayCopy,
            checkboxFlag: checkboxFlag,
            cruzaCero: cruzaCeroBool,
            tipoDeHorario: tipoDeHorario.tipoDeHorario,
            total: horasExtra.total,
            acomuladoDeHorasAlaSemana: {
              total: newValue,
              horasExtra: horasExtra.horasExtra,
              tipoDeHorario: tipoDeHorario.acomuladoDeHorasAlaSemana,
            },
          };
          setArrayToEmit(arrayToEmitNow);
          onValue(arrayToEmitNow);
        } else {
          setValid(false);
        }
      }
    };

    useEffect(() => {
      if (inicialObject) {
        setArrayToEmit({
          ...arrayToEmit,
          tipoDeHorario: inicialObject.tipoDeHorario || "",
          total: inicialObject.total || [],
          horarios: inicialObject.horarios || [],
          horariosPorTipo: inicialObject.horariosPorTipo || {},
          checkboxFlag: inicialObject.checkboxFlag || false,
          cruzaCero: inicialObject.cruzaCero || false,
          acomuladoDeHorasAlaSemana:
            inicialObject.acomuladoDeHorasAlaSemana || {
              total: [],
              horasExtra: [],
              tipoDeHorario: "",
            },
        });
        setArray(inicialObject.horarios || []);
        setIndexArray(inicialObject?.horarios?.length > 0 ? inicialObject?.horarios.length : 0);
        setCheckboxFlag(inicialObject.checkboxFlag);
        setValid(true);
      }
    }, [inicialObject]);

    return (
      <FlexRowInline invalid={!valid ? "true" : "false"}>
        <Flex1Inline>
          <label>
            <Checkbox
              removeMargin={true}
              checked={checkboxFlag}
              handleClick={(value: boolean) => setCheckboxFlag(value)}
              disabled={disabled}
            />
            {labelDay}
          </label>
        </Flex1Inline>
        {(checkboxFlag || viewPortWidth >= 1375) && (
          <>
            <Flex3Inline>
              {viewPortWidth < 1375 && (
                <FlexInlineTextLabel>Horario</FlexInlineTextLabel>
              )}
              <Flex style={{ columnGap: "15px" }}>
                <ContenedorIconos>
                  {!disabled && indexArray !== 0 && (
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      style={{ color: checkboxFlag ? "#b7d32d" : "black" }}
                      onClick={() => removeRegister()}
                    />
                  )}
                </ContenedorIconos>
                <ContenedorIconos>
                  {indexArray !== 0 && (
                    <FontAwesomeIcon
                      icon={faChevronCircleLeft}
                      onClick={() => regresarUnRegistro()}
                    />
                  )}
                </ContenedorIconos>
                <InputMini
                  type="text"
                  value={inicial}
                  onBlur={(e: any) => setInicial(ccHoursBlur(e.target.value))}
                  onChange={(e: any) =>
                    setInicial(ccHoursChange(e.target.value))
                  }
                  placeholder="00:00"
                  disabled={!checkboxFlag}
                />
                <ContenedorLables>
                  <TextLabel style={{ textAlign: "center" }}> a </TextLabel>
                </ContenedorLables>
                <InputMini
                  type="text"
                  value={final}
                  onBlur={(e: any) => setFinal(ccHoursBlur(e.target.value))}
                  onChange={(e: any) => setFinal(ccHoursChange(e.target.value))}
                  placeholder="00:00"
                  disabled={!checkboxFlag}
                />
                <ContenedorIconos>
                  {array.length > indexArray && (
                    <FontAwesomeIcon
                      icon={faChevronCircleRight}
                      onClick={() => adelantarUnRegistro()}
                    />
                  )}
                </ContenedorIconos>
                <ContenedorIconos>
                  {!disabled && (
                    <FontAwesomeIcon
                      icon={
                        array.length === indexArray
                          ? faPlusCircle
                          : faCheckCircle
                      }
                      style={{ color: checkboxFlag ? "#b7d32d" : "black" }}
                      onClick={() => addRegister()}
                    />
                  )}
                </ContenedorIconos>
              </Flex>
            </Flex3Inline>
            <Flex1Inline>
              {viewPortWidth < 1375 && (
                <FlexInlineTextLabel>Continuo</FlexInlineTextLabel>
              )}
              <Flex>
                {indexArray !== 0 && (
                  <Checkbox
                    checked={continuoCheckBox}
                    handleClick={(value: boolean) => setContinuoCheckBox(value)}
                    disabled={!checkboxFlag || disabled}
                  />
                )}
              </Flex>
            </Flex1Inline>
            {calculoDeHorario === "Por diá según horario establecido" && (
              <Flex1Inline>
                {viewPortWidth < 1375 && (
                  <FlexInlineTextLabel>Horas</FlexInlineTextLabel>
                )}
                <Flex>
                  <InputMini
                    style={{
                      border: `1px solid ${
                        arrayToEmit.acomuladoDeHorasAlaSemana.tipoDeHorario ===
                        "mixto"
                          ? "#FBBD08"
                          : arrayToEmit.acomuladoDeHorasAlaSemana
                              .tipoDeHorario === "nocturno"
                          ? "#DB2828"
                          : arrayToEmit.acomuladoDeHorasAlaSemana
                              .tipoDeHorario === "diurno"
                          ? "#B7D32D"
                          : "#ccc"
                      }`,
                    }}
                    type="text"
                    value={presentarNumero(arrayToEmit.total)}
                    disabled={true}
                  />
                </Flex>
              </Flex1Inline>
            )}
            {calculoDeHorario === "Por diá según horario establecido" && (
              <Flex1Inline>
                {viewPortWidth < 1375 && (
                  <FlexInlineTextLabel>Horas Extra</FlexInlineTextLabel>
                )}
                <Flex>
                  <InputMini
                    style={{
                      border: `1px solid ${
                        arrayToEmit.acomuladoDeHorasAlaSemana.tipoDeHorario ===
                        "mixto"
                          ? "#FBBD08"
                          : arrayToEmit.acomuladoDeHorasAlaSemana
                              .tipoDeHorario === "nocturno"
                          ? "#DB2828"
                          : arrayToEmit.acomuladoDeHorasAlaSemana
                              .tipoDeHorario === "diurno"
                          ? "#B7D32D"
                          : "#ccc"
                      }`,
                    }}
                    type="text"
                    value={presentarNumero(
                      arrayToEmit.acomuladoDeHorasAlaSemana.horasExtra
                    )}
                    disabled={true}
                  />
                </Flex>
              </Flex1Inline>
            )}
            {calculoDeHorario !== "Por diá según horario establecido" && (
              <Flex1Inline>
                {viewPortWidth < 1375 && (
                  <FlexInlineTextLabel>Totales</FlexInlineTextLabel>
                )}
                <Flex>
                  <InputMini
                    style={{
                      border: `1px solid ${
                        arrayToEmit.tipoDeHorario === "mixto"
                          ? "#FBBD08"
                          : arrayToEmit.tipoDeHorario === "nocturno"
                          ? "#DB2828"
                          : arrayToEmit.tipoDeHorario === "diurno"
                          ? "#B7D32D"
                          : "#ccc"
                      }`,
                    }}
                    type="text"
                    value={presentarNumero(
                      arrayToEmit.acomuladoDeHorasAlaSemana.total
                    )}
                    disabled={true}
                  />
                </Flex>
              </Flex1Inline>
            )}
          </>
        )}
      </FlexRowInline>
    );
  }
);

export default FilaDeHorarioNormal;
