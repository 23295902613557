import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore  } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import rootReducer from "./Redux/Reducers";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk]
});
const stripePromise = loadStripe('pk_test_51JMk4nDv4fylpGnTrDhWFU2Kp8DnRNeVx43V5YJ4f9s9yIcruyMOxjh1uvXnWf1NSVIOM5HNfoGzAsKHYuYSwOpj00PzHM70ji');


root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Provider store={store}>
    <Elements stripe={stripePromise} options={{locale: 'es'}}>
      <App />
      </Elements>
    </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
