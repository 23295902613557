import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Contenedor,
  ContenedorRegistro,
  FlexTable,
  FlexTableRow,
  Table,
} from "./SimpleTableStyled";
import { TextLabel, TextLabelTitle } from "../../Pages/communStyled";
import { scrollIntoEnd } from "../../Utils/sharedFunctions";

interface Props {
  title: string;
  columns: Array<{ label: string; value: string }>;
  rows: any[];
  onSelect: (row: any, index: number | null) => void;
  height?: string;
}

const SimpleTable = forwardRef(
  ({ title, columns, rows, onSelect, height }: Props, ref) => {
    const tableRef = useRef(null);
    const [activeRow, setActiveRow] = useState<number | null>(null);

    useImperativeHandle(ref, () => ({
      scrollIntoEndFunction,
    }));

    useEffect(() => {
      setActiveRow(null);
    }, [rows]);

    const scrollIntoEndFunction = () => {
      setTimeout(() => {
        scrollIntoEnd(tableRef);
      }, 0);
    };

    const onSelectFunction = (row: any, index: number) => {
      if (activeRow === index) {
        setActiveRow(null);
        onSelect(null, null);
      } else {
        setActiveRow(index);
        onSelect(row, index);
      }
    };

    return (
      <Contenedor>
        <TextLabelTitle>{title}</TextLabelTitle>
        <ContenedorRegistro style={{height: height || '200px'}}>
          <FlexTableRow>
            {columns.map((column) => (
              <FlexTable key={column.label}>
                <TextLabel style={{ width: "fit-content" }}>
                  {column.label}
                </TextLabel>
              </FlexTable>
            ))}
          </FlexTableRow>
          <Table ref={tableRef}>
            {rows.map((element, index) => (
              <FlexTableRow
                key={"row" + index}
                onClick={() => onSelectFunction(element, index)}
                activeclass={activeRow === index}
              >
                {columns.map((column) => (
                  <FlexTable key={element[column.value] + column.label + index}>
                    <TextLabel style={{ width: "fit-content" }}>
                      {element[column.value]}
                    </TextLabel>
                  </FlexTable>
                ))}
              </FlexTableRow>
            ))}
          </Table>
        </ContenedorRegistro>
      </Contenedor>
    );
  }
);

export default SimpleTable;
