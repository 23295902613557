import styled from "styled-components";

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  position: relative;
  border-radius: 4px;
`;

export const Span = styled.span`
  position: absolute;
  top: 13px;
  font-size: 13px;
  color: #767676;
  right: 11px;
`;

export const DropdownContent = styled.div`
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  background: #ffffff 0% 0% no-repeat padding-box;
  max-height: 223px;
  border: 1px solid #dededf;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow-y: auto;
  border-top: 0px;
  width: 100%;
  z-index: 4;
`;

export const DropdownContentOption = styled.p`
  color: black;
  padding: 5px 16px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-decoration: none;
  display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #f3f3f3;
  &:hover {
    background-color: #f1f1f1;
    font-weight: bold;
  }
`;
