import styled from "styled-components";

export const Contenedor = styled.div`
margin: 45px;`;

export const HorarioRowTitleDiv = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 18px;
`;

export const Tarjeta = styled.div`
margin-bottom: 10px;
padding: 16px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #DEDEDF;
border-radius: 4px;
& h3 {
    margin-top: 0px;
    font-size: 18px;
}
`;

export const TarjetaInfo = styled.div`
margin-bottom: 10px;
padding: 16px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #DEDEDF;
border-radius: 4px;
display: flex;
justify-content: space-evenly;
align-items: center;
flex-wrap: wrap;
gap: 10px;
`;

export const EditIcon = styled.div`
& svg {
color: #767676;
font-size: 16px;
    cursor: pointer;
    &:hover {
        color: #B7D32D;
        cursor: pointer;
    }
}
`;

export const HorariosItalic = styled.div`
margin-top: 20px;
    text-align: end;
    font-style: italic;
    font-size: 14px;
`;

export const HorariosRowTitles = styled.div`
display: flex;
& div {
width: 25%;
    text-align: center;
    background-color: #262A33;
    color: white;
    padding: 10px 0px;
}
    `;

export const HorariosRowNormal = styled.div`
display: flex;
& div {
    width: 25%;
    text-align: center;
    padding: 10px 0px;
    border-bottom: 1px solid #DEDEDF;
}
    `;

export const InputEditHorario = styled.input`
padding: 12px;
    width: 25%;
    text-align: center;
    border: 1px solid #ccc;
    border-top: none;
    cursor: pointer;
    resize: vertical;
    background: #F3F3F3 0% 0% no-repeat padding-box;
    `;

interface ButtonInterface {
    close: boolean;
}

export const TopButtonContainer = styled.div<ButtonInterface>`
  display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #DEDEDF;
    gap: 10px;
    & svg {
        font-size: 16px;
        color: #767676;
    }
    &:hover {
        background-color: #F3F3F3;
        cursor: pointer;
        & svg {
            color: ${(props) => props.close ? "#b7d32d" : "#FF0000"};
        }
    }
`;

export const TopButtonActionContainer = styled.div<ButtonInterface>`
   display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #DEDEDF;
    gap: 10px;
    & svg {
        font-size: 16px;
        color: #767676;
    }
    &:hover {
        cursor: pointer;
        & svg {
            color: ${(props) => props.close ? "#b7d32d" : "#FF0000"};
        }
    }
`;

interface ColorDivInterface {
    type: "Día de Descanso" |
    "Definido sin horario o Secuencia" |
    "Ordinario" |
    "Permiso con goce de sueldo" |
    "Permiso sin goce de sueldo" |
    "Incapacidad por enfermedad general" |
    "Incapacidad por maternidad" |
    "Incapacidad por riesgo de trabajo" |
    "Falta injustificada" |
    "Vacaciones" |
    "Descanso obligatorio" |
    "Suspensión" |
    "Retardo";
}

export const DateTitle = styled.h3`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
`;

export const ColorDiv = styled.div<ColorDivInterface>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props: ColorDivInterface) => {
        switch (props.type) {
            case "Día de Descanso":
                return "#c0c0c0";
            case "Definido sin horario o Secuencia":
                return "#F2ECA6";
            case "Ordinario":
                return "#FFFFFF";
            case "Permiso con goce de sueldo":
                return "#00FF00";
            case "Permiso sin goce de sueldo":
                return "#008000";
            case "Incapacidad por enfermedad general":
                return "#800000";
            case "Incapacidad por maternidad":
                return "#008080";
            case "Incapacidad por riesgo de trabajo":
                return "#FF0000";
            case "Falta injustificada":
                return "#800080";
            case "Vacaciones":
                return "#FFFF00";
            case "Descanso obligatorio":
                return "#808080";
            case "Suspensión":
                return "#ACFC9B";
            case "Retardo":
                return "#808000";
            default:
                return "#F3F3F3";
        }
    }};
`;

export const ColorInfoDiv = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const ContenedorBotonesTop = styled.div`
display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export const ContenedorBotonesTopHalf = styled.div`
display: flex;
    justify-content: space-between;
    gap: 10px;
    width: fit-content;
`;

export const ContenedorAccionesGenerales = styled.div`
margin-bottom: 10px;
padding: 16px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #DEDEDF;
border-radius: 4px;
display: flex;
gap: 10px;
justify-content: flex-end;
`;