import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  AplasamientoVigDiv,
  BitacoraDiv,
  ButtonBack,
  Carrito,
  CarritoSmall,
  CarritoText,
  CarritoTotalDiv,
  CartaCreditos,
  Contenedor,
  ContenedorCon,
  ContenedorConCon,
  ContenedorDeEmpresas,
  ContenedorInformacionDeFacturacion,
  ContenedorPlanes,
  ContenedorTransferenciaDeCreditos,
  CreditosH,
  CreditosY,
  CreditsNumber,
  CreditsText,
  DivCreditosP,
  DivInfoPaquetes,
  DivTimes,
  FirstRowDiv,
  MarginEndDDF,
  NavBar,
  PageContainer,
  PageContainerContent,
  PedidoDiv,
  PedidoElemento,
  SecondRowDiv,
  TextLabelC,
  TextLabelNav,
} from "./controlDeCuentaStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import paths from "../../paths";
import {
  Button,
  CopyrightBlack,
  DivTitulos,
  DivTitulosSubTitulo,
  DivTitulosTitulo,
  FootersBlack,
  HyperlinkSpan,
  InputGrande,
  Obligatorio,
  PrivacyNoticeBlack,
  TextLabel,
  TextLabelTitle,
  Alert,
  FlexInlineMarginTextLabel,
} from "../communStyled";
import {
  Checkbox,
  ConfirmationModal,
  FilterInput,
  Modal,
  QuantityManager,
  SortingTable,
  TermsAndConditions,
  Warning,
  Stripe,
  ChevronTabIcon,
  DomicilioFiscal,
  SearchDropdown,
} from "../../Components";
import { Subject } from "rxjs";
import { useEffect, useRef, useState } from "react";
import { apiRequest, apiRoutes } from "../../Api/services";
import { getRefreshToken, signOut } from "../../Services/tokenStorage";
import useViewPortSize from "../../Hooks/useViewPortSize";
import actions from "../../Redux/Actions";
import { useJwt } from "react-jwt";
import { scrollIntoView } from "../../Utils/sharedFunctions";

export default function ControlDeCuenta() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const alertRef = useRef(null);
  const sortingTableRef = useRef<any>();
  const { smallViewPort, viewPortWidth } = useViewPortSize();
  const informacionGeneralDeUsuario: any = useSelector(
    (store: any) => store.informacionGeneralDeUsuario
  );
  const informacionGeneral: any = useSelector(
    (store: any) => store.informacionGeneral
  );
  const alertSubject = new Subject<string>();
  const successfulSubject = new Subject<string>();
  const [confirmationDeleteCuentaModal, setConfirmationDeleteCuentaModal] =
    useState<boolean>(false);
  const [termsAndConditionsModal, setTermsAndConditionsModal] =
    useState<boolean>(false);
  const [uma, setUMA] = useState<number>(0);
  const [rfc, setRFC] = useState<string>("");
  const token = getRefreshToken();
  const { decodedToken, isExpired } = useJwt(token || "");
  const [rfcIsInvalid, setRfcIsInvalid] = useState<boolean>(false);
  const [rfcIsTouched, setRfcIsTouched] = useState<boolean>(false);
  const [rfcLengthInvalid, setRfcLengthInvalid] = useState<boolean>(false);
  const [factura, setFactura] = useState<boolean>(true);
  const [disableStripe, setDisableStripe] = useState<boolean>(false);
  const [creditosAComprar, setCreditosAComprar] = useState<number>(0);
  const [totalCreditosEnPesos, setTotalCreditosEnPesos] = useState<any>(0.0);
  const [nombre, setNombre] = useState<string>("");
  const [tabOpenComprarCreditos, setTabOpenComprarCreditos] =
    useState<boolean>(false);
  const [tabOpenDatosDeFacturacion, setTabOpenDatosDeFacturacion] =
    useState<boolean>(false);
  const [tabOpenAplasamientoDeVigencias, setTabOpenAplasamientoDeVigencias] =
    useState<boolean>(false);
  const [correoElectronico, setCorreoElectronico] = useState<string>("");
  const [referencias, setReferencias] = useState<string>("");
  const [logCopyArray, setLogCopyArray] = useState<any>([]);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState<string>("");
  const [creditosATransferir, setCreditosATransferir] = useState<number>(5);
  const [invalidUsuariosArray, setInvalidUsuariosArray] =
    useState<boolean>(false);
  const [arrayUsuarios, setArrayUsuarios] = useState<any>([]);
  const [disableCreditosATransferir, setDisableCreditosATransferir] =
    useState<boolean>(false);
  const [empresasPropietarioArray, setEmpresasPropietarioArray] = useState<any>(
    []
  );
  const [empresasCreditosArray, setEmpresasCreditosArray] = useState<any>([]);
  const [totalCreditos, setTotalCreditos] = useState<number>(0);
  const [isCorreoElectronicoValid, setIsCorreoElectronicoValid] =
    useState<boolean>(false);
  const [isCorreoElectronicoTouched, setIsCorreoElectronicoTouched] =
    useState<boolean>(false);
  const [domicilioFiscalForm, setDomicilioFiscalForm] = useState<any>({
    municipio: "",
    estado: "",
    colonia: "",
    calle: "",
    numero: "",
    telefono: "",
    codigoPostal: "",
  });
  const [columnsArrayLog] = useState<any>([
    {
      label: "Fecha",
      valueLabel: "fecha",
      filtrable: true,
      widthPX: 200,
      type: "Date",
    },
    {
      label: "Movimiento",
      valueLabel: "movimiento",
      filtrable: true,
      widthPX: 500,
      type: "String",
    },
    {
      label: "Tipo",
      valueLabel: "tipo",
      filtrable: true,
      widthPX: 150,
      type: "String",
    },
    {
      label: "Saldo",
      valueLabel: "saldo",
      filtrable: false,
      widthPX: 150,
      type: "Number",
    },
  ]);

  const [columnsArray] = useState<any>([
    {
      label: "Nombre",
      valueLabel: "nombre",
      filtrable: true,
      widthPX: 150,
      type: "String",
    },
    {
      label: "Vigencia",
      valueLabel: "vigencia",
      filtrable: true,
      widthPX: 150,
      type: "Date",
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      // @ts-ignore
      if (!isExpired && decodedToken && decodedToken["username"]) {
        await dispatch(
          actions.informacionGeneralDeUsuarioActions.replaceInformacionGeneralDeUsuario(
            {
              // @ts-ignore
              usuario: decodedToken["username"],
            }
          )
        );
      }
    };

    fetchData();
  }, [decodedToken]);

  useEffect(() => {
    if (informacionGeneralDeUsuario.usuario) {
      loadEmpresas();
    }
  }, [informacionGeneralDeUsuario.usuario, token]);

  useEffect(() => {
    initFunction();
  }, [informacionGeneralDeUsuario.informacionDeFacturacion]);

  useEffect(() => {
    setLogCopyArray([...informacionGeneralDeUsuario.estadoDeCuenta]);
  }, [informacionGeneralDeUsuario.estadoDeCuenta]);

  const initFunction = () => {
    if (
      informacionGeneralDeUsuario.informacionDeFacturacion.rfc !== "" ||
      informacionGeneralDeUsuario.informacionDeFacturacion.nombre !== "" ||
      informacionGeneralDeUsuario.informacionDeFacturacion.correoElectronico !==
        "" ||
      informacionGeneralDeUsuario.informacionDeFacturacion.referencias !== ""
    ) {
      setRFC(informacionGeneralDeUsuario.informacionDeFacturacion.rfc);
      setNombre(informacionGeneralDeUsuario.informacionDeFacturacion.nombre);
      setCorreoElectronico(
        informacionGeneralDeUsuario.informacionDeFacturacion.correoElectronico
      );
      setReferencias(
        informacionGeneralDeUsuario.informacionDeFacturacion.referencias
      );
    }
    setLogCopyArray([...informacionGeneralDeUsuario.estadoDeCuenta]);

    const fetchUMA = async () => {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.uma,
      });
      if (error) {
        alertScroll("Hubo un error en el servidor");
      }
      if (data && data.uma) {
        setUMA(parseFloat((data.uma * 15).toFixed(2)));
        dispatch(
          actions.informacionGeneralActions.replaceInformacionGeneral({
            uma: data.uma,
            smg: data.smg,
            smf: data.smf,
            umi: data.umi,
          })
        );
      }
    };

    if (informacionGeneral.uma === 0) {
      fetchUMA();
    } else {
      setUMA(parseFloat((informacionGeneral.uma * 15).toFixed(2)));
    }
    setEmpresasPropietarioArray(
      informacionGeneralDeUsuario.empresas.filter(
        (empresa: { creador: string }) => empresa.creador === "Propietario"
      )
    );
  };

  useEffect(() => {
    setRfcIsInvalid(rfc === "" && rfcIsTouched && factura);
    setRfcLengthInvalid(
      rfcIsTouched &&
        factura &&
        (!rfc || (rfc.length !== 12 && rfc.length !== 13))
    );
  }, [rfc]);

  useEffect(() => {
    setTotalCreditos(empresasCreditosArray.reduce(sumarCreditos, 0));
  }, [empresasCreditosArray]);

  const regex = /[^\w-@.\s]/g;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const omit_special_char_email = (string: any) => {
    let newString = string.replace(regex, "");
    setCorreoElectronico(newString);
    setIsCorreoElectronicoValid(emailRegex.test(newString.toLowerCase()));
  };

  const loadEmpresas = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.cargarEmpresas,
    });
    if (data) {
      if (data.message) {
        signOut();
        alertSubject.next(data.message);
      } else {
        const empresas = data["empresas"].map((empresa: any) => {
          return {
            ...empresa,
            creador:
              informacionGeneralDeUsuario.usuario === empresa.creador ||
              correoElectronico === empresa.creador
                ? "Propietario"
                : "Asistente",
          };
        });
        await dispatch(
          actions.informacionGeneralDeUsuarioActions.replaceInformacionGeneralDeUsuario(
            {
              empresas: empresas,
              creditos: data.creditos,
              estadoDeCuenta: data.estadoDeCuenta,
              informacionDeFacturacion: data.informacionDeFacturacion,
            }
          )
        );
      }
    }
    if (error) {
      alertSubject.next("Hubo un error en el servidor");
      signOut();
    }
  };

  const volverAControlDeEmpresas = () => {
    history(paths.Base);
  };

  const alertScroll = async (text: string) => {
    alertSubject.next(text);
    alertRef?.current &&
      (alertRef.current as HTMLDivElement).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  };

  const successfulAlertScroll = async (text: string) => {
    successfulSubject.next(text);
    alertRef?.current &&
      (alertRef.current as HTMLDivElement).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  };

  const removeAccount = async () => {
    setConfirmationDeleteCuentaModal(false);
    alertScroll("");
    for (const empresa of informacionGeneralDeUsuario.empresas) {
      if (empresa.creador === "Propietario") {
        alertScroll("No puedes borrar una cuenta con empresas propias");
        return;
      }
    }
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.eliminarCuenta,
    });
    if (error) {
      alertSubject.next("Hubo un error en el servidor");
      return;
    }
    if (data) {
      if (data.message && data.message === "Proceed") {
        signOut();
      } else {
        alertScroll("Hubo un error en el servidor");
      }
    }
  };

  const onValueSearchDropDown = (value: {
    searchValue: string;
    filter: { type: string; valueLabel: string | number };
  }) => {
    if (value.searchValue !== "") {
      if (value.filter.type === "Date") {
        setLogCopyArray(
          informacionGeneralDeUsuario.estadoDeCuenta.filter((row: any) => {
            const date = new Date(row[value.filter.valueLabel]);
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            const dateS =
              month < 10
                ? `${day}/0${month}/${year}`
                : `${day}/${month}/${year}`;
            return dateS.includes(value.searchValue.toString());
          })
        );
      } else {
        setLogCopyArray(
          informacionGeneralDeUsuario.estadoDeCuenta.filter((row: any) => {
            return row[value.filter.valueLabel]
              .toString()
              .toUpperCase()
              .includes(value.searchValue.toString().toUpperCase());
          })
        );
      }
    } else {
      setLogCopyArray([...informacionGeneralDeUsuario.estadoDeCuenta]);
    }
  };

  const transferirCreditos = async () => {
    setDisableCreditosATransferir(true);
    const body = {
      CreditosATransferir: creditosATransferir,
      UsuarioSelecionado: usuarioSelecionado,
    };
    const [error, response] = await apiRequest({
      method: "post",
      url: apiRoutes.transferirCreditos,
      data: body,
    });
    if (error) {
      alertScroll("Hubo un error en el servidor");
      return;
    }
    if (response) {
      if (response.message === "ServerError") {
        alertScroll("Hubo un error en el servidor");
      } else if (response.message === "CreditosInvalidos") {
        alertScroll("La cantidad de Créditos es Invalida");
      } else if (response.message === "CuentaBase") {
        alertScroll("No puedes trandferir creditos a tu cuenta base");
      } else if (response.message === "InvalidUser") {
        alertScroll("Esta Cuenta no existe");
      } else {
        successfulAlertScroll("La transferencia a sido procesada con éxito");
        await dispatch(
          actions.informacionGeneralDeUsuarioActions.replaceInformacionGeneralDeUsuario(
            {
              creditos: response.creditosRestantes,
              estadoDeCuenta: response.estadoDeCuenta,
            }
          )
        );
        setUsuarioSelecionado("");
        setArrayUsuarios([]);
      }
      setDisableCreditosATransferir(false);
    }
  };

  const onValueUsuarios = (value: any) => {
    setUsuarioSelecionado(value);
    setArrayUsuarios([]);
  };

  const getUsuariosList = async (Text: string) => {
    setUsuarioSelecionado(Text);
    if (Text !== "") {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.usariosList + Text,
      });
      if (error) {
        return { message: "Hubo un error en el servidor" };
      }
      setInvalidUsuariosArray(0 >= data.length);
      setArrayUsuarios(data || []);
    } else {
      setArrayUsuarios([]);
      setInvalidUsuariosArray(false);
    }
  };

  const onValueQuantityCreditosAComprar = (count: any) => {
    setCreditosAComprar(count);
    if (count > 450) {
      setTotalCreditosEnPesos((count * uma * 0.35).toFixed(2));
    } else if (count > 400) {
      setTotalCreditosEnPesos((count * uma * 0.45).toFixed(2));
    } else if (count > 350) {
      setTotalCreditosEnPesos((count * uma * 0.5).toFixed(2));
    } else if (count > 300) {
      setTotalCreditosEnPesos((count * uma * 0.55).toFixed(2));
    } else if (count > 250) {
      setTotalCreditosEnPesos((count * uma * 0.6).toFixed(2));
    } else if (count > 200) {
      setTotalCreditosEnPesos((count * uma * 0.65).toFixed(2));
    } else if (count > 150) {
      setTotalCreditosEnPesos((count * uma * 0.7).toFixed(2));
    } else if (count > 100) {
      setTotalCreditosEnPesos((count * uma * 0.75).toFixed(2));
    } else if (count > 50) {
      setTotalCreditosEnPesos((count * uma * 0.8).toFixed(2));
    } else if (count > 20) {
      setTotalCreditosEnPesos((count * uma * 0.85).toFixed(2));
    } else if (count > 10) {
      setTotalCreditosEnPesos((count * uma * 0.9).toFixed(2));
    } else setTotalCreditosEnPesos((count * uma).toFixed(2));
  };

  const precioPorCreditosArray = [
    { CreditsNumber: "1", CreditsText: "Crédito", Price: uma },
    {
      CreditsNumber: "+10",
      CreditsText: "Créditos",
      Price: (uma * 0.9).toFixed(2),
    },
    {
      CreditsNumber: "+20",
      CreditsText: "Créditos",
      Price: (uma * 0.85).toFixed(2),
    },
    {
      CreditsNumber: "+50",
      CreditsText: "Créditos",
      Price: (uma * 0.8).toFixed(2),
    },
    {
      CreditsNumber: "+100",
      CreditsText: "Créditos",
      Price: (uma * 0.75).toFixed(2),
    },
    {
      CreditsNumber: "+150",
      CreditsText: "Créditos",
      Price: (uma * 0.7).toFixed(2),
    },
    {
      CreditsNumber: "+200",
      CreditsText: "Créditos",
      Price: (uma * 0.65).toFixed(2),
    },
    {
      CreditsNumber: "+250",
      CreditsText: "Créditos",
      Price: (uma * 0.6).toFixed(2),
    },
    {
      CreditsNumber: "+300",
      CreditsText: "Créditos",
      Price: (uma * 0.55).toFixed(2),
    },
    {
      CreditsNumber: "+350",
      CreditsText: "Créditos",
      Price: (uma * 0.5).toFixed(2),
    },
    {
      CreditsNumber: "+400",
      CreditsText: "Créditos",
      Price: (uma * 0.45).toFixed(2),
    },
    {
      CreditsNumber: "+450",
      CreditsText: "Créditos",
      Price: (uma * 0.4).toFixed(2),
    },
  ];

  const sumarCreditos = (total: any, num: { meses: any }) => {
    return total + num.meses;
  };

  const setStripeToken = (token: { id: any; card: { country: any } }) => {
    if (rfc && !rfcIsInvalid && !rfcLengthInvalid) {
      dispatch(
        actions.informacionGeneralDeUsuarioActions.replaceInformacionGeneralDeUsuario(
          // @ts-ignore
          { sToken: { id: token.id, country: token.card.country } }
        )
      );
    } else {
      alertSubject.next(
        rfc.length !== 13 && rfc.length !== 12
          ? "La longitud del RFC no es valida"
          : "El RFC es requerido"
      );
      scrollIntoView(alertRef);
    }
  };

  useEffect(() => {
    informacionGeneralDeUsuario.sToken.id && makePayment();
  }, [informacionGeneralDeUsuario.sToken]);

  const makePayment = async () => {
    if (!rfc || rfc.length === 13 || rfc.length === 12 || !factura) {
      setDisableStripe(true);
      const body = {
        numCreditos: creditosAComprar,
        factura: factura,
        informacionDeFacturacion: {
          rfc: rfc,
          nombre: nombre,
          correoElectronico: correoElectronico,
          referencias: referencias,
          ...domicilioFiscalForm,
        },
        token: informacionGeneralDeUsuario.sToken,
      };
      try {
        const [error, response] = await apiRequest({
          method: "post",
          url: apiRoutes.chargeStripe,
          data: body,
        });
        if (error) {
          return { message: "Hubo un error en el servidor" };
        }
        if (response) {
          if (response && response.message === "ServerError") {
            alertScroll("Hubo un error en el servidor");
          } else if (response && response.message === "pagoAceptado") {
            successfulAlertScroll("Tu pago a sido procesado con éxito");
            dispatch(
              actions.informacionGeneralDeUsuarioActions.replaceInformacionGeneralDeUsuario(
                {
                  creditos: response.creditosRestantes,
                  estadoDeCuenta: response.estadoDeCuenta,
                }
              )
            );
            setCreditosAComprar(0);
            setTotalCreditosEnPesos(0.0);
          } else if (response && response.message === "CardDeclined") {
            alertScroll("Tu tarjeta ha sido rechazada");
          } else if (response && response.message === "NOMX") {
            alertScroll("Solo permitimos pago con tarjeta Mexicana");
          } else {
            alertScroll("Hubo un error en el servidor");
          }
          setDisableStripe(false);
        }
      } catch {
        alertScroll("Hubo un error en el servidor");
      }
    } else {
      setRfcIsTouched(true);
    }
  };

  const aplicarCargo = async () => {
    setDisableCreditosATransferir(true);
    const [error, response] = await apiRequest({
      method: "post",
      url: apiRoutes.aplicarCargo,
      data: {
        empresasCreditosArray: empresasCreditosArray.map(
          (data: { id: any; meses: any }) => {
            return { id: data.id, meses: data.meses };
          }
        ),
      },
    });
    if (error) {
      return { message: "Hubo un error en el servidor" };
    }
    if (response) {
      if (response["empresas"]) {
        response["empresas"] = response["empresas"].map(
          (empresa: { creador: any }) => {
            return {
              ...empresa,
              creador:
                informacionGeneralDeUsuario.usuario === empresa.creador
                  ? "Propietario"
                  : "Asistente",
            };
          }
        );
      }
      if (response.message === "ServerError") {
        alertScroll("Hubo un error en el servidor");
      } else if (response.message === "CreditosInsuficientes") {
        alertScroll("Tus Créditos son insuficientes");
      } else {
        successfulAlertScroll("Tu cargo a sido procesado con éxito");
        await dispatch(
          actions.informacionGeneralDeUsuarioActions.replaceInformacionGeneralDeUsuario(
            {
              creditos: response.creditosRestantes,
              estadoDeCuenta: response.estadoDeCuenta,
              empresas: response.empresas,
            }
          )
        );
        setEmpresasCreditosArray([]);
        setDisableCreditosATransferir(false);
        sortingTableRef.current && sortingTableRef.current.resetTable();
      }
    }
  };

  const onSelectEmpresa = (empresas: any[]) => {
    empresas = empresas.map((empresa: any) => {
      return empresa.meses ? { ...empresa } : { ...empresa, meses: 1 };
    });
    setEmpresasCreditosArray(empresas);
  };

  const onValueQuantity = (count: any, empresa: { id: any }) => {
    const empresas = empresasCreditosArray.map((empresaInner: any) => {
      return empresa.id === empresaInner.id
        ? { ...empresaInner, meses: count }
        : { ...empresaInner };
    });
    setEmpresasCreditosArray(empresas);
  };

  return (
    <PageContainer>
      <NavBar>
        <ButtonBack onClick={() => volverAControlDeEmpresas()}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </ButtonBack>
        <TextLabelNav>
          {informacionGeneralDeUsuario.creditos} creditos
        </TextLabelNav>
      </NavBar>
      <PageContainerContent>
        <DivTitulos>
          <DivTitulosTitulo>CONTROL DE CUENTA</DivTitulosTitulo>
          <DivTitulosSubTitulo>MEMBRECIA</DivTitulosSubTitulo>
        </DivTitulos>

        <div ref={alertRef} style={{margin: '0px 35px'}}>
          <Warning
            type={"Successful"}
            title={"Exito!"}
            warningSubject={successfulSubject}
          />
          <Warning
            type={"Alert"}
            title={"Warning"}
            warningSubject={alertSubject}
          />
        </div>

        <div style={{ margin: "0px 20px" }}>
          <CreditosH>
            <TextLabelC>
              Si deseas eliminar tu cuenta de click aqui:
              <HyperlinkSpan
                onClick={() => setConfirmationDeleteCuentaModal(true)}
              >
                Eliminar cuenta
              </HyperlinkSpan>
            </TextLabelC>
            <label>Conectado como: {informacionGeneralDeUsuario.usuario}</label>
          </CreditosH>
        </div>

        <ContenedorConCon>
          <ContenedorCon>
            <Contenedor>
              <ContenedorInformacionDeFacturacion>
                <TextLabelTitle>DATOS DE FACTURACION</TextLabelTitle>

                <ChevronTabIcon
                  tabOpen={tabOpenDatosDeFacturacion}
                  toggleTab={() =>
                    setTabOpenDatosDeFacturacion(!tabOpenDatosDeFacturacion)
                  }
                />
                {(tabOpenDatosDeFacturacion || !smallViewPort) && (
                  <div>
                    <TextLabel>
                      RFC<Obligatorio>*</Obligatorio>
                    </TextLabel>
                    <InputGrande
                      style={{ textTransform: "uppercase" }}
                      type="text"
                      value={rfc}
                      onChange={(e) => setRFC(e.target.value)}
                      onBlur={() => setRfcIsTouched(true)}
                    />
                    {rfcIsInvalid && <Alert>Este campo es requerido</Alert>}
                    {rfcLengthInvalid && (
                      <Alert>La longitud del rfc no es valida</Alert>
                    )}

                    <TextLabel>Nombre</TextLabel>
                    <InputGrande
                      type="text"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                    <DomicilioFiscal
                      onValuesChange={(values: any) =>
                        setDomicilioFiscalForm(values)
                      }
                      formValues={domicilioFiscalForm}
                    />

                    <TextLabel>Correo Electrónico</TextLabel>
                    <InputGrande
                      value={correoElectronico}
                      type="email"
                      autoComplete="on"
                      onChange={(e: any) =>
                        omit_special_char_email(e.target.value)
                      }
                      onBlur={() => setIsCorreoElectronicoTouched(true)}
                      style={
                        !isCorreoElectronicoValid && correoElectronico
                          ? { borderBottom: "2px solid #db282899" }
                          : {}
                      }
                    />
                    {!isCorreoElectronicoValid && correoElectronico && (
                      <Alert>Este correo no es un correo válido</Alert>
                    )}

                    <TextLabel>Referencias</TextLabel>
                    <MarginEndDDF
                      type="text"
                      value={referencias}
                      onChange={(e) => setReferencias(e.target.value)}
                    />
                  </div>
                )}
              </ContenedorInformacionDeFacturacion>

              {smallViewPort && (
                <AplasamientoVigDiv>
                  <TextLabelTitle>APLASAMIENTO DE VIGENCIA</TextLabelTitle>

                  <ChevronTabIcon
                    tabOpen={tabOpenAplasamientoDeVigencias}
                    toggleTab={() =>
                      setTabOpenAplasamientoDeVigencias(
                        !tabOpenAplasamientoDeVigencias
                      )
                    }
                  />
                </AplasamientoVigDiv>
              )}

              {(tabOpenAplasamientoDeVigencias || !smallViewPort) && (
                <>
                  <FirstRowDiv>
                    <ContenedorDeEmpresas>
                      <TextLabelTitle>
                        EMPRESAS DE LAS QUE SE ES PROPIETARIO
                      </TextLabelTitle>

                      <SortingTable
                        ref={sortingTableRef}
                        tableHeight={"337px"}
                        iSortDescending={true}
                        onSelectElement={(empresas: any) =>
                          onSelectEmpresa(empresas)
                        }
                        rowsArray={empresasPropietarioArray}
                        columnsArray={columnsArray}
                      />
                    </ContenedorDeEmpresas>
                    <CarritoSmall>
                      <TextLabelTitle>
                        APLASAMIENTO DE VIGENCIA POR MESES
                      </TextLabelTitle>
                      <PedidoDiv>
                        {empresasCreditosArray.map(
                          (empresa: any, index: number) => (
                            <PedidoElemento>
                              <CarritoText>{empresa.nombre}</CarritoText>
                              <DivTimes>
                                <QuantityManager
                                  Minimum={1}
                                  quantity={empresa.meses}
                                  Maximum={9999}
                                  onValue={(value: number) =>
                                    onValueQuantity(value, empresa)
                                  }
                                />
                              </DivTimes>
                            </PedidoElemento>
                          )
                        )}
                      </PedidoDiv>
                      <CarritoTotalDiv>
                        <label>Costo de aplasamiento:</label>
                        <label>{totalCreditos} creditos</label>
                      </CarritoTotalDiv>
                      <div style={{ marginTop: "10px" }}>
                        <Button
                          onClick={() => aplicarCargo()}
                          disabled={
                            totalCreditos === 0 ||
                            totalCreditos >
                              informacionGeneralDeUsuario.creditos ||
                            disableStripe
                          }
                        >
                          APLICAR CARGO
                        </Button>
                      </div>
                    </CarritoSmall>
                  </FirstRowDiv>
                </>
              )}

              {smallViewPort && (
                <AplasamientoVigDiv>
                  <TextLabelTitle>COMPRAR CREDITOS</TextLabelTitle>

                  <ChevronTabIcon
                    tabOpen={tabOpenComprarCreditos}
                    toggleTab={() =>
                      setTabOpenComprarCreditos(!tabOpenComprarCreditos)
                    }
                  />
                </AplasamientoVigDiv>
              )}

              {(tabOpenComprarCreditos || !smallViewPort) && (
                <SecondRowDiv>
                  <ContenedorPlanes>
                    <TextLabelTitle>PRECIO POR CREDITO</TextLabelTitle>
                    {precioPorCreditosArray.map((precio) => (
                      <CartaCreditos key={uuidv4()}>
                        <CreditosY>
                          <CreditsNumber>{precio.CreditsNumber}</CreditsNumber>
                          <CreditsText>{precio.CreditsText}</CreditsText>
                        </CreditosY>
                        <DivInfoPaquetes>
                          <label>Precio:</label>
                          <label>
                            $<span>{precio.Price}</span> MX
                          </label>
                        </DivInfoPaquetes>
                      </CartaCreditos>
                    ))}
                  </ContenedorPlanes>

                  <div>
                    <Carrito>
                      <TextLabelTitle>COMPRAR CREDITOS</TextLabelTitle>
                      <DivCreditosP>
                        <QuantityManager
                          Minimum={0}
                          quantity={creditosAComprar}
                          Maximum={9999}
                          onValue={(value: number) =>
                            onValueQuantityCreditosAComprar(value)
                          }
                        />
                      </DivCreditosP>
                      <CarritoTotalDiv>
                        <label>Total</label>
                        <label>{totalCreditosEnPesos}</label>
                      </CarritoTotalDiv>
                      <Stripe
                        onValue={(token: any) => setStripeToken(token)}
                        disabled={creditosAComprar === 0 || disableStripe}
                      />
                      <label>
                        <Checkbox
                          checked={factura}
                          handleClick={(value: boolean) => setFactura(value)}
                        />
                        <FlexInlineMarginTextLabel>
                          Solicitar factura (generalmente demora entre 2 y 3
                          días)
                        </FlexInlineMarginTextLabel>
                      </label>
                    </Carrito>

                    {1500 > viewPortWidth && (
                      <ContenedorTransferenciaDeCreditos>
                        <TextLabelTitle>
                          TRANSFERENCIA DE CREDITOS
                        </TextLabelTitle>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <TextLabel>Usuario</TextLabel>
                            <FilterInput
                              onValue={(value) => onValueUsuarios(value)}
                              onChange={(e) => getUsuariosList(e)}
                              options={arrayUsuarios}
                              option={usuarioSelecionado}
                              invalid={invalidUsuariosArray}
                            />
                            <TextLabel>Cantidad de Créditos</TextLabel>
                            <QuantityManager
                              Minimum={5}
                              Maximum={9999}
                              onValue={(value: number) =>
                                setCreditosATransferir(value)
                              }
                            />
                          </div>
                          <div style={{ margin: "0px 20px" }}>
                            <Button
                              onClick={() => transferirCreditos()}
                              disabled={
                                usuarioSelecionado === "" ||
                                5 > creditosATransferir ||
                                creditosATransferir >
                                  informacionGeneralDeUsuario.creditos ||
                                disableCreditosATransferir
                              }
                            >
                              TRANSFERIR CREDITOS
                            </Button>
                          </div>
                        </div>
                      </ContenedorTransferenciaDeCreditos>
                    )}
                  </div>
                </SecondRowDiv>
              )}
            </Contenedor>

            {viewPortWidth > 1500 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextLabelTitle>TRANSFERENCIA DE CREDITOS</TextLabelTitle>
                <ContenedorTransferenciaDeCreditos>
                  <div style={{ width: "100%" }}>
                    <TextLabel>usuario</TextLabel>
                    <FilterInput
                      onValue={(value) => onValueUsuarios(value)}
                      onChange={(e) => getUsuariosList(e)}
                      options={arrayUsuarios}
                      option={usuarioSelecionado}
                      invalid={invalidUsuariosArray}
                    />
                    <TextLabel>Cantidad de Créditos</TextLabel>
                    <QuantityManager
                      Minimum={5}
                      Maximum={9999}
                      onValue={(value: number) => setCreditosATransferir(value)}
                    />
                  </div>
                  <div style={{ margin: "0px 20px" }}>
                    <Button
                      onClick={() => transferirCreditos()}
                      disabled={
                        usuarioSelecionado === "" ||
                        5 > creditosATransferir ||
                        creditosATransferir >
                          informacionGeneralDeUsuario.creditos ||
                        disableCreditosATransferir
                      }
                    >
                      TRANSFERIR CREDITOS
                    </Button>
                  </div>
                </ContenedorTransferenciaDeCreditos>
              </div>
            )}
          </ContenedorCon>

          <BitacoraDiv>
            <TextLabelTitle>BITACORA</TextLabelTitle>
            <SearchDropdown
              onValue={onValueSearchDropDown}
              options={[
                { label: "Fecha", valueLabel: "Fecha", type: "Date" },
                {
                  label: "Movimiento",
                  valueLabel: "Movimiento",
                  type: "String",
                },
                { label: "Tipo", valueLabel: "Tipo", type: "String" },
              ]}
            />
            <SortingTable
              tableHeight={viewPortWidth > 2800 ? "1000px" : "525px"}
              iSortDescending={false}
              multiselect={false}
              rowsArray={logCopyArray}
              hideCheckbox={true}
              columnsArray={columnsArrayLog}
            />
          </BitacoraDiv>
        </ContenedorConCon>
      </PageContainerContent>

      <FootersBlack>
        <CopyrightBlack>
          Copyright © {new Date().getFullYear()} SIP. Todos los derechos
          reservados.
        </CopyrightBlack>
        <PrivacyNoticeBlack onClick={() => setTermsAndConditionsModal(true)}>
          Aviso de Privacidad | FAQ’s
        </PrivacyNoticeBlack>
      </FootersBlack>
      <Modal show={termsAndConditionsModal}>
        <TermsAndConditions
          handleClose={() => setTermsAndConditionsModal(false)}
        />
      </Modal>
      <ConfirmationModal
        title={"¿Estas seguro que deseas eliminar la empresa?"}
        text={
          "La siguiente operacion es irreversible, se eliminaran todos los datos de la empresa y no podran ser recuperados."
        }
        show={confirmationDeleteCuentaModal}
        redButton={true}
        onCancel={() => setConfirmationDeleteCuentaModal(false)}
        onAccept={() => removeAccount()}
      />
    </PageContainer>
  );
}
