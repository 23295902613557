import { forwardRef, useImperativeHandle } from "react";
import { BackgroundModal, ModalBody, ModalBodyTop } from "./ModalStyled.js";
import useViewPortSize from "../../Hooks/useViewPortSize.js";

interface Props {
  children: any;
  show: boolean;
}

const Modal = forwardRef(
  (
    {
      show,
      children,
    }: Props,
    Ref
  ) => {
    const {viewPortWidth} = useViewPortSize();
    const SmallViewPortValue = 1000;
    useImperativeHandle(Ref, () => ({}));

    return (
      show ? (
        <BackgroundModal style={{width: '100%', display: show ? "block" : "none" }}>
          <ModalBodyTop >
            <ModalBody style={viewPortWidth >= SmallViewPortValue ? {margin: 'auto', width: 'fit-content'} : {margin: '20px', width: 'calc(100% - 80px)'}}>
              {children}
            </ModalBody>
          </ModalBodyTop>
        </BackgroundModal>
      ) : null
    );
  }
);

export default Modal;
