import Paths from "../../paths.tsx";
import { Perfil, Blog , InformacionDeLaEmpresaOPatron , EsquemaDeNomina , RegistroEstatalYDelIMSS , HorarioYControlDeAsistencia , PrestacionesGenerales  , Parametrosdeoperacion , ParametrosDeOperacion , RegistroDeDepartamentos , RegistroDePuestos , ControlDePersonal  , NuevoEmpleado , ConfiguracionDeConceptos , ControldeAsistencia , ConfiguracionDeCriterios } from "../index";;

export const pageToRenderLiteral = {
  "": () => <Perfil />,
  [Paths.Perfil]: () => <Perfil />,
  [Paths.Blog]: () => <Blog />,
  [Paths.InformacionDeLaEmpresaOPatron]: () => <InformacionDeLaEmpresaOPatron />,
  [Paths.EsquemaDeNomina]: () => <EsquemaDeNomina />,
  [Paths.RegistroEstatalYDelIMSS]: () => <RegistroEstatalYDelIMSS />,
  [Paths.HorarioYControlDeAsistencia]: () => <HorarioYControlDeAsistencia />,
  [Paths.PrestacionesGenerales]: () => <PrestacionesGenerales />,
  [Paths.ParametrosDeOperacion]: () => <ParametrosDeOperacion />,
  [Paths.RegistroDeDepartamentos]: () => <RegistroDeDepartamentos />,
  [Paths.RegistroDePuestos]: () => <RegistroDePuestos />,
  [Paths.ControlDePersonal]: () => <ControlDePersonal />,
  [Paths.NuevoEmpleado]: () => <NuevoEmpleado />,
  [Paths.ConfiguracionDeConceptos]: () => <ConfiguracionDeConceptos />,
  [Paths.ControldeAsistencia]: () => <ControldeAsistencia />,
  [Paths.ConfiguracionDeCriterios]: () => <ConfiguracionDeCriterios />,
};