const replaceCurrentIMG = (newCurrentIMG) => {
    return {
      type: "REPLACECURRENTIMG",
      newCurrentIMG,
    };
  };
  
  export default {
    replaceCurrentIMG
  };
  