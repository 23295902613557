import informacionGeneralReducer from "./InformacionGeneralReducer";
import informacionGeneralDeUsuarioReducer from "./InformacionGeneralDeUsuarioReducer";
import empresaReducer from "./EmpresaReducer";
import currentEmpresaReducer from "./CurrentEmpresaReducer";
import currentIMGReducer from "./CurrentIMGReducer";
import currentTrabajadorReducer from "./CurrentTrabajadorReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
    informacionGeneral: informacionGeneralReducer,
    informacionGeneralDeUsuario: informacionGeneralDeUsuarioReducer,
    empresa: empresaReducer,
    currentEmpresa: currentEmpresaReducer,
    currentIMG: currentIMGReducer,
    currentTrabajador: currentTrabajadorReducer,
});

export default rootReducer;
